import { SpeechBubbleComponent } from "./components/speech-bubble/speech-bubble.component";
import { Component, NgZone, ChangeDetectorRef, ViewChild } from "@angular/core";

import { ModalController, Platform } from "@ionic/angular";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { PlayTTSService } from "./services/play-tts.service";
import { AccountService } from "./services/account.service";
import { HttpClient } from "@angular/common/http";
import { BaseService } from "./services/base.service";
import { MediaObject } from "@awesome-cordova-plugins/media/ngx";
import { Router, NavigationEnd } from "@angular/router";
import { environment } from "../environments/environment";
import { GlobalService, MenuEvents } from "./services/global.service";
import { Student } from "./models/student";
import { Classroom } from "./models/classroom";
import { CabriDataService } from "./services/cabri-data.service";
import { LrsService } from "./services/lrs.service";
import { SafariService } from "src/app/services/safari.service";
import { ConnectionStatus, NetworkService } from "./services/network.service";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";
import { LrsUtils } from "./models/lrs/lrsUtils";
import { RgpdGuard } from "./services/rgpd.guard";
import { StartingService } from "./services/starting.service";
import { CabriLoader } from "./models/cabri-loader";
import { LmsService } from "./services/lms.service";
import { LocalStorageService } from "./services/local-storage-service";
import { ConnectionStatusUpdaterService } from "./services/connection-status-updater.service";
import { lastValueFrom, ReplaySubject, timeout } from "rxjs";
import { ClassService } from "./services/class.service";
import { AppUtils } from "./app-utils";
import { AnalyticService } from "./services/analytic.service";
import { OseStatsService } from "./services/ose-stats.service";

declare var window: {
	location: any;
	console: any;
	postal: any;
	store: any;
	Globals: { Debug: any };
	weareready: any;
	params: any;
	document: any;
	innerHeight: any;
	innerWidth: any;
	open: any;
	cordova: any;
	plugins: any;
	devicePixelRatio: any;
	AndroidFullScreen: any;
	onpopstate: any;
	history: any;
};

declare var navigator: {
	splashscreen: any;
};

@Component({
	selector: "app-root",
	templateUrl: "app.component.html",
	providers: [SafariService, InAppBrowser]
})
export class AppComponent extends BaseService {
	showSplash = true;
	appPages = new Array();
	progress = 0;
	appPagesCopy = [];
	audioFile: MediaObject;
	dataMenu: any;
	media: any;
	file: any;
	tempProgress = 0;	
	// safari:boolean;
	// tslint:disable-next-line: variable-name
	public _blueLightFilter = false;
	public window;
	public environment: { production: boolean; activityVersion: number; kidaia: boolean; ose: boolean };

	constructor(
		private platform: Platform,
		public networkService: NetworkService,
		public connectedStatusUpdater: ConnectionStatusUpdaterService,
		private statusBar: StatusBar,
		public ttsService: PlayTTSService,
		private accountService: AccountService,
		public http: HttpClient,
		public router: Router,
		public globalService: GlobalService,
		public rgpdGuard: RgpdGuard,
		public cabriService: CabriDataService,
		public lrsService: LrsService,
		public safariService: SafariService,
		private inAppBrowser: InAppBrowser,
		public modalController: ModalController,
		public classService: ClassService,
		public startingService: StartingService,
		public _ngZone: NgZone,
		public lmsService: LmsService,
		public changeD: ChangeDetectorRef,
		public localStorageService: LocalStorageService,
		public analyticService: AnalyticService,
		public oseStats:OseStatsService
	) {
		super();
		this.environment = environment;
		this.initializeApp();

		
		// SET DEBUG MODE
		this.setDebugMode(!environment.production);

		// console.log("environment.production : " + environment.production);

		// ANALYTICS
		this.globalService.initObserveScreenOrientationSub();
		// settings observable init before its opening
		this.globalService.menuStorySettingOpenedEvent = new ReplaySubject(1);
		this.globalService.cdImageViewer = this.changeD;
		this.router.events.subscribe(navEvent => {
			this.analyticSendNavigationInfo(navEvent);
		});
	}

	analyticSendNavigationInfo<T>(navigationEvent: T) {
		const activityPage = ["/calculmental", "/jeudufuret", "/jeudekim", "/solides", "/heure", "/jeu-juste-point", "/galaxiedescalculs"];
		const ignorePage = ["/supervision", "/test-stt", "/test-tts", "/ocr", "/traces", "/test-connexion-bubble", "/test-bee", "/ar"];
		if (navigationEvent instanceof NavigationEnd) {
			if (!ignorePage.includes(navigationEvent.url)) {
				let response = {};
				response["pageUrl"] = navigationEvent.urlAfterRedirects;
				if (activityPage.includes(navigationEvent.url)) {
					response["activityName"] = this.cabriService.currentActivity?.name;
					if (navigationEvent.url === "/calculmental") {
						response["pageUrl"] = response["pageUrl"] + " " + this.cabriService.currentActivity?.name;
					}
					response["exerciceName"] = this.cabriService.currentExercice?.name;
					response["exerciceId"] = this.cabriService.currentExercice?.id;
					response["exerciceModeReponse"] = this.cabriService.currentExercice?.responseMode;
					if (this.cabriService.currentActivity?.story) {
						response["storyPosition"] = this.cabriService.currentActivity?.story?.position;
						response["storyChapterId"] = this.cabriService.currentActivity?.story?.chapter?.id;
						// exo already do bilan inside history
						if (this.cabriService.currentActivity?.story.exo) {
							response["storyChapterBilan"] = this.cabriService.currentActivity?.story.exo.bilan ? 1 : 0;
							response["storyChapterReplayExo"] = 1;
						} else {
							response["storyChapterBilan"] = this.cabriService.currentActivity?.story?.bilan ? 1 : 0;
						}
						if (this.cabriService.currentActivity?.story?.bilan && this.cabriService.currentActivity?.story["journeyId"]) {
							response["storyChapterBilanId"] = this.cabriService.currentActivity?.story["journeyId"];
						}
					}
				}
				if (navigationEvent.url == "/map") {
					if (this.accountService.user?.currentChapter) {
						response["chapter"] = this.accountService.user?.currentChapter.name;
						response["chapterId"] = this.accountService.user?.currentChapter.id;
					}
				}
				//console.warn("anaNavigate",response);
				this.analyticService.sendAnalytics("Navigation", response);
			}
		}
	}

	get ConnectionStatus() {
		return ConnectionStatus;
	}

	get NetworkService() {
		return NetworkService;
	}

	ionViewDidLoad() {}

	ionViewDidEnter() {}

	initializeApp() {
		// console.error("showSplash 2");
		this.platform.ready().then(async () => {
			//force Android in fullscreen Mode no virtual button and status bar
			if (this.globalService.isCordova && this.globalService.isAndroid) {
				window.AndroidFullScreen.immersiveMode();
			}
			if (environment.ose) {
				this.showSplash = false;
				this.globalService.setGlobalLoading(true);
			}
			if (this.globalService.isKidaia) {
				this.globalService.adobeAnimPreload = true;
			}
			if (this.platform.is("mobile") || (this.platform.is("tablet") && !this.platform.is("desktop"))) {
				// TODO enable only when needed?
				if (!environment.production) {
					console.error("devicePixelRatio @ init = ", devicePixelRatio);
				}
				this.globalService.devicePixelRatioForEngineScaling = window.devicePixelRatio;
				// window.devicePixelRatio = 1;
			}
			if (this.platform.is("cordova")) {
				navigator.splashscreen.hide();
				// this.statusBar.styleDefault();
				this.statusBar.overlaysWebView(false);
				if (this.platform.is("android")) {
					// setup auto hide:
					// this.navigationBar.setUp(true);
					// this.navigationBar.hideNavigationBar();
				}
				this.statusBar.hide();
				this.statusBar.backgroundColorByHexString("#000");
			}
			this.checkIfSafari();

			if (this.globalService.isIos || this.globalService.isSafari) {
				this.globalService.lowPerformanceMode = true;
			}
			this.accountService.setLrsService(this.lrsService);
			this.oseStats.setLRSJourney(this.lrsService)
			lastValueFrom(this.accountService.isUserLoaded).then(userConnected => {
				if (userConnected) {
					this.lmsService.initGamification(this.lrsService, this.lmsService, this.cabriService, this.accountService,this.localStorageService);
				}
			});
			this.ttsService.globalService = this.globalService;
			this.preloadFontFace();
			this.loading();
		});
	}

	async preloadFontFace() {
		return new Promise<void>(async resolve => {
			try {
				// tslint:disable-next-line: variable-name
				const FontFaceObserver = require("fontfaceobserver");
				await Promise.all([
					new FontFaceObserver("Quicksand-Bold", { weight: 600 }).load(null, 10000),
					new FontFaceObserver("Quicksand-Bold", { weight: 700 }).load(null, 10000),
					new FontFaceObserver("Quicksand-Bold", { weight: 500 }).load(null, 10000),
					new FontFaceObserver("Quicksand-Bold", { weight: 400 }).load(null, 10000),
					new FontFaceObserver("Quicksand-Bold", { weight: 300 }).load(null, 10000),
					new FontFaceObserver("GrandHotel-Regular", { weight: 400 }).load(null, 10000),
				]);
			} catch (e) {
				console.error("font face timeout", e);
			}
			resolve();
		});
	}

	audioPermissionsIOS() {
		return new Promise(resolve => {
			window.plugins.audioPermissions.check(allowed => {
				if (allowed) {
					console.log("You're already allowed to record audio.");
					resolve(true);
					return;
				}

				console.warn("You can not record audio.");
				window.plugins.audioPermissions.request(success => {
					if (success) {
						console.log("You're now allowed to record audio.");
						resolve(true);
					} else {
						console.warn("You did not allow us to record audio.");
						resolve(false);
					}
				});
			});
		});
	}

	checkIfSafari() {
		this.globalService.isSafari = this.safariService.onSafari("Safari") ? true : false;
	}

	async loading() {
		try {
			this.cabriService.cabriLoader = new CabriLoader(this.cabriService, this.platform, this, this.globalService);
			await this.cabriService.cabriLoader.loadBabylonJS();
			this.appReady();
		} catch (e) {
			throw e;
		}
	}

	async appReady() {
		// // check URL params
		// window.params = new URLSearchParams(window.location.search);
		const urlParams = window.params ? Array.from(window.params) : [];
		const urlParamsObj = AppUtils.objectify(urlParams);
		// console.log("urlParamsObj ", urlParamsObj);


		if (localStorage.getItem("rgpd_accepted") !== null) {

			if (typeof urlParamsObj.codemaison !== "undefined") {
				console.log("codemaison", urlParamsObj.codemaison);
				if(urlParamsObj.codemaison === ""){
					// direct link to code maison
					this.globalService.isCodeMaisonDefault = true;
					this.router.navigateByUrl("/class-code-entry");
				} else {
					const codeclasse = await this.accountService.autologCodeMaison(urlParamsObj.codemaison);
					this.lrsService.autenticateEnterCodeClass(codeclasse);
					this.router.navigate(["/activity-participants"]);
				}
			} else if (typeof urlParamsObj.premium !== "undefined") {
				// direct link to code maison
				this.accountService.connectDemoAccount();
				this.router.navigateByUrl("/activity-participants");
			} else {
				// isTrala / isAren / isMathador...
				this.globalService.checkIframeIntegration(urlParamsObj, this.classService);

				// initialize user Trala & mathador
				if (typeof urlParamsObj.uid !== "undefined") {
					if (typeof urlParamsObj.codeclasse === "undefined") {
						urlParamsObj.codeclasse = AppUtils.createGuid();
					}
					let prenom;
					if(urlParamsObj.prenom){
						prenom = urlParamsObj.prenom.split("_")[0];
					} else {
						prenom = "petit astronaute";
					}
					const newStudent = new Student(
						urlParamsObj.uid,
						new Classroom(urlParamsObj.codeclasse, urlParamsObj.codeclasse, null),
						prenom,
						true,
						false,
						1
					);
					this.accountService.updateUserData(urlParamsObj.uid);
					this.accountService.team = [newStudent];
					localStorage.setItem("team", JSON.stringify([newStudent.id]));
				} else if (urlParamsObj.navigate) {
					// custom reload to replace location.reload()
					this.router.navigateByUrl(urlParamsObj.navigate);
				} else if (this.globalService.isBeneylu) {
					window.onpopstate = function($event){
						AppUtils.preventBackButton();
					}
					// create false user for beneylu
					if (this.accountService.team.length === 0) {
						const randomKey = AppUtils.createGuid();
						const newStudent = new Student(
							randomKey,
							new Classroom(randomKey, randomKey, null),
							urlParamsObj.prenom ? urlParamsObj.prenom : "petit astronaute",
							true,
							false,
							1
						);
						this.accountService.team.push(newStudent);
						this.lrsService.autenticateEnterCodeClass(randomKey);
					}
				}

				// trala/aren code classe for LRS
				if (typeof urlParamsObj.codeclasse !== "undefined") {
					this.lrsService.autenticateEnterCodeClass(urlParamsObj.codeclasse);
				}

				if (this.globalService.isTrala) {
					// trala assignation id for LRS
					if (typeof urlParamsObj.assignation_id !== "undefined") {
						LrsUtils.assignationId = urlParamsObj.assignation_id;
					}

					// auth token for LMS
					if (typeof urlParamsObj.token !== "undefined") {
						// TODO: manque la date d'expiration du token valeur fictive
						this.classService.setSession({ access_token: urlParamsObj.token, expires_in: "86371" });
					}
				}

				if (this.globalService.isAren) {
					// redirect to exercise or aventure
					if (urlParamsObj.aid !== "undefined") {
						await this.lmsService.getAllClassJourneys(this.accountService.classroom);
						await this.cabriService.getAllActivitiesAsync();
						const journey = this.lmsService.getJourneyById(Number(urlParamsObj.aid));
						await this.lmsService.launchJourney(journey, this.cabriService, this.globalService);
					} else {
						this.router.navigateByUrl("/accueil");
					}
				} else if (this.globalService.isBeneylu) {
					// redirect to exercise or entrainement
					if (typeof urlParamsObj.aid !== "undefined") {
						await this.lmsService.getAllClassJourneys(this.accountService.classroom);
						await this.cabriService.getAllActivitiesAsync();

						await this.cabriService.setActivityId(urlParamsObj.aid);
						this.router.navigateByUrl(this.cabriService.currentActivity.routingPath);
					} else {
						this.router.navigateByUrl("/gabarits");
					}
				} else if (this.globalService.isTrala) {
					// exercice/activity id for direct access
					if (typeof urlParamsObj.aid !== "undefined" && typeof urlParamsObj.assignation === "undefined") {
						this.cabriService.getAllActivitiesAsync().then(async () => {
							await this.cabriService.setActivityId(urlParamsObj.aid);
							// redirect to cabri
							this.cabriService.toCabri = true;
							// @TODO change URL based on id (calcul mental / furet / kim, etc...)
							this.router.navigateByUrl(this.cabriService.currentActivity.routingPath);
						});
					} else {
						if (urlParamsObj.assignation_id && typeof urlParamsObj.assignation_id !== "undefined") {
							this.rgpdGuard.checkAndLaunchTralaAssignation(urlParamsObj);
						} else {
							this.router.navigateByUrl("/accueil");
						}
					}
				} else if (this.globalService.isMathador) {
					// redirect to exercise or entrainement
					if (typeof urlParamsObj.aid !== "undefined") {
						await this.lmsService.getAllClassJourneys(this.accountService.classroom);
						await this.cabriService.getAllActivitiesAsync();

						await this.cabriService.setActivityId(urlParamsObj.aid);
						this.router.navigateByUrl(this.cabriService.currentActivity.routingPath);
					} else {
						this.router.navigateByUrl("/gabarits");
					}
				}
			}
		}

		if (!this.globalService.lowPerformanceMode && !this.environment.ose) {
			this.globalService.loadOcrModel();
		}
		if (environment.ose) {
			await lastValueFrom(this.accountService.isUserLoaded);
		}

		// no preload (because of bug if preloaded ?)		
		this.globalService.adobeAnimPreload = false;
		
		// Hide splash screen
		this.showSplash = false;
	}

	setDebugMode(debug: boolean) {
		if (!debug) {
			if (!window.console) {
				window.console = {};
			}
			const methods = ["log", "debug", "warn", "info"];
			// tslint:disable-next-line: prefer-for-of
			for (let i = 0; i < methods.length; i++) {
				console[methods[i]] = () => {};
			}
		} else {
			// if (!environment.production){
			// 	if (!window.console) {
			// 		window.console = {};
			// 	}
			// 	const methods = ["log", "debug", "warn", "info"];
			// 	// tslint:disable-next-line: prefer-for-of
			// 	for (let i = 0; i < methods.length; i++) {
			// 		console[methods[i]] = e => {
			// 			if (window.document.querySelector("#log-html")) {
			// 				const p = window.document.createElement("p");
			// 				p.innerHTML = e;
			// 				window.document.querySelector("#log-html").append(p);
			// 			}
			// 		};
			// 	}
			// }
		}
	}

	arrayOne(n: number): any[] {
		return Array(n);
	}

	ionViewWilLeave() {
		this.ttsService.killSpeech();
		console.log("tts killed on app leave");
	}

	isSet(obj: any) {
		return typeof obj !== "undefined";
	}

	openLMS() {
		this.inAppBrowser.create("https://lms.mathia.education", "_blank", "location=false");
	}
	switchSettingsParams($mirror: boolean) {
		this.globalService.switchSettingParam.next($mirror);
	}
	launchStoryActivityEmitter() {
		this.globalService.launchStoryActivity.emit();
	}

}
