<div class="imagePuzzle" *ngIf="puzzle">


	<div id="headerContainer" *ngIf="!solved" #headerContainer>
		<app-ose-difficulty-selector *ngIf="puzzle && !solved" (setDifficulty)="difficultyChanged($event)"
			[difficultySelected]="difficultySelected"></app-ose-difficulty-selector>
		<div class="header-title">
			<div class="title-wrapper">
				<h1 class="sectionName">{{consigne}}</h1>
				<ion-button class="skip-button" (click)="nextGame()"
					*ngIf="!environment.production || accountService.team[0].preview" shape="round" i18n>Passer</ion-button>
			</div>
			<div class="helpBtnWrapper" (click)="displayHelp = !displayHelp">
				<p *ngIf="!displayHelp" i18n>Voir l'image</p>
				<p *ngIf="displayHelp" i18n>Continuer à jouer</p>
			</div>
		</div>
	</div>
	<div #puzzleWrapper class="puzzle-wrapper" [ngClass]="solved ? 'completed':'unsolvedPuzzle'">
		<img #imageSrc [src]="imageUrl" [style.opacity]="displayHelp ? '1' : '0'" />
		<ul class="puzzle" [hidden]="displayHelp" cdkDropListGroup>
			<li *ngFor="let img of imagesArray" class="box" [id]="img.index" cdkDropListSortingDisabled cdkDropList
				(cdkDropListDropped)="onDrop($event)" [style.width]="boxFixSizeX+'%'" [style.height]="boxFixSizeY+'%'">
				<div cdkDrag [cdkDragData]="img" [cdkDragDisabled]="solved" [style.width]="'100%'"
					[style.height]="'100%'" [ngStyle]="{
					'background-image':'url(' + imageUrl + ') ',
					'background-repeat':'no-repeat', 
					'background-position': '' + img.xPos + ' '  + img.yPos + '',
					'background-size':''+ (gridsizeX * 100) +'% '+ (gridsizeY * 100) +'%'
				}">

				</div>
			</li>
		</ul>
	</div>
</div>
<ng-container *ngIf="!puzzle && this.cabriService.puzzles">
	<nav id="nav-wrapper">
		<ul>
			<h2>Liste des puzzles</h2>
			<mat-form-field>
				<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrer">
			</mat-form-field>
			<mat-table [dataSource]="dataSource">
				<!-- Position Column -->
				<ng-container matColumnDef="id">
					<mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
					<mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
				</ng-container>


				<!-- Image Column -->
				<ng-container matColumnDef="image">
					<mat-header-cell *matHeaderCellDef> Image </mat-header-cell>
					<mat-cell *matCellDef="let element"> <ion-img src="{{element.image}}"
							style="width:100px"></ion-img></mat-cell>
				</ng-container>

				<!-- Name Column -->
				<ng-container matColumnDef="name">
					<mat-header-cell *matHeaderCellDef> Titre </mat-header-cell>
					<mat-cell *matCellDef="let element"> {{element.title}} </mat-cell>
				</ng-container>

				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
				<mat-row *matRowDef="let row; columns: displayedColumns;" (click)="loadPuzzle(row.id)"></mat-row>
			</mat-table>
		</ul>
	</nav>
</ng-container>