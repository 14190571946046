<div id="fiche-container" [ngClass]="{'isActivity' : this.currentFiche?.isworkshop === '1'}">
    <!-- <div id="fiche-container">  -->
    <div id="small-loader" *ngIf="globalService.smallLoaderStatus">
        <img id="loaderGIF" src="/assets/babylon/loading_low_ose.gif" alt="chargement">
    </div>

    <link rel="stylesheet" id="elementor-icons-shared-0-css"
        href="https://kidaia.com/wp-content/plugins/elementor/assets/lib/font-awesome/css/fontawesome.min.css?ver=5.15.3"
        type="text/css" media="all">
    <link rel="stylesheet" id="wp-block-library-css"
        href="https://kidaia.com/wp-includes/css/dist/block-library/style.min.css" type="text/css" media="all">
    <link rel="stylesheet" id="dashicons-css" href="https://kidaia.com/wp-includes/css/dashicons.min.css?ver=6.0.1"
        type="text/css" media="all">
    <link rel="stylesheet" id="theme-kidaia-css" href="https://kidaia.com/wp-content/themes/theme_kidaia/style.css"
        type="text/css" media="all">

    <div #customStyle>

    </div>
    <div *ngIf="fiche && !environment.production" id="next-fiche">
        <!-- <button (click)="updateFiguresHeight()" >🔄</button> -->
        <button (click)="prevFiche()">prev</button><button (click)="nextFiche()">next</button> <button
            (click)="updateFiguresHeight()">🔄</button> {{fiche.id}} - {{fiche.title}}
        <a href="https://kidaia.com/wp-admin/post.php?post={{fiche.id}}&action=edit" target="_blank"
            style="color: var(--osegreen)">éditer</a>
    </div>
    <!-- <div *ngIf="(swiper?.activeIndex > 0 && fiche) " id="previous" (click)="previousPage()">← Précédent</div> -->
    <swiper #ionSlides [hidden]="!fiche && !currentFiche?.isEtape"
        [style.opacity]="globalService.smallLoaderStatus ? 0 : 1" class="fiche-{{fiche?fiche.id:'not-loaded'}}"
        [resizeObserver]="true" (slideChangeTransitionEnd)="slideTouchTransitionEnd($event)"
        (init)="swiperInitliazed($event)" (slideChange)="onSwiperChange();" (resize)="swiperResizeEvent();">
        <ng-container *ngIf="fiche">
            <ng-container *ngFor="let page of fiche.pages">
                <ng-template swiperSlide>
                    <div class="page-container--fiche scrollbar-primary" [innerHTML]="page.innerHTML | safeHtml"
                        [style.opacity]="globalService.smallLoaderStatus ? 0 : 1"></div>
                </ng-template>
            </ng-container>
        </ng-container>


        <ng-container *ngIf="currentFiche?.isEtape">
            <ng-template swiperSlide>
                <div class="conclusion-end-seq page-container--fiche scrollbar-primary"
                    [style.opacity]="globalService.smallLoaderStatus ? 0 : 1">
                    <div class="congratulationWrapper" *ngIf="currentFiche.title === FicheEtapes.titleConclusion">
                        <h1 class="conclusion-end-seq__title">
                            <span *ngIf="totalSeqStep">Félicitations ! Tu as terminé la {{totalSeqStep}} étape de ce
                                parcours&nbsp;!</span>
                            <span *ngIf="!totalSeqStep">Félicitations ! Tu as terminé cette étape de ce parcours
                                !</span>
                        </h1>
                        <h2 *ngIf="totalExRemaining">
                            Encore {{totalExRemaining}}
                            <ng-container *ngIf="totalExRemaining === 'une'">étape</ng-container>
                            <ng-container *ngIf="totalExRemaining !== 'une'">étapes</ng-container>
                            sur {{totalExToDo}} pour terminer la séquence.
                        </h2>
                    </div>

                    <div class="congratulationWrapper" *ngIf="currentFiche.title === FicheEtapes.titleNextSeq">
                        <h1 class="conclusion-end-seq__title">Félicitations ! Tu as terminé
                            <ng-container
                                *ngIf="oseJourneyService.currentJourney.selectedCategoryType === Details.workshop">la
                                séquence atelier </ng-container>
                            <ng-container
                                *ngIf="oseJourneyService.currentJourney.selectedCategoryType === Details.learning">la
                                séquence d'apprentissage </ng-container>
                            {{' '}} du parcours {{oseJourneyService.currentJourney.title}}.
                        </h1>
                    </div>
                    <div class="conclusion-end-seq__choice">
                        <h2 class="choiceContinue" i18n>
                            Veux-tu poursuivre ?
                        </h2>
                        <div id="btnBlocksConclusion">
                            <div class="back-btn ">
                                <div id="btnQuit" class="btn" (click)="oseJourneyService.quitJourney()">
                                    <div class="icon"><img src="/assets/icon/backPlay.png"></div>
                                    <div class="text">Non - Retour à la carte</div>
                                </div>
                            </div>

                            <div id="buttonContinue" class="back-btn">
                                <div id="btnContinue" class="btn" (click)="continueActivity()">
                                    <div class="text">Oui - Direction la prochaine
                                        <span *ngIf="currentFiche.title === FicheEtapes.titleConclusion">étape</span>
                                        <span *ngIf="currentFiche.title === FicheEtapes.titleNextSeq">séquence</span>
                                    </div>
                                    <div class="icon"><img src="/assets/icon/nextPlay.png"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </ng-template>
        </ng-container>

    </swiper>
    <div class="mascotte" *ngIf="displayMascotte">
        <div class="mascotte__img"></div>
    </div>
    <div id="oseCloseButton" (click)="oseJourneyService.modalQuitJourney()"
        *ngIf="oseJourneyService.isPlaying && !standAlone">
        <div id="oseCloseButton-global__img"></div>
    </div>
    <div id="oseCloseButton" (click)="end?.emit(true)" *ngIf=" standAlone">
        <div id="oseCloseButton-global__img"></div>
    </div>
    <div class="oseNextButton" (click)="nextPage($event)" *ngIf="fiche && !feedback"
        [ngClass]="{'pulsingBtnNext' : animateNextButton && !vimeoIsPlaying}">
        <img src="/assets/icon/play-ose.svg" />
    </div>
    <div class="osePrevButton" (click)="prevPage($event)"
        *ngIf="( (!standAlone && fiche && !feedback && !(oseJourneyService?.currentJourney?.currentExerciseIndex === 0)) || this.swiper?.activeIndex > 0)">
        <img src="/assets/icon/play-ose.svg" />
    </div>
    <div class="feedback-skip-btn" (click)="nextPage($event)" *ngIf="fiche && feedback && !nextButtonClicked"
        [ngClass]="{'pulsingBtnNext' : !readingFeedback}">
        <p class="feedback-skip-btn__text">{{readingFeedback ? "Passer" : "Continuer"}}</p>
    </div>
    <div id="nav-wrapper" *ngIf="fiches && !fiche">
        <nav>
            <ul>
                <mat-form-field>
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrer">
                </mat-form-field>
                <mat-table [dataSource]="dataSource">
                    <!-- Position Column -->
                    <ng-container matColumnDef="id">
                        <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="type">
                        <mat-header-cell *matHeaderCellDef> TYPE </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <ion-icon *ngIf="element.hasvideo === '1'" name="film-outline" size="large"></ion-icon>
                            <ion-icon *ngIf="element.isworkshop === '1'" name="pencil-outline" size="large"></ion-icon>
                            <ion-icon *ngIf="element.isworkshop !== '1' && element.hasvideo !== '1'"
                                name="library-outline" size="large"></ion-icon>
                        </mat-cell>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef> Titre Wordpress </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.title}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="titre">
                        <mat-header-cell *matHeaderCellDef> Titre fiche </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <ng-container *ngIf="element.html_titles && element.html_titles.length > 0">
                                {{element.html_titles.join(", ")}}
                            </ng-container> 
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="loadFiche(row.id)"></mat-row>
                </mat-table>
            </ul>
        </nav>
    </div>
</div>