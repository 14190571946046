<ion-content id="territoire" scrollY="false">
  <app-toolbar-with-buttons [opacity]="globalService.isKidaia" [studentsList]="accountService.allStudents">
  </app-toolbar-with-buttons>
  <app-assignations-controller></app-assignations-controller>
  <div id="small-loader">
    <img id="loaderGIF" src="/assets/babylon/loading_low_ose.gif" alt="chargement">
  </div>
  <div class="globalContainer"  [ngClass]="{
      'globalContainerTerritoire': displayTerritoire, 
      'globalContainerMap':!displayTerritoire,
      'fiche-displayed': selectedActivities && selectedActivities[0]?.isFiche,
      'multi-page-fiche':ficheComponent?.last?.fiche?.pages?.length > 1,
      'fiche-end':ficheComponent?.last?.swiper?.isEnd,
      'journey-displayed': isJourneyDisplayed,
      'detailsOpened': detailsOpened,
      'all-quiz-completed': allQuizCompleted}" #content>
    <div class="page-container">
      <div class="main">
        <div class="burgerContainer" *ngIf="!getRecapPOI && selectedActivities?.length === 0 && currentTerritoireFranceMapJourneys?.length > 0">
          <ion-icon name="menu-outline"  (click)="recapPOI()" class="icon-burger cursor" size="large"></ion-icon>
        </div>
        <div class="btnBackToTerritoire" (click)="goBackTerritoire()" *ngIf="!displayTerritoire">
          <ion-icon name="arrow-back-outline"></ion-icon>
        </div>

        <div class="multiMapsArrowsContainer" *ngIf="!displayTerritoire">
          <div class="arrow-wrapper arrow-left" *ngIf="swiper.activeIndex > 0" (click)="prevSlide()"></div>
          <div class="arrow-wrapper arrow-right" *ngIf="swiper.activeIndex < oseJourneyService.selectedPOIActivity?.maps.length - 1"
            (click)="nextSlide()"></div>
        </div>

        <div class="swiperContainer">
          <div class="arrow-wrapper arrow-left" (click)="prevSlide()" *ngIf="displayTerritoire"></div>
          <swiper #ionSlides [zoom]="true" [config]="sliderOptions" (slideChange)="onSwiperChange($event)">
            <!-- Maps slides -->
            <ng-container *ngIf="oseJourneyService.selectedPOIActivity?.maps && !displayTerritoire">
              <ng-container *ngFor="let map of oseJourneyService.selectedPOIActivity?.maps;let mapIndex = index">
                <ng-template swiperSlide>
                  <pinch-zoom #pinchZoom>
                    <div #mapSvgContents id="svg-wrapper"></div>
                  </pinch-zoom>
                  <ng-container [ngTemplateOutlet]="reUseCardContainer" [ngTemplateOutletContext]="{index:mapIndex}">
                  </ng-container>
                </ng-template>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="displayTerritoire && territoireMapsToDisplay?.length > 0">
              <ng-container *ngFor="let emptyElem of territoireMapsToDisplay;let poiIndex = index">
                <ng-template swiperSlide>
                  <!-- Territoire slides -->
                  <ng-container>
                    <pinch-zoom #pinchZoom> 
                      <!-- SVG -->
                      <div #territoireSvgContent id="svg-wrapper"></div>
                    </pinch-zoom>
                  </ng-container>
                  <!-- MODAL -->
                  <ng-container >
                    <ng-container [ngTemplateOutlet]="reUseCardContainer" [ngTemplateOutletContext]="{index:poiIndex}">
                    </ng-container>
                  </ng-container>
                </ng-template>
              </ng-container>
              <ng-container *ngIf="cabriService.territoires">
                <ng-container *ngFor="let territoire of cabriService.territoires">
                  <ng-template swiperSlide>
                    <div class="territoire-wrapper">
                      <pinch-zoom>
                        <img [src]="territoire.carte" />
                      </pinch-zoom>
                    </div>
                  </ng-template>
                </ng-container>
              </ng-container>
            </ng-container>
          </swiper>
          <div class="arrow-wrapper arrow-right" (click)="nextSlide()" *ngIf="displayTerritoire"></div>
        </div>
       

      </div>
      <div class="footer">
        <h1 class="territoireName">{{currentTerritoireMap?.title}}</h1>
      </div>
    </div>
  </div>

</ion-content>


  <!-- TEMPLATES  -->
 <!-- MODAL WRAPPER -->
 <ng-template #reUseCardContainer let-index="index">
  <div class="card-wrapper card-reduce" #cardActivities 
    [@modalAnim]="{value: modalState, params: {modalFinalPosition: modalFinalPosition,modalDisappearPosition:modalDisappearPosition}}"
    [@.disabled]="globalService.lowPerformanceMode"
    (@modalAnim.start)="onAnimationStart($event)" (@modalAnim.done)="onAnimationEnd($event)"
    [hidden]="!selectedActivities || selectedActivities?.length === 0" *ngIf="index === swiper?.activeIndex">
    <div class="close-btn" (click)="closeModal()">
      <div class="close-icon-global__img"></div>
    </div>
    <div class="card-wrapper-content" [ngClass]="{'isRecap':getRecapPOI}">
      <ng-container *ngIf="selectedActivities && selectedActivities[0]">
        <div class="card-container" [ngClass]="{'cursor-pointer' : !isJourneyDisplayed}"
          *ngFor="let activity of selectedActivities;let activityIndex = index"
          (click)="!isJourneyDisplayed ? goNextActivity(activity,false) : null">
          <ng-container *ngIf="selectedActivities[0]?.isFiche;else poiJourneyModal">
            <app-fiche  #ficheComponent [idFiche]="selectedActivities[0].id" class="card-container is-fiche"
              [hideMascotte]="true" [scenario]="scenarioNav"></app-fiche>
          </ng-container>
          <ng-template #poiJourneyModal>
            <ng-container *ngIf="!getRecapPOI;else isRecap">
              <ng-container [ngTemplateOutlet]="reUseJourneyModalContent"
              [ngTemplateOutletContext]="{activity,activityIndex}">
            </ng-container>
          </ng-container>
            <ng-template #isRecap>
                <div class="summerizeContainer" [hidden]="dropDownIndex && activityIndex + 1 !== dropDownIndex" >
                    <mat-expansion-panel #expansionPanels>
                      <mat-expansion-panel-header [collapsedHeight]="'100%'" [expandedHeight]="'100%'" (click)="showSelectedJourney(activity,activityIndex)">
                          <mat-panel-title class="matTitle">
                            <ion-icon [name]="activity.isTotalExCompleted ? 'checkmark-circle-outline' : 'close-circle-outline'" class="statusJourney" [ngClass]="{'isCompletedIcon':activity.isTotalExCompleted}"></ion-icon>
                            <div class="summerizeTitle"><p > {{activity.title}} </p></div> 
                          </mat-panel-title>
                      </mat-expansion-panel-header>
                    <ng-container [ngTemplateOutlet]="reUseJourneyModalContent"
                      [ngTemplateOutletContext]="{activity,activityIndex}">
                    </ng-container>
                  </mat-expansion-panel>
                </div>
            </ng-template>
          </ng-template>

        </div>
      </ng-container>
    </div>
  </div>
</ng-template>


 <!-- MODAL CONTENT-->
<ng-template #reUseJourneyModalContent let-activity="activity" let-activityIndex="activityIndex">
  <div class="card-container-title" title="{{activity.id}}" *ngIf="!getRecapPOI">
    <p class="card-container-paragraph">{{activity.title}}</p>
  </div>
  <div class="card-container-body" [hidden]="detailsOpened">
    <img [src]="activity.background">
  </div>
  <div class="card-container-footer">
    <p class="card-container-footer-paragraph">{{activity.city}}</p>
    <div class="card-container-footer-item journeyContainer"
      *ngIf="activity && isJourneyDisplayed && totalExercises">
      <div id="fiche_peda_container"
        *ngIf="oseJourneyService.currentJourney?.fiche_peda && accountService.team?.[0].preview">
        <p class="ficha_peda_text" i18n> Fiche pédagogique </p>
        <a [href]="oseJourneyService.currentJourney?.fiche_peda" target="_blank">{{
          oseJourneyService.currentJourney?.fiche_peda | linkName}}</a>
      </div>

      <div id="btn-sequence" class="cat-sequence card-container-adventure cursor-pointer"
        (click)="startResumeJourney(activity, canJourneyResume,$event)"
        [ngClass]="{'Continuer' : canJourneyResume, 'animContinuer' : globalService.lowPerformanceMode === false && canJourneyResume}">
        <ng-container *ngIf="canJourneyResume">
          <h2 i18n>Continuer</h2>
        </ng-container>
        <ng-container *ngIf="!canJourneyResume">
          <h2 *ngIf="!alreadyCompletedJourney" i18n>Commencer</h2>
          <h2 *ngIf="alreadyCompletedJourney" i18n>Recommencer</h2>
        </ng-container>
      </div>

      <div id="btn-sequence" class="cat-dropdown card-container-adventure cursor-pointer"
        (click)="openCategories($event)" [ngClass]="{'opened':isCategoryListOpen}">
        <h2>Exploration</h2>
        <ion-icon class="detailStatus" name="caret-forward-outline"></ion-icon>
      </div>

      <div class="categoriesListContainer" *ngIf="isCategoryListOpen">
        <ng-container *ngIf="oseJourneyService.currentJourney?.workshops?.length > 0">
          <ion-list lines="none" class="categoryItem"
            [ngClass]="{'opened':oseJourneyService.currentJourney?.selectedCategoryType === Details.workshop}">
            <ion-item (click)="openDetails(Details.workshop,$event)" class="cursor-pointer">
              <ion-label class="categoryTitle">Je découvre</ion-label>
              <ion-icon class="detailStatus" name="caret-forward-outline"></ion-icon>
            </ion-item>
          </ion-list>
          <div id="containerDiscover" [class]="Details.workshop+'-details'"
            [hidden]="oseJourneyService.currentJourney?.selectedCategoryType !== Details.workshop">
            <ng-container *ngFor="let ex of oseJourneyService.currentJourney.workshops;let i = index">
              <div class="exerciseDetails" *ngIf="ex.displayInModal" title="{{ex.id}}">
                <ng-container [ngTemplateOutlet]="reUseCatDetails"
                  [ngTemplateOutletContext]="{ ex: ex,i:i}">
                </ng-container>
              </div>
            </ng-container>

            <div class="journeyActionBtnContainer">
              <div class="validationBtn" (click)="startJourneyWithSelectedExercises($event)">
                <p class="validationBtn" i18n>Commencer</p>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="oseJourneyService.currentJourney?.learnings?.length > 0">
          <ion-list lines="none" class="categoryItem"
            [ngClass]="{'opened':oseJourneyService.currentJourney?.selectedCategoryType === Details.learning}">
            <ion-item (click)="openDetails(Details.learning,$event)" class="cursor-pointer">
              <ion-label class="categoryTitle">J'apprends</ion-label>
              <ion-icon class="detailStatus" name="caret-forward-outline"></ion-icon>
            </ion-item>
          </ion-list>
          <div id="containerLearning" [class]="Details.learning+'-details'"
            [hidden]="oseJourneyService.currentJourney?.selectedCategoryType !== Details.learning"
            *ngIf="oseJourneyService?.currentJourney?.exercises">
            <ng-container *ngFor="let ex of oseJourneyService.currentJourney.learnings;let i = index">
              <div class="exerciseDetails" *ngIf="ex.displayInModal" title="{{ex.id}}">
                <ng-container [ngTemplateOutlet]="reUseCatDetails"
                  [ngTemplateOutletContext]="{ ex: ex,i:i}">
                </ng-container>
              </div>
            </ng-container>
            <div class="journeyActionBtnContainer" [ngClass]="{'disabled':disableValidateSelectionEx}">
              <div class="validationBtn" (click)="startJourneyWithSelectedExercises($event)">
                <p class="validationBtn" i18n>Commencer</p>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="oseJourneyService.currentJourney?.evaluations.length > 0">
          <div class="card-container-footer-item quizzContainer" #quizzContainer
            *ngIf="activity && activity.quizz && activity.quizz.length > 0">
            <ion-list lines="none" class="categoryItem"
              [ngClass]="{'opened':oseJourneyService.currentJourney?.selectedCategoryType === Details.evaluation}">
              <ion-item (click)="openDetails(Details.evaluation,$event)" class="cursor-pointer">
                <ion-label class="categoryTitle">Je me teste</ion-label>
                <ion-icon class="detailStatus" name="caret-forward-outline"></ion-icon>
              </ion-item>
            </ion-list>
            <ng-container *ngIf="oseJourneyService.currentJourney?.selectedCategoryType ===  Details.evaluation">
              <div id="containerEvaluation" [class]="Details.evaluation+'-details'"
                [hidden]="oseJourneyService.currentJourney?.selectedCategoryType !== Details.evaluation"
                *ngIf="oseJourneyService?.currentJourney?.exercises">
                <ng-container
                  *ngFor="let ex of oseJourneyService.currentJourney.evaluations;let i = index">
                  <div class="exerciseDetails" title="{{ex.id}}"
                    *ngIf="!ex.isEtape && !(ex.type === OseExerciceType.fiche && ex.titre_sommaire === oseJourneyService.currentJourney.evaluations[i - 1]?.titre_sommaire)"
                    [ngClass]="{'isSelected':ex.selected}">
                    <ng-container [ngTemplateOutlet]="reUseCatDetails"
                      [ngTemplateOutletContext]="{ ex: ex,i:i}">
                    </ng-container>
                  </div>
                </ng-container>
                <div class="card-container-footer-item all-quiz-done">
                  <p *ngIf="isJourneyDisplayed && allQuizCompleted" >
                    <span i18n>Bravo tu as terminé</span>
                    <ng-container *ngIf="activity.quizz?.length > 1"><span i18n>tous les quiz !</span></ng-container>
                    <ng-container *ngIf="activity.quizz?.length === 1"><span i18n>le quiz !</span></ng-container>
                  </p>
                </div>
                <div class="journeyActionBtnContainer"
                  [ngClass]="{'disabled':disableValidateSelectionEx}">
                  <div class="validationBtn" (click)="startJourneyWithSelectedExercises($event)">
                    <p class="validationBtn" i18n>Commencer
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <div
      *ngIf="(!isJourneyDisplayed || (isJourneyDisplayed && competenciesOpen)) && activity?.competencies?.length > 0"
      id="modal-competencies" class="card-container-footer-item competenciesContainer"
      (click)="grimoire($event)">

      <ng-container *ngIf="isJourneyDisplayed">
        <h4 i18n>Compétences</h4>
      </ng-container>
      <ng-container *ngIf="!isJourneyDisplayed">
        <p *ngIf="activity.competencies.length > 1" class="competencies-title" i18n>Thématiques</p>
        <p *ngIf="activity.competencies.length <= 1" class="competencies-title" i18n>Thématique</p>
      </ng-container>

      <div class="competencies-container">
        <ng-container *ngFor="let competency of activity.competencies">
          <div class="card-container-eachCompetencies">
            <ion-icon name="checkmark-circle-outline"></ion-icon>
            <p class="competency-paragraph"><span><i>{{competency.name}}</i></span></p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isJourneyDisplayed">
    <div id="competencies-wrapper" (click)="grimoire($event)"
      *ngIf="accountService.team.length > 0 && accountService.team[0].preview &&  activity?.competencies?.length > 0 && !getRecapPOI">
      <img id="open-competencies" src="/assets/icon/grimoire.png" />
      <h2 i18n>Compétences</h2>
    </div>

    <div id="progressBar" *ngIf="accountService.team.length > 0 && !accountService.team[0].preview">
      <app-custom-progressbar [number]="completedExercises?.length || 0"
        [total]="totalExercises?.length || 0" [mascotVisible]="true">
      </app-custom-progressbar>
    </div>
  </ng-container>

<div *ngIf="!isJourneyDisplayed" class="card-wrapper-global card-wrapper-play">
  <img src="/assets/icon/play-ose.svg" />
</div>
</ng-template>

  <!-- top wizard system -->
  <ng-template #reUseCatDetails let-ex="ex" let-i="i">
    <div class="exerciseDetails__icon_container">
      <img class="exerciseDetails__icon" *ngIf="!ex.subPartProgression;else inProgress" [src]="ex.icon" />
      <ng-template #inProgress>
        <div class="exInProgress"></div>
      </ng-template>
    </div>
    <div class="exerciseDetails__name_container"
      [ngClass]="{'sizeSolo': !ex.hasSubpart, 'sizeWithSubparts': ex.hasSubpart}">
      <p class="exerciseDetails__name" (click)="startSelectedActivityFromIndex(ex,i,$event)">
        {{ex.titre_sommaire || ex.title}} <ng-container *ngIf="ex.type === OseExerciceType.quizz">(quiz)</ng-container>
      </p>
    </div>
    <div class="exSubParts" *ngIf="ex.hasSubpart">
      <img *ngIf="ex.subpart?.hasGame" src="assets/icon/game.svg">
      <img *ngIf="ex.subpart?.hasQuiz" src="assets/icon/quiz.svg">
      <img *ngIf="ex.subpart?.hasVideo" src="assets/icon/play.svg">
    </div>
    <div class="exerciseDetails__choices">
      <ion-toggle class="toggleCustomElem"
        *ngIf="accountService.team?.length > 0 && accountService.team[0].preview;else visibleForStudent" slot="start"
        [(ngModel)]="ex.selected"></ion-toggle>
      <ng-template #visibleForStudent>
        <img [src]="ex?.alreadyCompleted ? 'assets/icon/etoile_full.png' : 'assets/icon/etoile_empty.png'"
          class="isCompleted" [ngClass]="{'awarded':ex?.alreadyCompleted, 'notAwarded':!ex?.alreadyCompleted}">
      </ng-template>
    </div>
  </ng-template>

