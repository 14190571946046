import { once } from "events";
import { Injectable } from "@angular/core";
import { Socket } from "ngx-socket-io";
import { SemVer } from "semver";
import { environment } from "src/environments/environment";
import { Platform } from "@ionic/angular";

@Injectable({
	providedIn: "root" // <--provides this service in the root ModuleInjector
})
export class SocketSttOne extends Socket {
	constructor(platform: Platform) {
		let url = "https://ws.mathia.education";
		let backupUrl = "https://backup.ws.mathia.education";
		//let url = "http://5.39.75.193:4500";
		const opt = { reconnectionAttempts: 5, withCredentials: true, autoConnect: true };
		if (environment.kidaia) {
			url = "https://ws.kidaia.com";
		}
		if (platform.is("cordova") && platform.is("ios")) {
			opt["transports"] = ["websocket"];
		}
		//patch for old safari with socket problem we force a route on endpoint service directly no balancing
		// use livrary semver for true version compare
		try {
			const regex = /^(?:(?!chrome|android).)*(?:Version\/([\d|.]*)).*safari/i;
			let m: RegExpExecArray;
			if ((m = regex.exec(navigator.userAgent)) !== null) {
				if (m[1]) {
					let version: SemVer = new SemVer(m[1]);
					if (version.compare("14.0.3") < 0) {
						url = "https://ws3.mathia.education";
						if (environment.kidaia) {
							url = "https://ws3.kidaia.com";
						}
					}
				}
			}
		} catch (error) {}
		super({ url: url, options: opt });
		//after all attempt try fallback server once at start
		this.ioSocket.io.once("reconnect_failed", data => {
			this.ioSocket.io.uri = backupUrl;
			this.connect();
		});
	}
}

@Injectable({
	providedIn: "root" // <--provides this service in the root ModuleInjector
})
export class SocketSttTwo extends Socket {
	constructor(platform: Platform) {
		let url = "https://ws2.mathia.education";
		//let url = null;
		const opt = { reconnectionAttempts: 1, withCredentials: true, autoConnect: false };
		if (platform.is("cordova") && platform.is("ios")) {
			opt["transports"] = ["websocket"];
		}
		if (environment.kidaia) {
			url = "https://none";
			opt.reconnectionAttempts = 0;
			opt.autoConnect = false;
		}
		super({ url: url, options: opt });
	}
}

@Injectable({
	providedIn: "root" // <--provides this service in the root ModuleInjector
})
export class SocketEmpty extends Socket {
	constructor(platform: Platform) {
		let url = "badUrl";
		const opt = { reconnectionAttempts: 0, withCredentials: true, autoConnect: false };
		super({ url: url, options: opt });
	}
}

@Injectable({
	providedIn: "root" // <--provides this service in the root ModuleInjector
})
export class SocketRemoteOne extends Socket {
	constructor() {
		let url = "https://ws2.rc.mathia.education";
		const opt = { reconnectionAttempts: 5, withCredentials: true, autoConnect: true };
		if (environment.kidaia) {
			url = "https://none";
			opt.reconnectionAttempts = 0;
			opt.autoConnect = false;
		}
		super({ url: url, options: opt });
	}
}
