<ion-content scrollY="false">

  <app-toolbar-with-buttons [activityName]="'solides'" [opacity]="globalService.isKidaia"
    (alertMessageAnswer)="alertKidaiaDemandSwitchUser($event)" [remote]="false"
    [studentsList]="accountService.allStudents" [progressBar]="false" [currentUser]="currentUser">
  </app-toolbar-with-buttons>
  <div class="globalContainer" #content>
    <ion-button class="skip-button showAllCards" *ngIf="!environment.production && !won && memory"
      (click)="flipAllCards()" >
      <span *ngIf="!openAllCards" i18n>Montrer</span>
      <span *ngIf="openAllCards" i18n>Fermer</span>
    </ion-button>
    <div class="title-container" *ngIf="cards?.length > 0 && !(won && environment.production)">
      <app-ose-difficulty-selector *ngIf="cards?.length !== 0 && !won" (setDifficulty)="difficultyChanged($event)"
        [difficulties]="difficultiesAvailable" [difficultySelected]="difficultySelected"></app-ose-difficulty-selector>
      <div class="title-wrapper" [ngStyle]="{'cursor':allowGetNextCards ? 'auto' : 'not-allowed'}">
        <h1 class="sectionName" *ngIf="!won">{{consigne}}</h1>
        <ion-button class="skip-button" shape="round"  *ngIf="(!environment.production || accountService.team[0].preview) && !won"
          (click)="goNextActivity()" [ngStyle]="{'pointer-events':allowGetNextCards ? 'auto' : 'none'}"
          i18n>Passer</ion-button>
      </div>
    </div>

    <div class="p-16 gameCardsContainer" [ngClass]="{'gameWonContainer':endFlipCards}">
      <div #cardsGrid class="grid p-16 grid-{{cards.length}}" [attr.cardsLength]="cards.length"
        [ngClass]="{'disableClick':!enableClick}">
        <app-game-card #gameCards class="gameCard" *ngFor="let currentCard of cards; let idx=index"
          [currentCard]="currentCard" [difficultySelected]="difficultySelected" [scenarioBubble]="scenarioBubble"
          [flippedCards]="flippedCards" [maxFontSize]="maxFontSize" [showText]="won" [endFlipCards]="endFlipCards"
          (cardClicked)="cardClicked(idx)">
        </app-game-card>
      </div>
    </div>
    <div id="oseCloseButton" (click)="oseJourneyService.quitJourney()" *ngIf="oseJourneyService.isPlaying">
      <div id="oseCloseButton-global__img"></div>
    </div>
    <div class="osePrevButton" *ngIf="memory && !(oseJourneyService?.currentJourney?.currentExerciseIndex === 0)"
      (click)="prevPage()">
      <img src="/assets/icon/play-ose.svg" />
    </div>
    <div class="oseNextButton" *ngIf="won" (click)="goNextActivity()">
      <img src="/assets/icon/play-ose.svg" />
    </div>

    <div id="nav-wrapper" *ngIf="cards?.length === 0 && !oseJourneyService.currentJourney">
      <nav>
        <ul>
          <h2>Liste des memory</h2>
          <mat-form-field>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrer">
          </mat-form-field>
          <mat-table [dataSource]="dataSource">
            <!-- Position Column -->
            <ng-container matColumnDef="id">
              <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef> Titre </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.title}} </mat-cell>
            </ng-container>

            <!-- Image Column -->
            <ng-container matColumnDef="image">
              <mat-header-cell *matHeaderCellDef> Image </mat-header-cell>
              <mat-cell *matCellDef="let element" class="ion-wrap ion-align-items-center ion-justify-content-center">
                <ng-container *ngFor="let card of element.images">
                  <ion-img src="{{card.imageUrl}}" style="width:100px"></ion-img>
                </ng-container>

              </mat-cell>
            </ng-container>


            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="loadMemory(row.id)"></mat-row>
          </mat-table>
        </ul>
      </nav>
    </div>
  </div>

</ion-content>