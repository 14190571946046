import { animate, keyframes, state, style, transition, trigger } from "@angular/animations";

// transition background color percent of good answer modal
export const customActivitiesMenuTrigger = trigger("openClose", [
	state(
		"containerLargeHeight",
		style({
			height: "30vh",
			transform: "translateY(0vh)"
		})
	),
	state(
		"containerSmallHeight",
		style({
			height: "0vh",
			transform: "translateY(-40vh)",
			paddingBottom: "0px"
		})
	),

	transition("containerLargeHeight => containerSmallHeight", [animate(".7s")]),
	transition("containerSmallHeight => containerLargeHeight", [animate(".7s")])
]);

// Rocket animation for gabarit modal
export const customRocketModalAnimation = trigger("animateRocket", [
	transition(":enter", [
		animate("1.5s cubic-bezier(0.0, 0.0, 0.2, 1.0)", keyframes([style({ right: "{{startPosition}}%" }), style({ right: "-2.25vh" })]))
	])
]);

export enum ModalStateName {
	hide = "hide",
	show = "show",
	close = "close"
}

// terriotire + map
export const oseModalPoiJourney = trigger("modalAnim", [

	state(
		ModalStateName.show,
		style({
			right: "{{modalDisappearPosition}}"
		}),
		{ params: { modalDisappearPosition: "0%" } } // default param value
	),
	state(
		ModalStateName.hide,
		style({
			right: "{{modalFinalPosition}}"
		}),
		{ params: { modalFinalPosition: "0%" } } // default param value
	),
	state(
		ModalStateName.hide,
		style({
			right: "{{modalFinalPosition}}"
		}),
		{ params: { modalFinalPosition: "0%" } } // default param value
	),
	state(
		ModalStateName.close,
		style({
			right: "{{modalDisappearPosition}}"
		}),
		{ params: { modalDisappearPosition: "0%" } } // default param value
	),


	transition(`${ModalStateName.show} => ${ModalStateName.close}`, animate("350ms ease-in-out")),
	transition(`${ModalStateName.show} => ${ModalStateName.hide}`, animate("450ms cubic-bezier(.63,1.47,.34,.97)"))
]);

// Memory
export const flipCards = trigger("cardFlip", [
	state(
		"default",
		style({
			transform: "none"
		})
	),
	state(
		"flipped",
		style({
			transform: "perspective(600px) rotateY(180deg)"
		})
	),
	state(
		"matched",
		style({
			transform: "rotateY(180deg)"
		})
	),
	transition("default => flipped", [animate("400ms")]),
	transition("flipped => default", [animate("400ms")]),
	transition("* => matched", [animate("400ms")])
]);
