import { Injectable } from "@angular/core";
import { User } from "../models/user";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { Classroom } from "../models/classroom";
import { Student } from "../models/student";
import { Journey, JourneyRecommendation } from "../models/journey";
import { BaseService } from "./base.service";
import { LrsService } from "./lrs.service";
import { ProposedActivity } from "../models/proposed-activity";
import { UrlTree } from "@angular/router";
import { BearerToken } from "./class.service";
import { CreditType } from "../models/in-app-state";
import { productInApp } from "./in-app-purchase.service";

export enum userAlertActions {
	removeStudent = "removeStudent",
	switchStudent = "switchStudent",
	changeParam = "changeParam"
}

@Injectable({
	providedIn: "root"
})
export abstract class AccountService extends BaseService {
	user: User;
	public isUserLoaded: ReplaySubject<boolean>;
	studentsClassLoaded: boolean;
	classroom: Classroom;
	allStudents: Student[] = new Array();
	public team: Student[];
	public teamsCopy: Student[];
	teams;
	maxKidaiaAccount: number;
	urlBeforeRedirection: UrlTree;
	public exerciseStatistics = {
		completedExercises: {},
		averageRateCorrectAnswers: {},
		exercisePerGoodAnswer: {},
		totalNumberAwardsByActivity: {}
	};
	studentsStatisticsRecovered: boolean;
	public journeyTeamShootingStarsCount = new Array<string>();
	public journeyTeamNormalStarsCount = new Array<string>();
	public journeyTeamMoonsCount = new Array<string>();
	public checkedUserRGPDState = false;
	public loadGabaritStudentInfos;
	public logReadyPromise: Promise<void>;
	studentsProfiles:{
		[studentId: string]: { [competence: string]: boolean } 
	}
	restore: boolean;
	abstract initJourneyTeamTotalAwards();
	abstract setCabriService(cabriService);
	abstract checkUserPermission(value?: string): boolean;
	abstract initializeUserStatistics();
	abstract get isRealUserConnected(): boolean;
	abstract get isRealClassConnected(): boolean;
	abstract checkUserConnected(): boolean;
	abstract getAssignations(classRoom: Classroom): Promise<Journey[]>;
	abstract restoreGroups();
	abstract restoreTeam(allStudents: Student[]);
	abstract setStudentSlot(student: Student, indexStudent?);
	abstract getStudentsAndJourneys(): Promise<void>;
	abstract getKidaiaStudents();
	abstract getStudentStatistics(studentJourneysPromise?: Promise<void>);
	abstract updateUserData(studentId: string): Promise<void>;
	abstract getJourneys();
	abstract getStudentJourneys(studentId: string | number, falseTraces?: boolean): Promise<void>;
	abstract recoverStudentsInfos();
	abstract diagnosticMode(allJourneyExercises: Journey[], idEleve, onStart?: boolean);
	abstract aiRecommandedAssignationId(journey: Journey);
	abstract searchRecommandedJourney(journeysEnum: { [key: string]: Journey[] }, aiSelectedJourney: Journey, _globalJourneys: Journey[]);
	abstract defineAiUserJourneysState(aiJourney: Journey);
	abstract recommendedJourneyModel(journey: Journey);
	abstract setLrsService(lrsService: LrsService);
	abstract defineDefaultSelectedJourney(checkJourneyState?: () => void);
	abstract disconnectUser(redirect?: boolean);
	abstract getNextActivity();
	abstract get isUserMakingExercise(): boolean;
	abstract setNextActivity();
	abstract saveUserJourney(launch: boolean, nextActivity?: ProposedActivity);
	abstract markDiagnosticExerciseDone(currentJourney: Journey);
	abstract removeStoryJourney();
	abstract getRecoveredJourney(): Journey;
	abstract countAwards(resume, currentJourney?: Journey);
	abstract trainingMode(countTotalAwards: boolean);
	abstract allowSupervision();
	abstract switchKidaiaCurrentUser(student: Student, $event?)
	abstract getStudentsAndJourneysLoggedIn(): Promise<void>
	abstract getJourneysLoggedIn(): Promise<void>
	abstract isClassLoggedIn(): boolean;
	abstract canAccessPremium(urlType : 'video'|'aventure'|'logbook'|"mathiaFamily" | "mathiaTeacher" ,callback : () => void);
	abstract canAccessPremiumOse(urlType : 'ose' ,callback : () => void);
	abstract get studentsIds():Array<string>;
	abstract removeStorageConnection(codemaison?: boolean);
	abstract getStudentsProfilesFromClassroom();
	abstract updateUserProfile(usersIdsMadeActivity:Array<string>);
	abstract getAiRecommendations(): Promise<JourneyRecommendation[]>;
	abstract getStudents():Promise<void>;
	abstract logWordpress(email: string, password?: string, apple?: boolean, f2a?:string,idUser?:string):Promise<Observable<BearerToken>>
	abstract isDemoAccount():boolean;
	abstract connectDemoAccount():void;
	abstract loginCodeMaison(codeMaison: string);
	abstract setCodeClasse(codeclasse: string);
	abstract resetClassVariables();
	abstract autologCodeMaison(codeMaison: string):Promise<string>;
	abstract loginApple(purchaseId?:string): Promise<void>
	abstract autologKidaia(noRedirect: boolean):any;
}
