import {
	Component,
	OnInit,
	Input,
	EventEmitter,
	Output,
	Renderer2,
	ViewChild,
	ElementRef,
	ViewChildren,
	QueryList,
	OnChanges,
	SimpleChanges,
} from "@angular/core";
import { AlertOptions, IonicSafeString, ModalController, Platform } from "@ionic/angular";
import { AccountService, userAlertActions } from "src/app/services/account.service";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { Student } from "src/app/models/student";
import { GlobalService } from "src/app/services/global.service";
import { AlertController } from "@ionic/angular";
import { Classroom } from "src/app/models/classroom";
import { cloneDeep } from "lodash";
import { NetworkService } from "src/app/services/network.service";
import { environment } from "src/environments/environment";
import { ClassService} from "src/app/services/class.service";
import { Router } from "@angular/router";
import { ReplaySubject } from "rxjs";

@Component({
	selector: "app-modal-activity-participants-kidaia",
	templateUrl: "./modal-activity-participants-kidaia.component.html",
	styleUrls: ["./modal-activity-participants-kidaia.component.scss"]
})
export class ModalActivityParticipantsKidaiaComponent implements OnChanges, OnInit {
	public studentInputLevel: any;
	// public studentInputName: string;
	public studentInput = {
		name: "",
		error: ""
	};
	public student: Student;
	public studentCreationMode: boolean;
	public studentsManagementMode = false;
	public editionStudent: Student;
	public alertTitle = $localize`Es-tu sûr de vouloir supprimer ce joueur ?`;
	public modalAlertDisplay = false;
	public studentNameValidation;
	public login = false;
	public formLog = {
		email: "",
		password: "",
		error: ""
	};
	public kidaiaBaseURL = "https://kidaia.com/api/1.1/wf";
	firstTimePlayerCreation: boolean;
	componentInitialized: boolean;

	public placeholderName = $localize`Prénom`;
	public placeholderLevel = $localize`Niveau`;
	public placeholderEmail = $localize`Email`;
	public placeholderPassword = $localize`Mot de passe`;

	public environment;
	public hideClassSelection: boolean;
	constructor(
		public modalCtrl: ModalController,
		public accountService: AccountService,
		public platform: Platform,
		public cabriDataService: CabriDataService,
		public globalService: GlobalService,
		public classService: ClassService,
		public alertController: AlertController,
		private renderer: Renderer2,
		public networkService: NetworkService,
		public router: Router
	) {
		this.environment = environment;
	}
	@ViewChild("leftArrowStudentsManagement", { static: false }) leftArrowStudentsManagement: ElementRef;
	@ViewChild("leftArrowStudentCreation", { static: false }) leftArrowStudentCreation: ElementRef;
	@ViewChildren("editStudentIcon") editStudentIcon: QueryList<ElementRef>;
	@ViewChildren("removeStudentIcon") removeStudentIcon: QueryList<ElementRef>;
	@Input() studentsList: Student[] = new Array();
	@Input() classes: Classroom[] = new Array();
	@Input() team: Array<any>;
	@Input() spinner: { status: false; text: "" };
	@Input() teamsCopy: Array<any>;
	@Input() teams: Array<any>;
	@Input() maxPlayer: number;
	@Input() maxKidaiaAccount: number;
	@Input() keepTeam: boolean;
	@Input() teamAlreadyFull = false;
	@Input() edition = true;
	@Input() modalMode = true;
	@Input() currentStudentStatistics;
	@Output() sendStudent: EventEmitter<Student> = new EventEmitter<Student>();
	@ViewChildren("statisticsResult") statisticsResult: QueryList<any>;

	@Input() removeStudentCallback = (student: Student): Promise<void> => {
		return null;
	};

	@Input() createStudentCallback = (student: Student): Promise<void> => {
		return null;
	};
	@Input() editStudentCallback = (student: Student): Promise<void> => {
		return null;
	};

	ngOnChanges(changes: SimpleChanges) {
		if (this.componentInitialized && changes.studentsList && changes.studentsList.currentValue) {
			if (
				changes.studentsList.currentValue.length > 0 &&
				changes.studentsList.previousValue &&
				changes.studentsList.previousValue.length === 0
			) {
				this.editionStudent = null;
				this.firstTimePlayerCreation = false;
			} else if (changes.studentsList.currentValue.length === 0) {
				if (changes.studentsList.isFirstChange) {
					this.enterStudentCreation(true);
				} else {
					this.enterStudentCreation(false);
				}
			}
		}
	}

	async ngOnInit() {
		this.componentInitialized = true;
	}

	get userAlertActions() {
		return userAlertActions;
	}

	/**
	 * Add a group containing multiple students
	 * @param teamSelected
	 */
	selectCurrentTeam(teamSelected) {
		const studentSelectedFromGroup = new Array();
		this.studentsList.forEach(student => {
			teamSelected.forEach(currentTeam => {
				if (student.id === currentTeam.teamId) {
					student.selected = true;
					studentSelectedFromGroup.push(student);
				}
			});
		});

		this.modalCtrl.dismiss({
			dismissed: true,
			team: this.team,
			studentSelectedFromGroup
		});
	}

	/**
	 * to inject a selected student to the cabri.team
	 */
	async chooseStudent(student: Student, $event = null) {
		if (this.modalMode) {
			await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget }, 150);
			if (this.team.length < this.maxPlayer) {
				if (!this.keepTeam) {
					student.selected = true;
				}
				this.student = student;
				this.team.push(student);
			} else {
				this.teamAlreadyFull = true;
			}

			this.modalCtrl.dismiss({
				dismissed: false,
				team: this.team,
				student,
				teamAlreadyFull: this.teamAlreadyFull
			});
		} else {
			await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget }, 150);
			this.sendStudent.emit(student);
		}
	}

	/**
	 * to Close the modal
	 */
	dismiss($event = null) {
		if ($event) {
			this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget });
		}
		this.modalCtrl.dismiss({
			dismissed: true,
			team: this.team,
			student: this.student
		});
	}

	/**
	 * to enter Student Creation Mode and display the "create student" card
	 */
	enterStudentCreation(firstTime = false) {
		this.editionStudent = new Student(null, new Classroom(null, null, null), "");
		this.editionStudent.parentalConsent = true;
		this.firstTimePlayerCreation = firstTime;
		this.studentCreationMode = true;
		this.studentsManagementMode = false;
		this.checkNeedHideClassSelection();
	}

	/**
	 * Hide class selection for Ose for existing of only one class
	 */
	checkNeedHideClassSelection() {
		this.hideClassSelection = this.globalService.isOse && this.classes.length === 1;
		if (this.hideClassSelection && this.studentCreationMode && Array.isArray(this.classes)) {
			// crete new student fill classe id
			this.editionStudent.classe.id = this.classes[0].id as string;
		}
	}

	/**
	 * cancel modifications done in editStudent mode & exit editStudent mode
	 */
	async cancelStudentCreation($event = null) {
		if ($event) {
			await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget });
		} else {
			this.renderer.removeClass(this.leftArrowStudentCreation.nativeElement, "leftArrow");
			this.renderer.addClass(this.leftArrowStudentCreation.nativeElement, "leftArrowClicked");
			await this.timeOut(150);
		}
		this.studentCreationMode = false;
		this.editionStudent = null;
	}

	/**
	 * Edition / Creation student name field validation
	 */
	creationEditionFormValidation() {
		const formError = new Array();
		// Verify if another student with the same first name exist
		let studentWithSameFirstNameExist = false;
		if (this.accountService.allStudents.length >= 1) {
			let found;
			if (this.edition) {
				found = this.accountService.allStudents.some((student: Student) => {
					return (
						Number(student.id) !== Number(this.editionStudent.id) &&
						student.name.toLowerCase() === this.editionStudent.name.toLowerCase()
					);
				});
			} else {
				found = this.accountService.allStudents.some((student: Student) => {
					return student.name.toLowerCase() === this.editionStudent.name.toLowerCase();
				});
			}

			if (found) {
				studentWithSameFirstNameExist = true;
			}
		}

		if (studentWithSameFirstNameExist) {
			formError.push($localize`Ce prénom est déjà utilisé.`);
		}

		if (this.editionStudent.name.length < 3) {
			formError.push($localize`Le champ prénom doit comporter au moins 3 caractères.`);
		}

		const regexName = /^[a-zA-ZÀ-ÿ-. ]*$/;
		// check if a string contains at least one number
		const regexNumber = /\d/;
		if (regexNumber.test(String(this.editionStudent.name).toLowerCase())) {
			formError.push($localize`Le champ prénom ne peut contenir que des lettres.`);
		} else if (!regexName.test(String(this.editionStudent.name).toLowerCase())) {
			formError.push($localize`Le champ prénom ne doit pas comporter des caractères spéciaux.`);
		}

		if (!this.editionStudent.classe || !this.editionStudent.classe.id) {
			formError.push($localize`Veuillez sélectionner un niveau.`);
		}

		return formError;
	}

	capitalizeFirstLetter(string: string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	/**
	 * validate student creation
	 */
	async validateStudentCreation($event) {
		await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget });
		this.studentNameValidation = this.creationEditionFormValidation();
		if (this.studentNameValidation.length === 0) {
			this.editionStudent.name = this.capitalizeFirstLetter(this.editionStudent.name);
			this.createStudentCallback(this.editionStudent)
				.then(() => {
					this.editionStudent = null;
					this.studentCreationMode = false;
					this.studentsManagementMode = false;
				})
				.catch(err => {
					this.studentNameValidation.push($localize`Une erreur s’est produite, veuillez réessayer ultérieurement.`);
					this.displayFormError();
				});
		} else {
			this.displayFormError();
			this.studentInput.error = " ";
		}
	}

	async displayFormError() {
		let message = "";
		this.studentNameValidation.forEach(element => {
			message += "<p>" + element + "</p>";
		});
		const alert = await this.alertController.create({
			cssClass: "creationEditionStudentAlert",
			header: "Message",
			message,
			buttons: [
				{
					text: $localize`Corriger`,
					handler: () => {
						console.log("Corriger");
					}
				}
			]
		});
		await alert.present();
	}

	/**
	 * Enter studentsManagementMode mode -> displays modification icons in cards to edit or delete a student profile
	 */
	async enterStudentsManagementMode($event) {
		await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget }, 150);
		this.studentsManagementMode = true;
	}

	/**
	 * exit studentsManagementMode mode & hide modification icons in cards
	 */
	async exitStudentsManagementMode($event = null) {
		if ($event) {
			await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget }, 150);
		} else {
			await this.animateArrowClick();
		}
		this.studentsManagementMode = false;
	}

	/**
	 * Enter studentEdition mode (hide other cards & allows to change player's name & level)
	 */
	async editStudent(student: Student, i, $event) {
		// await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget }, 150);
		const el = this.editStudentIcon.toArray()[i];
		this.renderer.removeClass(el.nativeElement, "managePlayerIcon");
		this.renderer.addClass(el.nativeElement, "managePlayerIconClicked");
		await this.timeOut(150);
		this.editionStudent = cloneDeep(student);
		this.checkNeedHideClassSelection();
	}

	/**
	 * Save modifications done in studentEdition mode calling the editStudentCallback method in component input
	 */
	async confirmStudentEdition(student, $event = null) {
		await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget }, 150);
		this.studentNameValidation = this.creationEditionFormValidation();
		if (this.studentNameValidation.length === 0) {
			this.editStudentCallback(student)
				.then(() => {
					this.clearStudentInputFields();
					this.editionStudent = null;
					this.studentsManagementMode = false;
				})
				.catch(err => {
					console.error("error update", err);
					this.studentNameValidation.push($localize`Une erreur s’est produite, veuillez réessayer ultérieurement.`);
					this.displayFormError();
				});
		} else {
			this.displayFormError();
		}
	}

	/**
	 * Cancel studentEdition mode & exit studentsManagementMode + animate arrow in yellow
	 */
	async cancelStudentEdition($event = null) {
		// await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget }, 150);
		if ($event) {
			await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget }, 150);
		} else {
			await this.animateArrowClick();
		}
		this.editionStudent = null;
		this.login = null;
	}

	async animateArrowClick(timeout = 150) {
		let unclickedClass;
		let clickedClass;
		if (this.globalService.isDesktop || this.globalService.isLandscape) {
			unclickedClass = "leftArrow";
			clickedClass = "leftArrowClicked";
		} else if (!this.globalService.isDesktop && !this.globalService.isLandscape) {
			unclickedClass = "leftArrowPortrait";
			clickedClass = "leftArrowPortraitClicked";
		}
		this.renderer.removeClass(this.leftArrowStudentsManagement.nativeElement, unclickedClass);
		this.renderer.addClass(this.leftArrowStudentsManagement.nativeElement, clickedClass);
		await this.timeOut(timeout);
		this.renderer.removeClass(this.leftArrowStudentsManagement.nativeElement, clickedClass);
		this.renderer.addClass(this.leftArrowStudentsManagement.nativeElement, unclickedClass);
	}

	/**
	 * Remove student after confirmation from Form
	 */
	async submitRemoveStudent($event) {
		await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget }, 150);
		this.formLog.error = "";
		if (this.formLog.email.length > 0 && this.formLog.password.length > 0) {
			const regexEmail = /\S+@\S+\.\S+/;
			const emailValide = regexEmail.test(String(this.formLog.email).toLowerCase());
			if (emailValide) {
				const formData = new FormData();
				formData.append("email", this.formLog.email);
				formData.append("password", this.formLog.password);
				this.classService.verifPassword(formData).subscribe(
					response => {
						this.formLog.error = "";
						this.removeStudent(this.editionStudent);
					},
					err => {
						this.formLog.error = $localize`L’email ou le mot de passe est incorrect`;
					}
				);
			} else {
				this.formLog.error = $localize`Veuillez saisir une adresse email valide`;
			}
		}
	}

	async dismissLogin($event?) {
		if ($event) {
			await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget }, 150);
		}
		this.login = false;
		this.editionStudent = null;
		this.studentsManagementMode = false;
	}

	alertDemandRemoveStudent($event) {
		if (!$event) {
			this.modalAlertDisplay = false;
			this.dismissLogin();
		} else {
			this.modalAlertDisplay = false;
			this.login = true;
		}
	}

	/**
	 * Triggers an Alert to confirm player profile deletion
	 */
	async confirmStudentRemoval(student: Student, i, $event): Promise<void> {
		// await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget }, 150);
		const el = this.removeStudentIcon.toArray()[i];
		this.renderer.removeClass(el.nativeElement, "removePlayerIcon");
		this.renderer.addClass(el.nativeElement, "removePlayerIconClicked");
		await this.timeOut(150);
		this.editionStudent = cloneDeep(student);
		// this.alertStudentId = studentId;
		this.modalAlertDisplay = true;
		// this.removeStudent(student);
	}

	/**
	 * Remove student method from component Input
	 */
	async removeStudent(student: Student) {
		this.removeStudentCallback(student).then(() => {
			this.login = false;
			this.editionStudent = null;
			this.studentsManagementMode = false;
		});
	}

	/**
	 * to cancel student profile removal from studentsList
	 */
	cancelStudentRemoval() {
		console.log("removal canceled");
	}

	/**
	 * Clears input fields of student creation / edition (name / level)
	 */
	clearStudentInputFields() {
		// this.studentInput.name = null;
		// this.studentInputLevel = null;
	}

	/**
	 * Custom async setTimeOut for waiting end of exe
	 */
	async timeOut(ms, callback: any = null): Promise<void> {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				if (callback) {
					callback();
				}
				resolve();
			}, ms);
		});
	}

	async disconnect($event) {
		if ($event) {
			await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget }, 150);
		}
		this.classService.disconnectUser().subscribe(() => {
			if (this.accountService.isUserLoaded) {
				this.accountService.isUserLoaded.complete();
			}
			this.accountService.isUserLoaded = null;
			this.accountService.isUserLoaded = new ReplaySubject(1);
			this.accountService.isUserLoaded.next(false);
			this.accountService.isUserLoaded.complete();
			this.accountService.disconnectUser();
		});
	}
}
