<div #mainContainer *ngIf="open" class="viewer-container">
  <div class="viewer-content">
    <div class="close-icon-global" (click)="closeModal()">
      <div class="close-icon-global__img"></div>
    </div>
    <swiper #swiper (slideChangeTransitionEnd)="onSwiperChange($event)" (init)="swiperInitliazed($event)" [config]="swiperOptions" 
      (slideChange)="onSwiperChange();">
      <ng-container *ngFor="let img of slides">
        <ng-template swiperSlide>
          <pinch-zoom #pinchZoom>
            <img class="main-img" [ngClass]="{'portrait': !isLandscape, 'landscape': isLandscape}" 
                  [src]="img.src||img" [srcset]="img.srcSet||''"  loading="eager" />
            </pinch-zoom>
        </ng-template>
      </ng-container>
    </swiper>
    <div class="footer">
      <div class="arrow-wrapper arrow-left" (click)="prevSlide()" *ngIf="gallery && gallery.length > 1"></div>
      <h1 class="territoireName"></h1>
      <div class="arrow-wrapper arrow-right" (click)="nextSlide()" *ngIf="gallery && gallery.length > 1"></div>
    </div>
  </div>
</div>