import { Pipe, PipeTransform } from "@angular/core";
import { GlobalService } from "../services/global.service";

@Pipe({
	name: "displayFullSpaceGrid",
	pure: true
})
export class ParamPipe implements PipeTransform {
	constructor(private globalService: GlobalService) {}
	transform(displayParamToRow : boolean ) {
		if (this.globalService.isMobile) {
			if (this.globalService.isLandscape) {
				return false;
			} else {
				return true;
			}
		} else {
			if (displayParamToRow && !this.globalService.isLandscape) {
				return false;
			} else if (displayParamToRow || !this.globalService.isDesktop) {
				return true;
			}
		}
		return false;
	}
}