import { UserCredential } from "./user-credential";
import { Story, StoryStatistics } from "./story";
import { Chapter } from "./chapter";
import { Badges } from "./badges";
import { Videos } from "./videos";

export class User {
	firstName: string;
	lastName: string;
	address: string;
	city: string;
	tel: string;
	auth: UserCredential;
	journeyState;
	codeClasse: any;
	grade: string;
	email: string;
	storys: Story[];
	currentStory: Story;
	currentChapter: Chapter;
	badges: Badges;
	studentId: string;
	storyStatistics: StoryStatistics;
	videos: Videos;
	premium: boolean;
	premium_ose: boolean;
	loggedFromWp:boolean;
	codeMaison: string;
	family: boolean;
	teacher: boolean;
	admin: boolean;
}

export interface KidaiaSso {
	codefamille: any;
	authentication: {
		email: {
			email: string;
			email_confirmed: boolean;
		};
	};
	user_signed_up: boolean;
	subscriptionid_number: number;
	tralalereauthbearer_text: string;
	oseauthbearer_text:string,
	maxuser_number: number;
	premium: boolean;
	premium_ose: boolean;
	admin ?: boolean;
	famille ?: boolean;
	enseignant ?: boolean;
	_id: string;
}
