import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-hourglass',
  templateUrl: './hourglass.component.html',
  styleUrls: ['./hourglass.component.scss'],
})
export class HourglassComponent implements OnInit {
  
  @Input() width: string = "140px";
  @Input() height: string = "500px";
  @Input() duration: string = "10s";

  @ViewChild('hourglass', { static: false }) hourglass :ElementRef<SVGSVGElement>;

  constructor() { }

  ngOnInit() {
    console.log("hourglass",this.hourglass);
  }
  public restart(){
    this.hourglass.nativeElement.querySelectorAll("animate").forEach((element) => {
      element.beginElement();
    });
  }

  public pause(){
    this.hourglass.nativeElement.pauseAnimations();
  }

  public resume(){
    this.hourglass.nativeElement.unpauseAnimations();

  }
}
