<ion-content class="pageContent" scrollY="false"
    [ngClass]="{'mobileL' : globalService.isMobile && globalService.isLandscape, 'mobileP' : globalService.isMobile && !globalService.isLandscape, 'tabletL' : globalService.isTablet && globalService.isLandscape, 'tabletP' : globalService.isTablet && !globalService.isLandscape, 'oneTwoPlayers' : accountService.team.length < 3, 'threeFourPlayers' : accountService.team.length >= 3, 'fullPerfMode' : !globalService.lowPerformanceMode}">
    <app-toolbar-with-buttons [studentSelectionPage]="true" (studentSelection)="backCustomLocation($event)">
    </app-toolbar-with-buttons>
    <!-- <app-custom-alert  (alertMessageAnswer)="alertSwitchUser($event)"></app-custom-alert> -->
    <!-- Page Container -->
    <div class="pageContainer activityParticipantsBg">
        <!-- Mathia -->
        <div *ngIf="!globalService.isMobile && !globalService.resizeEventRunning" class="mathiaContainer"
            [ngClass]="platform.isLandscape() ? 'mathiaContainerLandscape' : 'mathiaContainerPortrait'">
            <div class="mascotteContainer">
                <div [ngClass]="platform.isLandscape() ? 'mathiaMascotteAPLandscape mathiaMascotteAPAnimation' : 'mathiaMascotteAPPortrait mathiaMascotteAPAnimation'"
                    id="mathiaMascotteGrades"></div>
                <div *ngIf="helpText" class="bubble"
                    [ngClass]="{'bubbleMobile' : globalService.isMobile, 'bubbleTablet' : globalService.isTablet }"
                    (click)="playText(this.helpText, true)">
                    <div [ngClass]="platform.isLandscape() ? 'gradientBubbleLandscape' : 'gradientBubblePortrait'">
                        <div class="textContainer">
                            <span class="textBubble" [ngClass]="{'dyslexicFontHeight': globalService.dyslexicMode}">
                                {{helpText}}
                            </span>
                            <ion-img [src]="'/assets/mathia/buttons/play.svg'"
                                [ngClass]="[globalService.isMobile? 'playTextMobile':'playText']"></ion-img>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="chooseTextWrapperGlobal" *ngIf="!globalService.resizeEventRunning"
            [ngClass]="[globalService.isMobile? globalService.isLandscape ? 'chooseTextWrapperMobileL' : 'chooseTextWrapperMobile':'chooseTextWrapper']">
            <div *ngIf="!globalService.resizeEventRunning" class="chooseTextPanel">
            </div>
            <div *ngIf="!globalService.resizeEventRunning" class="chooseTextPanel chooseTextPanelText">
            </div>
        </div>

        <!-- student List + add button -->

        <!-- Players List -->
        <div *ngIf="!globalService.resizeEventRunning" class="playersGridWrapperGlobal scrollbar-primary"
            [ngClass]="[globalService.isMobile? 'playersGridWrapperMobile':'playersGridWrapper']">
            <ion-grid class="ion-no-padding ion-no-margin playersGrid"
                [ngClass]="{'playersGridMobile' : globalService.isMobile}">
                <div class="studentsContainer">
                    <ion-col size="6" *ngFor="let student of accountService.team; let i = index" class="studentList"
                        [ngClass]="{
                                'studentListRowTD' : accountService.team.length <= 2 && !globalService.isMobile,
                                'studentListPortraitMobile' : globalService.isMobile && !globalService.isLandscape,
                                'studentListLandscapeMobile2playersMax' : globalService.isMobile && globalService.isLandscape && accountService.team.length < 3,
                                'studentListLandscapeMobileMoreThan2Players' : globalService.isMobile && globalService.isLandscape && accountService.team.length > 2,
                                'studentListColumn' : accountService.team.length > 2 && !globalService.isMobile,
                                'studentListOneRow': accountService.team.length <= 2 }">
                        <ion-col class="bubbleStudent ion-no-padding ion-no-margin"
                            sizeXs="{{globalService.isLandscape && !globalService.isDesktop? '6' : globalService.isLandscape && globalService.isDesktop ? '6' : '8'}}"
                            sizeMd="{{globalService.isLandscape && !globalService.isDesktop? '6' : globalService.isLandscape && globalService.isDesktop ? '6' : '8'}}"
                            sizeXl="{{globalService.isLandscape && !globalService.isDesktop? '6' : globalService.isLandscape && globalService.isDesktop ? '5' : '6'}}">
                            <div class='gradientBubbleStudent'>
                                <div *ngIf="!classService.loggedFromHome"
                                    class="modalHeadercloseButtonContainer removeContainer"
                                    (click)="removeFromList(student)">
                                    <ion-icon class="modalHeadercloseButton" name="close-outline"></ion-icon>
                                </div>
                                <div class="studentInfo">
                                    <div class="iconWrapper"
                                        [ngClass]="{'iconWrapperMobile' : globalService.isMobile, 'iconWrapperTablet' : globalService.isTablet}">
                                        <img [ngClass]="[!globalService.isLandscape ? 'playerIconPortrait' : 'playerIcon']"
                                            src="/assets/icon/astro1.png" alt="close" />
                                    </div>

                                    <!-- Name -->
                                    <div class="nameWrapper">
                                        <p class="studentLabel"
                                            [ngClass]="{'studentLabelMobile' : globalService.isMobile, 'brighterColor' : (i + 1) % 2 === 0}">
                                            {{student.displayName}}</p>
                                    </div>
                                </div>
                            </div>
                        </ion-col>
                    </ion-col>
                </div>
                <div class="addPlayerIconContainer" [hidden]="!allowStudentAdd || classService.loggedFromHome">
                    <ion-col size="2" class="addPlayerSize"
                        sizeXs="{{globalService.isLandscape && !globalService.isDesktop ? '1' : globalService.isLandscape && globalService.isDesktop ? '2' : '2'}}"
                        sizeMd="{{globalService.isLandscape && !globalService.isDesktop ? '1' : globalService.isLandscape && globalService.isDesktop ? '1' : '2'}}">
                        <div class="playerIconWrapper">
                            <div class="addPlayerIcon" (click)="openListStudents()">
                                <ion-icon src="/assets/icon/add_student.svg" size="large" slot="icon-only"
                                    class="ion-no-padding add-student-icon">
                                </ion-icon>
                            </div>
                        </div>
                    </ion-col>
                </div>
            </ion-grid>
        </div>

        <!-- Buttons -->
        <!-- *ngIf="accountService.team === undefined || accountService.team.length > 0"   -->
        <div *ngIf="!globalService.resizeEventRunning"
            [ngClass]="globalService.isLandscape? globalService.isMobile ? 'buttonsContainerOpenedListLandscapeM' : 'buttonsContainerOpenedListLandscape' : 'buttonsContainerOpenedListPortrait'">
            <button class="btnListOpened btnReturnListOpened btnNoDecoration" (click)="backCustomLocation($event)">
                <span *ngIf="!accountService.loggedFromHome" i18n>Changer de classe</span>
                <span *ngIf="accountService.loggedFromHome" i18n>Changer de joueur</span>
            </button>
            <button class="btnListOpened btnNoDecoration"
                [ngClass]="(this.accountService.team.length > 0 && !debounce) ? 'btnStartListOpened' : 'btnStartListOpenedDisable'"
                (click)="launchActivity($event)" i18n>Valider</button>
        </div>

    </div>

</ion-content>