<div class="card" (click)="cardClicked.emit()" [@cardFlip]="currentCard.state" [class]="currentCard.state" >
  <div class="back"  [ngStyle]="{'border-color': currentCard.colorEasyMode || null}">
  </div>
    <div class="front"  [ngClass]="{'success':currentCard.state === CardStats.matched && !endFlipCards}">
      <ng-container *ngIf="!currentCard.displayText">
 
        <div class="img" [ngClass]="{'textWon': showText, 'textWonEnd':endFlipCards}" *ngIf="currentCard.imageId">
          <div class="imgContainer">
            <img [src]="currentCard.imageId">
          </div>
          <div class="textAudioContainer" [hidden]="!currentCard.displayText && !endFlipCards">
            <div class="playTextContainer">
              <img  src="/assets/icon/play-ose.svg">
            </div>
              <div class="textContainer">
                <p>{{currentCard.text}}</p>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="currentCard.displayText">
        <div class="text">
          <div class="innertext">
            <p class="frontParagraphe">{{currentCard.text}}</p>
          </div>
        </div>
      </ng-container>
    </div>
</div>