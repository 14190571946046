import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { Router } from "@angular/router";
import { addSeconds, format, isBefore, parse } from "date-fns";
import { Observable, catchError, tap, throwError, shareReplay, lastValueFrom, of, map } from "rxjs";
import { environment } from "src/environments/environment";
import { Classroom } from "../models/classroom";
import { AppLanguage } from "../models/enums/enum-list";
import { Role, Student } from "../models/student";
import { KidaiaSso } from "../models/user";
import { BaseService } from "./base.service";
import { BearerToken, ClassService } from "./class.service";
import { Journey } from "../models/journey";
import { CreditType } from "../models/in-app-state";
import { productInApp } from "./in-app-purchase.service";

@Injectable({
	providedIn: "root"
})
export class OpenSteamService extends BaseService implements ClassService {
	authBearer: string;
	private teacherInformationObs: Observable<any>;
	private listClasseTeacherObs: Observable<any>;
	private kidaiaSsoObs: Observable<KidaiaSso>;
	dataLogin: FormData;
	private ouputClasse: Classroom;
	loggedIn: boolean;
	source = "ose";
	isAren = false;
	isBeneylu = false;
	isMathador = false;
	loggedFromHome = false;
	homeStudent: any;

	constructor(private http: HttpClient, public router: Router, @Inject(LOCALE_ID) public locale: string) {
		super();
	}
	public authFromAppleCode(code?: string): Promise<KidaiaSso> {
		throw new Error("Method not implemented.");
	}
	public inAppPurchaseProduct(): Observable<productInApp[]> {
		throw new Error("Method not implemented.");
	}
	public inAppPurchaseValidation(type: CreditType, receipt: string): Observable<any> {
		throw new Error("Method not implemented.");
	}
	public authFromFamilyCode(code?: string): Promise<KidaiaSso> {
		throw new Error("Method not implemented.");
	}

	public authFromApp(login?: string, password?: string): Promise<KidaiaSso> {
		throw new Error("Method not implemented.");
	}

	public registerTeacherAccount(email: string, password: string, label: string, firstName: string): Observable<any> {
		const dataForm = new FormData();
		dataForm.append("firstname", firstName);
		dataForm.append("surname", label);
		dataForm.append("email", email);
		dataForm.append("password", password);
		dataForm.append("password_confirm", password);

		const observable = new Observable<any>(subscriber => {
			//Create account
			this.http
				.post<any>(this.postOpenSteam + "?controller=groupadmin&action=registerTeacher", dataForm, {
					withCredentials: true
				})
				.pipe(
					tap(dataTap => {
						if (!dataTap.isUserAdded) {
							if (dataTap.errors.emailExists) {
								dataTap.title = "account-management.mail_taken";
							} else if (dataTap.errors.emailInvalid) {
								dataTap.title = "account-management.mail_invalid";
							} else if (dataTap.errors.invalidPassword) {
								dataTap.title = "account-management.password_invalid";
							}
							throw new HttpErrorResponse({ status: 422, error: dataTap });
						}
					})
				)
				.subscribe({
					next: data => {
						this.dataLogin = new FormData();
						this.dataLogin.append("mail", dataForm.get("email"));
						this.dataLogin.append("password", dataForm.get("password"));
						this.dataLogin.append("za78e-username", dataForm.get("email"));
						//Activate account with link from server
						if (data.link) {
							const token = data.link.substr(data.link.indexOf("confirm_account.php?") + 20);
							this.http.get(this.postOpenSteam + "?controller=kidaia&action=confirm_account&" + token).subscribe(() => {
								subscriber.next(data);
								subscriber.complete();
							});
						}
					},
					error: err => {
						subscriber.error(err);
						subscriber.complete;
					}
				});
		});
		return observable;
	}

	classes: Classroom[];
	public getAssignationList(idProf: string): Observable<{ data: Journey[] }> {
		return this.http.post<{ data: Journey[] }>(this.postOpenSteam + "?controller=classroom&action=get_by_user", null, {
			withCredentials: true
		});
		// let assignationObs = new Observable<any>(subscriber => {
		// 	subscriber.next({ data: [] });
		// 	subscriber.complete();
		// });
		// return assignationObs;
	}

	/**
	 * request a rest transaction token for tralalere
	 * if only login specified the loggin is the authBearer token
	 */
	login(login?: string, password?: string): Observable<BearerToken> {
		const observable = new Observable<BearerToken>(subscriber => {
			if (environment.kidaia) {
				if (this.loggedIn) {
					subscriber.next({ access_token: null, expires_in: null });
					subscriber.complete();
				} else {
					if (login && password) {
						this.dataLogin = new FormData();
						this.dataLogin.append("mail", login);
						this.dataLogin.append("password", password);
						this.dataLogin.append("za78e-username", login);
					} else if (login && !password) {
						let loginSSO = login.split(":");
						this.dataLogin = new FormData();
						this.dataLogin.append("mail", loginSSO[0]);
						this.dataLogin.append("password", loginSSO[1]);
						this.dataLogin.append("za78e-username", loginSSO[0]);
					} else if (!this.dataLogin) {
						const error: any = new Error("No bearer for autolog");
						error.timestamp = Date.now();
						subscriber.error(error);
						subscriber.complete();
						return null;
					}
					this.disconnectUser().subscribe(() => {
						this.http
							.post<BearerToken>(this.postOpenSteam + "?controller=user&action=login", this.dataLogin, {
								withCredentials: true
							})
							.pipe(
								catchError(async err => {
									this.tralaError = true;
									return err;
								})
							)
							.pipe(
								tap(res => {
									if (res.error) {
										throw throwError(() => res);
									} else {
										this.authBearer = this.dataLogin.get("login") + ":" + this.dataLogin.get("password");
										this.setSession({ access_token: null, expires_in: "1200" });
										// remove listClasseTeacherObs we want a new class list for the new user
										this.listClasseTeacherObs = null;
									}
								})
							)
							.subscribe({
								next: next => {
									subscriber.next(next);
									subscriber.complete();
								},
								error: error => {
									const form = new FormData();
									form.append("email", login);
									form.append("password", password);
									this.http.post<KidaiaSso>(this.postApiMonKidaiaLoggedIn + "?action=authFromApp", form, {
										withCredentials: true
									}).subscribe({next:()=>{
										subscriber.next(({runKidaiaSSO: true} as any));
										subscriber.complete();
									}, error: () => {
										subscriber.error(error);
										subscriber.complete();
									}});
								}
							});
					});
				}
			} else {
				subscriber.next({ access_token: null, expires_in: null });
				subscriber.complete();
			}
		});
		return observable;
	}

	public setSession(authResult: BearerToken) {
		const expiresAt = addSeconds(new Date(), Number(authResult.expires_in));

		localStorage.setItem("id_token_trala", authResult.access_token);
		localStorage.setItem("expires_at_trala", format(expiresAt, "T"));
	}
	/**
	 *  return tralalere token for auth
	 *  @returns string
	 */
	public getSessionToken(): Promise<string> {
		return new Promise((resolve, reject) => {
			if (this.isLoggedIn()) {
				resolve(localStorage.getItem("id_token_trala"));
			} else {
				lastValueFrom(this.login()).then(
					data => {
						resolve(data.access_token);
					},
					error => {
						reject(error);
					}
				);
			}
		});
	}

	logout() {
		localStorage.removeItem("id_token_trala");
		localStorage.removeItem("expires_at_trala");
	}

	public isLoggedIn(): boolean {
		return this.loggedIn;
	}

	isLoggedOut() {
		return !this.isLoggedIn();
	}

	getExpirationTralalere(): Date {
		const expiration = localStorage.getItem("expires_at_trala");
		const expiresAt = parse(expiration, "T", new Date());
		return expiresAt;
	}

	private getGroupDetail(codeClass: string): Observable<any> {
		return this.http.get<any>(this.postOpenSteam + "?controller=kidaia&action=get_classroom_info&classCode=" + codeClass, {
			withCredentials: true
		});
	}

	/**
	 * Get a classe from tralalere with an array of Student
	 * @return Classroom
	 */
	getClasseFromGroupDetail(codeClass: string): Observable<Classroom> {
		return this.getGroupDetail(codeClass)
			.pipe(
				catchError(err => {
					return of(err);
				})
			)
			.pipe(
				map(data => {
					if (data) {
						const outputClasse = new Classroom(
							data.classroom.id,
							data.classroom.link,
							data.classroom.name,
							data.classroom.name
						);
						const grade = data.classroom.name;
						data.students.forEach(element => {
							let student = null;
							if (element.user.pseudo === "elevetest") {
								student = new Student(element.user.id, outputClasse, $localize`Mode Enseignant`);
								student.preview = true;
							} else {
								student = new Student(element.user.id, outputClasse, element.user.pseudo);
							}
							//student.parentalConsent = element.parentalConsent === 1;
							student.level = grade;
							student.picture = element.user.picture;
							outputClasse.students.push(student);
						});
						this.ouputClasse = outputClasse;
						return outputClasse;
					}
				})
			);
	}

	/**
	 * Get teacher/Parent account information raw data from tralalere
	 * Not use in opensteam
	 */
	getTeacherInformation(): Promise<any> {
		return null;
	}

	getListClasseTeacher(): Promise<Classroom[]> {
		if (!this.listClasseTeacherObs) {
			this.listClasseTeacherObs = this.http
				.get<any>(this.postOpenSteam + "?controller=kidaia&action=get_kidaia_teacher_classroom", { withCredentials: true })
				.pipe(
					catchError(() => {
						return of(null);
					})
				)
				.pipe(
					shareReplay(),
					map(data => {
						if (data) {
							//throw new Error("missing teacher UID in new Classroom(uid)");
							const classes = new Array();
							data.forEach(classe => {
								classes.push(new Classroom(null, classe.classroom.link, classe.classroom.name, classe.classroom.name));
							});
							if (this.locale === AppLanguage.EN && environment.kidaia) {
								classes.forEach(classe => {
									if (classe.label === "CP") {
										classe.label = "Age 5";
									} else if (classe.label === "CE1") {
										classe.label = "Age 6";
									} else if (classe.label === "CE2") {
										classe.label = "Age 7";
									} else if (classe.label === "CM1") {
										classe.label = "Age 8";
									} else if (classe.label === "CM2") {
										classe.label = "Age 9";
									}
								});
							}
							this.classes = classes;
							return classes;
						}
					})
				);
		}
		return lastValueFrom<Classroom[]>(this.listClasseTeacherObs);
	}

	/**
	 * Create Student tralalere use classe from student if classe exist or first classe from parent account
	 * @param student use name and parental consent and picture classe not ready actually
	 */
	createStudent(student: Student): Promise<void> {
		return new Promise((resolve, reject) => {
			// retrieve parent/teacher information for mail region and classe

			const postData = new FormData();
			postData.append("users[]", student.name);
			postData.append("classroom", student.classe.id);
			// create a learner
			this.http
				.post<any>(this.postOpenSteam + "?controller=classroom_link_user&action=add_users", postData, { withCredentials: true })
				.pipe(map(data => {}))
				.subscribe({
					next: data => {
						resolve();
					},
					error: error => {
						reject(error);
					}
				});
		});
	}

	/**
	 * Delete student tralalere use id of The Student
	 */
	deleteStudent(student: Student): Promise<void> {
		return new Promise((resolve, reject) => {
			const postData = new FormData();
			postData.append("id", student.id);
			this.http
				.post<any>(this.postOpenSteam + "?controller=user&action=delete", postData, { withCredentials: true })
				.pipe(map(data => {}))
				.subscribe({
					next: data => {
						resolve();
					},
					error: error => {
						reject(error);
					}
				});
		});
	}

	/**
	 * Update Student information update name and parental consent
	 */
	updateStudent(student: Student): Promise<void> {
		return new Promise((resolve, reject) => {
			const postData = new FormData();
			postData.append("pseudo", student.name);
			postData.append("id", student.id);
			/*const studentBeforeChange = this.ouputClasse.students.find(lStudent => {
				return lStudent.id === student.id;
			});
			if (studentBeforeChange.classe.id !== student.classe.id) {
				postData.append("oldClasse", studentBeforeChange.classe.id);
				postData.append("newClasse", student.classe.id);
				this.http
					.post<any>(this.postOpenSteam + "?controller=kidaia&action=change_classroom_user", postData, { withCredentials: true })
					.subscribe();
			}*/
			this.http
				.post<any>(this.postOpenSteam + "?controller=user&action=change_pseudo_classroom_user", postData, { withCredentials: true })
				.pipe(map(data => {}))
				.subscribe({
					next: data => {
						resolve();
					},
					error: error => {
						reject(error);
					}
				});
		});
	}

	/**
	 * Get Kidaia sso information
	 */
	getKidaiaSso(): Promise<KidaiaSso> {
		if (environment.autolog && !this.kidaiaSsoObs) {
			this.kidaiaSsoObs = new Observable<KidaiaSso>(subscriber => {
				subscriber.next({
					authentication: {
						email: {
							email: environment.login,
							email_confirmed: true
						}
					},
					user_signed_up: true,
					subscriptionid_number: null,
					tralalereauthbearer_text: environment.login + ":" + environment.password,
					oseauthbearer_text: environment.login + ":" + environment.password,
					maxuser_number: -1,
					_id: null,
					premium: true,
					premium_ose: true,
					codefamille: ""
				});

				subscriber.complete();
			});
		} else {
			this.kidaiaSsoObs = new Observable<KidaiaSso>(subscriber => {
				this.http
					.get(this.postOpenSteam + "?controller=session", {
						withCredentials: true
					})
					.subscribe((data: any) => {
						if (!data || !data.isRegular) {
							this.http
								.post<KidaiaSso>(this.postApiMonKidaiaLoggedIn + "?action=getKidaiaInfo", null, {
									withCredentials: true
								})
								.pipe(shareReplay())
								.subscribe({next: data => {
									subscriber.next(data);
									subscriber.complete();
								}, error: () => {
									subscriber.error("not connected");
									subscriber.complete();
								}} );
						} else {
							this.loggedIn = true;
							subscriber.next({
								authentication: {
									email: {
										email: data.isRegular,
										email_confirmed: true
									}
								},
								user_signed_up: true,
								subscriptionid_number: null,
								tralalereauthbearer_text: "azerty",
								oseauthbearer_text: "azerty",
								maxuser_number: -1,
								_id: null,
								premium: true,
								premium_ose: true,
								codefamille: ""
							});
							subscriber.complete();
						}
					});
			});
		}
		return lastValueFrom(this.kidaiaSsoObs);
	}

	/**
	 * Get the password from tokenBearer
	 */
	private getLearnerPassword(): string {
		const decode = atob(this.authBearer.slice(6));
		const splited = decode.split(":");
		return splited.length > 1 ? splited[1] : null;
	}

	verifPassword(formData: FormData) {
		const dataLogin = new FormData();
		dataLogin.append("mail", formData.get("email").toString());
		dataLogin.append("password", formData.get("password").toString());
		dataLogin.append("za78e-username", formData.get("email").toString());
		return this.http
			.post<BearerToken>(this.postOpenSteam + "?controller=user&action=login", dataLogin)
			.pipe(
				catchError(async err => {
					this.tralaError = true;
					return err;
				})
			)
			.pipe(
				tap(res => {
					if (res.error) {
						throw throwError(() => res);
					}
				})
			);
	}

	public redirectionError() {
		if (!environment.autolog) {
			this.router.navigateByUrl("/starting");
		}
	}

	public disconnectUser(): Observable<any> {
		this.loggedIn = false;
		this.authBearer = null;
		// delete teacher's classrooms
		this.listClasseTeacherObs = null;
		return new Observable<void>((subscriber) => {
			const logoutOpenSteam = lastValueFrom(this.http.post(this.postOpenSteam + "?controller=user&action=disconnect", null, {
				withCredentials: true
			}))
			const logoutWP = lastValueFrom(this.http.post(this.postApiMonKidaiaLoggedIn + "?action=logout", null, {
				withCredentials: true
			}));
			Promise.all([logoutOpenSteam, logoutWP]).then(() => {
				subscriber.next();
				subscriber.complete();
			});

		});
	}
	public loginCodeMaison(code: string) {
		return new Promise<string>((resolve, reject) => {
			reject("not implemented");
		});
	}

	public isLoggedInStudent(): boolean {
		return isBefore(new Date(), this.getExpirationStudent());
	}

	getExpirationStudent(): Date {
		const expiration = localStorage.getItem("expires_at_student");
		const expiresAt = parse(expiration, "T", new Date());
		return expiresAt;
	}
}
