import { ConnectionStatus, NetworkService } from "src/app/services/network.service";
import { Injectable } from "@angular/core";
import { Platform as Platform } from "@ionic/angular";
import { EMPTY, Observable, ReplaySubject, Subject, lastValueFrom } from "rxjs";
import { CreditType, InAppState, ResultSignInApple } from "../models/in-app-state";
import "cordova-plugin-purchase";
import { BaseService } from "./base.service";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import {
	ASAuthorizationAppleIDRequest,
	AppleSignInErrorResponse,
	AppleSignInResponse,
	SignInWithApple
} from "@awesome-cordova-plugins/sign-in-with-apple/ngx";

declare var window: {
	CdvPurchase: {
		store: CdvPurchase.Store;
		productType: CdvPurchase.ProductType;
		Platform: CdvPurchase.Platform;
	};
};
export interface productInApp extends CdvPurchase.IRegisterProduct {
	title: string;
	price: string;
	text: string;
}
@Injectable({
	providedIn: "root"
})
export class InAppPurchaseService extends BaseService {
	public INAPP_PRODUCTS_IDS: productInApp[];
	products: CdvPurchase.Product[];
	obsNetworkStatus: any;
	online: boolean;
	logWithApple: boolean;
	canLinkPurchase: boolean;
	public get store() {
		return window.CdvPurchase?.store;
	}
	storeReadyObs: ReplaySubject<boolean>;
	purchaseFinishObs: Subject<boolean>;
	productCancelledObs: Subject<any>;
	productUpdatedObs: Subject<any>;
	productInitiatedObs: Subject<any>;
	appleUser: AppleSignInResponse;

	state: InAppState;

	constructor(
		public platform: Platform,
		private http: HttpClient,
		networkService: NetworkService,
		public signInWithApple: SignInWithApple
	) {
		super();

		this.platform.ready().then(async () => {
			this.storeReadyObs = new ReplaySubject();
			this.purchaseFinishObs = new ReplaySubject();
			this.state = new InAppState();
			this.INAPP_PRODUCTS_IDS = [
				{
					id: "MM1F",
					type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
					platform: CdvPurchase.Platform.APPLE_APPSTORE,
					title: "Mensuel",
					price: "9,99 €",
					text: "Deux enfants"
				},
				{
					id: "MT3F",
					type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
					platform: CdvPurchase.Platform.APPLE_APPSTORE,
					title: "Trimestriel",
					price: "23,99 €",
					text: "Deux enfants"
				},
				{
					id: "MA12F",
					type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
					platform: CdvPurchase.Platform.APPLE_APPSTORE,
					title: "Annuel",
					price: "79,99 €",
					text: "Deux enfants"
				}
			];
			(window as any).state = this.state;
			if (platform.is("cordova") && platform.is("ios")) {
				try {
					let result = await lastValueFrom(this.inAppPurchaseProduct());
					if (Array.isArray(result)){
						this.INAPP_PRODUCTS_IDS = result;
					}
				} catch (error) {}
				this.store.verbosity = CdvPurchase.LogLevel.DEBUG;

				this.registerProducts();
				this.obsNetworkStatus = networkService.onNetworkChange().subscribe(async value => {
					this.online = value === ConnectionStatus.Online;
					if (this.online) {
						try {
							this.INAPP_PRODUCTS_IDS = await lastValueFrom(this.inAppPurchaseProduct());
						} catch (error) {}
						this.update();
						this.restorePurchases();
					}
				});
			} else {
				this.storeReadyObs.next(true);
				this.storeReadyObs.complete();
			}
		});
	}

	public inAppPurchaseValidation(type: CreditType, receipt: string): Observable<any> {
		const form = new FormData();
		form.append("type", type);
		form.append("receipt", receipt);
		if (this.canLinkPurchase) {
			form.append("canLinkPurchase", "1");
		}
		return this.http.post<any>(this.postKidaia + "?action=iosValidation", form, { withCredentials: true });
	}

	inAppPurchaseProduct(): Observable<productInApp[]> {
		const form = new FormData();
		form.append("version", environment.activityVersion.toString());
		return this.http.post<productInApp[]>(this.postKidaia + "?action=inAppProduct", form);
	}

	registerProducts() {
		if (this.store) {
			console.log("\\o/ REGISTER START \\o/");
			this.store.validator = (product, callback) => {
				const transactionType: CreditType = this.platform.is("ios") ? CreditType.IOS : CreditType.ANDROID;
				let transactionReceipt: string;
				if (product.transaction.type === CdvPurchase.Platform.APPLE_APPSTORE) {
					transactionReceipt = product.transaction.appStoreReceipt;
				} else if (product.transaction.type === CdvPurchase.Platform.GOOGLE_PLAY) {
					transactionReceipt = product.transaction.receipt;
				}
				this.inAppPurchaseValidation(transactionType, transactionReceipt).subscribe(
					response => {
						try {
							if (response.valid) {
								let transaction = null;
								let collection = [];

								if (response?.raw?.latest_receipt_info?.length && response?.raw?.latest_receipt_info.length > 0) {
									transaction = response?.raw?.latest_receipt_info[0];

									let pendingRenewal = response?.raw?.pending_renewal_info[0];
									response?.raw?.latest_receipt_info.forEach(trans => {
										collection.push({
											/** Product identifier */
											id: trans.product_id,
											purchaseId: trans.original_transaction_id,

											/** Identifier of the last transaction (optional) */
											transactionId: trans.transaction_id,

											/** Date of first purchase (timestamp). */
											purchaseDate: trans.purchase_date_ms,

											/** Date of expiry for a subscription. */
											expiryDate: trans.expires_date_ms,

											/** True when a subscription is expired. */
											isExpired: trans.expires_date_ms && new Date() > trans.expires_date_ms,

											/** True when a subscription a subscription is in the grace period after a failed attempt to collect payment */
											isBillingRetryPeriod: pendingRenewal.is_in_billing_retry_period === 1,

											/** True when a subscription is in trial period */
											isTrialPeriod: trans.is_in_trial_period,

											/** True when a subscription is in introductory pricing period */
											isIntroPeriod: trans.is_in_intro_offer_period
										});
									});
								}
								callback({
									// Indicates a successful request

									ok: true,

									data: {
										// Id of the product that have been validated
										id: product.id,
										// Tell the plugin that we've used the latest receipt
										latest_receipt: transaction ? true : false,
										// Native transaction detail
										transaction: transaction,
										collection
									}
								});
							} else {
								callback({ ok: false });
							}
						} catch (e) {
							callback({ ok: false });
						}
					},
					err => {
						callback({ ok: false });
					}
				);
				console.log("inAppPurchase.validator", JSON.stringify(product));
			};
			this.store.register(this.INAPP_PRODUCTS_IDS);
			this.setupEventHandlers();
			this.store.when;
			this.store.initialize([CdvPurchase.Platform.APPLE_APPSTORE]).then(() => {
				console.log("\\o/ STORE INITIALYZE END \\o/");
				this.products = this.store.products;
			});
		}
	}

	private stateUpdates(): Partial<InAppState> {
		// subscription purchases sorted by expiry date
		const sortedSubscriptions = this.store.verifiedPurchases
			.filter(purchase => {
				const product = this.store.get(purchase.id, purchase.platform);
				return product?.type === CdvPurchase.ProductType.PAID_SUBSCRIPTION;
			})
			.sort((a, b) => (a.expiryDate ?? a.purchaseDate ?? 0) - (b.expiryDate ?? b.purchaseDate ?? 0));

		// active one
		const activeSubscription = this.store.verifiedPurchases.find(purchase => {
			const product = this.store.get(purchase.id, purchase.platform);
			return product?.type === CdvPurchase.ProductType.PAID_SUBSCRIPTION && product.owned;
		});
		if (activeSubscription) {
			this.purchaseFinishObs.next(true);
			this.purchaseFinishObs.complete();
		}

		// no active one, show info about the expired one
		const expiredSubscription = activeSubscription ? undefined : sortedSubscriptions.slice(-1)[0];

		return { activeSubscription, expiredSubscription };
	}
	private setupEventHandlers() {
		this.store
			.when()
			.productUpdated(() => {
				console.log("productUpdated");
				this.state.set({
					products: this.store.products,
					...this.stateUpdates()
				});
			})
			.receiptsReady(() => {
				console.log("receiptsReady");
				this.state.set({
					transactions: this.store.localTransactions,
					...this.stateUpdates()
				});
			})
			.receiptUpdated(receipt => {
				console.log("receiptUpdated", receipt);
				this.state.set({
					transactions: this.store.localTransactions,
					...this.stateUpdates()
				});
			})
			.approved(transaction => {
				console.log("approved", transaction);
				transaction.verify();
				this.state.set({
					isVerifying: true,
					...this.stateUpdates()
				});
			})
			.verified(receipt => {
				console.log("verified", receipt);
				this.state.set({
					purchases: this.store.verifiedPurchases,
					isVerifying: false,
					...this.stateUpdates()
				});
				receipt.finish();
				this.verifiedCallback.forEach(callback => callback());
			})
			.unverified(unverified => {
				console.log("unveridifed", unverified);
				this.state.set({
					isProcessingOrder: false,
					isVerifying: false,
					...this.stateUpdates()
				});
			})
			.receiptsVerified(receiptsVerified => {
				console.log("veridifed", receiptsVerified);
				console.log("\\o/ STORE READY \\o/");
				this.storeReadyObs.next(true);
				this.storeReadyObs.complete();
			})
			.finished(transaction => {
				this.state.set({
					isProcessingOrder: false,
					...this.stateUpdates()
				});
			});

		// Show errors for 10 seconds.
		this.store.error(error => {
			if (error.code === CdvPurchase.ErrorCode.PAYMENT_CANCELLED) {
				console.log("The user cancelled the purchase flow.");
				return;
			}
			this.state.set({ error: `ERROR ${error.code}: ${error.message}` });
			setTimeout(() => {
				this.state.set({ error: `` });
			}, 10000);
		});
	}

	update() {
		this.state.set({ isRefreshing: true });
		this.store.update().then(() => {
			this.state.set({ isRefreshing: false });
		});
	}

	restorePurchases() {
		this.state.set({ isRefreshing: true });
		let promise = this.store.restorePurchases();
		promise.then(() => {
			this.state.set({ isRefreshing: false });
		});
		return promise;
	}
	// called when a receipt has been verified with the server.
	private verifiedCallback: VoidFunction[] = [];
	onVerified(callback: VoidFunction) {
		this.verifiedCallback.push(callback);
	}

	order(productId: string) {
		return this.subscribe(CdvPurchase.Platform.APPLE_APPSTORE, productId, null);
	}

	subscribe(platform: CdvPurchase.Platform, productId: string, offerId: string) {
		this.state.set({ isProcessingOrder: true, error: "" });
		let promise = this.store.get(productId, platform)?.getOffer(offerId)?.order();

		return promise.then(error => {
			if (error) {
				this.state.set({ isProcessingOrder: false });
			}
			if (error?.code === CdvPurchase.ErrorCode.PAYMENT_CANCELLED) {
				// payment cancelled by the user
			}
		});
	}

	manageSubscriptions() {
		this.store.manageSubscriptions();
	}

	deleteAccount() {
		const idPurchase = this.appleUser?.user
			? this.appleUser?.user
			: this.state?.activeSubscription?.purchaseId
			? this.state?.activeSubscription?.purchaseId
			: this.state?.expiredSubscription?.purchaseId
			? this.state?.expiredSubscription?.purchaseId
			: null;
		if (idPurchase) {
			const form = new FormData();
			form.append("transaction_id", idPurchase);
			return this.http.post<void>(this.postKidaia + "?action=iosDeleteAccount", form, { withCredentials: true });
		} else {
			return EMPTY;
		}
	}

	appleCreateLogAccount(subscribe = false) {
		if (this.appleUser) {
			const form = new FormData();
			form.append("uuidAppleSignIn", this.appleUser.user);
			form.append("token", this.appleUser.authorizationCode);
			form.append("email", this.appleUser.email);
			form.append("familyName", this.appleUser.fullName.familyName);
			form.append("givenName", this.appleUser.fullName.givenName);
			if (!subscribe) {
				form.append("onlyConnect", "1");
			}

			return this.http.post<ResultSignInApple>(this.postKidaia + "?action=appleCreateLogAccount", form, { withCredentials: true });
		} else {
			return EMPTY;
		}
	}

	checkOprhanPurchaseId() {
		const subscriptionId = this.state.activeSubscription
			? this.state.activeSubscription.purchaseId
			: this.state.expiredSubscription
			? this.state.expiredSubscription.purchaseId
			: null;
		if (subscriptionId) {
			const form = new FormData();
			form.append("uuidApplePurchase", subscriptionId);

			return this.http.post<boolean>(this.postKidaia + "?action=checkOrphanPurchaseId", form, { withCredentials: true });
		} else {
			return EMPTY;
		}
	}

	/**
	 * signin with apple and retrieve user Information in this.appleUser
	 * @param subscribe can create and acocunt if not exist with signin information
	 * @returns
	 */
	signInApple(subscribe = false) {
		return new Promise<ResultSignInApple>((resolve, reject) => {
			this.signInWithApple
				.signin({
					requestedScopes: [
						ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
						ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
					]
				})
				.then(async (res: AppleSignInResponse) => {
					this.appleUser = res;
					// https://developer.apple.com/documentation/signinwithapplerestapi/verifying_a_user
					//alert('Send token to apple for verification: ' + res.identityToken);
					if (subscribe) {
						try {
							const resultAppleSubscribe = await lastValueFrom(this.appleCreateLogAccount(subscribe));
							resolve(resultAppleSubscribe);
						} catch (error) {
							reject(error);
						}
					} else {
						resolve({
							connected: true,
							created: false
						});
					}
				})
				.catch((error: AppleSignInErrorResponse) => {
					reject(error);
				});
		});
	}
}
