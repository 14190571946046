import { ChangeDetectorRef } from "@angular/core";
import { AppUtils } from "../app-utils";
import { MenuTabItems, SettingName, SettingsComponent } from "../components/settings/settings.component";
import { AccountService } from "../services/account.service";
import { GlobalService } from "../services/global.service";
import { PlayTTSService } from "../services/play-tts.service";
import { Param } from "./params/param";
import { Scenario } from "./scenario";
import { ScenarioPhrase } from "./scenario-phrase";
import { environment } from "src/environments/environment";
export interface HelpPhrasesActivitySettings {
	[paramName: string]: { label: string; phrases: Array<{ img: string; text: string }> };
}

export interface HelpPhrases {
	label: string;
	description: string;
	icon;
}

class ScenarioAnimations {
	public APPEARANCE_CLASSNAME = "highlight";
	public page: SettingsComponent;
	public eachMenuTab;
	public parametersContainers;
	// public eachSettingsElement;
	constructor(page) {
		this.page = page;
		this.eachMenuTab = this.page.stepWizardItems.nativeElement.querySelectorAll(".step-wizard-item_description");
	}

	async displayAllMenuTabs(): Promise<void> {
		return new Promise(async resolve => {
			for (const eachTabMenu of this.eachMenuTab) {
				this.page.renderer.addClass(eachTabMenu, this.APPEARANCE_CLASSNAME);
				await AppUtils.timeOut(250);
				this.page.renderer.removeClass(eachTabMenu, this.APPEARANCE_CLASSNAME);
			}
			this.page.stepWizardItems.nativeElement.classList.add(this.APPEARANCE_CLASSNAME);
			this.page.stepWizardItems.nativeElement.addEventListener("animationend", () => {
				resolve();
			});
		});
	}

	async displayMenusAllItems(tabIndex): Promise<void> {
		return new Promise(async resolve => {
			let paramContainerTargetted;
			const selectedScreen = this.page.wizardTabItems.findIndex(item => {
				return item.menu === this.page.menuTabItemSelected.menu;
			});
			paramContainerTargetted = this.page.paramsContainer.toArray()[selectedScreen].nativeElement;

			this.eachMenuTab[selectedScreen].classList.add(this.APPEARANCE_CLASSNAME);

			for (const eachTabMenu of paramContainerTargetted.children) {
				this.page.renderer.addClass(eachTabMenu, this.APPEARANCE_CLASSNAME);
				await AppUtils.timeOut(250);
				this.page.renderer.removeClass(eachTabMenu, this.APPEARANCE_CLASSNAME);
			}
			paramContainerTargetted.classList.add(this.APPEARANCE_CLASSNAME, "animate");
			paramContainerTargetted.addEventListener("animationend", () => {
				paramContainerTargetted.classList.remove(this.APPEARANCE_CLASSNAME);
				resolve();
			});
		});
	}

	getSettingsByOrder(menuItem: MenuTabItems): Array<{ text: string; indexDOMText: number }> {
		const elements = new Array();
		let indexDOMText = 0;
		const selectedScreen = this.page.wizardTabItems.findIndex(item => {
			return item.menu === menuItem;
		});
		const allParams = this.page.paramsContainer.toArray()[selectedScreen].nativeElement.children;
		allParams.forEach(element => {
			let text = element.querySelector(".labelKid")?.textContent;
			if (text) {
				text = text.trim();
				elements.push({ text, indexDOMText });
				indexDOMText++;
			}
		});

		return elements;
	}

	getMenuTabByIndex(menuName: MenuTabItems) {
		this.page.menuTabItemSelected = this.page.wizardTabItems.find(item => item.menu === menuName);
	}

	async targetEachParams(tabIndex, isLastElement) {
		const selectedScreenIndex = this.page.wizardTabItems.findIndex(item => {
			return item.menu === this.page.menuTabItemSelected.menu;
		});

		const elementTargetted = this.page.paramsContainer.toArray()[selectedScreenIndex].nativeElement;
		elementTargetted.children[tabIndex].classList.add(this.APPEARANCE_CLASSNAME);
		if (isLastElement) {
			await AppUtils.timeOut(500);
			elementTargetted.classList.add(this.APPEARANCE_CLASSNAME);
		}
	}
}

/**
 * Scénarios pour le tutriel des Gabarits
 */
export class ScenarioSettings extends Scenario {

	constructor(
		accountService: AccountService,
		globalService: GlobalService,
		page: any,
		cd: ChangeDetectorRef,
		public ttsService: PlayTTSService
	) {
		super(accountService, globalService, page, cd, ttsService);
	}

	/**
	 * Params phrases available  depending on activity page / free mode
	 * @returns first param name as key and following values : label and phrases(icon,text)
	 */
	static getActivityParamsTutorialPhrases(generalParamsAvailable: Param[]): HelpPhrasesActivitySettings {
		const tutorialPhrases: HelpPhrasesActivitySettings = {};
		generalParamsAvailable.forEach(param => {
			tutorialPhrases[param.name] = {} as any;
			tutorialPhrases[param.name].label = param.label;

			if (param.name === "input-method") {
				tutorialPhrases[param.name].phrases = ScenarioSettings.manageInputMethodPhrases(param.selectionList);
			}

			if (param.name === "holo") {
				tutorialPhrases[param.name].phrases = ScenarioSettings.manageHoloPhrases(param.selectionList);
			}

			if (param.name === "remoteHost") {
				tutorialPhrases[param.name].phrases = ScenarioSettings.manageRemoteHostPhrases(param.selectionList);
			}
		});

		return tutorialPhrases;
	}

	private static manageInputMethodPhrases(paramsSelectionList): Array<{ img: string; text: string }> {
		const paramsList: Array<{ img: string; text: string }> = new Array();
		if (paramsSelectionList?.length > 0) {
			paramsSelectionList.forEach(list => {
				if (list[1] === "stt") {
					paramsList.push({ img: list[4], text: $localize`Supra Vocal pour répondre en me parlant.` });
				} else if (list[1] === "numpad") {
					paramsList.push({ img: list[4], text: $localize`Cosmo Clavier pour répondre à l’aide du clavier numérique.` });
				} else if (list[1] === "drawing") {
					paramsList.push({ img: list[4], text: $localize`Astro Ardoise pour dessiner le résultat sur l’écran.` });
				}
			});
		}

		return paramsList;
	}

	private static manageHoloPhrases(paramsSelectionList): Array<{ img: string; text: string }> {
		const paramsList: Array<{ img: string; text: string }> = new Array();
		if (paramsSelectionList?.length > 0) {
			paramsSelectionList.forEach(list => {
				if (list[1] === "-1") {
					paramsList.push({
						img: list[4],
						text: $localize`Projeter un hologramme sur une pyramide si vous disposez du matériel nécessaire.`
					});
				} else if (list[1] === "2") {
					paramsList.push({
						img: list[4],
						text: $localize`Projeter un hologramme sur une vitre si vous disposez du matériel nécessaire.`
					});
				}
			});
		}

		return paramsList;
	}

	private static manageRemoteHostPhrases(paramsSelectionList): Array<{ img: string; text: string }> {
		const paramsList: Array<{ img: string; text: string }> = new Array();
		if (paramsSelectionList?.length > 0) {
			paramsSelectionList.forEach(list => {
				if (list[1] === "1") {
					paramsList.push({
						img: list[4],
						text: $localize`Le contrôle à distance permet de répondre aux questions de l’exercice depuis un autre appareil.`
					});
				}
			});
		}
		return paramsList;
	}
	static get assistantPhrases(): Array<HelpPhrases> {
		const phrases: Array<HelpPhrases> = new Array();
		phrases.push();

		return phrases;
	}
	static get audioMicroPhrases(): Array<HelpPhrases> {
		const phrases: Array<HelpPhrases> = new Array();
		phrases.push(
			{
				label: $localize`Volume voix`,
				description: $localize`Permet d’ajuster le volume de la synthèse vocale.`,
				icon: { name: "chatbubbles", size: "normal" }
			},
			{
				label: $localize`Volume sons`,
				description: $localize`Permet d’ajuster le volume des bruitages (bip touche et effets sonores).`,
				icon: { name: "volume-high-outline", size: "normal" }
			},
			{
				label: $localize`Volume musique`,
				description: $localize`Permet d’ajuster le volume de la musique.`,
				icon: { name: "musical-notes", size: "normal" }
			},
			{
				label: $localize`Voix`,
				description: $localize`Permet de choisir la voix parmi celles disponibles sur votre appareil.`,
				icon: { name: `mic`, size: "normal" }
			},
			{
				label: $localize`Vitesse voix`,
				description: $localize`Permet d’accélérer ou ralentir le rythme de la synthèse vocale.`,
				icon: { name: `speedometer`, size: "normal" }
			},
			{
				label: $localize`Test du micro`,
				description: $localize`Permet de tester ton micro en cliquant sur ce bouton.`,
				icon: { name: `mic-outline`, size: "normal" }
			}
		);

		return phrases;
	}

	static get graphicPhrases(): Array<HelpPhrases> {
		const phrases: Array<HelpPhrases> = new Array();
		phrases.push(
			{
				label: SettingName.filtreLumiereBleu,
				description: $localize`Permet de réduire les effets négatifs de la lumière bleue. Conseillé le soir en complément du "mode nuit" et du réglage de l’intensité lumineuse de votre appareil.`,
				icon: { name: "contrast", size: "normal" }
			},
			{
				label: SettingName.modeFaiblePerformance,
				description: $localize`Le mode performance te permet d’utiliser Mathia même si tu as une faible connexion internet ou si tu travailles sur un ancien appareil (ordinateur, tablette) ou disposant de peu de mémoire vive (RAM < 4 Giga octets)`,
				icon: { src: `/assets/icon/icon-turtle.svg`, size: "normal" }
			});
		if(!environment.kidaia){
			phrases.push(
				{
					label: SettingName.voix,
					description: $localize`Permet de désactiver la synthèse vocale en dehors des exercices.`,
					icon: { name: `mic`, size: "normal" }
				}
			);
		}
		phrases.push(	{
				label: SettingName.modeDyslexique,
				description: $localize`Active la police d’écriture OpenDyslexic pour faciliter la lecture.`,
				icon: { name: `eye`, size: "normal" }
			}
		);

		return phrases;
	}

	/**
	 * Explanation of settings fir the first time
	 */
	async launchSettingsTuto() {
		const settingsAnimations = new ScenarioAnimations(this.page);
		const speechSequences = [
			new ScenarioPhrase([
				$localize`Je vais t'accompagner en t’expliquant les différents paramètres afin que tu puisses mieux les configurer.`
			]),
			new ScenarioPhrase(
				[$localize`En haut nous avons les différents menus permettant d’accéder aux différents réglages.`],
				async () => {
					await settingsAnimations.displayAllMenuTabs();
				}
			)
		];

		speechSequences.push(
			new ScenarioPhrase([$localize`Menu audio`], async () => {
				await settingsAnimations.displayMenusAllItems(0);
			})
		);
		let paramDOMElementsOrder = settingsAnimations.getSettingsByOrder(MenuTabItems.audio);
		paramDOMElementsOrder.forEach(element => {
			speechSequences.push(
				new ScenarioPhrase([element.text], () => {
					const isLast = element.indexDOMText === paramDOMElementsOrder.length - 1;
					settingsAnimations.targetEachParams(element.indexDOMText, isLast);
				})
			);
		});

		speechSequences.push(
			new ScenarioPhrase([$localize`Passons au menu suivant`], () => {
				settingsAnimations.getMenuTabByIndex(MenuTabItems.graphic);
			}),
			new ScenarioPhrase([$localize`Menu accessibilité`], () => {})
		);
		paramDOMElementsOrder = settingsAnimations.getSettingsByOrder(MenuTabItems.graphic);
		paramDOMElementsOrder.forEach(element => {
			speechSequences.push(
				new ScenarioPhrase([element.text], () => {
					const isLast = element.indexDOMText === paramDOMElementsOrder.length - 1;
					settingsAnimations.targetEachParams(element.indexDOMText, isLast);
				})
			);
		});

		await this.runMathiaSpeech(speechSequences, false, true);
	}

	wizardInit() {
		const array = [
			new ScenarioPhrase($localize`Bienvenue dans l’assistant de configuration`),
			new ScenarioPhrase($localize`Nous allons ensemble parcourir les différents paramètres pour une configuration optimale`),
		]

		if(this.page.commonErrors.length === 0){
			array.push(new ScenarioPhrase($localize`Tu peux démarrer la configuration ou passer cette étape en choisissant la configuration automatique`));
		}
		array.push(new ScenarioPhrase($localize`Tu pourras relancer l'assistant à n’importe quel moment depuis les paramètres.`));
		this.runMathiaSpeech(array, false, true);
	}

}
