import { Student } from "../student";
import { LrsUtils } from "./lrsUtils";

export class XapiExtensionsValues {
	static dontKnow = "incertain";
}
export class XapiExtensions {
	static activite = `$url$/extensions/activite`;
	static assignationId = `$url$/extensions/assignation_id`;
	static binome = `$url$/extensions/binome`;
	static codeclasse = `$url$/extensions/codeclasse`;
	static isCodeFamille = `$url$/extensions/codeFamille`;
	static competences = `$url$/extensions/competences`;
	static competencesValidees = `$url$/extensions/competences_validees`;
	static controleAdistance = `$url$/extensions/contrôle_à_distance`;
	static difficulty = `$url$/extensions/difficulty`;
	static erreursType = `$url$/extensions/erreurs_type`;
	static erreursTypeValidees = `$url$/extensions/erreurs_type_validees`;
	static finAutomatique = `$url$/extensions/fin_automatique`;
	static formatHeure = `$url$/extensions/format_heure`;
	static hid = `$url$/extensions/hid`;
	static holo = `$url$/extensions/holo`;
	static id = `$url$/extensions/id`;
	static idActivite = `$url$/extensions/id_activite`;
	static idEquipe = `$url$/extensions/id_Equipe`;
	static idSession = `$url$/extensions/id_session`;
	static kidaia = `$url$/extensions/kidaia`;
	static ose = `$url$/extensions/ose`;
	static osedevmode = `$url$/extensions/osedevmode`;
	static kidaiadevmode = `$url$/extensions/kidaiadevmode`;
	static modeAffichage = `$url$/extensions/mode_affichage`;
	static modeJeu = `$url$/extensions/mode_jeu`;
	static modeOperatoire = `$url$/extensions/mode_opératoire`;
	static modeReponse = `$url$/extensions/mode_reponse`;
	static nomActivite = `$url$/extensions/nom_activite`;
	static nomExercice = `$url$/extensions/nom_exercice`;
	static parcoursEtape = `$url$/extensions/parcours_etape`;
	static parcoursid = `$url$/extensions/parcours_id`;
	static parcoursSessionId = `$url$/extensions/parcours_session_id`;
	static prerequis = `$url$/extensions/prerequis`;
	static recommandation = `$url$/extensions/recommandation`;
	static reponse = `$url$/extensions/reponse`;
	static resume = `$url$/extensions/resume`;
	static story = `$url$/extensions/story`;
	static codemaison = `$url$/extensions/codemaison`;
	static chapterId = `$url$/extensions/chapitre_id`;
	static chapterPosition = `$url$/extensions/chapitre_position`;
	static typeParcours = `$url$/extensions/type_parcours`;
	static remediation = `$url$/extensions/remediation`;
	static pourcentageBonnesReponses = `$url$/pourcentage_bonnes_reponses`;
	static pourcentageMauvaisesReponses = `$url$/pourcentage_mauvaises_reponses`;
	static dureeActivite = `$url$/durée_activité`;
	static theme = `$url$/extensions/theme`;
	static sequenceTermine = `$url$/extensions/sequence_termine`;
	static horsLigne = `$url$/extensions/hors_ligne`;
	static percentProgression = `$url$/extensions/pourcentage_progression`;
	static skippedExerciseId = "$url$/extensions/exercice_passe_id"
	constructor() {
		for (const property in XapiExtensions) {
			if (property) {
				XapiExtensions[property] = XapiExtensions[property].replace(/(\$url\$|https:\/\/xapi\.ose|https:\/\/xapi\.mathia\.education)/g, LrsUtils.statementUrl);
			}
		}
	}

}
export class XapiContext {
	team: object | null;
	extensions: object;
	contextActivities?: { parent: { id: string; objectType?: string }[] };

	constructor(team: Student[], extensions, contextActivities?) {
		return {
			// team: this.getLrsTeam(team),
			extensions,
			contextActivities
		} as XapiContext;
	}

	public getLrsTeam?(team: Student[]) {
		let member;

		if (team.length > 1) {
			member = new Array();
			team.forEach((student, idx, array) => {
				member.push({
					name: student.id,
					awardsCurrent: student.awardsCurrent,
					account: {
						homePage: LrsUtils.statementUrl,
						name: student.id
					},
					objectType: "Agent"
				});
			});
		}

		return member;
	}
}
