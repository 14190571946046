import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { PinchZoomComponent } from "@meddv/ngx-pinch-zoom";
import { Subscription } from "rxjs";
import { AppUtils } from "src/app/app-utils";
import { LrsUtils } from "src/app/models/lrs/lrsUtils";
import { XapiContext, XapiExtensions } from "src/app/models/lrs/xapicontext";
import { XapiObject, XObjectType } from "src/app/models/lrs/xapiobject";
import { XApiResult } from "src/app/models/lrs/xapiresult";
import { LrsVerbs } from "src/app/models/lrs/xapiverbs";
import { OseActivity } from "src/app/models/ose-activities";
import { Ose2Journey, OseExerciceType } from "src/app/models/ose2-journey";
import { ScenarioOseBubble } from "src/app/models/scenario-ose-bubble";
import { Statement } from "src/app/models/statement";
import { AccountService } from "src/app/services/account.service";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { GlobalService } from "src/app/services/global.service";
import { LrsService } from "src/app/services/lrs.service";
import { OseJourneyService } from "src/app/services/ose-journeys.service";
import { environment } from "src/environments/environment";
import { OseDifficulty } from "src/app/models/enums/oseDifficulty";
import { ChangeDetectionStrategy } from "@angular/compiler";
import { MatTableDataSource } from "@angular/material/table";
export class Coloring {
	id: number;
	image: string;
	title: string;
	feedback: string;
	consigne: string;
}
@Component({
	selector: "app-coloring",
	templateUrl: "./coloring.component.html",
	styleUrls: ["./coloring.component.scss"]
})
export class ColoringComponent implements OnInit, OnDestroy {
	@ViewChild("svgElement") svgElement;
	@ViewChild("pinch") pinchZoom: PinchZoomComponent;
	@Input() scenarioBubble: ScenarioOseBubble;
	public currentSVGIndex = 0;
	public counterItemsToColor = 0;
	public totalItemsColored: number;
	public currentColoring: Coloring;
	public won: boolean;
	pZProperties;
	svg: SVGElement;
	windowResizedSubscription: Subscription;
	public deltaDistance = 50;
	public startX;
	public startY;
	public endStatement: Statement[];
	environment;
	skip = false;
	public defaultConsigne = $localize`Colorie cette image en cliquant sur tous ses éléments`;
	public consigne;
	public difficultiesAvailable: Array<OseDifficulty> = new Array();
	public svgPathes = new Array();

	public difficultySelected: OseDifficulty = OseDifficulty.easy;

	public countEasyTotalItems: number = 0;
	public countNormalTotalItems: number = 0;

	displayedColumns = ["id", "image", "name"];
	dataSource;

	constructor(
		public http: HttpClient,
		public globalService: GlobalService,
		public cabriService: CabriDataService,
		public accountService: AccountService,
		public lrs: LrsService,
		public oseJourneyService: OseJourneyService,
		public router: Router,
		public cd: ChangeDetectorRef
	) {
		this.environment = environment;
		this.pZProperties = {
			zoomControlScale: 2,
			limitPan: true,
			wheelZoomFactor: 0.3,
			backgroundColor: "transparent",
			disableZoomControl: "never",
			"double-tap": false
		};
		this.oseJourneyService.launchExercise.subscribe({
			next: (data: OseActivity) => {
				if (data.type === OseExerciceType.coloriage) {
					this.loadSVG(data.id);
				}
			}
		});
	}

	async loadColoring() {
		await this.cabriService.getColoring();
		this.dataSource = new MatTableDataSource(this.cabriService.coloring);

		let journeyRecovered = false;
		if (!this.oseJourneyService.getCurrentExerciseId()) {
			const ex = await this.oseJourneyService.recoverLSJourneyWithCurrentEx();
			if (ex) {
				journeyRecovered = true;
				this.loadSVG(ex?.id);
			}
		}

		if (!journeyRecovered) {
			this.loadSVG(this.oseJourneyService.getCurrentExerciseId());
		}
	}

	async ngOnInit() {}

	mouseDown(event) {
		this.startX = event.pageX;
		this.startY = event.pageY;
	}

	updateSvgPosition() {
		if (this.svg) {
			this.svg.style.height = this.svgElement.nativeElement.offsetHeight + "px";
			this.svg.style.width = this.svgElement.nativeElement.offsetWidth + "px";
		}
	}

	public initializeGame() {
		this.won = this.skip = false;
		this.consigne;
		this.counterItemsToColor = 0;
		this.countEasyTotalItems = 0;
		this.countNormalTotalItems = 0;
		this.totalItemsColored = 0;
		this.svgPathes = new Array();
		this.difficultiesAvailable = new Array();
	}

	async loadSVG(id, difficultyChange = false) {
		this.globalService.setGlobalLoading(true);
		if (id) {
			this.initializeGame();
			this.currentSVGIndex = this.cabriService.coloring.findIndex(c => Number(c.id) === Number(id));
			this.currentColoring = this.cabriService.coloring[this.currentSVGIndex]
			this.currentColoring.image += "?env="+(environment.production ? 'prod' : 'dev')
			this.cabriService.currentOseActivity = {
				id: this.currentColoring.id,
				title: this.currentColoring.title,
				type: OseExerciceType.coloriage
			};
			// this.currentColoring.image = "/assets/coloriage/Carte Europe pays coloriage.svg";
			this.http.get(this.currentColoring.image as any, { responseType: "text" }).subscribe({
				next: async data => {
					if (this.pinchZoom.isZoomedIn) {
						this.pinchZoom.toggleZoom();
					}
					this.svgElement.nativeElement.innerHTML = data;
					this.svg = this.svgElement.nativeElement.querySelector("svg");
					// capture coords when mouse down
					this.svg.addEventListener("mousedown", this.mouseDown.bind(this));
					await this.svgReady();
					// set position of svg
					this.updateSvgPosition();
					// handle autorized svg objects
					const autorized = ["path", "rect", "polygon", "circle", "ellipse"];
					Array.from(this.svg.children).forEach((element: HTMLElement) => {
						console.log(element);
						if (element.tagName !== "defs") {
							if (autorized.includes(element.tagName) && !this.isReward(element)) {
								this.svgPathes.push(element);
							} else {
								let items = Array.from(element.querySelectorAll(autorized.join(",")));
								items = items.filter((e: HTMLElement) => {
									return !e.parentElement || e.parentElement.tagName !== "defs" && !this.isReward(e);
								});
								this.svgPathes = this.svgPathes.concat(...items);
							}

							const rewards = element.querySelectorAll('g[id^="reward"],g[id^="Reward"], g[inkscape\\:label^="reward"], g[inkscape\\:label^="Reward"]');
							console.log(rewards);
							if (rewards && rewards.length) {
								rewards.forEach((r: HTMLElement) => {
									r.style.display = "none";
								});
							}
						}
					});

					this.countTotalItemsToColourize();
					this.addDifficulties();

					this.svgPathes.forEach((p: any) => {
						p.saveFill = p.style.fill;
						const style = getComputedStyle(p);
						console.error(style.fill);
						if(style.fill === "none" || style.fill === ""){
							p.classList.add("noFill");
						}
						p.classList.add("noColorized");
						p.addEventListener("mouseup", e => {
							if (this.isSingleClick(e)) {
								// colorize (sub)elements
								if (e.target.parentElement.tagName === "g" && this.difficultySelected === OseDifficulty.easy) {
									const highestParent = this.findHighestParent(e.target.parentElement);
									this.colorizeAuthorizedElement(highestParent, autorized);
									this.counterItemsToColor++;
								} else {
									if (e.target.style.stroke !== "none") {
										this.counterItemsToColor++;
									}
									this.colorizeSelectedElement(e.target);
								}

								// check need display skip button
								this.skip = this.perGivenAnswer > 50;

								// check end
								this.checkEnd();
							}
						});
					});
					this.globalService.setGlobalLoading(false);
					const statement = this.startActivityStatement();
					if (statement) {
						this.lrs.send(statement);
					}
					this.consigne = this.currentColoring.consigne || this.defaultConsigne;
					if(!difficultyChange){
						this.scenarioBubble.readCustomText(this.consigne, true);
					}
				}
			});
		} else {
			if (this.environment.production) {
				this.router.navigateByUrl("/territoire");
			} else {
				this.globalService.setGlobalLoading(false);
			}
		}
	}

	/**
	 * Colorize all authorized in group element recursively.
	 * @param element : <g> element
	 * @param autorizedElements
	 */
	private colorizeAuthorizedElement(element: HTMLElement, autorizedElements: Array<string>, checkAward = true) {
		Array.from(element.children).forEach((child: any) => {
			if (autorizedElements.includes(child.tagName)) {
				this.colorizeSelectedElement(child, false);
			} else if (child.tagName === "g") {
				this.colorizeAuthorizedElement(child, autorizedElements, false);
			}
		});
		if (checkAward) {
			this.checkReward(element);
		}
	}

	private checkReward(element: HTMLElement){
		const remainingElementsToColorized = element.querySelectorAll('.noColorized');
		if(remainingElementsToColorized.length === 0){
			//find rewards and display them
			const rewards = element.querySelectorAll('g[id^="reward"],g[id^="Reward"], g[inkscape\\:label^="reward"], g[inkscape\\:label^="Reward"]');
			if (rewards && rewards.length) {
				rewards.forEach((r: HTMLElement) => {
					r.style.display = "block";
				});
			}
		}
	}
	private isReward(element: HTMLElement){
		if(element.parentElement && element.parentElement.id?.toLowerCase().startsWith('reward') || 
			(element.parentElement.getAttribute('inkscape:label')?.toLowerCase().startsWith('reward'))){
			return true;
		} else if (!element.parentElement || element.parentElement.tagName.toLowerCase() === 'svg') {
			return false;
		} else {
			return this.isReward(element.parentElement);
		}
	}
	/**
	 * Find highest group layer
	 * @param element
	 * @returns
	 */
	private findHighestParent(element: HTMLElement) {
		if (this.isEligibleGroup(element.parentElement)) {
			return this.findHighestParent(element.parentElement);
		} else {
			return element;
		}
	}
	private isEligibleGroup(element){
		return element?.tagName === "g" && (element.style.clipPath === "")
		&& (!element.parentElement || this.getNumberGroupChildren(element.parentElement) > 1);
	}
	private getNumberGroupChildren(element: HTMLElement):number{
		let count = 0;
		Array.from(element.children).forEach(e => {
			if(e.tagName.toLowerCase() === 'g'){
				count++;
			}
		})
		return count;
	}

	/**
	 * Count the total number of elements to colorize based on the difficulty level
	 */
	public countTotalItemsToColourize() {
		this.countNormalTotalItems = this.svgPathes.length;
		// merge groups and autorized elements
		[...this.svgPathes, ...Array.from(this.svg.querySelectorAll("g"))].forEach((p: any) => {
			if (this.isEligibleGroup(p)) {
				this.countEasyTotalItems++;
			}
		});
		this.totalItemsColored = this.difficultySelected === OseDifficulty.easy ? this.countEasyTotalItems : this.countNormalTotalItems;
	}

	/**
	 * Add multiple difficulty levels (easy, normal) based on the difference in the total number of items to colorize
	 */
	public addDifficulties() {
		if (this.countEasyTotalItems > 0 && this.countEasyTotalItems < this.countNormalTotalItems) {
			this.difficultiesAvailable = [OseDifficulty.easy];
		} else {
			if (this.difficultySelected === OseDifficulty.easy) {
				this.difficultyChanged(OseDifficulty.normal);
			}
		}
		this.difficultiesAvailable = [...new Set([...this.difficultiesAvailable, OseDifficulty.normal])];
	}

	/**
	 * Differentiate single click from double click(zoom or click)
	 * @param e Event
	 * @returns boolean
	 */
	public isSingleClick(e): boolean {
		const diffX = Math.abs(e.pageX - this.startX);
		const diffY = Math.abs(e.pageY - this.startY);
		return diffX < this.deltaDistance && diffY < this.deltaDistance;
	}

	/**
	 * Colorize svg element
	 * @param e svgElement
	 */
	colorizeSelectedElement(e, checkAward = true) {
		e.classList.remove("noColorized");
		e.classList.add("colorized");
		e.style.fill = e.saveFill;
		e.removeAllListeners("click");

		if (checkAward) {
			this.checkReward(e.parentElement);
		}
	}

	public get perGivenAnswer() {
		return (this.counterItemsToColor / this.totalItemsColored) * 100;
	}

	async checkEnd() {
		if (this.counterItemsToColor === this.totalItemsColored) {
			this.endStatement = this.endActivityStatement();
			if (this.pinchZoom?.isZoomedIn) {
				this.pinchZoom.toggleZoom();
			}
			await this.displayFeedBackMessage();
		}
	}

	public async displayFeedBackMessage() {
		this.won = true;
		this.cd.detectChanges();
		this.updateSvgPosition();
		this.globalService.confettiCanvasComponent.launchConfetti();
		if (this.scenarioBubble) {
			await this.scenarioBubble.launchFeedbackWithEnd(this.currentColoring.feedback, true);
		}
	}

	svgReady() {
		return new Promise<void>(resolve => {
			AppUtils.timeOut(50).then(async () => {
				let counter = 0;
				while (this.svgElement.nativeElement.offsetWidth === 0 && counter < 50) {
					await AppUtils.timeOut(10);
					counter++;
				}
				resolve();
			});
		});
	}
	public async nextDrawing() {
		await this.scenarioBubble.skipMathiaSpeechSequence(true);
		this.endStatement = this.endActivityStatement();
		if (this.oseJourneyService.currentJourney) {
			this.lrs
				.send(this.endStatement)
				.then(() => {
					this.oseJourneyService.exerciseEnd.next();
				})
				.catch(error => {
					console.error("error last statement not send", error);
					this.oseJourneyService.exerciseEnd.next(true);
				});
		} else {
			this.currentSVGIndex++;
			if (this.currentSVGIndex === this.cabriService.coloring.length) {
				this.currentSVGIndex = 0;
			}
			this.loadSVG(this.cabriService.coloring[this.currentSVGIndex].id);
		}
	}

	/**
	 * Statement object created at the beginning of the activity.
	 * @returns Statement[] contains one or two elements depending if it's a journey(ex + journey) or a simple exercise(1)
	 */
	startActivityStatement(): Statement[] {
		if (this.oseJourneyService.currentJourney) {
			const statements = new Array();
			LrsUtils.idsession = LrsUtils.generateUniqueSessionId(this.accountService, true);
			LrsUtils.currentUserResponseTime = Date.now();
			const object = new XapiObject(
				`${LrsUtils.statementUrl}/exercise/${String(this.currentColoring.id)}`,
				this.currentColoring.title,
				this.currentColoring.title,
				XObjectType.exercise
			);
			const context = new XapiContext(this.accountService.team, {
				...this.lrs.globalActivityExtensions()
			});

			const exerciseStatement = new Statement(this.accountService.team[0]?.id, LrsVerbs.initialized, object, context);
			const journeyStatement = this.lrs.startJourneyStatement();
			if (journeyStatement) {
				statements.push(journeyStatement);
			}
			statements.push(exerciseStatement);
			return statements;
		}
	}

	public difficultyChanged(difficulty: OseDifficulty, restart = false) {
		if (difficulty !== this.difficultySelected) {
			this.difficultySelected = difficulty;
			if(restart){
				this.loadSVG(this.cabriService.currentOseActivity?.id, true);
			} else {
				this.countTotalItemsToColourize()
			}
		}
	}

	async prevPage() {
		if (!this.oseJourneyService.currentJourney) {
			if (this.currentColoring) {
				this.prevNextPage(false);
			}
		} else {
			await this.scenarioBubble?.skipMathiaSpeechSequence(true);
			this.oseJourneyService.exercisePrev.next();
		}
	}

	prevNextPage(next: boolean) {
		const currentExerciseIndex = this.cabriService.coloring.findIndex(m => Number(m.id) === Number(this.currentColoring.id));
		let direction = next ? 1 : -1;
		if (currentExerciseIndex > -1 && this.cabriService.coloring[currentExerciseIndex + direction]) {
			this.loadSVG(this.cabriService.coloring[currentExerciseIndex + direction].id);
		} else {
			let reStartIndex = next ? 0 : this.cabriService.coloring.length - 1;
			this.loadSVG(this.cabriService.coloring[reStartIndex].id);
		}
	}

	/**
	 * Statement object created at the end of the activity
	 * @returns Statement[] contains one or two elements depending if it's the last exercise of journey (ex + journey) or a simple exercise(1)
	 */

	endActivityStatement(): Statement[] {
		if (this.oseJourneyService.currentJourney) {
			const statements = new Array();
			this.oseJourneyService.currentJourney.calculateSessionExerciseCompletionPercentage();
			const object = new XapiObject(
				`${LrsUtils.statementUrl}/${String(this.currentColoring.id)}`,
				this.currentColoring.title,
				this.currentColoring.title,
				XObjectType.exercise
			);
			const activityDuration = LrsUtils.duration8601(LrsUtils.timeStampConversion(LrsUtils.currentUserResponseTime));
			const result = new XApiResult(LrsVerbs.completed, activityDuration);
			const context = new XapiContext(this.accountService.team, {
				...this.lrs.globalActivityExtensions(),
				[XapiExtensions.dureeActivite]: activityDuration
			});

			const exerciseStatement = new Statement(this.accountService.team[0]?.id, LrsVerbs.completed, object, context, result);
			const endJourneyStatement = this.lrs.endJourneyStatement();
			if (endJourneyStatement) {
				statements.push(endJourneyStatement);
			}

			statements.push(exerciseStatement);
			return statements;
		}
	}

	async ngOnDestroy() {
		await this.scenarioBubble.skipMathiaSpeechSequence(true);
	}

	applyFilter(filterValue: string) {
		filterValue = filterValue.trim(); // Remove whitespace
		filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
		this.dataSource.filter = filterValue;
	}
}
