<ng-container *ngIf="myAssignations?.length > 0 && displayModal">
  <div *ngIf="accountService.team[0]?.preview  && !ose.assignationTeacherModalClosed" class="main">
    <ng-template  [ngTemplateOutlet]="reUsedisplayModal"></ng-template>
  </div>

  <div *ngIf="!accountService.team[0]?.preview" class="main">
    <ng-template  [ngTemplateOutlet]="reUsedisplayModal"></ng-template>
  </div>
</ng-container>


<ng-template #reUsedisplayModal>
      <div class="wrapper">
        <div id="oseCloseButton" *ngIf="accountService.team[0]?.preview" (click)="closeModal()">
          <div id="oseCloseButton-global__img"></div>
      </div>
        <h2>Ton professeur t'a assigné une activité.</h2>
        <h2 class="bottom-separator">Clique sur l'image ci-dessous pour commencer !</h2>
        <!-- <ion-button (click)="startAssignation()" >C’est parti</ion-button> -->
        <img (click)="startAssignation()" src="https://kidaia.com/flowerpower/flower{{flowerPower}}.png"/>
       </div>

</ng-template>

