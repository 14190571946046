import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { Platform } from "@ionic/angular";
import { count } from "console";
import {timer } from "rxjs";
import { AppUtils } from "src/app/app-utils";
import { OseDifficulty } from "src/app/models/enums/oseDifficulty";
import { LrsUtils } from "src/app/models/lrs/lrsUtils";
import { XapiContext, XapiExtensions } from "src/app/models/lrs/xapicontext";
import { XapiObject, XObjectType } from "src/app/models/lrs/xapiobject";
import { XApiResult } from "src/app/models/lrs/xapiresult";
import { LrsVerbs } from "src/app/models/lrs/xapiverbs";
import { OseActivity } from "src/app/models/ose-activities";
import { OseExerciceType } from "src/app/models/ose2-journey";
import { ScenarioOseBubble } from "src/app/models/scenario-ose-bubble";
import { Statement } from "src/app/models/statement";
import { AccountService } from "src/app/services/account.service";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { GlobalService } from "src/app/services/global.service";
import { LrsService } from "src/app/services/lrs.service";
import { OseJourneyService } from "src/app/services/ose-journeys.service";
import { environment } from "src/environments/environment";

class ImageBox {
	xPos: string;
	yPos: string;
	index: number;
}

@Component({
	selector: "app-puzzle-game",
	templateUrl: "./puzzle-game.component.html",
	styleUrls: ["./puzzle-game.component.scss"]
})
export class PuzzleGameComponent implements OnDestroy {
	@ViewChild("imageSrc", { static: false }) imageSrc;
	@ViewChild("puzzleWrapper", { static: false }) puzzleWrapper;
	@ViewChild("headerContainer", { static: false }) headerContainer: ElementRef;
	@Input() scenarioBubble: ScenarioOseBubble;
	imageUrl: string;
	public consigne
	public defaultConsigne = $localize`Peux-tu résoudre le puzzle ?`;
	gridsizeX: number;
	gridsizeY: number;
	boxSizeX: number;
	boxSizeY: number;
	boxFixSizeX: number;
	boxFixSizeY: number;
	totalBoxes: number;
	index = 0;
	imagesArray: any[] = [];
	steps = 0;
	ticks = "0:00";
	timer: any = timer(0, 1000);
	timeVar: any;
	gameComplete = false;
	indexes: number[] = [];
	exerciseId: number;
	position: number[] = [];
	puzzle: any;
	solved = false;
	Math = Math;
	public image: HTMLImageElement;
	endStatement: Statement[];
	displayHelp = false;
	public environment;
	public difficultySelected:OseDifficulty = OseDifficulty.normal;

	displayedColumns = ["id", "image", "name"];
	dataSource;

	constructor(
		public globalService: GlobalService,
		public accountService: AccountService,
		public oseJourneyService: OseJourneyService,
		public platform: Platform,
		public lrs: LrsService,
		public cabriService: CabriDataService,
		public cd: ChangeDetectorRef,
		public render: Renderer2,
		public router: Router
	) {
		this.environment = environment;
		this.oseJourneyService.launchExercise.subscribe({
			next: (data: OseActivity) => {
				if (data.type === OseExerciceType.puzzle) {
					this.loadPuzzle(data.id);
				}
			}
		});
		this.globalService.activityRunningName = OseExerciceType.puzzle;
	}

	async loadPuzzle(idExercise: number, difficultyChange = false) {
		this.endStatement = null;
		this.exerciseId = idExercise;
		this.startGame(idExercise, difficultyChange);
	}

	async loadCurrentPuzzle() {
		await this.oseJourneyService.journeysLoaded();
		await this.cabriService.getPuzzles();
		this.dataSource = new MatTableDataSource(this.cabriService.puzzles);
		this.platform.ready().then(async () => {
			let journeyRecovered = false;
			if(!this.oseJourneyService.getCurrentExerciseId()){
				const ex = await this.oseJourneyService.recoverLSJourneyWithCurrentEx();
					if(ex){
						journeyRecovered = true;
						this.loadPuzzle(ex.id);
					}
			}

			if(!journeyRecovered){
				this.loadPuzzle(this.oseJourneyService.getCurrentExerciseId());
			}
			
		});
	}

	isSorted(indexes): boolean {
		if(indexes?.length > 0){
			let i = 0;
			for (i = 0; i < indexes.length; i++) {
				if (indexes[i] !== i) {
					return false;
				}
			}
		}
		return true;
	}
	randomize(imageParts: any[]){
			let i = 0;
			let img: any[] = [];
			let ran = 0;
			let imagePartsTemp = imageParts.slice();
			for (i = 0; i < imagePartsTemp.length; i++) {
				ran = Math.floor(Math.random() * imagePartsTemp.length);
				while (imagePartsTemp[ran] == null) {
					ran = Math.floor(Math.random() * imagePartsTemp.length);
				}
				img.push(imagePartsTemp[ran]);
				this.position.push(imagePartsTemp[ran].index);
				imagePartsTemp[ran] = null;
			}
			const isTheSame = this.isSorted(this.position);
			if(isTheSame){
				// security loop not randomized restart operation
				let counter = 0
				let result;
				while(counter <= 10 && this.isSorted(result)){
					this.position = new Array();
					result = this.randomize(this.imagesArray);
					counter++;
				}
				img = result;
			}
			this.imagesArray = imagePartsTemp;

			this.printIndexes(this.indexes);
			this.printIndexes(this.position);
			return img;
	}

	public async onDrop(event: any) {
		// console.log(event);
		const origin = event.previousContainer.element.nativeElement.id;
		const dest = event.container.element.nativeElement.id;

		const originEl = document.getElementById(origin);
		const destEl = document.getElementById(dest);

		const origincss = originEl.style.cssText;
		const destcss = destEl.style.cssText;

		destEl.style.cssText = origincss;
		originEl.style.cssText = destcss;
		originEl.id = dest;
		destEl.id = origin;

		for (let i = 0; i < this.position.length; i++) {
			if (this.position[i].toString() === originEl.id) {
				this.position[i] = Number(destEl.id);
			} else if (this.position[i].toString() === destEl.id) {
				this.position[i] = Number(originEl.id);
			}
		}
		this.updateImagesArray(originEl, destEl);
		this.printIndexes(this.position);

		this.steps++;
		this.gameComplete = this.isSorted(this.position);
		if (this.gameComplete) {
			this.endStatement = this.endActivityStatement();
			await this.displayFeedBackMessage();
			// console.log("game ticksinsecond  ----------", this.ticksinsecond);

			if (this.timeVar) {
				this.timeVar.unsubscribe();
			}
		}
	}

	async displayFeedBackMessage() {
		this.solved = true;
		this.globalService.confettiCanvasComponent.launchConfetti();
		if (this.scenarioBubble) {
			await this.scenarioBubble.launchFeedbackWithEnd(this.puzzle.feedback, true);
		}
	}

	/**
	 * Statement object created at the end of the activity
	 * @returns Statement[] contains one or two elements depending if it's the last exercise of journey (ex + journey) or a simple exercise(1)
	 */

	endActivityStatement(): Statement[] {
		if (this.oseJourneyService.currentJourney) {
			const statements = new Array();
			this.oseJourneyService.currentJourney.calculateSessionExerciseCompletionPercentage();
			const object = new XapiObject(
				`${LrsUtils.statementUrl}/${String(this.puzzle.id)}`,
				this.puzzle.title,
				this.puzzle.theme,
				XObjectType.exercise
			);
			const activityDuration = LrsUtils.duration8601(LrsUtils.timeStampConversion(LrsUtils.currentUserResponseTime));
			const result = new XApiResult(LrsVerbs.completed, activityDuration);
			const context = new XapiContext(this.accountService.team, {
				...this.lrs.globalActivityExtensions(),
				[XapiExtensions.dureeActivite]: activityDuration
			});

			const exerciseStatement = new Statement(this.accountService.team[0]?.id, LrsVerbs.completed, object, context, result);
			const endJourneyStatement = this.lrs.endJourneyStatement();
			if (endJourneyStatement) {
				statements.push(endJourneyStatement);
			}

			statements.push(exerciseStatement);
			return statements;
		}
	}
	updateImagesArray(originEl, destEl) {
		let image1Index, image2Index;

		// find images to switch
		this.imagesArray.forEach((img: ImageBox, index) => {
			// console.log(img.index, originEl.id, destEl.id);
			if (img.index == originEl.id) {
				image1Index = index;
			}
			if (img.index == destEl.id) {
				image2Index = index;
			}
		});

		// switch images
		const tmp = JSON.parse(JSON.stringify(this.imagesArray[image1Index]));
		this.imagesArray[image1Index] = JSON.parse(JSON.stringify(this.imagesArray[image2Index]));
		this.imagesArray[image2Index] = tmp;
	}
	allowDrop(event): void {
		event.preventDefault();
		event.target.style.opacity = 1;
	}

	printIndexes(sorts: number[]): void {
		let i = 0,
			ind = "";
		for (i = 0; i < sorts.length; i++) {
			ind += sorts[i].toString() + " , ";
		}
		// console.log(ind);
	}

	 reRandomize() {
		this.gameComplete = false;
		this.imagesArray = this.randomize(this.imagesArray);
	}

	initializePuzzlGrids() {
		const { gridSizeX, gridSizeY } = this.manageGridsSizesByDevice();
		this.gridsizeX = gridSizeX;
		this.gridsizeY = gridSizeY;
		this.boxSizeX = 100 / (this.gridsizeX - 1);
		this.boxSizeY = 100 / (this.gridsizeY - 1);
		this.totalBoxes = this.gridsizeX * this.gridsizeY;
		this.boxFixSizeX = 100 / this.gridsizeX - 1.35 / this.gridsizeX;
		this.boxFixSizeY = 100 / this.gridsizeY - 1.35 / this.gridsizeY;
		window.document.documentElement.style.setProperty("--puzzleDifficultyX", String(this.gridsizeX));
		window.document.documentElement.style.setProperty("--puzzleDifficultyY", String(this.gridsizeY));
	}

	manageGridsSizesByDevice() {
		let gridSizeX = this.puzzle.difficulty;
		let gridSizeY = this.puzzle.difficulty;
		if (!this.globalService.isDesktop) {
			if (window.innerHeight <= 350) {
				if (gridSizeX > 3) {
					gridSizeX = 3;
				}
				gridSizeY = 2;
			} else if (window.innerHeight < 500) {
				if (gridSizeY > 3) {
					gridSizeY = 3;
				}
				if (gridSizeX > 5) {
					gridSizeX = 5;
				}
			} else if (window.innerHeight > 500 && window.innerHeight < 650) {
				if (gridSizeY > 4) {
					gridSizeY = 4;
				}
				if (gridSizeX > 6) {
					gridSizeX = 6;
				}
			} else {
				// other tablet devices
				if (gridSizeY > 5) {
					gridSizeY = 5;
				}
				if (gridSizeX > 7) {
					gridSizeX = 7;
				}
			}
		}

		if(this.difficultySelected === OseDifficulty.easy){
			gridSizeX = 3;
			gridSizeY = 2;
		}else if(this.difficultySelected === OseDifficulty.hard){
			const extraSize = 1;
			gridSizeX = gridSizeX + extraSize;
			gridSizeY = gridSizeY + extraSize;
		}

		return { gridSizeX, gridSizeY };
	}

	resizePuzzleContainer() {
		if (this.imageSrc?.nativeElement) {
			this.imageSrc.nativeElement.style.height = this.puzzleWrapper.nativeElement.offsetHeight + "px";
			this.imageSrc.nativeElement.style.width =
				(this.puzzleWrapper.nativeElement.offsetHeight * this.image.width) / this.image.height + "px";
		}
	}

	async startGame(idPuzzle: number, difficultyChange = false) {
		if (idPuzzle) {
			this.puzzle = this.cabriService.puzzles.find(p => Number(p.id) === Number(idPuzzle));
			this.imageUrl = this.puzzle.image;
			this.consigne = this.puzzle.consigne || this.defaultConsigne;
			this.reset();
			this.initializePuzzlGrids();
			this.image = new Image();
			this.image.onload = () => {
				this.resizePuzzleContainer();
			};

			if (this.puzzle) {
				this.image.src = this.puzzle.image;
			} else {
				this.image.src = this.imageUrl;
			}
			this.breakImageParts();
			this.reRandomize();
			this.steps = 0;

			if (this.timeVar) {
				this.timeVar.unsubscribe();
			}

			this.cabriService.currentOseActivity = { id: this.puzzle.id, title: this.puzzle.title, type: OseExerciceType.puzzle };
			const statement = this.startActivityStatement();
			if (statement) {
				this.lrs.send(statement);
			}
			if(!difficultyChange){
				this.scenarioBubble.readCustomText(this.consigne,true);
			}
		} else {
			if (this.environment.production) {
				this.router.navigateByUrl("/territoire");
			} else {
				// retro
				this.globalService.setGlobalLoading(false);
			}
		}
	}

	/**
	 * Statement object created at the beginning of the activity.
	 * @returns Statement[] contains one or two elements depending if it's a journey(ex + journey) or a simple exercise(1)
	 */
	startActivityStatement(): Statement[] {
		if (this.oseJourneyService.currentJourney) {
			const statements = new Array();
			LrsUtils.idsession = LrsUtils.generateUniqueSessionId(this.accountService, true);
			LrsUtils.currentUserResponseTime = Date.now();
			const object = new XapiObject(
				`${LrsUtils.statementUrl}/exercise/${String(this.puzzle.id)}`,
				this.puzzle.title,
				this.puzzle.image,
				XObjectType.exercise
			);
			const context = new XapiContext(this.accountService.team, {
				...this.lrs.globalActivityExtensions()
			});

			const exerciseStatement = new Statement(this.accountService.team[0]?.id, LrsVerbs.initialized, object, context);
			const journeyStatement = this.lrs.startJourneyStatement();
			if (journeyStatement) {
				statements.push(journeyStatement);
			}
			statements.push(exerciseStatement);
			return statements;
		}
	}
	breakImageParts(): void {
		for (this.index = 0; this.index < this.totalBoxes; this.index++) {
			const x: string = this.boxSizeX * (this.index % this.gridsizeX) + "%";
			const y: string = this.boxSizeY * Math.floor(this.index / this.gridsizeX) + "%";
			const img: ImageBox = new ImageBox();
			img.xPos = x;
			img.yPos = y;
			img.index = this.index;
			this.indexes.push(this.index);
			this.imagesArray.push(img);
		}
	}

	reset(): void {
		this.imagesArray = [];
		this.indexes = [];
		this.position = [];
		this.solved = false;
	}

	async nextGame() {
		await this.scenarioBubble.skipMathiaSpeechSequence(true);
		this.solved = true;
		this.displayHelp = false;
		if (this.oseJourneyService.currentJourney) {
			if (!this.endStatement) {
				this.endStatement = this.endActivityStatement();
			}
			this.lrs
				.send(this.endStatement)
				.then(() => {
					this.oseJourneyService.exerciseEnd.next();
				})
				.catch(error => {
					console.error("error last statement not send", error);
					this.oseJourneyService.exerciseEnd.next(true);
				});
		} else {
			this.prevNextPage(true);
		}
	}

	async prevPage() {
		if (!this.oseJourneyService.currentJourney) {
			if (this.puzzle) {
				this.prevNextPage(false);
			}
		} else {
			await this.scenarioBubble?.skipMathiaSpeechSequence(true);
			this.oseJourneyService.exercisePrev.next();
		}
	}

	prevNextPage(next: boolean) {
		const currIndex = this.cabriService.puzzles.findIndex(puzzle => puzzle.id === this.puzzle.id);
		let direction = next ? 1 : -1;
		if (currIndex > -1 && this.cabriService.puzzles[currIndex + direction]) {
			this.loadPuzzle(this.cabriService.puzzles[currIndex + direction].id);
		} else {
			let reStartIndex = next ? 0 : this.cabriService.puzzles.length - 1;
			this.loadPuzzle(this.cabriService.puzzles[reStartIndex].id);
		}
	}

	difficultyChanged(difficulty) {
		if(difficulty !== this.difficultySelected){
			this.difficultySelected = difficulty;
			this.loadPuzzle(this.exerciseId, true);
		}
	}

	async ngOnDestroy() {
		await this.scenarioBubble.skipMathiaSpeechSequence(true);
	}

	applyFilter(filterValue: string) {
		filterValue = filterValue.trim(); // Remove whitespace
		filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
		this.dataSource.filter = filterValue;
	}
}
