<div class="studentsContainer scrollbar-primary"
  [ngClass]="!globalService.isBrowser && platform.isLandscape() ? 'scrollbarMobileLandscape' : 'scrollbar'">

  <div *ngIf="modalAlertDisplay">
    <app-custom-alert (alertMessageAnswer)="alertDemandRemoveStudent($event)"
      [alertAction]="userAlertActions.removeStudent" [alertTitle]="alertTitle"></app-custom-alert>
  </div>

  <div class="spinnerWrapper" *ngIf="spinner.status">
    <ion-spinner name="crescent"></ion-spinner>
    <ion-label>{{spinner.text}}</ion-label>
  </div>

  <!-- LOGIN FOR PLAYER REMOVAL -->
  <ng-container *ngIf="login">
    <ion-row class="formLoginContainer" [hidden]="spinner.status" [ngClass]="{'hasInputFocused': elementFocused && elementFocused !=''}">
      <ion-col class="formLoginCol" sizeXs="10" sizeSm="8" sizeMd="6" sizeLg="5" sizeXl="4">
        <h3 [hidden]="elementFocused && elementFocused !== ''" i18n>Attention cette action est irréversible</h3>
        <form (ngSubmit)="submitRemoveStudentForm()" class="formLogin"
          [ngStyle]="formLog.error?.length > 0 ?  {'height': '90%'} : {'height': '60%'}">
          <ion-item [hidden]="globalService.isLandscape && elementFocused && elementFocused !== 'mail'">
            <ion-input id="mail" class="fieldRemoveStudent" type="email" [placeholder]="placeholderEmail" [(ngModel)]="formLog.email"
              name="email" (ionFocus)="setFocusedElement('mail')" (ionBlur)="removeFocusedElement('mail')"></ion-input>
          </ion-item>
          <ion-item [hidden]="globalService.isLandscape && elementFocused && elementFocused !== 'password'" >
            <ion-input id="password" class="fieldRemoveStudent" type="password" [placeholder]="placeholderPassword"
              [(ngModel)]="formLog.password" name="password"
              (ionFocus)="setFocusedElement('password')" (ionBlur)="removeFocusedElement('password')"></ion-input>
          </ion-item>
          <ng-container *ngIf="formLog.error && formLog.error.length > 0">
            <div class="formError">
              <p>{{formLog.error}}</p>
            </div>
          </ng-container>
        </form>
      </ion-col></ion-row>
  </ng-container>

  <!-- EDIT / CREATE STUDENT -->
  <ng-container *ngIf="editionStudent && !login">
    <div id="edit-student-wrapper" [hidden]="spinner.status" class="bubbleStudent ion-no-padding ion-no-margin notSelectedStudent">
      <div class='gradientBubbleStudent'>
        <div class="studentInfo"  [ngClass]="{'hasInputFocused': elementFocused && elementFocused =='prenom'}">
          <div class="iconWrapperPlayerEdition" [hidden]="globalService.isLandscape && elementFocused && elementFocused == 'prenom'">
            <!-- player icon -->
            <img [ngClass]="[!globalService.isLandscape ? 'playerIconPortrait' : 'playerIcon']"
              src="/assets/icon/astro1.png" alt="close" 
              />
          </div>

          <!-- Player info -->
          <div class="labelWrapperEdition">
            <div class="nameWrapper">
              <!-- Edition -->
              <ion-input type="text" id="name" [(ngModel)]="editionStudent.name" [placeholder]="placeholderName"
                autocomplete="off" name="name" required minlength="2" maxlength="30"
                class="studentNameInputField" autocapitalize="words"
                (ionFocus)="setFocusedElement('prenom')" (ionBlur)="removeFocusedElement('prenom')"></ion-input>
            </div>

            <div class="classRoomWrapper" [hidden]="hideClassSelection">
              <ion-select [label]="globalService.locale == AppLanguage.FR ? 'Classe' : ''" class="studentClassSelect" [(ngModel)]="editionStudent.classe.id"
                [interface]="globalService.isDesktop ? 'popover' : 'alert'" [placeholder]="placeholderLevel"
                [interfaceOptions]="{cssClass:'kidaia-popover'}" cancelText="Quitter" okText="Valider">
                <ion-select-option *ngFor="let classroom of classes" [value]="classroom.id">
                  {{classroom.name}}
                </ion-select-option>
              </ion-select>
            </div>

          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- TABS STUDENTS OR GROUPS -->
  <div class="tabsContainer" *ngIf="!editMode && accountService.user.codeClasse && (teamsCopy && teamsCopy.length > 0)"
    [hidden]="spinner.status">
    <div class="tab tabStudents" (click)="tabs('students',$event)">
      <span class="buttonText">
        <p i18n>Élèves</p>
      </span>
    </div>
    <div class="tab tabGroups" (click)="tabs('groups',$event)">
      <span class="buttonText">
        <p i18n>Groupes</p>
      </span>
    </div>
  </div>

  <ion-grid fixed class="ion-no-padding ion-no-margin" [hidden]="spinner.status || login || editionStudent"
    class="studentsList">
    <ion-row style="width: 100%;">

      <!-- STUDENTS -->
      <ng-container *ngIf="showStudents;else groups">
        <!-- ADD STUDENT BUTTON -->
        <ng-container *ngIf="editMode && students && (students.length < maxKidaiaAccount || maxKidaiaAccount === -1)">
          <ion-col size='5' size-sm="4" size-md="3" size-lg="2">
            <div class="bubbleStudent ion-no-padding ion-no-margin" [ngClass]="'selectedStudent'"
              (click)="enterStudentCreation()">
              <div class='gradientBubbleStudent'>
                <div class="studentInfo">
                  <ion-icon src="/assets/icon/add_student.svg" size="large" slot="icon-only"
                    class="ion-no-padding add-student-icon">
                  </ion-icon>
                  <!-- <p class="studentLabel">{{student.name}}</p> -->
                </div>
              </div>
            </div>
          </ion-col>
        </ng-container>
        <!-- STUDENTS LIST / PLAYER SELECTION-->
        <ng-container *ngFor="let student of students; let i = index">
          <ion-col [hidden]="!editMode && student.selected" size='5' size-sm="4" size-md="3" size-lg="2">
            <div class="bubbleStudent ion-no-padding ion-no-margin"
              [ngClass]="student.selectedTemp || student.selected ? 'selectedStudent' : 'notSelectedStudent'"
              (click)="addStudent(student)">
              <div class='gradientBubbleStudent'>
                <div class="studentInfo">
                  <img src="/assets/icon/astro1.png" 
                    [ngClass]="student.selectedTemp || student.selected ? 'child-picto-selected ion-no-padding' : 'child-picto-not-selected ion-no-padding'"/>
                  <p class="studentLabel">{{student.displayName}}</p>
                  <div [hidden]="!editMode" class="edit-student-buttons">
                    <div #editStudentIcon class="managePlayerIcon" (click)="editStudent(student, i, $event)"></div>
                    <div #removeStudentIcon class="removePlayerIcon"
                      (click)="confirmStudentRemoval(student, i, $event)"></div>
                  </div>
                </div>

              </div>
            </div>
          </ion-col>
        </ng-container>
      </ng-container>
    </ion-row>

    <!-- GROUPS -->
    <ion-row style="width: 100%;">
      <ng-template #groups>
        <div class="gr_bubbles">
          <ng-container *ngFor="let team of teamsCopy; let i = index">
            <ion-col size='12' size-sm="12" size-md="6" size-lg="4">
              <div class='gr_gradientBubbles' (click)="selectCurrentTeam(team)">
                <div class="gr_Container">
                  <div class="gr_IconsContainer" [ngClass]="team.length > 3 ? 'twoRows' : ''">
                    <ng-container *ngFor="let student of team">
                      <ion-icon name="person"></ion-icon>
                    </ng-container>
                  </div>
                  <div class="gr_StudentNames">
                    <div class="gr_studentNamesContainer">
                      <ng-container *ngFor="let student of team;let last = last">
                        <p *ngIf="student">{{student.name}}</p>
                        <div *ngIf="!last" class="gr_Divider"></div>
                      </ng-container>
                    </div>
                  </div>

                </div>
              </div>
            </ion-col>
          </ng-container>
        </div>
      </ng-template>
    </ion-row>
  </ion-grid>
</div>

<ion-footer [ngClass]="{'mobile-landscape' : !globalService.isBrowser && platform.isLandscape()}"
  class="gradientFooter">
  <ng-container *ngIf="!login && !editionStudent">
    <button *ngIf="editModeAllowed" class="footerBtn btnPink btnNoDecoration" (click)="editMode = !editMode" i18n>Gestion des joueurs</button>
    <button class="footerBtn btnCancel btnNoDecoration" (click)="dismiss($event)" i18n>Annuler</button>
  </ng-container>
  <ng-container *ngIf="!login && editionStudent">
    <button class="footerBtn btnPink btnNoDecoration" (click)="editionStudent = null" i18n>Annuler</button>
    <button class="footerBtn btnStart btnNoDecoration" (click)="confirmStudentEdition(editionStudent, $event)"
      i18n>Valider</button>
  </ng-container>
  <ng-container *ngIf="login">
    <button class="footerBtn btnPink btnNoDecoration" (click)="dismissLogin($event)" i18n>Annuler</button>
    <button class="footerBtn btnStart btnNoDecoration" (click)="submitRemoveStudent($event)" i18n>Confirmer la
      suppresion</button>
  </ng-container>
</ion-footer>