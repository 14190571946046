import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { flipCards } from "src/app/models/custom-angular-animations";
import { OseDifficulty } from "src/app/models/enums/oseDifficulty";
import { ScenarioOseBubble } from "src/app/models/scenario-ose-bubble";
import { CardData, CardStats } from "src/app/pages/memory/memory.page";
import { CabriDataService } from "src/app/services/cabri-data.service";

@Component({
	selector: "app-game-card",
	templateUrl: "./game-card.component.html",
	styleUrls: ["./game-card.component.scss"],
	animations: [flipCards]
})
export class GameCardComponent implements OnInit {
	@Input() currentCard: CardData;
	@Input() showText?= false;
	@Input() endFlipCards
	@Input() flippedCards;
	@Input() difficultySelected:OseDifficulty;
	@Input() scenarioBubble:ScenarioOseBubble;

	@Output() cardClicked = new EventEmitter();

	public get OseDifficulty(){
		return OseDifficulty
	}
	public get CardStats(){
		return CardStats;
	}
	

	constructor(public cabriService:CabriDataService) {}

	ngOnInit(): void {}

}
