import { cloneDeep } from 'lodash';
import { Component, Input, Output, EventEmitter, AfterViewInit, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { Drawing } from "src/app/models/drawing";
import { DrawingProd } from "src/app/models/drawing-prod";
import { HttpClient } from "@angular/common/http";
import { GlobalService } from "src/app/services/global.service";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { Platform } from "@ionic/angular";
import { lastValueFrom, Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { NetworkService } from "src/app/services/network.service";
import { AudioService } from "src/app/services/audio.service";

@Component({
	selector: "app-drawing",
	templateUrl: "./drawing.component.html",
	styleUrls: ["./drawing.component.scss"]
})
export class DrawingComponent implements AfterViewInit, OnDestroy {
	@Input() mode = "prod";
	@Input() activity;
	@Input() dyslexicMode = false;
	@Output() change: EventEmitter<{ code: any; event: any, onlineRecognition: boolean }> = new EventEmitter<{ code: any; event: any, onlineRecognition: boolean }>();
	@ViewChild('drawingcanvasprod') drawingcanvasprod: ElementRef<HTMLCanvasElement>;
	drawing: DrawingProd | Drawing;
	textExtraction;
	currentNumber;
	training;
	imagelist;
	document: any;
	public enterClicked: boolean;
	private updateSizeSub: Subscription;
	video: any;

	constructor(
		private http: HttpClient,
		public globalService: GlobalService,
		public cabriService: CabriDataService,
		public networkService : NetworkService,
		public platform: Platform,
		public audioService: AudioService
	) {	}

	ngAfterViewInit() {
		this.imagelist = [];
		this.platform.ready().then(() => {
			this.globalService.checkToolbarStatus(true);
			if (this.mode === "prod") {
				this.drawing = new DrawingProd(this.http, this.globalService, this.cabriService, this.platform, this.activity, this.networkService, this.drawingcanvasprod.nativeElement);
			} else if (this.mode === "train") {

				this.drawing = new Drawing(this.http);
				this.video = document.getElementById("live");
				(navigator as any).mediaDevices.getUserMedia({video:true, audio:false}).then( stream => {
					this.gotStream(stream);
				});
			}
			this.enterClicked = false;
			this.subscribeToUpdateSize();
		});
	}

	gotStream(stream) {
		this.video.srcObject = stream;
		if (this.drawing instanceof Drawing){
			this.drawing.playWithVideo(this.video);
		}
	}
	subscribeToUpdateSize() {
		this.unsubscribeToUpdateSize();
		this.updateSizeSub = this.globalService.updateDrawCanvasSizeSubject.subscribe({
			next: () => {
				try {
					this.drawing.updateSize(this.activity);
				} catch (error) {
					console.error("Drawing component subscribeToUpdateSize() error = ", error);
					throw error;
				}
			}
		});
	}

	unsubscribeToUpdateSize() {
		if (this.updateSizeSub) {
			this.updateSizeSub.unsubscribe();
			this.updateSizeSub = null;
		}
	}

	testTesseract() {
		this.textExtraction = "";
		if (this.networkService.isConnected && this.drawing instanceof DrawingProd){
			this.drawing.textRecognition(this.currentNumber, this.training).subscribe(response => {
				this.processResponseTrain(response);
			});
		} else {
			this.drawing.tfjsTextRecognition().subscribe(response => {
				this.processResponseTrain(response);
			});
		}
	}

	processResponseTrain(response){
		if (response[0] === "") {
			this.textExtraction = "No result :(";
		} else {
			this.textExtraction = response[0].trim();
		}
		let texts = this.textExtraction.split(" ");
		if (texts.length === 0) {
			texts = [this.textExtraction];
		}
		let count = 0;
		response[1].forEach(element => {
			if (element.indexOf("https") > -1) {
				this.imagelist.unshift({ src: element, text: texts[count], correctedValue: null });
				count++;
			}
		});
		this.drawing.clearCanvas();
	}

	emitEvent(value: string) {
		if (value === "enter") {
			if (this.enterClicked === false && !this.globalService.mathiaSpeechRunning) {
				this.audioService.playValidateSound();
				this.enterClicked = true;
				if ((this.globalService.lowPerformanceMode || this.globalService.ocrOfflineUnavailable) && this.networkService.isConnected && this.drawing instanceof DrawingProd ){
					this.drawing.textRecognition(this.currentNumber, this.training).subscribe(response => {
						this.processResponse(response,value);
					});
				} else {
					// load ocr model on lowPerf off event in place of first enter hit?
					this.drawing.tfjsTextRecognition().subscribe(response => {
						this.processResponse(response,value);
					});
				}
			}
		} else if (value === "clear") {
			this.audioService.playCancelSound();
			this.drawing.clearCanvas();
		}
	}

	processResponse(response, value, onlineRecognition = false){
		this.drawing.clearCanvas();
		response[0] = response[0].replace(/ /g, "");
		this.change.emit({ code: {text : response[0], confidence : response[1]}, event: value, onlineRecognition });
		this.enterClicked = false;
	}
	hasOnlineRecognition(){
		return this.networkService.isConnected && this.drawing.onlineRecognitionPromise !== null && this.drawing.onlineRecognitionPromise !== undefined;
	}
	async processOnlineRecognition(){
		console.log("processOnlineRecognition after offline");
		await lastValueFrom(this.drawing.onlineRecognitionPromise);
		const saveResult = cloneDeep(this.drawing.onlineRecognitionResult);
		this.drawing.onlineRecognitionPromise = null;
		this.drawing.onlineRecognitionResult = null;
		this.processResponse(saveResult, "enter", true);
	}
	emptyImageList() {
		this.imagelist = [];
	}

	train(img: any) {
		// console.log(img);
		const formData = new FormData();
		formData.append("img_src", img.src);
		formData.append("currentNumber", img.correctedValue);
		this.http
			.post("https://mathia.education/wp-admin/admin-ajax.php?action=app_mathia_train_digit_recognition", formData)
			.subscribe(result => {
				this.imagelist = this.imagelist.filter(item => item.src != img.src);
			});
	}

	ngOnDestroy() {
		this.unsubscribeToUpdateSize();
	}
}
