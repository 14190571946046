import * as confetti from "canvas-confetti";

export class ConfettiOptions {
	particleCount?: number;
	spread?: number;
	startVelocity?: number;
	gravity?: number;
	angle?: number;
	origin?: { x: number; y: number };
}

export class ConfettiCanvas {
	public confettiCreation: any;
	options: ConfettiOptions;
	private defaultOptions: ConfettiOptions = {
		particleCount: 150,
		angle: 90,
		spread: 45,
		startVelocity: 45,
		origin: {
			x: 0.5,
			y: 0.5
		}
		// follow doc https://github.com/catdad/canvas-confetti
	};
	constructor(canvas: HTMLCanvasElement, options?: ConfettiOptions) {
		this.options = { ...this.defaultOptions, ...options };
		this.confettiCreation = confetti.create(canvas, {
			resize: true,
			useWorker: false
		});
	}

	launchConfetti(): Promise<void> {
		return new Promise<void>(resolve => {
			this.confettiCreation({
				...this.options
			}).finally(() => {
				resolve();
				this.confettiCreation.reset();
			});
		});
	}
}
