import { NavigationStart, Router, RouterEvent } from "@angular/router";
import { EventEmitter, Injectable, ViewChild, ViewChildren } from "@angular/core";
import { AngularDelegate, ModalController, Platform } from "@ionic/angular";
import { VideoComponent } from "../components/video/video.component";
import { Video } from "../models/video";
import { OverlayEventDetail } from "@ionic/core";
import { Badge } from "../models/badge";
import { RewardComponent } from "../components/reward/reward.component";
import { AudioEngineLockedComponent } from "../components/audio-engine-locked/audio-engine-locked.component";
import { filter } from "rxjs/operators";
import { PremiumModalComponent } from "../components/premium-modal/premium-modal.component";
import { AccountImplService } from "./account.Impl.service";
import { NgxScannerQrcodeComponent, ScannerQRCodeConfig, ScannerQRCodeResult } from "ngx-scanner-qrcode";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { IonicQrCodeComponent } from "../components/ionic-qr-code/ionic-qr-code.component";
import { AndroidPermissions } from "@awesome-cordova-plugins/android-permissions/ngx";
import { IframeModalComponent } from "../components/iframe-modal/iframe-modal.component";
@Injectable({
	providedIn: "root"
})
export class ModalsService {
	modalReward: HTMLIonModalElement;
	modalVideo: HTMLIonModalElement;
	modalaudioEngineLocked: HTMLIonModalElement;
	fullscreenEvent= new EventEmitter<boolean>();
	endStatementVideoEvent= new EventEmitter<boolean>();
	modalPremium: HTMLIonModalElement;
	modalQrCode: HTMLIonModalElement;
	qrCodeScanner : NgxScannerQrcodeComponent;
	@ViewChild("scanner") scanner : NgxScannerQrcodeComponent;
	data: BehaviorSubject<ScannerQRCodeResult[]>;
	modalIframe: HTMLIonModalElement;

	constructor(public modalController: ModalController, router: Router,public angularDelegate : AngularDelegate, public platform : Platform,public androidPermissions: AndroidPermissions) {
		router.events.pipe(
			filter((e: RouterEvent): e is RouterEvent => e instanceof RouterEvent)
		 ).subscribe((e: RouterEvent) => {
		   if (e instanceof NavigationStart) {
			this.modalVideo?.dismiss();
			this.modalReward?.dismiss();
			this.modalQrCode?.dismiss();
		   }
		 });
		 platform.ready().then(() => {
			if (platform.is("android")) {
				this.requestAndroidPermissions();
			}
		}); 
	}
	requestAndroidPermissions() {
		this.androidPermissions
			.requestPermissions([
				this.androidPermissions.PERMISSION.CAMERA
			])
			.then(() => {
				console.log("Camera");
			})
			.catch(err => {
				console.log("catch Camera", err);
			});
	}

	private async videoModal(video: Video = null, contextXapi ={}) {
		this.modalVideo = await this.modalController.create({
			component: VideoComponent,
			cssClass: "videoModalClass",
			componentProps: {
				videoF: video,
				descriptionFull : true,
				context : contextXapi,
				fullscreen : this.fullscreenEvent,
				endStatement : this.endStatementVideoEvent
			},
			backdropDismiss: false,
			showBackdrop: false,
			mode: "md"
		});
		return await this.modalVideo.present();
	}

	public async popVideoModal(video: Video = null, contextXapi ={}): Promise<OverlayEventDetail<any>> {
		if (!this.modalVideo || !this.modalVideo.isConnected) {
			await this.videoModal(video, contextXapi);
			return this.modalVideo.onDidDismiss();
		} else {
			return null;
		}
	}

	private async rewardModal(badges: Badge[]) {
		this.modalReward = await this.modalController.create({
			component: RewardComponent,
			cssClass: "rewardModalClass",
			componentProps: {
				badges: badges
			}
		});
		return await this.modalReward.present();
	}

	public async popRewardModal(badges: Badge[]): Promise<OverlayEventDetail<any>> {
		if (!this.modalReward || !this.modalReward.isConnected) {
			await this.rewardModal(badges);
			return this.modalReward.onDidDismiss();
		} else {
			return null;
		}
	}

	// modal to unlock babylon audio engine via user action
	private async audioEngineLockedModal(launchMusic: boolean) {
		this.modalaudioEngineLocked = await this.modalController.create({
			component: AudioEngineLockedComponent,
			cssClass: "audioEngineLockedModal",
			animated: false,
			showBackdrop: false,
			componentProps: {
				launchMusic
			}
		});
		return await this.modalaudioEngineLocked.present();
	}

	public async popAudioEngineLocked(launchMusic = false): Promise<OverlayEventDetail<any>> {
		if (!this.modalaudioEngineLocked || !this.modalaudioEngineLocked.isConnected) {
			await this.audioEngineLockedModal(launchMusic);
			return this.modalaudioEngineLocked.onDidDismiss();
		} else {
			return null;
		}
	}


		// modal to unlock babylon audio engine via user action
		private async premiumModal(urlType : 'video'|'aventure'|'logbook'|'ose'|"mathiaFamily" | "mathiaTeacher") {
			this.modalPremium = await this.modalController.create({
				component: PremiumModalComponent,
				cssClass: "videoModalClass",//"premiumModal",
				animated: false,
				showBackdrop: false,
				componentProps: {
					urlType
				}
			});
			return await this.modalPremium.present();
		}

		public async popModalPremium(urlType : 'video'|'aventure'|'logbook'|'ose'|"mathiaFamily" | "mathiaTeacher"){
			if (!this.modalPremium || !this.modalPremium.isConnected) {
				await this.premiumModal(urlType);
				return this.modalPremium.onDidDismiss();
			} else {
				return null;
			}
		}


		private async qrCodeModal(config? : ScannerQRCodeConfig) {
			this.modalQrCode = await this.modalController.create({
				component: IonicQrCodeComponent,
				cssClass: "QrModalClass",
				componentProps: {
					config,
				},
				backdropDismiss: true,
				showBackdrop: true,
				mode: "md"
			});
			return await this.modalQrCode.present();
		}

		public async popQrCodeModal(config? : ScannerQRCodeConfig): Promise<OverlayEventDetail<ScannerQRCodeResult[]>> {
			if (!this.modalQrCode || !this.modalQrCode.isConnected) {
				await this.qrCodeModal(config);
				//this.modalQrCode.delegate['elRefMap'].get(this.modalQrCode.children[0]).instance.start();
				return this.modalQrCode.onDidDismiss();
			} else {
				return null;
			}
		}

		public async iFrameModal(url: string) {
			this.modalIframe = await this.modalController.create({
				component: IframeModalComponent,
				cssClass: "modal-inscription",
				componentProps: { myUrl: url }
			});
			return await this.modalIframe.present();
		}

		public async popIFrameModal(url: string): Promise<OverlayEventDetail<boolean>> {
			if (!this.modalIframe || !this.modalIframe.isConnected) {
				await this.iFrameModal(url);
				return this.modalIframe.onDidDismiss();
			} else {
				return null;
			}
		}

}
