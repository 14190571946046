import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OseDifficulty } from 'src/app/models/enums/oseDifficulty';
import { CabriDataService } from 'src/app/services/cabri-data.service';

@Component({
  selector: 'app-ose-difficulty-selector',
  templateUrl: './ose-difficulty-selector.component.html',
  styleUrls: ['./ose-difficulty-selector.component.scss'],
})
export class OseDifficultySelectorComponent implements OnInit {
  @Output() setDifficulty = new EventEmitter<string>();
  @Input() difficulties?:Array<OseDifficulty>;
  @Input() difficultySelected?:OseDifficulty;

  public get OseDifficulty(){
    return OseDifficulty
  }
  constructor(public cabriService:CabriDataService) {}

  ngOnInit() {
    if(!this.difficulties || this.difficulties?.length === 0){
      this.difficulties = [OseDifficulty.easy,OseDifficulty.normal,OseDifficulty.hard]
    }
  }

  setCurrentDifficulty(difficultyLevel:OseDifficulty){
      this.setDifficulty.next(difficultyLevel);
  }

}
