import { Injectable, Inject } from '@angular/core';
import { LrsVerbs, XapiVerbs } from "../models/lrs/xapiverbs";
import { AccountService } from "./account.service";
import { GlobalService } from "./global.service";
import { LrsService } from "./lrs.service";
import { CompletedExercises, JourneysStatus } from "../models/lrs/ose-stats-models";

@Injectable({
	providedIn: "root"
})
export class OseStatsService {
	journeysStatus: Array<JourneysStatus>;
	exercisesStatus: Array<JourneysStatus>;
	currentCompletedJourneys: Array<JourneysStatus> = new Array();
	prevCompletedJourneys: Array<JourneysStatus>;
	onGoingJourneys: Array<JourneysStatus>;
	completedExercises: Array<CompletedExercises>;
	onGoingExercises: Array<CompletedExercises>;
	sequencesTraces: Array<any>;
	public lrs:LrsService
	constructor(
		public accountService: AccountService,
		public globalService: GlobalService,
	) {}

	/**
	 * Circular dependancy avoid from LrsService -> oseJourneys -> oseStatsService
	 * @param lrs LrsService
	 */
	setLRSJourney(lrs:LrsService){
		this.lrs = lrs;
	}

	/**
	 * Completed journeys/exercises
	 */
	public completedJourneys() {
		return new Promise<void>((resolve, reject) => {
			if (this.journeysStatus) {
				resolve();
			} else {
				if (!this.accountService.team[0]) {
					this.journeysStatus = null;
					resolve();
				} else {
					const statement = [
						{
							$match: {
								timestamp: {
									$gte: {
										$dte: "2022-09-02T08:09:00.000Z"
									}
								}
							}
						},
						{
							$match: {
								$and: [
									{
										"statement.actor.account.name": {
											$in: [String(this.accountService.team[0].id)]
										}
									},
									{
										"statement.verb.id": {
											$in: ["https://xapi.ose/verbs/initialized", "https://xapi.ose/verbs/completed"]
										}
									}
								]
							}
						},
						{
							$project: {
								session_id: "$statement.object.definition.type",
								journeyId: "$statement.context.extensions.https://xapi&46;ose/extensions/parcours_id",
								id: {
									$cond: [
										{
											$and: [
												{
													$eq: ["$statement.object.definition.type", "https://xapi.ose/objectType/journey"]
												}
											]
										},
										"$statement.context.extensions.https://xapi&46;ose/extensions/parcours_id",
										"$statement.context.extensions.https://xapi&46;ose/extensions/id"
									]
								},
								verb: "$statement.verb.id",
								isJourney: {
									$cond: [
										{
											$and: [
												{
													$eq: ["$statement.object.definition.type", "https://xapi.ose/objectType/journey"]
												}
											]
										},
										true,
										false
									]
								},
								
							}
						},

						{
							$group: {
								_id: {
									id: "$id",
									session_id: "$session_id",
									status: "$verb",
									isJourney: "$isJourney",
								},
								associatedJourneys:{
									$push: '$journeyId'
								}
							}
						},
						{
							$group: {
								_id: {
									id: "$_id.id",
									status: "$_id.status",
									isJourney: "$_id.isJourney",
									associatedJourneys: "$associatedJourneys",
								}
							}
						},
						{
							$sort: {
								timestamp: -1
							}
						}
					];

					this.lrs
						.request(statement)
						.then((completedItems: any) => {
							const journeyItems = new Array();
							const exerciseItems = new Array();
							completedItems.forEach(currJ => {
								if (currJ._id?.id) {
									currJ._id.id = Number(currJ._id.id);
									if (currJ._id.isJourney) {
										// filter by completed or initialized by journeys
										journeyItems.push(currJ._id);
									} else {
										// filter by completed or initialized by exercises
										exerciseItems.push(currJ._id);
									}
								}
							});

							// filter duplicates values journeys avoid mongoDB's processing time
							this.journeysStatus = journeyItems.filter((value, index, self) => {
								return index === self.findIndex(t => t.id === value.id && t.status === value.status);
							});
							// filter duplicates values journeys avoid mongoDB's processing time
							this.exercisesStatus = exerciseItems.filter((value, index, self) => {
								return index === self.findIndex(t => t.id === value.id && t.status === value.status);
							});

							this.getStatsBasedOnProgression();
							resolve();
						})
						.catch(err => {
							reject(true);
						});
				}
			}
		});
	}

	/**
	 * Segregate journeys and exercises  statuses(initialized/completed) statements in different arrays
	 */
	private getStatsBasedOnProgression() {
		/** JOURNEYS */
		this.currentCompletedJourneys = this.journeysStatus.filter(journey => {
			return journey.status === XapiVerbs.get(LrsVerbs.completed).id;
		});

		this.onGoingJourneys = this.journeysStatus.filter(alLStatuses => {
			return !this.currentCompletedJourneys.some(completedJourney => {
				return alLStatuses.id === completedJourney.id;
			});
		});

		/** EXERCISES */
		this.completedExercises = this.exercisesStatus.filter(journey => {
			return journey.status === XapiVerbs.get(LrsVerbs.completed).id;
		});

		this.onGoingExercises = this.exercisesStatus.filter(alLStatuses => {
			return !this.completedExercises.some(completedJourney => {
				return alLStatuses.id === completedJourney.id;
			});
		});
	}


	public sequencesProgression() {
		return new Promise<void>((resolve, reject) => {
			const statement = [
				{
					$match: {
						timestamp: {
							$gte: {
								$dte: "2022-09-02T08:09:00.000Z"
							}
						}
					}
				},
				{
					$match: {
						$and: [
							{
								"statement.actor.account.name": {
									$in: [String(this.accountService.team[0].id)]
								}
							},
							{
								"statement.context.extensions.https://xapi&46;ose/extensions/parcours_id": {
									$nin: [null]
								}
							},
							{
								"statement.verb.id": {
									$nin: ["https://xapi.ose/verbs/finished"]
								}
							}
						]
					}
				},
				{
					$project: {
						parcoursSessionId: "$statement.context.extensions.https://xapi&46;ose/extensions/parcours_session_id",
						parcoursId: "$statement.context.extensions.https://xapi&46;ose/extensions/parcours_id",
						exerciseSessionId: "$statement.context.extensions.https://xapi&46;ose/extensions/id_session",
						exId: "$statement.context.extensions.https://xapi&46;ose/extensions/id",
						timestamp:"$statement.timestamp",
						type: "$statement.context.extensions.https://xapi&46;ose/extensions/nom_activite",
						isCompleted: {
							$cond: [{ $eq: ["$statement.verb.id", "https://xapi.ose/verbs/completed"] }, true, false]
						},
						isObjectJourneyType: {
							$cond: [{ $eq: ["$statement.object.definition.type", "https://xapi.ose/objectType/journey"] }, true, false]
						},
					}
				},
				{
					$group: {
						_id: "$parcoursSessionId",
						parcoursId: { $first: "$parcoursId" },
						exercises_progressions: {
							$push: {
								$cond: {
									if: {
										$eq: ["$isCompleted", true]
									},
									then: {
										$cond: {
											if: {
												$eq: ["$isObjectJourneyType", true]
											},
											then: {id:"$exId", completed:true,type:"$type",timestamp:"$timestamp", isObjectJourneyType:true},
											else: {id:"$exId", completed:true,type:"$type",timestamp:"$timestamp", isObjectJourneyType:false}

										}
									},
									else: {id:"$exId", completed:false,type:"$type",timestamp:"$timestamp", isObjectJourneyType:false}
								},
							}
						}
					}
				},
				{
					$sort: {
						_id: -1
					}
				}
			];

			this.lrs
				.request(statement)
				.then((allSequencies: any) => {
					// remove duplicate values. Values are already returned by storage date (newest to oldest)
					this.sequencesTraces = allSequencies.filter((ex, index, self) => {
						return (
							index ===
							self.findIndex(t => {
								return t.parcoursId === ex.parcoursId;
							})
						);
					})

					this.sequencesTraces.forEach((currSeq) => {
						currSeq.exercises_progressions = currSeq.exercises_progressions.sort((a, b) => a.timestamp.localeCompare(b.timestamp));
					})
					resolve();
				})
				.catch(err => {
					console.error("errerr", err);
					reject(true);
				});
		});
	}
}
