import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import { PinchZoomComponent } from "@meddv/ngx-pinch-zoom";
import { SwiperOptions } from "swiper";
import { SwiperComponent } from "swiper/angular";

@Component({
	selector: "app-image-viewer",
	templateUrl: "./image-viewer.component.html",
	styleUrls: ["./image-viewer.component.scss"]
})
export class ImageViewerComponent implements OnChanges {
	@ViewChild("pinchZoom") pinchZoom: PinchZoomComponent;
	@ViewChild("mainContainer", { static: false }) mainContainer: ElementRef;
	@ViewChild("swiper", { static: false }) slideElement;
	
	@Input() imageURL: string | { src: string; srcSet: string };
	@Input() gallery: Array<any>;

	@Output() close = new EventEmitter();
	public open = false;
	public isLandscape = true;
	public slides: Array<string> = [];
	public swiperOptions: SwiperOptions = {
		noSwiping: true,
		noSwipingClass: "swiper-slide",
		allowTouchMove: false,
		loop: true
	}
	constructor(public changeDetector : ChangeDetectorRef) {
	}
	ngOnChanges(changes: SimpleChanges): void {
		console.log(changes);		
		if (
			changes.imageURL?.currentValue &&
			(!changes.imageURL.previousValue || changes.imageURL.previousValue.src !== changes.imageURL.currentValue.src)
		) {
			this.open = true;
			const img = new Image();
			img.onload = () => {
				console.log("image loaded");
				this.isLandscape = img.width > img.height;
				this.slides = [this.imageURL, ...this.gallery.filter(i => {
					let isrc= i instanceof Object ? i.src : i;
					return img.src!== isrc;
				})];
			};
			img.src = changes.imageURL.currentValue.src?changes.imageURL.currentValue.src: changes.imageURL.currentValue;
		}
		if (this.imageURL && changes.gallery?.currentValue &&  (!changes.gallery.previousValue || changes.gallery.previousValue !== changes.gallery.currentValue)){
			this.open = true;
			this.slides = [this.imageURL, ...this.gallery.filter(i => {
				let src = this.imageURL instanceof Object ? this.imageURL.src : this.imageURL;
				let isrc= i instanceof Object ? i.src : i;
				return src!== isrc;
			})];
		}

		// const zBtn = this.mainContainer.nativeElement.querySelector(".pz-zoom-button");
		// zBtn.removeEventListener("click", this.pinchZoom.toggleZoom);
		// zBtn.removeEventListener("click", this.customToggleZoom);
		// zBtn.addEventListener("click", this.customToggleZoom);
	}
	customToggleZoom($event) {
		$event.preventDefault();
		$event.stopPropagation();
		this.pinchZoom.toggleZoom();
	}

	onSwiperChange($event){
		console.log("swiper change", $event);
		const currentImg = this.slideElement?.elementRef.nativeElement.querySelector(".swiper-slide-active img");
		if(currentImg){
			const img = new Image();
			img.onload = () => {
				console.log("image loaded");
				this.isLandscape = img.width > img.height;
			};
			img.src = currentImg.src;
		}
	}
	swiperInitliazed($event){
		console.log("swiper initialized", $event);
	}


	nextSlide() {
		this.slideElement.swiperRef.slideNext();
	}

	prevSlide() {
		this.slideElement.swiperRef.slidePrev();
	}
	closeModal(){
		this.close.emit();
		this.open = false;
		this.changeDetector.detectChanges();
	}
}
