import { Actor } from "./lrs/actores";
import { XapiObject } from "./lrs/xapiobject";
import { LrsService } from "../services/lrs.service";
import { CabriDataService } from "../services/cabri-data.service";
import { GlobalService } from "../services/global.service";
import { AccountService } from "../services/account.service";
import { LrsUtils } from "./lrs/lrsUtils";
import { Journey } from "./journey";
import { NetworkService } from "../services/network.service";
import { Status } from "./proposed-activity";
import { Result } from "./lrs/result";
import { XapiContext, XapiExtensions } from "./lrs/xapicontext";
import { LmsService } from "../services/lms.service";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Phrase } from "./phrase";
import { CastelGrid } from "./castel-grid";
import { LrsVerbs, XapiVerbs } from "./lrs/xapiverbs";
import { Statement } from "./statement";

export class LrsStatement {
	public actor = new Array<Actor>();
	public object: XapiObject;
	public verb: any;
	public context: XapiContext;
	public result: Result;
	public timeStamp: string;
	public stored: string;

	public durationOfJourney: string;
	public durationOfActivity: string;

	public statement: Statement[] = new Array();

	public lrsService: LrsService;
	public lmsService: LmsService;
	public cabriService: CabriDataService;
	public globalService: GlobalService;
	public accountService: AccountService;
	public networkService: NetworkService;
	public http: HttpClient;

	userIsAutenticated = false;
	public cabriActivity = true;

	public environment;

	requirements: {
		cabriService: CabriDataService;
		lmsService: LmsService;
		globalService: GlobalService;
		accountService: AccountService;
		lrsService: LrsService;
		http: HttpClient;
	};
	verbRequirement;

	constructor(requirements, cabri = true) {
		/** Initialisation du LRS */
		this.cabriService = requirements.cabriService;
		this.globalService = requirements.globalService;
		this.lrsService = requirements.lrsService;
		this.accountService = requirements.accountService;
		this.lmsService = requirements.lmsService;
		this.http = requirements.http;
		this.networkService = requirements.networkService;
		this.environment = environment;
		this.cabriActivity = cabri;
	}


	/**
	 * Define object definition params
	 */
	public _setDefinitionParams() {
		const typeOfActivity = LrsUtils.detectCurrentActivityNameById(+this.cabriService.currentActivity.id);
		let definitionName;

		if (!LrsUtils.currentOperation) {
			definitionName = `${this.cabriService.currentActivity.name} `;
		} else {
			if (typeOfActivity == "jeu_de_l'heure") {
				definitionName = `${LrsUtils.currentOperation} `;
			} else if (typeOfActivity == "jeu_de_kim") {
				definitionName = "jeu de kim";
			} else {
				definitionName = `Opération ${LrsUtils.currentOperation} `;
			}
		}

		return {
			definitionName,
			definitionDescription: `${LrsUtils.currentOperation}`
		};
	}


	offlineStatementSave(currentStatement) {
		const currentMoment = LrsUtils.getLrsTimestampFormat();
		currentStatement.timestamp = currentMoment;
		currentStatement.stored = currentMoment;
		this.lrsService.offlineStatementsStore.push(currentStatement);
		localStorage.setItem("statement", JSON.stringify(this.lrsService.offlineStatementsStore));
	}


	/**
	 * Total trainging duration of journey after the resume
	 */
	durationResumedTrainingJourney(sessionAskedQuestions) {
		if (sessionAskedQuestions && sessionAskedQuestions.allAskedQuestions.length > 0) {
			let journeyAskedQuestions = new Array();
			journeyAskedQuestions = sessionAskedQuestions.allAskedQuestions;
			// Group journeys maked in 3 minutes
			const training = journeyAskedQuestions.reduce((r, o, i, { [i - 1]: last }) => {
				let nextTimestamp;
				let lastTimestamp;
				if (o && o.timestamp) {
					nextTimestamp = new Date(o.timestamp);
				}
				if (last && last.timestamp) {
					lastTimestamp = new Date(last.timestamp);
				}
				if (!last || nextTimestamp - lastTimestamp > 3 * 60000) {
					r.push([o]);
				} else {
					r[r.length - 1].push(o);
				}
				return r;
			}, []);

			// Calculate total duration of journey by substraction of different timestamps
			let totalDuration = 0;
			for (const j in training) {
				// Only one timestamp
				if (training[j].length === 1) {
					totalDuration += Number(training[j][0].vTimer);
				} else {
					// Multiple timestamps
					training[j].forEach((item, index) => {
						if (training[j][index + 1]) {
							const next: any = new Date(training[j][index + 1].timestamp);
							const before: any = new Date(training[j][index].timestamp);
							totalDuration += (next - before) / 1000;
						}
					});
				}
			}
			if (typeof totalDuration === "number") {
				if (totalDuration < 0) {
					return null;
				} else {
					return Math.round(totalDuration);
				}
			}
		}
		return null;
	}


	/**
	 * Duration of resume journey in training mod
	 */
	durationTotalTrainingJourneyResume() {
		const startedJourney = LrsUtils.durationStartJourney / 1000;
		const currentDate = new Date().getTime() / 1000;
		const asSeconds = currentDate - startedJourney;
		const totalFinalDuration = this.lmsService.currentUserJourney.duration + asSeconds;
		return Math.floor(totalFinalDuration);
	}

	/**
	 * Training total duration when it has been already played
	 * @param currentJourney
	 */

	setResumeTrainingDuration(currentJourney: Journey) {
		let journeyAskedQuestions = new Array();
		journeyAskedQuestions = currentJourney.allAskedQuestions;
		// Group journeys maked in 3 minutes
		const training = journeyAskedQuestions.reduce((r, o, i, { [i - 1]: last }) => {
			let nextTimestamp;
			let lastTimestamp;
			if (o && o.timestamp) {
				nextTimestamp = new Date(o.timestamp);
			}
			if (last && last.timestamp) {
				lastTimestamp = new Date(last.timestamp);
			}
			if (!last || nextTimestamp - lastTimestamp > 3 * 60000) {
				r.push([o]);
			} else {
				r[r.length - 1].push(o);
			}
			return r;
		}, []);

		// Calculate total duration of journey by substraction of different timestamps
		let totalDuration = 0;
		for (const j in training) {
			// Only one timestamp
			if (training[j].length === 1) {
				totalDuration += Number(training[j][0].vTimer);
			} else {
				// Multiple timestamps
				training[j].forEach((item, index) => {
					if (training[j][index + 1]) {
						const next: any = new Date(training[j][index + 1].timestamp);
						const before: any = new Date(training[j][index].timestamp);
						totalDuration += (next - before) / 1000;
					}
				});
			}
		}
		if (typeof totalDuration === "number") {
			if (totalDuration < 0) {
				return null;
			} else {
				return Math.round(totalDuration);
			}
		}
	}


	/** Random traces for trala*/

	/**
	 * Generate random operation with its appropriate response according to the verb type
	 */
	generateRandomResponseOperation(verb): { response: string; currentOperation: string } {
		const variables = this.cabriService.currentActivity.variables;
		let response: string;
		let currentOperation = null;
		if (Number(this.cabriService.currentActivity.id) === 1 || Number(this.cabriService.currentActivity.id) === 7) {
			if (Number(variables.operation) === 1) {
				currentOperation = String(variables.nb1 + " + " + variables.nb2);
			} else if (Number(variables.operation) === 2) {
				currentOperation = String(variables.nb1 + " * " + variables.nb2);
			} else if (Number(variables.operation) === 3) {
				currentOperation = String(variables.nb1 + " - " + variables.nb2);
			}
			// Bonnes réponses
			response = eval(currentOperation);
			// Mauvaises réponses
			if (verb === "failed" || verb === "failed-on-first-attempt") {
				response = response + 1;
			}
		} else if (Number(this.cabriService.currentActivity.id) === 3) {
			if (Number(variables.operation) === 1) {
				currentOperation = String(variables.nb1 + " + " + variables.pas);
			} else {
				currentOperation = String(variables.nb1 + " - " + variables.pas);
			}
			// Bonnes réponses
			response = eval(currentOperation);

			// Mauvaises réponses
			if (verb === "failed" || verb === "failed-on-first-attempt") {
				response = response + 2;
			}
		} else if (Number(this.cabriService.currentActivity.id) === 5) {
			const randomHour = this._getHour();
			currentOperation = randomHour.goodResult;
			// Bonnes réponses
			response = randomHour.goodResult;
			// Mauvaises réponses
			if (verb === "failed" || verb === "failed-on-first-attempt") {
				response = randomHour.multiChoices[0];
			}
		} else if (Number(this.cabriService.currentActivity.id) === 6) {
			const castelGrid = new CastelGrid();
			// Définir nombre de lignes et de colonnes
			castelGrid._nbRows = Number(variables["v-nbRow"]);
			castelGrid._nbCols = Number(variables["v-nbCol"]);
			if (Number(variables.operation) === 1) {
				castelGrid.mode = "addition";
			} else if (Number(variables.operation) === 2) {
				castelGrid.mode = "multiplication";
			} else if (Number(variables.operation) === 3) {
				castelGrid.mode = "soustraction";
			} else if (Number(variables.operation) === 4) {
				castelGrid.mode = "addition-classic";
			}
			// L'ensemble des résultats possibles au cours de la session
			castelGrid.generateUndisplayedNumbers(Number(variables["v-hidingLevel"]));
			const nbHiddenNumbers = Number(variables["v-nbHidden"]);
			const difficultyLevel = Number(variables["v-difficulty"]);
			const hiddenNumbers = castelGrid.generateHiddenNumbers(nbHiddenNumbers, difficultyLevel);
			const cellId = hiddenNumbers[Math.floor(Math.random() * hiddenNumbers.length)];

			// La cellule correspondante
			const currentCell = castelGrid.getCellById(Number(cellId));
			let operation = "";
			operation += castelGrid.cells[0][currentCell.col].value;
			if (castelGrid.mode === "addition" || castelGrid.mode === "addition-classic") {
				operation += " + ";
			} else if (castelGrid.mode === "multiplication") {
				operation += " * ";
			} else if (castelGrid.mode === "soustraction") {
				operation += " - ";
			}
			operation += castelGrid.cells[0][currentCell.row].value;
			currentOperation = operation;
			// Bonnes réponses
			response = eval(operation);
			// Mauvaises réponses
			if (verb === "failed" || verb === "failed-on-first-attempt") {
				response = response + 2;
			}
		} else if (Number(this.cabriService.currentActivity.id) === 4) {
			let response = Math.floor(Math.random() * this.cabriService.currentActivity.variables["v-solides"].length);
			// Mauvaises réponses
			if (verb === "failed" || verb === "failed-on-first-attempt") {
				let searchWrongAnswer;
				do {
					searchWrongAnswer = Math.floor(Math.random() * this.cabriService.currentActivity.variables["v-solides"].length);
				} while (response == searchWrongAnswer);
				response = searchWrongAnswer;
			}
		}
		return { response, currentOperation };
	}

	/**
	 * Generate hour
	 */
	private _getHour() {
		let possibleMinutes = new Array();
		let minHour = 1;
		let maxHour = 24;
		if (this.cabriService.currentActivity.variables["v-difficulty"]) {
			if (Number(this.cabriService.currentActivity.variables["v-difficulty"]) === 1) {
				possibleMinutes = [0];
			} else if (Number(this.cabriService.currentActivity.variables["v-difficulty"]) === 2) {
				possibleMinutes = [0, 30];
			} else if (Number(this.cabriService.currentActivity.variables["v-difficulty"]) === 3) {
				possibleMinutes = [0, 15, 30, 45];
			} else if (Number(this.cabriService.currentActivity.variables["v-difficulty"]) === 4) {
				for (let i = 0; i < 24; i += 5) {
					possibleMinutes.push(i);
				}
			} else if (Number(this.cabriService.currentActivity.variables["v-difficulty"]) === 5) {
				for (let i = 0; i < 24; i++) {
					possibleMinutes.push(i);
				}
			}
		} else if (this.cabriService.currentActivity.variables["v-AmPm"]) {
			if (Boolean(this.cabriService.currentActivity.variables["v-AmPm"])) {
				minHour = 1;
				maxHour = 24;
			} else {
				minHour = 1;
				maxHour = 12;
			}
		}

		const date = LrsUtils.generateHour(possibleMinutes, maxHour, minHour);
		const dateFormatted = LrsUtils.formattingResult(date);
		return LrsUtils.createMultiChoice(dateFormatted, possibleMinutes, maxHour, minHour);
	}

	/**
	 * Verb based on percentage
	 */
	verbBasedOnPercentage(goodAnswers, numberOfQuestions, cursorMod = null) {
		const wrongAnswers = numberOfQuestions - goodAnswers;
		const isActivityWithHelp =
			Number(this.cabriService.currentActivity.id) === 1 ||
			Number(this.cabriService.currentActivity.id) === 7 ||
			Number(this.cabriService.currentActivity.id) === 4 ||
			Number(this.cabriService.currentActivity.id) === 5 ||
			Number(this.cabriService.currentActivity.id) === 6 ||
			Number(this.cabriService.currentActivity.id) === 10;

		const failedVerbToFilter = new Array();
		const passedVerbToFilter = new Array();

		if (cursorMod === "2" || !isActivityWithHelp || wrongAnswers === 0) {
			failedVerbToFilter.push("failed");
			passedVerbToFilter.push("passed");
		} else {
			failedVerbToFilter.push("failed", "failed-on-first-attempt");
			passedVerbToFilter.push("passed", "passed-with-help");
		}

		let failds = Object.keys(XapiVerbs.verbs).filter(verb => {
			return failedVerbToFilter.some(verbToFilter => {
				return verb === verbToFilter;
			});
		});
		let passed = Object.keys(XapiVerbs.verbs).filter(verb => {
			return passedVerbToFilter.some(verbToFilter => {
				return verb === verbToFilter;
			});
		});

		const finalFailds = new Array();
		const finalPassed = new Array();

		for (let i = 0; i < wrongAnswers; i++) {
			failds = failds.sort(() => {
				return 0.5 - Math.random();
			});
			finalFailds.push(failds[0]);
		}

		for (let i = 0; i < goodAnswers; i++) {
			passed = passed.sort(() => {
				return 0.5 - Math.random();
			});

			finalPassed.push(passed[0]);
		}
		let concatVerbs = finalFailds.concat(finalPassed).sort(() => {
			return 0.5 - Math.random();
		});

		concatVerbs.forEach((verb, index) => {
			const isPassedVerb = passed.some(passedVerb => passedVerb === verb);
			if (index === concatVerbs.length - 1) {
				concatVerbs[index] = isPassedVerb ? "passed" : "failed";
			} else {
				if (concatVerbs[index - 1]) {
					if (isPassedVerb) {
						if (verb === "passed-with-help") {
							if (concatVerbs[index - 1] === "passed" || concatVerbs[index - 1] === "failed-on-first-attempt") {
								concatVerbs[index] = "passed-with-help";
							} else {
								concatVerbs[index] = "passed";
							}
						} else {
							if (concatVerbs[index - 1] === "failed-on-first-attempt") {
								concatVerbs[index] = "passed-with-help";
							} else {
								concatVerbs[index] = "passed";
							}
						}
					} else {
						if (verb === "failed-on-first-attempt") {
							if (
								concatVerbs[index - 1] === "failed" ||
								concatVerbs[index - 1] === "passed" ||
								concatVerbs[index - 1] === "passed-with-help"
							) {
								concatVerbs[index] = "failed-on-first-attempt";
							} else {
								concatVerbs[index] = "failed";
							}
						} else {
							if (
								concatVerbs[index - 1] === "passed" ||
								concatVerbs[index - 1] === "failed" ||
								concatVerbs[index - 1] === "passed-with-help"
							) {
								concatVerbs[index] = "failed-on-first-attempt";
							} else {
								concatVerbs[index] = "failed";
							}
						}
					}
				} else {
					// first verb of array
					if (isPassedVerb) {
						concatVerbs[index] = "passed";
					} else {
						concatVerbs[index] = "failed-on-first-attempt";
					}
				}
			}
		});

		const finalVerbs = new Array();

		if (cursorMod === "2") {
			concatVerbs = concatVerbs.sort((a, b) => {
				if (a === "passed") {
					return -1;
				} else {
					return 1;
				}
			});

			const anyFailedExist = concatVerbs.some(verb => {
				return verb === "failed";
			});
			concatVerbs = concatVerbs.filter((verb, index) => {
				return verb === "passed";
			});
			if (anyFailedExist) {
				concatVerbs.push("failed");
			}
		}
		concatVerbs.forEach(verb => {
			finalVerbs.push({ verb, finished: LrsVerbs.finished });
		});

		return finalVerbs;
	}

	/**
	 * Get random activity param
	 */
	getRandomItem(array) {
		const randomNumber = Math.floor(Math.random() * array.length);
		return array[randomNumber];
	}
}
