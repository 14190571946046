import { FicheEtapes } from "../components/fiche/fiche.component";
import { Details } from "../page/territoire/territoire.page";
import { CompletedExercises, JourneysStatus } from "./lrs/ose-stats-models";
import { OseActivity } from "./ose-activities";
import { OseCompetencies } from "./ose-competencies";

export enum OseExerciceType {
	fiche = "fiche",
	puzzle = "puzzles",
	coloriage = "coloriage",
	spotDifference = "7erreurs",
	memory = "memory",
	quizz = "quizz",
	wordSearch = "mots-caches",
	wasteSorting = "wastesort",
	visit = "visit"
}

export enum QuizzStatus {
	done,
	notDone,
	toDo
}

export class Ose2Journey {
	id: number;
	title: string;
	name: string;
	exercises: Array<OseActivity>;
	idJourneySession:string
	selectedCategoryType: Details;
	currentCategoryExercise: Array<OseActivity> = new Array();
	background: string;
	competencies: Array<OseCompetencies>;
	started = false;
	startedProgression = false;
	quizz: Array<{ id: number; completedQuizz: boolean; statusCompletion: QuizzStatus; isEvaluation:boolean, title?: string; icon?: string; typeNameFr?: string }>;
	feedback: string;
	isQuizzTypeCompleted: boolean; // true if lauched journey starts from (Je me teste) is quizz type
	isFiche = false;
	completed = false;
	lrsResume: boolean;
	currentExerciseIndex = 0;
	fiche_peda?: string;
	evaluations: Array<OseActivity> = new Array();
	learnings: Array<OseActivity> = new Array();
	workshops: Array<OseActivity> = new Array();
	lrsStart:boolean;
	skippedMode = false;
	percentProgression:number;
	exoCompletedDuringSession = new Array();
	assignation_id: any;
	isQuizzTypeStarted = false;

	constructor(element: any, oseCompetencies?: OseCompetencies[], oseActivities?: OseActivity[]) {
		this.id = element.id;
		this.title = element.title;
		this.idJourneySession = element.idJourneySession
		this.feedback = element.feedback;
		this.background = element.background;
		this.fiche_peda = element.fiche_peda;
		this.skippedMode = element.skippedMode;
		this.percentProgression = element.percentProgression;

		if(element.exercises){
			// recovering journey after the page was reloaded.
			this.exercises = element.exercises;
			this.competencies = element.competencies;
		}else{
			// journeys loaded for the first time
			if (oseActivities) {
				const deepCopiedExercises = JSON.stringify(this.getExerciseInfos(element.exerciseIds, oseActivities));
				this.exercises = JSON.parse(deepCopiedExercises);
				this.setExercisesTypeNameFr();
			}
			if (oseCompetencies) {
				this.competencies = this.getCompetencies(element.competencies, oseCompetencies);
			}
		}
		this.quizz = this.getQuizzStatuses(element.quizz);
	}

	/**
	 * Create quizz progression status
	 * @param quizzIds quizz ids
	 * @returns Quizz
	 */
	private getQuizzStatuses(quizzIds) {
		if (quizzIds) {
			return quizzIds?.map(quizzId => {
				return {
					id: Number(quizzId),
					completed: false,
					statusCompletion: QuizzStatus.notDone,
					type: OseExerciceType.quizz
				};
			});
		} else {
			return quizzIds;
		}
	}

	setExercisesTypeNameFr() {
		this.exercises.forEach(ex => {
			if (ex) {
				if (ex.type === OseExerciceType.fiche) {
					ex.typeNameFr = "Cours";
				} else if (ex.type === OseExerciceType.puzzle) {
					ex.typeNameFr = "Puzzle";
				} else if (ex.type === OseExerciceType.memory) {
					ex.typeNameFr = "Memory";
				} else if (ex.type === OseExerciceType.spotDifference) {
					ex.typeNameFr = "Jeu des erreurs";
				} else if (ex.type === OseExerciceType.wordSearch) {
					ex.typeNameFr = "Mots mêlés";
				} else if (ex.type === OseExerciceType.quizz) {
					ex.typeNameFr = "Quiz";
				} else if (ex.type === OseExerciceType.coloriage) {
					ex.typeNameFr = "Coloriage";
				} else {
					ex.typeNameFr = ex.type;
				}
			}
		});
	}

	/**
	 * Set quizz boolean status based on user completed journey ids
	 * @param completedJourneyIds Array<number>
	 */
	setQuizzCompletionStatuses(completedJourneyIds: Array<JourneysStatus>) {
		if (this.quizz) {
			this.quizz.forEach((quizz, quizzIndex) => {
				const statusDone = completedJourneyIds?.some(journeyStatus => Number(journeyStatus.id) === Number(quizz.id));
				this.quizz[quizzIndex].completedQuizz = statusDone;
			});
		}
	}

	/**
	 * Verify if current journey status match which one pass in param
	 * @param completedJourneyIds Array<number>
	 */
	hasJourneyStatusMatch(journeyStatus: Array<JourneysStatus>) {
		return journeyStatus?.some(journey => {
			return Number(journey.id) === Number(this.id);
		});
	}

	/**
	 * Change exercise ids array to an object with status
	 */
	private getExerciseInfos(exerciseIds, oseActivities: OseActivity[]) {
		return exerciseIds.map(exerciseId => {
			return oseActivities.find(activity => Number(activity.id) === Number(exerciseId));
		});
	}

	/**
	 * Convert competency ids into an object of competencies
	 *
	 * @param competencyIds competencies ids from server
	 * @param oseCompetencies competencies width multiple properties
	 */
	private getCompetencies(competencyIds: Array<number>, oseCompetencies: Array<OseCompetencies>): Array<OseCompetencies> {
		competencyIds.forEach((exerciseId, index) => {
			oseCompetencies.forEach(currOseComp => {
				if (currOseComp.id === (exerciseId as any)) {
					(competencyIds[index] as any) = currOseComp;
					return;
				}
			});
		});
		return competencyIds as any;
	}

	/**
	 * Current exercise of journey that is playing
	 */
	public getCurrentExercise(startFromBeginning = false,verify = false) {
		if (this.started || verify) {
			this.currentExerciseIndex = -1;
			this.currentExerciseIndex = this.exercises.findIndex(ex => {
				if(startFromBeginning && !ex.completed && ex.isworkshop === "1"){
					// worshop is skipped if startFromBeginning == true (Commencer / Recommencer)
					ex.completed = true;
				}
				return !ex.completed;
			});
			const currentExercise = this.exercises[this.currentExerciseIndex];
			return currentExercise;
		} else {
			return null;
		}
	}

	/**
	 * Calculate the percentage of completed exercises during the session
	 * @param seqTraces LRS traces of number exercises completed during the session
	 */
	public calculateSessionExerciseCompletionPercentage(seqTraces?):void{
		const addUniqCompletedEx = (currEx:OseActivity) => {
			// number completed exo during the journey's session
			if(!currEx?.isEtape){
				const exist = this.exoCompletedDuringSession.some(ex => ex.id === currEx.id);
				if(!exist){
					this.exoCompletedDuringSession.push(currEx)
				}
			}
		}
		if(this.lrsResume && seqTraces){
			// before launching journey
				seqTraces.forEach((j) => {
					if(j.parcoursId === this.id){
						j.exercises_progressions.forEach((currEx) => {
							if(currEx.completed && !currEx.isObjectJourneyType){
								addUniqCompletedEx(currEx);
							}
						})
					}
				})
		}else{
			// during the journey
			const currentEx = this.getCurrentExercise();
			if(currentEx){
				addUniqCompletedEx(currentEx);
			}
		}
		const allExercisesWithoutEtapes = this.exercises.filter(ex => !ex.isEtape);
		this.percentProgression =  Number(((this.exoCompletedDuringSession.length / allExercisesWithoutEtapes.length) * 100).toFixed(1));
		// console.log("this.percentProgression",this.percentProgression)
		// console.log("this.exoCompletedDuringSession",this.exoCompletedDuringSession)
		// console.log("allExercisesWithoutEtapes",allExercisesWithoutEtapes)
	}

		/**
		 * Get total exercises and exercises done
		 * @returns completedExercises(all done exercises for the current journey from start) / totalExercises(total exercises of current journey)
		 */
		public getProgressionJourney(): { completedExercises: Array<OseActivity>, totalExercises: Array<OseActivity> } {
			let totalExercises:Array<OseActivity>;
			let completedExercises:Array<OseActivity>;
			totalExercises = this.getTotalExercisesToDo();
			completedExercises = totalExercises.filter(ex => ex.alreadyCompleted)
			totalExercises.forEach((ex, index) => {
				if (index === totalExercises.length - 1 && ex instanceof OseActivity) {
					// add additional item for progression if last activity is fiche and has no video
					completedExercises.push(new OseActivity());
				}
			});
	
			if(totalExercises.length === completedExercises.length){
				const notDoneFiche = this.exercises.filter((ex) => ex.hasSubpart && !ex.alreadyCompleted)
				if(notDoneFiche?.length > 0){
					completedExercises = completedExercises.slice(0, -notDoneFiche.length);
				}
			}

			return {completedExercises,totalExercises}
		}

	/**
	 * Previous exercise of journey that is playing
	 */
	public getPrevExercise() {
		let exercice;
		let found = false;
		const currentExercise = this.getCurrentExercise();
		this.exercises.forEach(ex => {
			if (!found) {
				if (!ex.completed) {
					found = true;
				} else {
					exercice = ex;
				}
			}
		});
		return exercice;
	}
	setCurrentExerciseStatus(exercise, status: boolean) {
		exercise.completed = status;
	}

	/**
	 * change all exercises statuses
	 * @params completion status
	 */
	public setAllExercisesStatuses(completed: boolean) {
		this.exercises.forEach(ex => {
			ex.completed = completed;
			// ex.skip = completed;
		});
	}

	/**
	 * Change exercise status to completed at the end of the activity
	 */
	public setExerciseCompleted(id: number) {
		const exercise = this.exercises.find(ex => ex.id === id && !ex.completed);

		exercise.completed = true;
		if(exercise.requiredExercisesToComplete === true){
			exercise.requiredExercisesToComplete = false;
		}
	}

	/**
	 * get all exercises to do
	 * @returns OseActivity
	 */
	public getTotalExercisesToDo() {
		const categoryExercises = this.getSelectedCategory();
		let exercisesTargetted = this.exercises;
		const exercisesToDo = exercisesTargetted.filter(ex => {
			return ex.type !== OseExerciceType.fiche || ex.hasvideo === "1";
		});

		const lastActivity = exercisesTargetted[exercisesTargetted.length - 1];
		if (lastActivity && lastActivity.type === OseExerciceType.fiche && lastActivity.hasvideo !== "1" && !lastActivity.isEtape) {
			// add additional cell for progression
			exercisesToDo.push(new OseActivity());
		}

		return exercisesToDo;
	}

	/**
	 * Verify if all exercises are completed
	 */
	public isCompleted(checkEndActivity:boolean) {
		let exercises;
		if(checkEndActivity){
			const currentExercise = this.getCurrentExercise();
			if(currentExercise){
				exercises = this.exercises.filter((ex) => ex.id !== currentExercise?.id)
			}
		}else{
			exercises = this.exercises;
		}
		return exercises.every(ex => {
			// return this.skippedMode ? ex.skip : ex.completed;
			return ex.completed
		});
	}

	public isLastExerciseCompleted(lastExerciseDone:OseActivity):boolean{
		let isLastExCompleted = false;
		if(lastExerciseDone){
			const lastExIndexDone = this.exercises.findIndex((ex) => ex.id === lastExerciseDone.id);
			if(lastExIndexDone > -1){
				isLastExCompleted = this.exercises.length - 1 !== lastExIndexDone;
			}
		}
		
		return isLastExCompleted;
	}

	public initializeCategoryExercises() {
		const workshops = new Array();
		const learnings = new Array();
		const evaluations = new Array();

		let workshopLastIndex = this.exercises
			.map((element, index) => (element.isworkshop === "1" ? index : ""))
			.filter(String)
			.pop() as number;

		if (workshopLastIndex === null || workshopLastIndex === undefined) {
			workshopLastIndex = -1;
		}
		const evaluationStartIndex = this.exercises.findIndex(element => element?.isevaluation === "1");
		this.exercises.forEach((ex, index) => {
			if (index <= workshopLastIndex) {
				workshops.push(ex);
			}
			if (evaluationStartIndex > -1) {
				// evaluation tag exist
				if (index > workshopLastIndex && index < evaluationStartIndex) {
					learnings.push(ex);
				}
				if (index >= evaluationStartIndex) {
					evaluations.push(ex);
				}
			} else {
				// evaluation tag not exist
				if (index > workshopLastIndex) {
					learnings.push(ex);
				}
			}
		});

		return { workshops, learnings, evaluations };
	}

	/**
	 * Create new etape in order to insert in exercise array
	 *
	 * @param title accepting two values (sequence/conclusion)
	 * @returns OseActivity
	 */
	createNewEtape(title: string): OseActivity {
		return {
			id: Math.floor(Math.random() * 900000) + 100000,
			title,
			type: OseExerciceType.fiche,
			completed: false,
			isEtape: true,
			// skip: false
		};
	}

	/**
	 * Return category array of ose activities depending on the value of selected category
	 * @returns OseActivity
	 */
	public getSelectedCategory(): OseActivity[] {
		if (this.selectedCategoryType) {
			if (this.selectedCategoryType === Details.learning) {
				return this.learnings;
			} else if (this.selectedCategoryType === Details.workshop) {
				return this.workshops;
			} else if (this.selectedCategoryType === Details.evaluation) {
				return this.evaluations;
			}
		}
	}

	/**
	 * Get type of exercise's category
	 * @param exercise OseActivity
	 * @returns Details type
	 */
	public getSelectedCategoryType(exercise:OseActivity): Details {
		let type:Details
		if(exercise?.isworkshop === "1"){
			type = Details.workshop
		}else if(exercise?.isevaluation === "1"){
			type = Details.evaluation
		}else{
			type = Details.learning
		}
		return type;
	}


	/**
	 * Redirect to the associated exercise page based on his type
	 * @param exerciseType the type of exercise
	 * @returns page of activity
	 */
	public getExerciseUrlByType(exerciseType) {
		let url: string = "";
		switch (exerciseType) {
			case OseExerciceType.fiche:
				url = "cours";
				break;
			case OseExerciceType.memory:
				url = "memory";
				break;
			case OseExerciceType.quizz:
				url = "quizz";
				break;
			case OseExerciceType.coloriage:
				url = "coloriage";
				break;
			case OseExerciceType.puzzle:
				url = "puzzle";
				break;
			case OseExerciceType.spotDifference:
				url = "jeu-des-erreurs";
				break;
			case OseExerciceType.wordSearch:
				url = "search-words";
				break;
			case OseExerciceType.visit:
				url = "photo-dome";
				break;
			case OseExerciceType.wasteSorting:
				url = "waste-sorting";
				break;
		}

		return url;
	}
}
