export enum CreditType {
    ANDROID = "android-playstore",
    IOS = "ios-appstore"
}


export interface ServerSubscription {
  username: string;
  isActive: boolean;
  isExpired: boolean;
  expirationDate: string;
  purchase?: CdvPurchase.VerifiedPurchase;
}

export interface ServerSession {
  token: string;
  username: string;
  subscription: ServerSubscription;
}

export interface ServerContent {
  title?: string;
  content?: string;
  error?: string;
}

export class InAppState {
  
    /** True if the local subscription service is ready */
    subscriptionServiceReady: boolean = false;
  
    /** True if the user is logged in, and its session data has been loaded */
    sessionReady: boolean = false;
  
    /** True when the session is being restored */
    isRestoringSession: boolean = false;
  
    error: string = '';
  
    isLogin: boolean = false;
    isProcessingOrder: boolean = false;
    isRefreshing: boolean = false;
    isVerifying: boolean = false;
  
    /** User that logged in, or attempted to (cf sessionReady) */
    username?: string;
  
  
  
    /** Subscription present on the device's receipt. */
    activeSubscription?: CdvPurchase.VerifiedPurchase;
  
    /** Subscription expired, as reported on the device's receipt. */
    expiredSubscription?: CdvPurchase.VerifiedPurchase;
  
    products: CdvPurchase.Product[] = [];
    purchases: CdvPurchase.VerifiedPurchase[] = [];
    transactions: CdvPurchase.Transaction[] = [];
  
  
    /**
     * Update the state and refresh the user interface.
     */
    set(attr: Partial<InAppState>) {
      Object.assign(this, attr);
    }
  }

  export interface ResultSignInApple {
    connected : boolean,
    created : boolean
  }
