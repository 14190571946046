import { Injectable } from "@angular/core";
import { Network } from "@awesome-cordova-plugins/network/ngx";
import { fromEvent, merge, Observable, of, ReplaySubject } from "rxjs";
import { Platform, AlertController, ToastController } from "@ionic/angular";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AnalyticService } from "./analytic.service";
import { AppUtils } from "../app-utils";
export enum ConnectionStatus {
	Online = "online",
	Offline = "offline"
}

@Injectable({
	providedIn: "root"
})
export class NetworkService {
	// static status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(ConnectionStatus.Online);
	public status: ReplaySubject<any> = new ReplaySubject(1);
	public isConnected: boolean;
	public isOfflineToastDisplayed:boolean; // boolean setted to true when the last connection status was offline
	public environment: any;
	public toast: HTMLIonToastElement;
	public errorOccured: boolean;
	public connectionStatus:ConnectionStatus
	public lastConnectionStatus:ConnectionStatus
	public offlineCounter:number = 0;
	lastEventSentAt: any;

	constructor(
		public network: Network,
		private plt: Platform,
		public alertController: AlertController,
		public toastController: ToastController,
		public analyticService:AnalyticService
	) {
		this.plt.ready().then(() => {
			console.log("NETWORK SERVICE INIT");
			this.connectionStatus = this.network.type !== "none" ? ConnectionStatus.Online : ConnectionStatus.Offline;
			// nombre de statement synchroniser code classe 
			if(this.plt.is("cordova")){
				// verify connection for the first time when app is launched.
				// No verification need for desktop event if triggered unlike network plugin for cordova
				this.updateNetworkStatus(this.connectionStatus);
			}
			this.initializeNetworkEvents();
		});
		this.environment = environment;
	}

	public initializeNetworkEvents() {
		if (this.plt.is("cordova")) {
			this.network.onConnect().subscribe(status => {
				this.updateNetworkStatus(ConnectionStatus.Online);
			});
			this.network.onDisconnect().subscribe(status => {
				this.updateNetworkStatus(ConnectionStatus.Offline);
			});
		} else {
			merge(of(null), fromEvent(window, "online"), fromEvent(window, "offline"))
				.pipe(
					map(() => {
						return navigator.onLine;
					})
				)
				.subscribe(async connected => {
					const isConnected = connected
					//await AppUtils.timeOut(200)
					this.updateNetworkStatus(isConnected ? ConnectionStatus.Online : ConnectionStatus.Offline);
				});
		}
	}
	private async timeBuffer(){
		return new Promise<void>(async (resolve) => {
			const time = Date.now();
			
			if(this.lastEventSentAt){ 
				const bufferTime = (this.lastEventSentAt - time ) + 200;
				if(bufferTime > 0){
					console.log("waiting "+bufferTime+"ms")
					this.lastEventSentAt = Date.now();
					await AppUtils.timeOut(bufferTime);
				}
			}
			this.lastEventSentAt = Date.now();
			resolve();
		});
	}
	isNeedDisplayToaster() {
		if (this.toast) {
			this.toast.dismiss();
		}
		if (!this.isConnected && !this.isOfflineToastDisplayed) {
			let text: string;
			if (this.environment.ose || this.environment.kidaia) {
				text = $localize`Pas de connexion internet, veuillez-vous reconnecter.`;
			} else {
				text = $localize`Pas de connexion internet, veuillez-vous reconnecter pour utiliser toutes les fonctionnalités de Mathia.`;
			}
			this.isOfflineToastDisplayed = true;
			this.presentToast(text);
		}else if(this.isConnected){
			this.isOfflineToastDisplayed = false;
		}
	}

	async presentToast(message,duration = 2500) {
		this.toast = await this.toastController.create({
			message,
			position: "bottom",
			duration,
			cssClass: "custom-toast-internet"
		});

		await this.toast.present();
	}

	public updateNetworkStatus(status:ConnectionStatus,serverError = false) {
		this.lastConnectionStatus = this.connectionStatus;
		this.connectionStatus = status;
		this.isConnected = this.connectionStatus === ConnectionStatus.Online;
		if(!this.isConnected){
			this.offlineCounter++;
		}
		if(!serverError){
			this.status.next(status);
			this.isNeedDisplayToaster();
		}
	}

	public onNetworkChange(): Observable<ConnectionStatus> {
		return this.status.asObservable();
	}
}
