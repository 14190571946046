import { ScenarioOseBubble } from "./../../models/scenario-ose-bubble";
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, QueryList, Renderer2, ViewChild, ViewChildren } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { Insomnia } from "@awesome-cordova-plugins/insomnia/ngx";
import { Platform, AlertController } from "@ionic/angular";
import { AppUtils } from "src/app/app-utils";
import { OseDifficulty } from "src/app/models/enums/oseDifficulty";
import { LrsUtils } from "src/app/models/lrs/lrsUtils";
import { XapiContext, XapiExtensions } from "src/app/models/lrs/xapicontext";
import { XapiObject, XObjectType } from "src/app/models/lrs/xapiobject";
import { XApiResult } from "src/app/models/lrs/xapiresult";
import { LrsVerbs } from "src/app/models/lrs/xapiverbs";
import { OseActivity } from "src/app/models/ose-activities";
import { Ose2Journey, OseExerciceType } from "src/app/models/ose2-journey";
import { Statement } from "src/app/models/statement";
import { GameBasePageDirective } from "src/app/page/base/gamebasepage.page";
import { AccountService } from "src/app/services/account.service";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { GlobalService } from "src/app/services/global.service";
import { LrsService } from "src/app/services/lrs.service";
import { OseJourneyService } from "src/app/services/ose-journeys.service";
import { PlayTTSService } from "src/app/services/play-tts.service";

export interface CardData {
	imageId: string;
	state: CardStats.default | CardStats.flipped | CardStats.matched;
	text: string;
	displayText: boolean;
	colorEasyMode?: string;
}

export class Memory {
	id: number;
	title: string;
	theme: string;
	images: Array<any>;
	feedback: string;
	consigne: string;
}

export enum CardStats {
	default = "default",
	flipped = "flipped",
	matched = "matched"
}

@Component({
	selector: "app-memory",
	templateUrl: "./memory.page.html",
	styleUrls: ["./memory.page.scss"]
})
export class MemoryPage extends GameBasePageDirective implements OnDestroy {
	@ViewChild("cardsGrid", { static: false }) grid: ElementRef;
	@ViewChild("content", { static: false }) content: ElementRef;
	@ViewChildren("gameCards", { read: ElementRef }) allGameCards: QueryList<ElementRef>;
	public cardImages;
	public cardImagesOriginal;
	public cards: CardData[] = [];
	public cardsOriginal: CardData[] = [];
	public flippedCards: CardData[] = [];
	public beforeSavedFlippedCards: CardData[] = [];
	matchedCount = 0;
	currentExerciseIndex = 0;
	public won = false;
	public endFlipCards = false;
	public exerciseId: number;
	memory: Memory;

	numberTotalCards: number;
	get scenarioBubble(): ScenarioOseBubble {
		return this.globalService.speechBubbleComponent.scenario;
	}
	endStatement: Statement[];
	public defaultConsigne = $localize`Retrouve toutes les paires en cliquant sur les cercles`;
	public consigne;
	public openAllCards = false;
	public getWonOriginalSize;

	public randomColors = ["blue", "green", "cyan", "red"];
	public titleContainer: HTMLBaseElement;
	public cardDefaultSize = 150;
	public difficultiesAvailable = [];

	public difficultyChangedByUser = false;
	public allowGetNextCards = true;
	public gridWidth: number;
	public gridHeight: number;
	public difficultySelected = OseDifficulty.normal;
	public enableClick = true;
	public gameCardWidth;

	displayedColumns = ["id", "name", "image"];
	dataSource;

	constructor(
		public globalService: GlobalService,
		public accountService: AccountService,
		public ttsService: PlayTTSService,
		public cd: ChangeDetectorRef,
		public router: Router,
		public oseJourneyService: OseJourneyService,
		public platform: Platform,
		public insomnia: Insomnia,
		public alertController: AlertController,
		public lrs: LrsService,
		public cabriService: CabriDataService,
		public renderer: Renderer2,
		public route: ActivatedRoute
	) {
		super(globalService, ttsService, cd, router, accountService, platform, insomnia, alertController, lrs, cabriService, route);
		this.globalService.speechBubbleComponent.scenario = new ScenarioOseBubble(
			this.accountService,
			this.globalService,
			this.oseJourneyService,
			this.cd,
			ttsService
		);
		this.oseJourneyService.launchExercise.subscribe({
			next: (data: OseActivity) => {
				if (data.type === OseExerciceType.memory) {
					this.loadMemory(data.id);
				}
			}
		});
	}

	public async ionViewWillEnter() {
		super.ionViewWillEnter();
		this.globalService.activityRunningName = OseExerciceType.memory;
		await this.oseJourneyService.journeysLoaded();
		await this.cabriService.getMemory();
		this.dataSource = new MatTableDataSource(this.cabriService.memorys);
		let journeyRecovered = false;
		if (!this.oseJourneyService.getCurrentExerciseId()) {
			const ex = await this.oseJourneyService.recoverLSJourneyWithCurrentEx();
			if (ex) {
				journeyRecovered = true;
				this.loadMemory(ex.id);
			}
		}

		if (!journeyRecovered) {
			this.loadMemory(this.oseJourneyService.getCurrentExerciseId());
		}
	}

	loadMemory(id: number) {
		// id = 34214;
		this.exerciseId = id;
		if (this.exerciseId) {
			this.memory = this.cabriService.memorys.find(m => m.id === this.exerciseId);
			this.cabriService.currentOseActivity = this.oseJourneyService.oseActivities.find(
				activity => Number(activity.id) === Number(this.memory.id)
			);
			this.setupCards();
			const statement = this.startActivityStatement();
			if (statement) {
				this.lrs.send(statement);
			}
		} else {
			if (this.environment.production) {
				this.router.navigateByUrl("/territoire");
			}
		}
	}

	public async scaleCardsEnd() {
        const calculateGridSize = (
            width: number,
            height: number,
            cardWidth: number,
            cardHeight: number
        ): { cols: number; rows: number } => {
            const cols = Math.ceil(width / (cardWidth + 16));
            const rows = Math.ceil(height / (cardHeight + 16));
            return { cols, rows };
        };
        const getCardSize = (parentWidth: number, parentHeight: number, cols: number, rows: number): number => {
            const cardSizeWidth = (parentWidth - 16 * (cols + 1)) / cols;
            const cardSizeHeight = (parentHeight - 16 * (rows + 1)) / rows;
            return Math.min(cardSizeWidth, cardSizeHeight);
        };
        const { cols: defaultCols, rows: defaultRows } = calculateGridSize(
            this.gridWidth,
            this.gridHeight,
            this.gameCardWidth,
            this.gameCardWidth
        );
        let cardSize: number;
        let cols: number;
        const button = this.content.nativeElement.querySelector(".skip-button") as HTMLElement;
        let buttonHeight = 0;
        if (button) {
            buttonHeight = button.offsetHeight;
        }
        const parentWidth = this.content.nativeElement.offsetWidth;
        const parentHeight = this.content.nativeElement.offsetHeight - buttonHeight - 20;
			const maxCols2 = Math.ceil(this.cards.length / 2);

			const results = new Array(maxCols2).fill(null).map((eachData, index) => {
				// verify and keep the configuration where the col are in the larger size
				const colsTemp2 = index + 1;
				const rowsTemp2 = Math.ceil(this.cards.length / colsTemp2);
				const scaleCards = getCardSize(parentWidth, parentHeight, colsTemp2, rowsTemp2);
				return { col: colsTemp2, rows: rowsTemp2, value: scaleCards };
			})
			const maxCardSizeValue = results.reduce((max, current) => (current.value > max.value ? current : max), results[0]);
            const cols2 = maxCardSizeValue.col
            const rows2 = maxCardSizeValue.rows
            // Take larger grid size btwn multirows and onerow
            cardSize = Math.max(
                maxCardSizeValue.value,
                getCardSize(parentWidth, parentHeight, defaultCols, defaultRows)
            );
           	this.removeGridConfiguration();
			let finalRows
            // Check one row card is larger in size than a multirow card
            if(cardSize > getCardSize(parentWidth, parentHeight, defaultCols, defaultRows)){
                cols = cols2
				finalRows = rows2;
            } else{
				cardSize = getCardSize(parentWidth, parentHeight, defaultCols, defaultRows);
                cols = defaultCols;
				finalRows = defaultRows;
            }
			this.centerGridElements(cols,finalRows)

        this.renderer.setStyle(this.grid.nativeElement, "grid-template-columns", `repeat(${cols}, 1fr)`);
        document.documentElement.style.setProperty("--cardDefaultSize", `${cardSize}px`);

        this.allGameCards.toArray().forEach(currCard => {
            this.renderer.addClass(currCard.nativeElement, "cardSizeFinal");
        });

        await AppUtils.timeOut(500);
        this.getMaxFontSizeEnd();
    }

	/**
	 * Make all cards fit in its container in hard mode
	 */
	public scaleCardsStart() {
		return new Promise<void>(async (resolve) => {
			let cols;
			let rows;
			let cardSize;
			await Promise.all([this.definedElementHeight(this.titleContainer),this.definedElementHeight(this.content.nativeElement)]);
			const titleContainer = this.titleContainer?.offsetHeight || 0;
			const parentWidth = this.content.nativeElement.offsetWidth;
			const parentHeight = this.content.nativeElement.offsetHeight - titleContainer - 20;
		// if (this.difficultySelected === OseDifficulty.hard) {
			const calculateTempCardSize = (tempCol, tempRow) => {
				// calculate min card size width/height
				const eachCardSizeWidth = (parentWidth - 16 * (tempCol + 1)) / tempCol;
				const eachCardSizeHeight = (parentHeight - 16 * (tempRow + 1)) / tempRow;
				const cardSize = Math.min(eachCardSizeWidth, eachCardSizeHeight);

				return cardSize;
			};

			// Calculate maximum number of rows available in order to get same amount of cards in each row
			if(this.difficultySelected === OseDifficulty.hard){
				// difficile
				let maxRows = 1;
				let racineCarree = Math.sqrt(this.numberTotalCards);
				while (maxRows <= racineCarree) {
					if (this.numberTotalCards % maxRows === 0) {
						maxRows++;
					} else {
						break;
					}
				}
				maxRows--;

				rows = maxRows;
				cols = Math.ceil(this.numberTotalCards / maxRows);

				// reverse calcul
				const tempRow = Math.floor(racineCarree);
				const tempCol = Math.ceil(this.numberTotalCards / tempRow);

				const finalVerticalSize = calculateTempCardSize(tempCol, tempRow);
				const finalHorizontalSize = calculateTempCardSize(cols, rows);
				//  determine card size in its horizontal and its vertical dimension and apply larger card's size(with same amount cards in each row).
				if (finalVerticalSize > finalHorizontalSize) {
					rows = tempRow;
					cols = tempCol;
				}
			}else{
				// easy // normal
				cols = await this.getCols();
				if(cols){
					rows = Math.ceil(this.numberTotalCards / cols)
				}
			}

			let maxCardSizeRate = 1;
			if(this.difficultySelected !== OseDifficulty.hard){
				maxCardSizeRate =  this.globalService.isMobile ? 1 : .9;
			}
			let eachCardSizeWidth = ((parentWidth - 16 * (cols+ 1)) / cols) * maxCardSizeRate;
			let eachCardSizeHeight = ((parentHeight - 16 * (rows + 1)) / rows) * maxCardSizeRate;
			cardSize =  Math.round(Math.min(eachCardSizeWidth, eachCardSizeHeight));

			// if (cols * rows > this.numberTotalCards) {						
			// 	while (cols * rows > this.numberTotalCards && cardSize > 50) {		
			// 		// Reduce the size of cards
			// 		cardSize -= 10;
			// 		cols = Math.floor(parentWidth / (cardSize + 16));
			// 		rows = Math.floor(parentHeight / (cardSize + 16));
			// 	}
			// }
			this.centerGridElements(cols,rows)
			this.renderer.setStyle(this.grid.nativeElement, "grid-template-columns", `repeat(${cols},1fr)`);
			this.allGameCards.toArray().forEach(currCard => {
				this.renderer.setStyle(currCard.nativeElement, "height", cardSize + "px");
				this.renderer.setStyle(currCard.nativeElement, "width", cardSize + "px");
			});
			this.getMaxFontSizeStart();
			resolve();
		})
	}

	definedElementHeight(domElement) {
		return new Promise<void>(async resolve => {
				let counter = 0;
				while ((!domElement?.offsetHeight || domElement?.offsetHeight === 0) && counter < 30) {
					await AppUtils.timeOut(50);
					counter++;
				}
				resolve();
		});
	}

	getCols(){
		return new Promise<any>(async resolve => {
			let counter = 0;
			let cols = getComputedStyle(this.grid.nativeElement)?.gridTemplateColumns?.trim()?.split(" ")?.length;
				while((!cols || cols === 1) && counter <= 30){
					cols = getComputedStyle(this.grid.nativeElement)?.gridTemplateColumns?.trim()?.split(" ")?.length;
					await AppUtils.timeOut(50);
					counter++;
				}
				resolve(cols)
		})
	}

	/**
	 * Center grid when the number of cards is odd
	 * 
	 * @param cols number
	 * @param rows number
	 */
	public centerGridElements(cols:number,rows:number){
		const isSameElements = cols * rows === this.cards.length;
		if(!isSameElements && rows > 1){
			// center last row grids
			let gridAreaNames = [];
			let currentIndex = 0;
			let gridAreaIndex = 0;
			let gridTemplateString = "";
			const totalItemsLastRow = this.cards.length % cols;
			const totalNeedEmptyElements = cols - totalItemsLastRow;
			for (let row = 1; row <= rows; row++) {
				let rowString = ''; 
				for (let col = 1; col <= cols * 2; col++) {
					const isEven = gridAreaIndex % 2 === 0;
					if (row === rows && col <= totalNeedEmptyElements) {
						// last row cards number is different comparing to another row so center them by adding no used grid area value
						rowString += col === 1 ? `empty${row}${col} ` : `empty${row}${col} `;
					} else {
						if(!gridAreaNames.includes(`box${currentIndex}`)){
							gridAreaNames.push(`box${currentIndex}`);
						}
						gridAreaIndex++;
						rowString += `box${currentIndex} `;
						if (!isEven) {
							currentIndex++;
						}
					}
				}
				gridTemplateString += `"${rowString.trim()}" `;
			}
			
			this.allGameCards.toArray().forEach((currItem,index) => {
				const gridAreaValue = gridAreaNames[index];
				if(gridAreaValue){
					this.renderer.setStyle(currItem.nativeElement,'grid-area',gridAreaValue)
				}
			})
			this.renderer.setStyle(this.grid.nativeElement, "grid-template-areas", `${gridTemplateString}`);
		}
	}

	difficultyChanged(difficulty) {
		this.difficultyChangedByUser = true;
		if (difficulty !== this.difficultySelected) {
			this.difficultySelected = difficulty;
			this.loadMemory(this.exerciseId);
		}
	}

	async customOnResize(): Promise<void> {
		return new Promise(async resolve => {
			await super.customOnResize();
			if (this.globalService.isLandscape && this.cardImagesOriginal && !this.won) {
				const maxGridSize = this.maxGridSize(this.cardImagesOriginal.length * 2, this.difficultySelected);
				if (
					this.content.nativeElement.offsetWidth < this.grid.nativeElement.offsetWidth ||
					this.content.nativeElement.offsetHeight < this.grid.nativeElement.offsetHeight ||
					this.numberTotalCards !== maxGridSize
				) {
					this.setupCards();
				}
			}
			if (this.won && this.endFlipCards) {
				this.scaleCardsEnd();
			} else if (this.cards) {
				this.scaleCardsStart();
			}
			resolve();
		});
	}

	/**
	 * Statement object created at the beginning of the activity.
	 * @returns Statement[] contains one or two elements depending if it's a journey(ex + journey) or a simple exercise(1)
	 */
	startActivityStatement(): Statement[] {
		if (this.oseJourneyService.currentJourney) {
			const statements = new Array();
			LrsUtils.idsession = LrsUtils.generateUniqueSessionId(this.accountService, true);
			LrsUtils.currentUserResponseTime = Date.now();
			const object = new XapiObject(
				`${LrsUtils.statementUrl}/exercise/${String(this.memory.id)}`,
				this.memory.title,
				this.memory.theme,
				XObjectType.exercise
			);
			const context = new XapiContext(this.accountService.team, {
				...this.lrs.globalActivityExtensions()
			});

			const exerciseStatement = new Statement(this.accountService.team[0]?.id, LrsVerbs.initialized, object, context);
			const journeyStatement = this.lrs.startJourneyStatement();
			if (journeyStatement) {
				statements.push(journeyStatement);
			}
			statements.push(exerciseStatement);
			return statements;
		}
	}

	/**
	 * Statement object created at the end of the activity
	 * @returns Statement[] contains one or two elements depending if it's the last exercise of journey (ex + journey) or a simple exercise(1)
	 */

	endActivityStatement(): Statement[] {
		if (this.oseJourneyService.currentJourney) {
			const statements = new Array();
			if (this.oseJourneyService.currentJourney) {
				this.oseJourneyService.currentJourney.calculateSessionExerciseCompletionPercentage();
			}
			const object = new XapiObject(
				`${LrsUtils.statementUrl}/${String(this.memory.id)}`,
				this.memory.title,
				this.memory.theme,
				XObjectType.exercise
			);
			const activityDuration = LrsUtils.duration8601(LrsUtils.timeStampConversion(LrsUtils.currentUserResponseTime));
			const result = new XApiResult(LrsVerbs.completed, activityDuration);
			const context = new XapiContext(this.accountService.team, {
				...this.lrs.globalActivityExtensions(),
				[XapiExtensions.dureeActivite]: activityDuration
			});

			const exerciseStatement = new Statement(this.accountService.team[0]?.id, LrsVerbs.completed, object, context, result);
			const endJourneyStatement = this.lrs.endJourneyStatement();
			if (endJourneyStatement) {
				statements.push(endJourneyStatement);
			}

			statements.push(exerciseStatement);
			return statements;
		}
	}

	maxGridSize(imagesNumber: number, difficulty: OseDifficulty) {
		let allowedSizes = [];
		if (difficulty !== OseDifficulty.hard) {
			allowedSizes = [8,6, 4];
			if (window.innerHeight > 310) {
				allowedSizes.push(10);
			}

			if (this.platform.is("desktop")) {
				const allowedSizesDesktop = [];
					// add as first element
					allowedSizesDesktop.push(12);
				// }
				allowedSizes = allowedSizesDesktop.concat(allowedSizes);
				if(window.innerWidth < 1430){
					const index = allowedSizes.findIndex((value) => value === 8);
					if(index > -1){
						allowedSizes.splice(index, 1);
					}
				}
			} else {
				if (
					window.innerWidth > 1000 ||window.innerWidth < window.innerHeight ||
					(window.innerHeight > 430 && window.innerWidth >= 900)
				) {
					if (window.innerWidth > 1000) {
						if (window.innerHeight <= 700) {
							allowedSizes.push(14, 12);
						} else {
							allowedSizes.push(12);
						}
					} else {
						allowedSizes.push(14, 12);
					}
				}else if(window.innerHeight <= 350){
					const index = allowedSizes.findIndex((value) => value === 8);
					if(index > -1){
						allowedSizes.splice(index, 1);
					}
				}
			}
		} else {
			if (this.globalService.isMobile) {
				if (window.innerHeight >= 350) {
					allowedSizes = [18];
				} else {
					allowedSizes = [6,4];
				}
				if (window.innerHeight >= 350 && window.innerHeight <= 470 ) {
					allowedSizes = [8];
				}
				const notAllowedAddNumber = allowedSizes.some(size => size <= imagesNumber);
				if (!notAllowedAddNumber) {
					allowedSizes.push(imagesNumber);
				}
			} else {
				allowedSizes = [20,24,28,30,40,32,imagesNumber];
			}
		}
		// first allowed size less or egal to images number
		allowedSizes = allowedSizes.sort((a, b) => b - a);
		let numberGrid = allowedSizes.find(n => n <= imagesNumber);
		if (difficulty === OseDifficulty.easy) {
			if(numberGrid > 10){
				numberGrid = 10;
			}else if(numberGrid >= 8){
				numberGrid = numberGrid - 4
			}else{
				numberGrid = numberGrid - 2
			}
		}
		return numberGrid;
	}

	async setupCards() {
		if (this.exerciseId) {
			this.globalService.setGlobalLoading(true);
			this.initializeExercise();
			this.cardImagesOriginal = [...this.memory.images];
			this.cardImages = this.shuffleArray(this.cardImagesOriginal);
			this.numberTotalCards = this.maxGridSize(this.cardImages.length * 2, this.difficultySelected);
			this.checkDifficultiesAvailable();
			this.cardImages = this.cardImages.slice(0, this.numberTotalCards / 2);
			this.cardImages.forEach((image: any) => {
				// image
				const cardData: CardData = {
					imageId: image.imageId ? image.imageId : image.imageUrl,
					state: CardStats.default,
					text: image.text,
					displayText: true
				};
				this.cards.push(cardData);
				// text type
				const cardClone = Object.assign(Object.create(cardData), cardData);
				cardClone.displayText = false;
				this.cards.push(cardClone);
			});
			this.cardsOriginal = Object.assign(this.cardsOriginal, this.cards);
			this.cards = this.shuffleArray(this.cards);
			this.consigne = this.memory.consigne || this.defaultConsigne;
			this.scenarioBubble.readCustomText(this.consigne, true);
			this.titleContainer = this.content.nativeElement.querySelector(".title-container");
			await this.scaleCardsStart();
		}

		this.globalService.setGlobalLoading(false);
	}

	/**
	 * Displaying difficulty levels (for hard mode display based on the total count of cards that should be greater than in normal mode)
	 */
	public async checkDifficultiesAvailable() {
		const totalNormalCardsAvailable = this.maxGridSize(this.memory.images?.length * 2, OseDifficulty.normal);
		const totalHardCardsAvailable = this.maxGridSize(this.memory.images?.length * 2, OseDifficulty.hard);

		this.difficultiesAvailable = new Array();
		this.difficultiesAvailable.push(OseDifficulty.easy, OseDifficulty.normal);
		if (
			totalHardCardsAvailable > totalNormalCardsAvailable ||
			(this.difficultySelected === OseDifficulty.hard && this.difficultyChangedByUser)
		) {
			this.difficultiesAvailable = this.difficultiesAvailable.concat(OseDifficulty.hard);
		} else if (this.difficultySelected === OseDifficulty.hard && this.difficultyChangedByUser === false) {
			//  exercise changed and there is no hard mode for current exercise (hard mode selected in the previous exercise)
			this.difficultySelected = OseDifficulty.normal;
			await AppUtils.timeOut(200);
			this.loadMemory(this.exerciseId);
		}
	}

	async prevPage() {
		if (!this.oseJourneyService.currentJourney) {
			if (this.memory) {
				this.prevNextPage(false);
			}
		} else {
			await this.scenario?.skipMathiaSpeechSequence(true);
			this.oseJourneyService.exercisePrev.next();
		}
	}

	flipAllCards() {
		this.openAllCards = !this.openAllCards;
		if (this.openAllCards) {
			this.beforeSavedFlippedCards = this.cards.slice().filter(c => {
				return c.state !== CardStats.default;
			});

			if (this.beforeSavedFlippedCards?.length > 0) {
				this.flippedCards = new Array();
			}

			this.cards.forEach(c => {
				c.state = CardStats.flipped;
				this.flippedCards.push(c);
			});
		} else {
			this.flippedCards = new Array();
			this.cards.forEach(c => {
				const flippedIndex = this.beforeSavedFlippedCards.indexOf(c);
				if (flippedIndex > -1) {
					const savedFlipped = this.beforeSavedFlippedCards[flippedIndex];
					c.state = savedFlipped.state;
					if (c.state === CardStats.flipped) {
						this.flippedCards.push(c);
						if (this.flippedCards.length >= 2) {
							this.flippedCards = new Array();
						}
					}
				} else {
					c.state = CardStats.default;
				}
			});
		}
	}
	async cardClicked(index: number) {
		let cardInfo = this.cards[index];
		if (!this.openAllCards) {
			if (this.won) {
				this.readTTS(cardInfo, true);
			}
			const openedMaxNumber = this.difficultySelected === OseDifficulty.easy ? 3 : 2;
			if (cardInfo.state === CardStats.default && this.flippedCards.length < openedMaxNumber) {
				cardInfo.state = CardStats.flipped;
				this.flippedCards.push(cardInfo);
				if (!this.won) {
					this.readTTS(cardInfo, false);
				}
				if (this.flippedCards.length > 1) {
					this.checkForCardMatch();
				}
			} else if (cardInfo.state === CardStats.flipped) {
				cardInfo.state = CardStats.default;
				const i = this.flippedCards.indexOf(cardInfo);
				this.flippedCards.splice(i, 1);
				const atLeastOneFlipped = this.flippedCards.some(card => card.state === CardStats.flipped);
				if (!atLeastOneFlipped) {
					this.flippedCards = new Array();
				}
			}
		}
	}

	/**
	 * Read text on card
	 * @param currentCard cardData
	 * @param isEnd boolean end of activity all cards flipped
	 */
	async readTTS(currentCard: CardData, isEnd: boolean) {
		if (currentCard) {
			let readTEXT = true;
			if (!isEnd) {
				if (this.difficultySelected !== OseDifficulty.easy && !currentCard.displayText) {
					readTEXT = false;
				}
			}
			if (readTEXT) {
				await this.scenarioBubble.skipMathiaSpeechSequence(true);
				this.scenarioBubble.readCustomText(currentCard.text);
			}
		}
	}
	async checkForCardMatch() {
		this.enableClick = this.difficultySelected === OseDifficulty.easy ? false : true;
		await AppUtils.timeOut(1600);
		let cardOne = this.flippedCards[0];
		let cardTwo = this.flippedCards[1];
		const cardThree = this.flippedCards[2];
		let nextState;
		if ((cardOne && cardTwo) || (cardOne && cardTwo && cardThree && this.difficultySelected === OseDifficulty.easy)) {
			let matched = false;
			if (cardOne && cardTwo) {
				if (cardOne.imageId !== cardTwo.imageId) {
					cardOne.state = CardStats.flipped;
					cardTwo.state = CardStats.default;
					if (cardThree && cardOne.imageId !== cardThree.imageId) {
						cardThree.state = CardStats.default;
					}
					this.enableClick = true;
				}
			}

			const flippedItems = this.flippedCards.filter(card => card.state === CardStats.flipped);

			if (this.difficultySelected !== OseDifficulty.easy) {
				nextState = cardOne.imageId === cardTwo.imageId ? CardStats.matched : CardStats.default;
				cardOne.state = cardTwo.state = nextState;
			} else {
				// check all 3 cards
				flippedItems.forEach((currCard, i) => {
					flippedItems.forEach((currCard2, j) => {
						if (currCard.imageId === currCard2.imageId && i !== j) {
							matched = true;
							nextState = currCard.state = currCard2.state = CardStats.matched;
						}
					});
				});
			}

			if ((this.flippedCards.length >= 3 || matched) && this.difficultySelected === OseDifficulty.easy) {
				// easy mode
				if (matched) {
					this.flippedCards.forEach(currCard => {
						if (currCard.state !== CardStats.matched) {
							currCard.state = CardStats.default;
						}
					});
				} else {
					const needReturnCards = flippedItems.every(currCart => {
						return currCart.state === CardStats.flipped;
					});
					if (needReturnCards) {
						this.flippedCards.forEach(currCard => {
							currCard.state = CardStats.default;
						});
					}
				}
				this.flippedCards = [];
			} else if (this.difficultySelected !== OseDifficulty.easy) {
				// normal + hard mode
				this.flippedCards = [];
			}
			if (nextState === CardStats.matched) {
				// verify the end
				this.matchedCount++;
				this.enableClick = true;
				if (this.matchedCount === this.cardImages.length) {
					this.won = true;
					if (this.oseJourneyService.currentJourney) {
						this.endStatement = this.endActivityStatement();
					}
					await this.showAssociatedElements();
					await this.displayFeedBackMessage();
				}
			}
		}
	}

	async displayFeedBackMessage() {
		this.globalService.confettiCanvasComponent.launchConfetti();
		await this.scenarioBubble.launchFeedbackWithEnd(this.memory.feedback, true);
	}

	getMaxFontSizeEnd() {
		const textes = window.document.querySelectorAll(".textWon p");
		const container = window.document.querySelector(".textContainer") as HTMLElement;
		Array.from(textes).forEach((eachText: HTMLBaseElement) => {
			let containerHeight = container.offsetHeight;
			let containerWidth = container.offsetWidth;
			let fontSize = parseInt(getComputedStyle(eachText).fontSize);
			let textHeight = eachText.offsetHeight;
			let textWidth = eachText.clientWidth;
			let counter = 0;

			if (textHeight > containerHeight) {
				// increase fontSize of associated image text
				while (textHeight > containerHeight && fontSize > 10 && counter < 50) {
					textHeight = eachText.offsetHeight;
					fontSize--;
					eachText.style.fontSize = fontSize + "px";
					counter++;
				}
			} else {
				// decrease fontSize of associated image text
				while (textWidth < containerWidth && textHeight <= containerHeight && fontSize < this.getWonOriginalSize && counter < 50) {
					textWidth = eachText.offsetWidth;
					textHeight = eachText.offsetHeight;
					fontSize++;
					eachText.style.fontSize = fontSize + "px";
					textHeight = eachText.offsetHeight;
					if (textHeight >= containerHeight) {
						// decrease before stoping loop
						fontSize--;
						eachText.style.fontSize = fontSize + "px";
						textHeight = eachText.offsetHeight;
						break;
					}
					counter++;
				}
			}
			eachText.style.fontSize = fontSize + "px";
		});
	}

	public containsWhitespace(str) {
		return /(\s|-)/.test(str);
	}

	public async getMaxFontSizeStart() {
		await AppUtils.timeOut(100);
		const textes = window.document.querySelectorAll(".frontParagraphe");
		const container = window.document.querySelector(".front") as HTMLElement;
		const containerWords = window.document.querySelectorAll(".front > div");
		let maxFontSize = 40;
		if (textes?.length > 0 && container) {
			Array.from(textes).forEach((eachText: HTMLBaseElement) => {
				let finalFontSize
				let str = eachText?.innerHTML;
				const containerWidth = container.offsetWidth;
				if (containerWidth < 90) {
					Array.from(containerWords).forEach((eachContainerWords: HTMLBaseElement) => {
						eachContainerWords.style.border = "2px solid #88b627";
					});
				}
				if (this.containsWhitespace(str)) {
					const longestWordLength = AppUtils.longestWord(str).length;
					const fontSize = Math.floor(containerWidth / longestWordLength);
					const defaultRate = this.globalService.isMobile ? 0.85 : 0.9;
					let value = Math.floor(fontSize + longestWordLength) * defaultRate
					finalFontSize = value
					const nbrLine = this.getLineCount(eachText);
					const nbrLinesResponsive = this.globalService.isDesktop ? 3 : 2;
					if (nbrLine > nbrLinesResponsive) {
						let rate;
						if(nbrLine < 5){
							rate = 0.75
						}else{
							rate = this.globalService.isDesktop ? 0.75 : 0.9
							if(this.globalService.isDesktop){
								rate = 0.75;
							}else if (this.globalService.isTablet){
								rate = 0.85;
							}else{
								rate = 0.6;
							}
						}
						finalFontSize = (fontSize + longestWordLength) * rate;
					}
				} else {
					let nbChar = str.length;
					let calcFontSize = Math.floor(containerWidth / nbChar);
					let fontSize = (calcFontSize + nbChar) * 0.8;
					if(this.globalService.isMobile){
						fontSize = (calcFontSize + nbChar) * 0.7 
					}
					finalFontSize = fontSize;
				}

				if(finalFontSize > maxFontSize){
					finalFontSize = maxFontSize;
				}

				eachText.style.fontSize = finalFontSize + "px";
			});
		}
	}

	 getLineCount(element) {
		const lineHeight = parseInt(window.getComputedStyle(element).lineHeight);
		const height = element.clientHeight ;
		return  height / lineHeight;
	  }
	  

	async showAssociatedElements() {
		this.allowGetNextCards = false;
		for (const currCard of this.cards) {
			await AppUtils.timeOut(100);
			currCard.state = CardStats.default;
		}
		this.cards = this.cards.filter(e => !e.displayText);
		// force detect change view cards filtered
		this.cd.detectChanges();
		this.endFlipCards = true;
		const textWon = window.document.querySelector(".textWon p");
		this.getWonOriginalSize = parseInt(getComputedStyle(textWon).fontSize);
		this.gridWidth = this.grid.nativeElement.offsetWidth;
		this.gridHeight = this.grid.nativeElement.offsetHeight;
		const gameCardsOffsetWidth = this.grid.nativeElement.querySelectorAll(".gameCard")?.[0]?.offsetWidth;
		if (!gameCardsOffsetWidth || gameCardsOffsetWidth === 0) {
			this.gameCardWidth = 150;
		} else {
			this.gameCardWidth = gameCardsOffsetWidth;
		}
		this.scaleCardsEnd();
		for (const currCard of this.cards) {
			await AppUtils.timeOut(200);
			currCard.state = CardStats.matched;
		}
		await AppUtils.timeOut(400);
		this.allowGetNextCards = true;
	}

	initializeExercise() {
		this.flippedCards = new Array();
		this.cards = new Array();
		this.enableClick = true;
		this.removeGridConfiguration();
		this.cardsOriginal = new Array();
		this.matchedCount = 0;
		this.openAllCards = this.difficultyChangedByUser = this.endFlipCards = this.won = false;
		this.allowGetNextCards = true;
		this.endStatement = this.consigne = null;
		document.documentElement.style.setProperty("--cardDefaultSize", `${this.cardDefaultSize}px`);
		if (this.grid?.nativeElement) {
			this.grid.nativeElement.style.removeProperty("grid-template-columns");
		}

		if (this.grid.nativeElement.querySelectorAll(".gameCard")?.length > 0) {
			this.grid.nativeElement.querySelectorAll(".gameCard").forEach(currElement => {
				this.renderer.removeClass(currElement, "cardSizeFinal");
			});
		}
	}

	async goNextActivity() {
		await this.scenarioBubble.skipMathiaSpeechSequence(true);
		if (this.oseJourneyService.currentJourney) {
			if (!this.endStatement) {
				this.endStatement = this.endActivityStatement();
			}
			this.lrs
				.send(this.endStatement)
				.then(() => {
					this.oseJourneyService.exerciseEnd.next();
				})
				.catch(error => {
					console.error("error last statement not send", error);
					this.oseJourneyService.exerciseEnd.next(true);
				});
		} else {
			this.globalService.setGlobalLoading(true);
			this.prevNextPage(true);
		}
	}

	prevNextPage(next: boolean) {
		this.currentExerciseIndex = this.cabriService.memorys.findIndex(m => Number(m.id) === Number(this.memory.id));
		let direction = next ? 1 : -1;
		if (this.currentExerciseIndex > -1 && this.cabriService.memorys[this.currentExerciseIndex + direction]) {
			this.loadMemory(this.cabriService.memorys[this.currentExerciseIndex + direction].id);
		} else {
			let reStartIndex = next ? 0 : this.cabriService.memorys.length - 1;
			this.loadMemory(this.cabriService.memorys[reStartIndex].id);
		}
	}

	shuffleArray(anArray: any[]): any[] {
		return anArray
		.map(a => [Math.random(), a])
		.sort((a, b) => a[0] - b[0])
		.map(a => a[1]);
	}

	async ionViewWillLeave() {
		super.ionViewWillLeave();
		this.initializeExercise();
	}

	async ngOnDestroy() {
		await this.scenarioBubble.skipMathiaSpeechSequence(true);
	}


	/**
	 * Remove the grid configuration that was applied at the end when the cards are flipped 
	 */
	public removeGridConfiguration(){
		if(this.grid?.nativeElement){
			this.renderer.removeStyle(this.grid.nativeElement, "grid-template-areas");
		}
		const removeGridAreaProperty = (gameCards) => {
			if(gameCards?.toArray()){
				gameCards.toArray().forEach((currItem,index) => {
					this.renderer.removeStyle(currItem.nativeElement,'grid-area')
				})
			}
		}
		if (this.allGameCards?.last) {
			removeGridAreaProperty(this.allGameCards);
			
		} else {
			this.allGameCards.changes.subscribe(gameCard => {
				removeGridAreaProperty(gameCard);
			});
		}
		
	}

	applyFilter(filterValue: string) {
		filterValue = filterValue.trim(); // Remove whitespace
		filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
		this.dataSource.filter = filterValue;
	}
}
