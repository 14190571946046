import { InAppPurchaseService, productInApp } from "src/app/services/in-app-purchase.service";
import { Injectable, ChangeDetectorRef, LOCALE_ID, Inject } from "@angular/core";
import { AccountService } from "./account.service";
import { lastValueFrom, Observable, ReplaySubject } from "rxjs";
import { User, KidaiaSso } from "../models/user";
import { HttpClient } from "@angular/common/http";
import { BaseService, HttpErrorStatus } from "./base.service";
import { catchError, map, retry } from "rxjs/operators";
import { Student } from "../models/student";
import { Classroom } from "../models/classroom";
import { Params, Router, UrlTree } from "@angular/router";
import { LrsService } from "./lrs.service";
import { ProposedActivity, Status } from "../models/proposed-activity";
import { LrsUtils } from "../models/lrs/lrsUtils";
import { CabriDataService } from "./cabri-data.service";
import { ConnectionStatus, NetworkService } from "./network.service";
import { Journey, journeyStatus, JourneysByStatus, JourneyRecommendation, JourneyMode } from "../models/journey";
import { LmsService } from "./lms.service";
import { environment } from "src/environments/environment";
import { StoryService } from "./story.service";
import { BadgeService } from "./badge.service";
import { StoryByUserId } from "../models/story";
import { ActivityParticipantsPage } from "../page/activity-participants/activity-participants.page";
import { Platform } from "@ionic/angular";
import { cloneDeep } from "lodash";
import { VideosService } from "./videos.service";
import { Videos } from "../models/videos";
import { LocalStorageService, StorageKey } from "./local-storage-service";
import { Network } from "@awesome-cordova-plugins/network/ngx";
import { BearerToken, ClassService } from "./class.service";
import { GlobalService } from "./global.service";
import { StudentExerciseStatistics } from "../models/lrs/studentExerciseStatistics";
import { AppUtils } from "../app-utils";
import { ModalsService } from "./modals.service";
import { BadgesByUserId } from "../models/badge";
import { AnalyticService } from "./analytic.service";
import { RemoteService } from "./remote.service";
import { CreditType } from "../models/in-app-state";
@Injectable({
	providedIn: "root"
})
export class AccountImplService extends BaseService implements AccountService {
	user: User;
	lrsService: LrsService;
	classroom: Classroom;
	public allStudents: Student[] = new Array();
	public checkedUserRGPDState = false;
	public exerciseStatistics = {
		completedExercises: {},
		averageRateCorrectAnswers: {},
		exercisePerGoodAnswer: {},
		totalNumberAwardsByActivity: {}
	};

	studentsStatisticsRecovered: boolean;

	cabriService: CabriDataService;
	authBearer: string;
	studentsClassLoaded: boolean;
	private teacherInformationObs: Observable<object>;
	listClasseTeacher: Observable<any>;
	kidaiaSso: Observable<KidaiaSso>;
	maxKidaiaAccount = environment.ose || !environment.kidaia ? -1 : 4;
	private cd: ChangeDetectorRef;
	studentsAndJourneysLoaded: boolean;
	// awards during journey
	public journeyTeamShootingStarsCount = new Array<string>();
	public journeyTeamNormalStarsCount = new Array<string>();
	public journeyTeamMoonsCount = new Array<string>();
	public loadGabaritStudentInfos = false;

	public team: Student[];
	public teamsCopy: Student[];
	teams;
	public firstTimeStudentLoaded = false;
	public isUserLoaded: ReplaySubject<boolean>;

	public environment: { production: boolean; activityVersion: number; kidaia: boolean; ose: boolean; login: string; password: string };
	logReadyPromise: Promise<void>;

	studentsPromises: Promise<Videos | BadgesByUserId | StoryByUserId | Student | JourneyRecommendation[] | void>[];
	urlBeforeRedirection: UrlTree;
	studentsProfiles: {
		[studentId: string]: { [competence: string]: boolean };
	};

	constructor(
		private http: HttpClient,
		private router: Router,
		public lmsService: LmsService,
		public classService: ClassService,
		public storyService: StoryService,
		public badgeService: BadgeService, // public networkService: NetworkService
		public networkService: NetworkService,
		public localStorageService: LocalStorageService,
		public videosService: VideosService,
		public platform: Platform,
		public network: Network,
		@Inject(LOCALE_ID) public locale: string,
		public globalService: GlobalService,
		public modalService: ModalsService,
		private analyticService: AnalyticService,
		private remoteService: RemoteService,
		public InAppPurchaseService: InAppPurchaseService
	) {
		super();
		this.environment = environment;
		this.user = new User();
		this.isUserLoaded = new ReplaySubject(1);
		if (!this.team) {
			this.team = new Array();
		}
		if (localStorage.getItem("teams" + this.user.codeClasse)) {
			this.teams = JSON.parse(localStorage.getItem("teams" + this.user.codeClasse));
		} else {
			this.teams = new Array();
		}
		//mathia user have access to all kidaia premium content ose need premium to access video
		this.user.premium = !environment.kidaia || environment.ose;
		//ose user have access to all ose premium content
		this.user.premium_ose = environment.ose;
		if(!environment.production){
			//debug reference to change premium with console
			(window as any).user = this.user;
		}
		this.user.grade = localStorage.getItem("level") ? localStorage.getItem("level") : "CP";

		this.platform.ready().then(() => {
			const urlParams = Array.from(new URLSearchParams(window.location.search));
			const urlParamsObj = this.objectify(urlParams);
			this.checkIframeIntegration(urlParamsObj);
			this.recoverStudentsInfos();
		});
	}
	restore: boolean;

	canAccessPremium(urlType: "video" | "aventure" | "logbook" | "mathiaFamily" | "mathiaTeacher", callback: () => void) {
		if (this.user.premium) {
			callback();
		} else {
			if (this.globalService.isCordova && this.globalService.isIos) {
				this.globalService
					.simpleAlert($localize`S’abonner via  :`, [
						{ text: "Site mathia", value: "mail", cssButton: "a-button-mail", role: "primary" },
						{ text: "Apple", value: "apple", cssButton: "a-button-apple", role: "primary" }
					])
					.then(async value => {
						if (value === "mail") {
							const callbackEvent = message => this.eventMessageListener(message);
							window.addEventListener("message", callbackEvent);
							this.modalService.popModalPremium(urlType).then(() => {
								window.removeEventListener("message", callbackEvent);
							});
						} else {
							this.InAppPurchaseService.canLinkPurchase = true;
							this.InAppPurchaseService.restorePurchases();
							// connected with apple redirect to manage subscription
							this.router.navigateByUrl("/inapp-payment");
						}
					});
			} else {
				const callbackEvent = message => this.eventMessageListener(message);
				window.addEventListener("message", callbackEvent);
				this.modalService.popModalPremium(urlType).then(() => {
					window.removeEventListener("message", callbackEvent);
				});
			}
		}
	}

	canAccessPremiumOse(urlType: "ose", callback: () => void) {
		if (this.user.premium_ose) {
			callback();
		} else {
			const callbackEvent = message => this.eventMessageListener(message);
			window.addEventListener("message", callbackEvent);
			this.modalService.popModalPremium(urlType).then(() => {
				window.removeEventListener("message", callbackEvent);
			});
		}
	}

	private eventMessageListener(message) {
		if (message.data === "Payment complete") {
			this.user.premium = true;
		}
		if (message.data === "Payment complete ose") {
			this.user.premium_ose = true;
		}
		if (message.data === "Payment complete all") {
			this.user.premium = true;
			this.user.premium_ose = true;
		}
		AppUtils.timeOut(3000).then(() => {
			this.modalService.modalPremium.dismiss();
		});
	}

	async recoverStudentsInfos() {
		this.user.studentId = localStorage.getItem("team") ? JSON.parse(localStorage.getItem("team"))[0] : null;
		this.user.codeClasse = localStorage.getItem("codeclasse") ? Number(localStorage.getItem("codeclasse")) : null;

		await this.recoverCodeConnection();
		try {
			await this.getStudentsAndJourneys();
		} catch (err) {}
		this.studentsPromises = [];
		let storys;
		let badges;
		if (this.user.studentId) {
			if (!this.user.videos) {
				this.studentsPromises.push(this.videosService.getVideos(this.user.studentId));
			}
			if (this.storyService.allStories && !this.user.storys) {
				// ensure that user story hasn't already been assigned to not erase the user story instance from offline to online()
				const userStory = this.storyService.allStories[this.user.studentId];
				if (userStory) {
					this.user.storys = userStory;
					this.user.currentStory = userStory[0];
					this.user.currentChapter = userStory[0].getCurrentChapter();
				}
			}

			if (this.badgeService.allBadges) {
				const userBadge = this.badgeService.allBadges[this.user.studentId];
				this.user.badges = userBadge;
			}
			this.studentsPromises.push(this.getStudentStatistics());
			const getUserDatas = result => {
				if (result instanceof Videos) {
					this.user.videos = result;
				}
			};
			Promise.all(this.studentsPromises)
				.then(values => {
					values.forEach(result => {
						getUserDatas(result);
					});
					this.isUserLoaded.next(true);
					this.isUserLoaded.complete();
				})
				.catch(async error => {
					try {
						this.getStudentStatistics().then(() => {
							this.isUserLoaded.next(this.networkService.isConnected ? false : true);
							this.isUserLoaded.complete();
						});
					} catch (err) {}
				});
		} else {
			// recover story even if no student selected but connected to class
			try {
				await Promise.all([badges, storys]);
			} catch (err) {
				console.log("hors classe connexion");
			}
			this.isUserLoaded.next(false);
			this.isUserLoaded.complete();
		}
	}

	/**
	 * Get user story
	 */
	public updateUserData(studentId: string): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.lmsService.storedJourneyStatementLoad = false;
			const storyPromise = this.storyService.getStorys(studentId);
			const badagePromise = this.badgeService.getBadges(studentId);
			const videosPromise = this.videosService.getVideos(studentId);
			const studentWithJourney = this.getJourneysLoggedIn();
			const studentStatistics = this.getStudentStatistics(studentWithJourney);
			Promise.all([storyPromise, badagePromise, videosPromise, studentStatistics])
				.then(values => {
					this.user.studentId = studentId;
					this.user.storys = values[0];
					this.user.currentStory = values[0][0];
					this.user.currentChapter = values[0][0].getCurrentChapter();
					this.user.badges = values[1];
					this.user.videos = values[2];
					resolve();
				})
				.catch(async error => {
					await Promise.all([this.badgeService.getAllBadges(this.studentsIds), this.storyService.getAllStorys(this.studentsIds)]);
					if (this.storyService.allStories) {
						this.user.storys = this.storyService.allStories[studentId];
						this.user.currentStory = this.storyService.allStories[studentId][0];
						this.user.currentChapter = this.storyService.allStories[studentId][0].getCurrentChapter();
					}
					if (this.badgeService.allBadges) {
						this.user.badges = this.badgeService.allBadges[studentId];
					}

					console.error("Can't retreive story or badge on server");
					reject();
				});
		});
	}

	/**
	 * Retrieve a sso and a token tralalere and load all student from talalere openSteam
	 * Avoid reuse this in the app this is call on service constructor one time at start
	 * and manual auth already exec logInTralalere
	 */
	getStudentsAndJourneys(): Promise<void> {
		return new Promise<void>(async resolve => {
			this.logInTralalere().then(async () => {
				await this.getStudentsAndJourneysLoggedIn();
				resolve();
			});
		});
	}
	/**
	 * Get All student from tralalere or openSteam
	 * @returns
	 */
	getStudentsAndJourneysLoggedIn(): Promise<void> {
		return new Promise<void>(async resolve => {
			this.getStudents().then(() => {
				this.getJourneys().finally(async () => {
					await this.saveUsersDataOffline();
					this.studentsClassLoaded = true;
					resolve();
				});
			});
		});
	}

	/**
	 * Save user offline data once at the moment of loggin
	 */
	public async saveUsersDataOffline() {
		if (this.isRealClassConnected) {
			try {
				await this.getStudentsProfilesFromClassroom();
			} catch (err) {
				console.log("students profiles not recovered");
			}
			const storys = this.storyService.getAllStorys(this.studentsIds);
			const badges = this.badgeService.getAllBadges(this.studentsIds);
			await Promise.all([storys, badges]);
		}
	}

	getJourneysLoggedIn(): Promise<void> {
		return new Promise<void>(async resolve => {
			this.getJourneys().finally(() => {
				this.studentsClassLoaded = true;
				resolve();
			});
		});
	}

	allowSupervision() {

		return this.user?.admin;
	}
	/**
	 * Log in to tralalere before recovering journeys and students
	 */
	logInTralalere(): Promise<void> {
		return new Promise<void>(resolve => {
			this.platform.ready().then(async () => {
				if (environment.kidaia) {
					const urlParams = new URLSearchParams(window.location.search);
					if (urlParams.has("tokenBearer")) {
						this.classService.authBearer = "Basic " + urlParams.get("tokenBearer");
						this.maxKidaiaAccount = Number(urlParams.get("maxAccount"));
					} else {
						try {
							try {
								await this.autologKidaia();
							} catch (err) {
								this.isUserLoaded.next(false);
								this.isUserLoaded.complete();
								console.error("Error while login kidaia");
							}
							resolve();
						} catch (err) {
							console.error("not need to resolve because of redirection to kidaia.com");
						}
					}
				} else {
					// test autolog if error go to mathia authbearer
					try {
						await this.autologKidaia();
						resolve();
					} catch (err) {
						if (err.status === 0 && this.networkService.connectionStatus === ConnectionStatus.Online) {
							this.networkService.updateNetworkStatus(ConnectionStatus.Offline, true);
						}
						this.classService.getSessionToken().finally(() => {
							resolve();
						});
					}
				}
			});
		});
	}

	public get studentsIds(): Array<string> {
		return this.allStudents.map(student => student.id);
	}

	/**
	 * get student journeys
	 */
	async getJourneys() {
		await this.cabriService.getAllActivitiesAsync();
		// open journeys modal if modal is clicked and dont execute the rest
		this.lmsService.initGamification(this.lrsService, this.lmsService, this.cabriService, this, this.localStorageService);
		if (this.networkService.isConnected && !this.tralaError) {
			if (this.isRealClassConnected) {
				if (!this.lmsService.allJourneysLrsProgression) {
					// journey progression store only one time after entering code class
					this.lmsService.allJourneysLrsProgression = await this.lmsService.gamification.getStoredJourneys(false);
				}
			}
			if (this.team?.length === 1) {
				// current player
				const currentPlayer = this.team[0];
				try {
					await this.getAssignations(currentPlayer.classe);
				} catch (err) {
					console.error("can't get assignation");
				}
				await this.lmsService.getAllClassJourneys(currentPlayer.classe);
				await this.getStudentJourneys(currentPlayer.id);
				// save data for offline mode
				this.platform.ready().then(async () => {
					if (this.lmsService.allJourneys) {
						this.removeJourneysServices(this.lmsService.allJourneys);
					}
					await this.localStorageService.set(StorageKey.allJourneys, this.lmsService.allJourneys);
					this.restoreJourneysServices(this.lmsService.allJourneys);
					this.localStorageService.set(StorageKey.journeysByAssignation, this.lmsService.journeysByAssignation);
				});
			} else {
				this.loadGabaritStudentInfos = true;
			}
		} else {
			try {
				await this.lmsService.getAllClassJourneys(this.classroom);
			} catch (error) {
				console.error("error connexion");
			}
			await this.platform.ready();
			try {
				const values = await Promise.all([
					this.localStorageService.get(StorageKey.journeysByAssignation) as Promise<Journey[]>,
					this.localStorageService.get(StorageKey.allJourneys) as Promise<Journey[]>,
					this.localStorageService.get(StorageKey.students) as Promise<Student[]>
				]);
				this.lmsService.journeysByAssignation = values[0];
				if (!this.lmsService.allJourneys) {
					this.lmsService.allJourneys = values[1];
				}
				const allStudents = values[2];

				this.restoreTeam(allStudents);
				this.restoreJourneysServices(this.lmsService.allJourneys);
				if (this.team && this.team.length === 1) {
					this.setStudentSlot(this.team[0], 0);
					await this.getStudentJourneys(this.team[0].id);
				} else {
					this.loadGabaritStudentInfos = true;
				}
				try {
					await this.lmsService.getAllClassJourneys(this.classroom);
				} catch (err) {
					console.error("error", err);
				}
				this.loadGabaritStudentInfos = true;
			} catch (error) {
				console.log("error", error);
			}
		}
	}

	/**
	 * Restore services after removing them
	 */
	restoreJourneysServices(allJourneys: Journey[]) {
		if (allJourneys) {
			allJourneys.forEach(journey => {
				if (!journey.lmsService) {
					journey.lmsService = this.lmsService;
				}
				if (!journey.cabriService) {
					journey.cabriService = this.cabriService;
				}
			});
		}
	}
	/**
	 * Remove services before storing data in order to avoid circular dependencies
	 */
	removeJourneysServices(allJourneys: Journey[]) {
		allJourneys.forEach(journey => {
			journey.lmsService = null;
			journey.cabriService = null;
		});
	}

	setCabriService(cabriService) {
		this.cabriService = cabriService;
	}

	/**
	 * Get all students
	 */
	public getStudents() {
		return new Promise<void>(async (resolve, reject) => {
			if (!this.environment.kidaia) {
				await this.getMathiaStudents();
			} else {
				await this.getKidaiaStudents();
			}
			resolve();
		});
	}

	/**
	 * Get student statistics and attribute shooting value for Kidaia(activity-participants)
	 */
	async getStudentStatistics(studentJourneysPromise?: Promise<void>): Promise<void> {
		return new Promise(async resolve => {
			if (studentJourneysPromise) {
				try {
					await studentJourneysPromise;
				} catch (err) {
					console.error("err recover student or journeys");
				}
			}
			this.lmsService.allActivitiesLoaded.subscribe(async loaded => {
				if (loaded) {
					let isUserConnected = true;
					if (!this.globalService.isKidaia) {
						isUserConnected = this.isRealUserConnected && this.team.length === 1;
					}
					if (isUserConnected && this.lmsService.gamification) {
						try {
							await this.lmsService.gamification.getExercisesProgression(this.locale);
							if (environment.kidaia) {
								// attribute total shooting values to each student
								this.allStudents.forEach(student => {
									const studentStatistics = new StudentExerciseStatistics(this.lmsService.exerciseStatistics, student.id);
									student.statisticsCurrent = studentStatistics.totalNumberAwards;
								});
								// Get total awards per player
								this.studentsStatisticsRecovered = true;
							}
						} catch (err) {
							console.error("student statistics not available");
						}
					}
				}
				resolve();
			});
		});
	}

	/**
	 * Retrieve all student from all source of connection
	 * @returns
	 */
	getMathiaStudents() {
		return new Promise<void>(async (resolve, reject) => {
			if (this.classService.isAren || this.classService.isBeneylu || this.classService.isMathador) {
				try {
					this.allStudents = [this.user as any];
					await this.lmsService.getAllClassJourneys(this.classroom);
					this.loadGabaritStudentInfos = true;
				} catch (e) {
					console.error("getMathiastudent aren", e);
				}
				resolve();
			} else {
				let retrieveStudentsPromise: Promise<void | Classroom>;
				//connection from wordpress
				if (this.user.loggedFromWp) {
					retrieveStudentsPromise = this.getListStudentFromTeacherAccount();
				} else if (this.user.codeClasse) {
					//connection from class
					retrieveStudentsPromise = this.getListStudentFromCodeClasse(this.user.codeClasse.toString());
				} else {
					//connection demo
					retrieveStudentsPromise = new Promise<Classroom>(resolve => {
						resolve(ActivityParticipantsPage.buildMockClass());
					});
				}
				retrieveStudentsPromise
					.then(data => {
						if (data) {
							this.handleStudents(data);
						}
						resolve();
					})
					.catch(error => {
						if (error.status === 401) {
							this.globalService.simpleAlert("L'authentification a échoué", "OK");
						} else {
							this.networkService.errorOccured = true;
							if (error.status === 0 && this.networkService.isConnected) {
								this.networkService.updateNetworkStatus(ConnectionStatus.Offline, true);
							} else if (error.status === 422 && this.networkService.isConnected) {
								this.globalService.simpleAlert("Ce code classe n'existe pas.", "OK");
							} else if (error.status !== 0) {
								this.globalService.simpleAlert("Le serveur ne répond pas passage en mode offline", "OK");
							}
							//error retrieve student from storage offline
							this.getListStudentFromStorage().then(data => {
								this.handleStudents(data);
								resolve();
							});
						}
					});
			}
		});
	}

	/**
	 *
	 * @param codeCLasse Retreive student list from teacher Account and merge all player in one classroom
	 * @returns Classroom
	 */
	async getListStudentFromTeacherAccount() {
		let data = await this.classService.getListClasseTeacher();
		let studentPromise: Promise<Classroom>;
		let allStudents: Student[] = new Array();
		let classroom: Classroom = null;
		if (data) {
			const allPromise: Promise<Classroom>[] = new Array();
			data.forEach(classe => {
				allPromise.push(lastValueFrom(this.classService.getClasseFromGroupDetail(classe.id.toString(), true)));
			});
			studentPromise = new Promise(studentResolve => {
				Promise.all(allPromise).then(async promisesResult => {
					promisesResult.forEach(studentsClasse => {
						// save Classroom to export same object for amthia with student concatenated fromm all classroom
						classroom = studentsClasse;
						allStudents = allStudents.concat(studentsClasse.students);
					});
					classroom.students = allStudents;
					studentResolve(classroom);
				});
			});
		}
		return studentPromise;
	}

	/**
	 *
	 * @param codeCLasse Retreive student list from classCode
	 * @returns Classroom
	 */
	getListStudentFromCodeClasse(codeCLasse: string) {
		return lastValueFrom(
			this.classService.getClasseFromGroupDetail(codeCLasse).pipe(
				catchError(async error => {
					if (error.status === 401) {
						try {
							await this.classService.getSessionToken();
						} catch (err) {
							console.error("login trala err");
						}
					}
					throw error;
				}),
				retry(3)
			)
		);
	}

	/**
	 * retrieve student list from storage
	 * @returns Classroom
	 */
	getListStudentFromStorage() {
		let studentPromise = new Promise<Classroom>(studentResolve => {
			this.localStorageService.get(StorageKey.students).then(studentsRecovered => {
				if (studentsRecovered) {
					let classroom = new Classroom(null, null, null);
					studentsRecovered.forEach((eachStudent, index, currArray) => {
						classroom = eachStudent.classe;
						currArray[index] = new Student(eachStudent.id, eachStudent.classe, eachStudent.name, eachStudent.selected);
					});
					classroom.students = studentsRecovered;
					studentResolve(classroom);
				}
				studentResolve(null);
			});
		});
		return studentPromise;
	}

	/**
	 * retrieve information and restore student userSelection for activityParticipant
	 * @param classroom
	 */
	async handleStudents(classroom: Classroom) {
		if (classroom) {
			let students = classroom.students;
			if (students?.length === 0) {
				// Initialiser les groupes
				this.teamsCopy = new Array();
				try {
					await this.lmsService.getAllClassJourneys(classroom);
				} catch (err) {
					throw err;
				}
				//Not wordpress and not student it's a demo
				if (this.user.loggedFromWp) {
					this.storeStudents(students);
				}else{
					this.allStudents = ActivityParticipantsPage.buildMockClassroom();
				}
				this.allStudents = students;
				this.restoreTeam(this.allStudents);
			} else {
				if (localStorage.getItem("teams" + this.user.codeClasse)) {
					this.teams = JSON.parse(localStorage.getItem("teams" + this.user.codeClasse));
				}
				this.allStudents = students;
				this.teamsCopy = this.restoreGroups();
				this.restoreTeam(this.allStudents);
				if (!this.networkService.errorOccured) {
					this.storeStudents(this.allStudents);
				}
			}
			this.sortStudentsByName();
			this.loadGabaritStudentInfos = true;
			this.networkService.errorOccured = false;
		}
	}

	/**
	 * KIDAIA STUDENTS
	 */
	getKidaiaStudents() {
		return new Promise<void>(async (resolve, reject) => {
			this.classService.getListClasseTeacher().then(async data => {
				let studentPromise: Promise<Student[]>;
				let allStudents: Student[] = new Array();
				if (data) {
					const allPromise: Promise<any>[] = new Array();
					data.forEach(classe => {
						allPromise.push(lastValueFrom(this.classService.getClasseFromGroupDetail(classe.id.toString(), true)));
					});
					studentPromise = new Promise(studentResolve => {
						Promise.all(allPromise).then(async promisesResult => {
							promisesResult.forEach(studentsClasse => {
								studentsClasse.students.forEach((student: Student) => {
									student.classe = studentsClasse;
								});
								allStudents = allStudents.concat(studentsClasse.students);
							});
							studentResolve(allStudents);
						});
					});
				} else {
					studentPromise = new Promise(studentResolve => {
						this.localStorageService.get(StorageKey.students).then(studentsRecovered => {
							if (studentsRecovered) {
								studentsRecovered.forEach((eachStudent, index, currArray) => {
									currArray[index] = new Student(
										eachStudent.id,
										eachStudent.classe,
										eachStudent.name,
										eachStudent.selected
									);
								});
								studentResolve(studentsRecovered);
							}
						});
					});
				}
				studentPromise
					.then(async students => {
						if (students.length === 0) {
							// Initialiser les groupes
							this.teamsCopy = new Array();
							try {
								await this.lmsService.getAllClassJourneys(this.classroom);
							} catch (err) {
								throw err;
							}
							// this.allStudents = ActivityParticipantsPage.buildMockClassroom();
							this.allStudents = students;
							this.restoreTeam(this.allStudents);
						} else {
							if (localStorage.getItem("teams" + this.user.codeClasse)) {
								this.teams = JSON.parse(localStorage.getItem("teams" + this.user.codeClasse));
							}
							this.allStudents = students;
							this.teamsCopy = this.restoreGroups();
							this.restoreTeam(this.allStudents);
							if (!this.networkService.errorOccured) {
								this.storeStudents(this.allStudents);
							} else {
								this.loadGabaritStudentInfos = true;
							}
						}
						this.loadGabaritStudentInfos = true;
						this.sortStudentsByName();
						resolve();
					})
					.catch(err => {
						reject(err);
						console.error("error = ", err);
					});
			});
		});
	}
	public sortStudentsByName() {
		return this.allStudents.sort((studentA, studentB) => {
			const studentAname = studentA.name
				.normalize("NFD")
				.replace(/[\u0300-\u036f]/g, "")
				.toLowerCase();
			const studentBname = studentB.name
				.normalize("NFD")
				.replace(/[\u0300-\u036f]/g, "")
				.toLowerCase();
			if (studentAname < studentBname) {
				return -1;
			} else if (studentAname > studentBname) {
				return 1;
			} else {
				return 0;
			}
		});
	}
	restoreTeam(allStudents: Student[]) {
		if (this.team.length === 0 && allStudents?.length > 0) {
			const team = JSON.parse(localStorage.getItem("team"));
			if (Array.isArray(team)) {
				let count = 1;
				team.forEach(studentId => {
					const result = allStudents.find(student => {
						return studentId === student.id;
					});
					if (result) {
						result.selected = true;
						result.playerId = count;
						count++;
						if (this.environment.kidaia) {
							let codeClassIsNumeric;
							if (result.classe?.id.match(/^-?\d+$/)) {
								codeClassIsNumeric = true;
							}
							this.user.codeClasse = codeClassIsNumeric ? Number(result.classe?.id) : (result.classe?.id as any);
						}
						this.team.push(result);
					}
				});
				// if (this.lrs?.statement) {
				// 	this.lrs.statement.setActor(this.team);
				// }
			}
		}
	}

	setStudentSlot(student: Student, indexStudent?) {
		// CRED: Creditsposition de l'élève dans l'équipe
		if (!indexStudent) {
			indexStudent = this.team.indexOf(student);
		}
		if (indexStudent === 0) {
			this.team[indexStudent].playerId = 1;
		} else if (indexStudent === 1) {
			this.team[indexStudent].playerId = 2;
		} else if (indexStudent === 2) {
			this.team[indexStudent].playerId = 3;
		} else if (indexStudent === 3) {
			this.team[indexStudent].playerId = 4;
		}
	}

	/**
	 * Gather students into groups
	 */
	restoreGroups() {
		let teamsCopy = this.teams.sort((a, b) => {
			if (Number(a.length) < Number(b.length)) {
				return -1;
			}
			return Number(a.length) > Number(b.length) ? 1 : 0;
		});

		// Add student first name besides teams id
		teamsCopy = this.teams.map(team => {
			return team
				.map(teamId => {
					const result: any = this.allStudents.find((student: any) => {
						return +student.id === +teamId;
					});
					if (result) {
						return { teamId, name: result.name };
					}
				})
				.filter(notUndefined => notUndefined !== undefined);
		});

		// keep only team with more than one student (a student can be removed from trala but not from localstorage)
		teamsCopy = teamsCopy.filter(team => {
			return team.length > 1;
		});

		return teamsCopy;
	}

	/**
	 * Restructure students infos in order to save them in file/indexDB
	 */
	storeStudents(classeGroupDetail) {
		let listOfStudents;
		if (classeGroupDetail.students) {
			listOfStudents = cloneDeep(classeGroupDetail.students);
		} else {
			listOfStudents = cloneDeep(classeGroupDetail);
		}
		listOfStudents.forEach(student => {
			student.classe.students = null;
		});

		this.localStorageService.set(StorageKey.students, listOfStudents);
		this.localStorageService.set(StorageKey.codeclasse, this.user.codeClasse);
	}

	checkUserPermission(value: string = null): boolean {
		let haveRight: boolean;
		if (!value) {
			haveRight =
				localStorage.getItem("codeclasse") != null ||
				localStorage.getItem("level") != null ||
				localStorage.getItem("codeClassProf") != null ||
				localStorage.getItem("sentencesTop") != null ||
				localStorage.getItem("sentencesCurent") != null ||
				localStorage.getItem("sentencesBottom") != null;
		} else {
			haveRight = localStorage.getItem(value) != null;
		}
		return haveRight;
	}

	/**
	 * Check if a real user is connected
	 */
	get isRealUserConnected(): boolean {
		if (this.checkUserConnected()) {
			if (this.team[0]?.classe?.id) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	get isRealClassConnected(): boolean {
		return this.allStudents?.some((student: Student) => {
			return student?.classe?.id;
		});
	}

	checkUserConnected(): boolean {
		// console.error("update rules of checkUserConnected !");
		return this.user?.codeClasse || this.user?.loggedFromWp ? true : false;
	}

	/** FIN LOCALSTORAGE  */

	/**
	 * At the end of the activity change current activity by another one
	 */
	async setNextActivity() {
		const oldParams = this.cabriService.currentActivity._params;
		let nextExerciseId;
		if (this.lmsService.currentUserJourney?.nextActivityProposed) {
			this.lmsService.currentUserJourney.firstActivityDone = true;
			this.saveUserJourney(true, this.lmsService.currentUserJourney.nextActivityProposed);
			if (this.lmsService.currentUserJourney.nextActivityProposed.exerciseId) {
				const exercise = this.cabriService.exercices.getExercise(
					this.lmsService.currentUserJourney.nextActivityProposed.exerciseId
				);
				this.cabriService.currentActivity._params.forEach(param => {
					if (param.name === "ex") {
						param.defaultValue = exercise;
						param.value = exercise;
					}
				});
				nextExerciseId = this.lmsService.currentUserJourney.nextActivityProposed.exerciseId;
			}
		} else if (this.cabriService.exercices.nextExerciseProposed) {
			if (this.lmsService.currentUserJourney) {
				this.removeStoryJourney();
			}
			this.cabriService.currentActivity = this.cabriService.activities.find(activity => {
				return Number(activity.id) === Number(this.cabriService.exercices.nextExerciseProposed.gabarit);
			});
			nextExerciseId = this.cabriService.exercices.nextExerciseProposed.id;
		} else {
			throw new Error("No activity found from next Activity proposed");
		}

		if (nextExerciseId) {
			await this.cabriService.setActivityId(nextExerciseId);
		}

		if (this.lmsService.currentUserJourney?.nextActivityProposed) {
			this.cabriService.currentActivity.step = this.lmsService.currentUserJourney.nextActivityProposed.step;
		}
		if (this.cabriService.currentActivity) {
			this.cabriService.currentActivity._params.forEach(param => {
				const oldParam = oldParams.find(currentOldParam => currentOldParam.name === param.name);
				if (oldParam) {
					const selectedList = param.selectionList.find(paramList => {
						return paramList[1] === oldParam.value;
					});
					if (selectedList) {
						param.value = oldParam.value;
					}
				}
			});

			if (this.lmsService.currentUserJourney?.nextActivityProposed) {
				this.lmsService.currentUserJourney.nextActivityProposed.params.forEach(forcedParam => {
					const paramFound = this.cabriService.currentActivity._params.find(param => param.name === forcedParam.name);
					if (paramFound) {
						paramFound.value = forcedParam.value;
					}
				});
				this.lmsService.currentUserJourney.nextActivityProposed = null;
			} else if (this.cabriService.exercices?.nextExerciseProposed) {
				this.cabriService.exercices.nextExerciseProposed = null;
			}
			this.cabriService.currentActivity.buildVariables();
		}

		this.cabriService.nextActivity = null;
	}

	/**
	 * Count journey total awards before the redirection (resume & start)
	 */
	countAwards(resume, currentJourney: Journey = null): void {
		if (resume && currentJourney) {
			currentJourney.allAskedQuestions.forEach(data => {
				if (data.award && typeof data.award === "string") {
					if (data.award === "moon") {
						this.journeyTeamMoonsCount.push(data.award);
					} else if (data.award === "normal") {
						this.journeyTeamNormalStarsCount.push(data.award);
					} else if (data.award === "shooting") {
						this.journeyTeamShootingStarsCount.push(data.award);
					}
				}
			});
		} else {
			this.journeyTeamMoonsCount = new Array();
			this.journeyTeamNormalStarsCount = new Array();
			this.journeyTeamShootingStarsCount = new Array();
		}

		const journeyAwards = {
			moon: this.journeyTeamMoonsCount.length,
			normal: this.journeyTeamNormalStarsCount.length,
			shooting: this.journeyTeamShootingStarsCount.length
		};

		localStorage.setItem("journeyTotalAwards", JSON.stringify(journeyAwards));
		// init journey awards
		this.initJourneyTeamTotalAwards();
	}

	/**
	 * Check if the user is coonnected to network and is in exercise mode
	 */
	get isUserMakingExercise(): boolean {
		return this.networkService.isConnected && this.team.length === 1 && this.isRealUserConnected;
	}

	/**
	 * find the next activity and populate this.nextActivity: Activity
	 */
	getNextActivity(): Promise<void> {
		return new Promise(async resolve => {
			if (this.lmsService.currentUserJourney) {
				if (this.lmsService.currentUserJourney.bilan && !this.lmsService.currentUserJourney.isAdventureBilan) {
					await this.diagnosticProcessing();
				} else {
					this.trainingMode(true);
				}
			} else {
				// Get next exercise of the category
				if (this.isUserMakingExercise && this.lmsService.gamification) {
					try {
						await this.lmsService.gamification.getExercisesProgression(this.locale);
					} catch (err) {
						console.error("student statistics not available");
					}

					// Page reloaded
					this.lmsService.getStudentExercisesStatistics(this, this.cabriService, this.cabriService.currentActivity);

					if (!this.lmsService.alreadyDoneExercises) {
						this.lmsService.alreadyDoneExercises = Object.keys(this.exerciseStatistics.exercisePerGoodAnswer);
					}
					this.lmsService.alreadyDoneExercises.push(this.cabriService.currentExercice.id);
					this.cabriService.exercices.nextExerciseProposed = this.cabriService.exercices.getNextCategoryExerciseToDo(
						this.cabriService,
						this.lmsService.alreadyDoneExercises
					);
				}
			}
			resolve();
		});
	}

	/**
	 * mark diagnostic exercise done at the end of the activity
	 */
	async markDiagnosticExerciseDone(currentJourney: Journey) {
		return new Promise(async resolve => {
			const diagnosticNotDoneExercises = this.lmsService.getNextDiagnosticExercise(this.cabriService.currentExercice, currentJourney);
			// Not change directly the main object in order to not filter current exercise which has been done from lrs
			const resoProblem = this.cabriService.currentActivity.isResolutionProblem();
			if (resoProblem) {
				this.cabriService.currentActivity.removeProblemFromParam();
			}
			currentJourney.exercises = diagnosticNotDoneExercises;
			const exercises = this.lmsService.addDiagnosticExercisesSteps(diagnosticNotDoneExercises, this.cabriService.exercices);
			const result = await this.diagnosticMode(exercises, this.team[0].id, false);
			resolve(result);
		});
	}

	/**
	 * Save user journey in order to resume it
	 */
	public saveUserJourney(launch: boolean, nextActivity?: ProposedActivity) {
		if (this.lmsService.currentUserJourney) {
			this.lmsService.currentUserJourney.lmsService = null;
			this.lmsService.currentUserJourney.cabriService = null;
			this.lmsService.storedJourney = {
				journey: JSON.stringify(this.lmsService.currentUserJourney),
				launch,
				activityId: nextActivity ? Number(nextActivity.activityId) : Number(this.cabriService.currentActivity.id),
				bilanExerciseId:
					this.lmsService.currentUserJourney.bilan && nextActivity
						? Number(nextActivity.exerciseId)
						: this.lmsService.currentUserJourney.bilan
						? Number(this.cabriService.currentExercice.id)
						: undefined
			};

			localStorage.setItem("currentUserJourney", JSON.stringify(this.lmsService.storedJourney));
			this.lmsService.currentUserJourney.lmsService = this.lmsService;
			this.lmsService.currentUserJourney.cabriService = this.cabriService;
		}
	}

	public getRecoveredJourney(): Journey {
		let journeyTargetted;
		if (!this.lmsService.currentUserJourney) {
			journeyTargetted = JSON.parse(this.lmsService.storedJourney.journey as any);
			if (journeyTargetted?.resume) {
				LrsUtils.resume = true;
			}
			return journeyTargetted;
		} else {
			journeyTargetted = this.lmsService.currentUserJourney;
			return journeyTargetted;
		}
	}

	public removeStoryJourney() {
		if (localStorage.getItem("story")) {
			localStorage.removeItem("story");
		}

		if (localStorage.getItem("currentUserJourney")) {
			localStorage.removeItem("currentUserJourney");
			localStorage.removeItem("journeyTotalAwards");
		}
	}

	diagnosticProcessing(): Promise<void> {
		return new Promise<void>(async resolve => {
			if (!this.networkService.isConnected) {
				if (this.lmsService.currentUserJourney) {
					this.lmsService.currentUserJourney.nextActivityProposed = null;
				}
				resolve();
			} else {
				const diagnosticNotDoneExercises = this.lmsService.getNextDiagnosticExercise(this.cabriService.currentExercice);
				if (diagnosticNotDoneExercises && diagnosticNotDoneExercises.length > 0) {
					// else update currentUserJourney
					// this.lmsService.currentUserJourney.exercises = diagnosticNotDoneExercises;
					// get all journey exercises
					let exercises = this.lmsService.addDiagnosticExercisesSteps(
						// this.lmsService.currentUserJourney,
						diagnosticNotDoneExercises,
						this.cabriService.exercices
					);
					// get next exercise to be done
					const diagnosticResult = await this.diagnosticMode(exercises, this.team[0].id);
					if (!diagnosticResult.hasNextActivity) {
						// the rest of the exercises are already mastering so the journey is interrupted
						this.countJourneyEndAwards();
						resolve();
					} else {
						// journey continues
						if (this.lmsService.currentUserJourney) {
							try {
								this.lmsService.currentUserJourney.nextActivityProposed =
									diagnosticNotDoneExercises[diagnosticResult.nextActivity.step];
								// Change all exercises status to done if prerequis not fulfilled or already mastered
								for (let i = 0; i < diagnosticResult.nextActivity.step; i++) {
									this.lmsService.currentUserJourney.exercises[i].status = Status.done;
								}
								// get remaining exercises
								const notDoneExercises = this.lmsService.currentUserJourney.exercises.filter(ex => {
									return ex.status === Status.notDone;
								});

								// this.lmsService.currentUserJourney.exercises = notDoneExercises;
								exercises = this.lmsService.addDiagnosticExercisesSteps(notDoneExercises, this.cabriService.exercices);
								this.detectChanges();
								this.lmsService.currentUserJourney.allAskedQuestions = new Array();
								// choose next exercise
								if (this.lmsService.currentUserJourney?.nextActivityProposed) {
									this.cabriService.nextActivity = this.cabriService.activities.find(
										activity =>
											Number(activity.id) === this.lmsService.currentUserJourney.nextActivityProposed.activityId
									);
									this.lmsService.currentUserJourney.next = true;
								} else {
									console.error("should never append");
									// starboard
									this.countJourneyEndAwards();
								}
							} catch (err) {
								console.error(err);
							} finally {
								resolve();
							}
						} else {
							resolve();
						}
					}
				} else {
					if (this.lmsService.currentUserJourney.nextActivityProposed) {
						this.lmsService.currentUserJourney.nextActivityProposed = null;
					}
					this.countJourneyEndAwards();
					resolve();
				}
			}
		});
	}

	/**
	 * Journey as training
	 */
	public trainingMode(countTotalAwards: boolean) {
		// find next exercise (not done yet)
		if (this.lmsService.currentUserJourney) {
			this.lmsService.currentUserJourney.nextActivityProposed = this.lmsService.getTrainingNextActivity(
				this.cabriService.currentExercice
			);
		}
		this.cabriService.detectChanges();
		if (countTotalAwards) {
			this.countJourneyEndAwards();
		}
	}
	/**
	 * Calculate journey's total awards amount according it's mode (training or bilan)
	 *
	 * @param trainingMod determine if it's is in training mod
	 */
	countJourneyEndAwards() {
		const awards = {
			moonsCount: this.journeyTeamMoonsCount.length,
			starsCount: this.journeyTeamNormalStarsCount.length,
			shootingCounts: this.journeyTeamShootingStarsCount.length
		};
		let result;
		result = this.lmsService.countTotalJourneyAwards(awards);
		if (result) {
			if (!this.lmsService.currentUserJourney.nextActivityProposed) {
				this.cabriService.teamMoonsCount = result.moonsCount;
				this.cabriService.teamNormalStarsCount = result.normalStarts;
				this.cabriService.teamShootingStarsCount = result.shootingStars;
				this.cabriService.detectChanges();
			} else {
				const journeyAwards: any = JSON.parse(localStorage.getItem("journeyTotalAwards"));
				localStorage.setItem("journeyTotalAwards", JSON.stringify(journeyAwards));
			}
		}
	}

	/**
	 * Initialize awards for the jurney
	 */
	initJourneyTeamTotalAwards() {
		this.journeyTeamMoonsCount = new Array();
		this.journeyTeamNormalStarsCount = new Array();
		this.journeyTeamShootingStarsCount = new Array();
	}

	public detectChanges() {
		if (this.cd) {
			this.cd.detectChanges();
		}
	}

	/**
	 * Get recommanded journey for current student (main function)
	 */
	async getStudentJourneys(studentId: number | string, falseTraces = false): Promise<void> {
		return new Promise<void>(async resolve => {
			const userTralaJourneys: Journey[] = new Array();
			this.lmsService.userJourneysByAssignation = new Array();
			if (this.lmsService.journeysByAssignation) {
				this.lmsService.journeysByAssignation.forEach((assignJourney: Journey) => {
					if (assignJourney.learner !== undefined && assignJourney.learner.uid === studentId && assignJourney.content) {
						// get journeys by assignation
						const posInd = userTralaJourneys.findIndex((journey: Journey) => {
							return assignJourney.content.source === journey.content.source && journey.content.source != null;
						});

						// push into array if exercise not exist or if it is a journey
						if (posInd === -1 && assignJourney.content.difficulty && assignJourney.content.difficulty.length > 0) {
							userTralaJourneys.push(assignJourney);
						}
					}
				});
				if (userTralaJourneys.length > 0) {
					this.lmsService.userJourneysByAssignation = this.fillUserAssignationJourney(userTralaJourneys);
				}

				// console.log("this.lmsService.journeysByAssignation",this.lmsService.journeysByAssignation)
				// console.log("this.lmsService.userJourneysByAssignationthis.lmsService.journeysByAssignation",this.lmsService.userJourneysByAssignation)
			}

			// Display gabarit studentInfos buttons when lrs informations are available
			// Journey available so display display buttons
			if (!falseTraces && this.lmsService.gamification) {
				try {
					this.lmsService.initGamification(this.lrsService, this.lmsService, this.cabriService, this, this.localStorageService);
					const studentJourneys = await this.lmsService.gamification.getStoredJourneys(true);
					if (studentJourneys.lastMadeJourney) {
						this.lmsService.lastMadeJourney = studentJourneys.lastMadeJourney;
					}
					if (studentJourneys.journey) {
						this.lmsService.journeyState = studentJourneys.journey;
					}

					if (studentJourneys.journeysByStatus) {
						this.lmsService.allJourneysByStatus = studentJourneys.journeysByStatus;
						// this.lmsService.updateLrsJourneyList();
					}

					if (studentJourneys.recommandedJourney) {
						this.lmsService.allStartedCompletedJourneys = studentJourneys.recommandedJourney;
					}

					if (studentJourneys.recommandedJourneyByStatus) {
						this.lmsService.allJourneysByInProgressStatus = studentJourneys.recommandedJourneyByStatus;
					}
					this.lmsService.storedJourneyStatementLoad = true;
					// set default journey, if not already defined
				} catch (error) {
					console.log("error", error);
				}
				this.loadGabaritStudentInfos = true;
			}

			this.lmsService.extractJourneyIdFromTitle();
			resolve();
		});
	}
	/**
	 * Select first journey by default when journey's modal is opened and check if it's should be resumed
	 */
	defineDefaultSelectedJourney(checkJourneyState) {
		if (!this.lmsService.userJourneyFromParams && this.lmsService.allJourneysByStatus[0]) {
			// in progress if available, else to do (assignation), else first in all journeys list
			if (this.lmsService.allJourneysByStatus[0][this.lmsService.getStatusAsIndex(journeyStatus.inProgress)]?.items?.length > 0) {
				this.lmsService.userJourneyFromParams =
					this.lmsService.allJourneysByStatus[0][this.lmsService.getStatusAsIndex(journeyStatus.inProgress)].items[0];
			} else if (this.lmsService.allJourneysByStatus[0][this.lmsService.getStatusAsIndex(journeyStatus.toDo)]?.items?.length > 0) {
				this.lmsService.userJourneyFromParams =
					this.lmsService.allJourneysByStatus[0][this.lmsService.getStatusAsIndex(journeyStatus.toDo)].items[0];
			} else if (
				this.lmsService.allJourneysByStatus[0][this.lmsService.getStatusAsIndex(journeyStatus.allJourneys)]?.items?.length > 0
			) {
				this.lmsService.userJourneyFromParams =
					this.lmsService.allJourneysByStatus[0][this.lmsService.getStatusAsIndex(journeyStatus.allJourneys)].items[0];
			}

			if (checkJourneyState) {
				checkJourneyState();
			}
		}
	}

	filterStudentBilanOffline(journey: Journey[]) {
		const bilanJourneysFiltered = journey.filter(j => {
			let result = true;
			if (!j.bilan) {
				if (j.isAdventureBilan) {
					result = true;
				} else {
					result = false;
				}
			}
			return result;
		});

		return bilanJourneysFiltered;
	}

	fillUserAssignationJourney(userTralaJourneys: Array<Journey>) {
		const userAssignationJourneys = new Array<Journey>();
		userTralaJourneys.forEach(element => {
			let journey: Journey;
			if (element.content != null) {
				if (element.content.exercises) {
					journey = new Journey(
						this.cabriService,
						this.lmsService,
						Number(element.content.id),
						element.content.educationalLevel,
						element.id,
						element.content.title,
						element.content.difficulty,
						false,
						true,
						element.content.exercises
					);
				}
			}

			if (journey) {
				// check if journey already in array
				const currentPos = userAssignationJourneys.findIndex(j => {
					return j.id === journey.id;
				});

				// add it if not already in array and remove bilan if offline
				if (currentPos === -1 && (this.networkService.isConnected || !journey.bilan)) {
					userAssignationJourneys.push(journey);
				}
			}
		});
		return userAssignationJourneys;
	}

	getAssignations(classRoom: Classroom): Promise<Journey[]> {
		return new Promise<Journey[]>((resolve, reject) => {
			if (classRoom) {
				// assignations already downloaded
				if (this.lmsService.journeysByAssignation) {
					resolve(this.lmsService.journeysByAssignation);
				} else {
					// get assignations for this class
					return this.classService
						.getAssignationList(classRoom.idProf)
						.pipe(
							map(data => {
								if (!this.environment.ose) {
									this.lmsService.journeysByAssignation = data.data;

									// filter assignations not yet available for students
									this.lmsService.journeysByAssignation = this.lmsService.journeysByAssignation.filter(currentData => {
										return +currentData.dates.start <= new Date().getTime();
									});

									// filter journeys without exercices
									this.lmsService.journeysByAssignation = this.lmsService.journeysByAssignation.filter(ex => {
										return ex.content.exercises.length > 0;
									});

									this.lmsService.journeysByAssignation // create ProposedActivity object for each exercise of each journey
										.forEach((ex, i) => {
											if (ex.content && ex.content.exercises) {
												if (!Array.isArray(ex.content.exercises)) {
													console.error("array not array :", ex.content.exercises);
													ex.content.exercises = new Array(ex.content.exercises);
												}
												let indexEx = 0;
												ex.content.exercises.forEach((exId, index, journeyExercices) => {
													// find this exercice
													const exerciseTargetted = this.cabriService.exercices.getAll().find(exo => {
														return Number(exo.id) === Number(exId);
													});

													// create ProposedActivity
													if (exerciseTargetted) {
														const instProposedActivity = new ProposedActivity(
															ex.id + "-" + indexEx,
															exerciseTargetted.name,
															exerciseTargetted.classe,
															Number(exerciseTargetted.gabarit),
															exerciseTargetted.id,
															new Array()
														);
														journeyExercices[index] = instProposedActivity;
														indexEx++;
														return;
													} else {
														console.error("exercice not found :", exId);
													}
												});
											}
										});
								}
								return this.lmsService.journeysByAssignation;
							})
						)
						.subscribe({
							next: allJourneys => {
								resolve(allJourneys);
							},
							error: err => {
								this.lmsService.journeysByAssignation = [];
								reject(true);
							}
						});
				}
			} else {
				if (classRoom && !classRoom.id) {
					console.error("pas de classroom id");
				} else {
					console.error("pas de classroom");
				}
				reject();
			}
		});
	}

	public diagnosticMode(allJourneyExercises: Journey[], idEleve: string, onStart = false): Promise<any> {
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			formData.append("action", "app_mathia_get_next_activity2");
			formData.append("currentExercise", JSON.stringify(allJourneyExercises));
			formData.append("uidEleve", String(idEleve));
			formData.append("onStart", String(onStart));
			this.http.post(this.postUrl, formData).subscribe({
				next: currentExercise => {
					resolve(currentExercise);
				},
				error: error => {
					reject(error);
				}
			});
		});
	}

	/**
	 * create an assignation id  by concatenating current student id with journey's id
	 */
	aiRecommandedAssignationId(journey: Journey) {
		return Number(Number(this.team[0].id) + "" + Number(journey.id));
	}

	/**
	 * set Lrs service in order to avoid dependencies
	 */
	setLrsService(lrsService: LrsService) {
		this.lrsService = lrsService;
	}

	/**
	 * Give the requirments for recommanded journey
	 */
	recommendedJourneyModel(journey: Journey) {
		journey.kidaia = false;
		journey.recommandation = true;
		LrsUtils.jounreyExerciseStep = journey.exercises[0].step;
		const assignatedJourney = this.lmsService.journeysByAssignation?.find((j: Journey) => {
			return j.id === journey.id;
		});
		if (assignatedJourney && assignatedJourney.assignationId) {
			// give the assignation id if current recommanded journey is also an assignated journey
			journey.assignationId = assignatedJourney.assignationId;
			journey.assignation = true;
		}

		return journey;
	}

	/**
	 * Search targetted recommended journey by assignationid
	 */
	async searchRecommandedJourney(journeysEnum: { [key: string]: Journey[] }, aiSelectedJourney: Journey, gllobalJourneys: Journey[]) {
		return new Promise(async resolve => {
			let journey: Journey;
			let assignationNotYetCompleted = false;
			if (aiSelectedJourney?.assignation && aiSelectedJourney.exercises?.length > 0) {
				assignationNotYetCompleted = aiSelectedJourney.exercises.some(ex => ex.status === Status.notDone);
			}
			if (assignationNotYetCompleted) {
				// assignation resume
				journey = aiSelectedJourney;
			} else {
				if (!this.lmsService.randomRecommandedJourney) {
					Object.entries(journeysEnum).forEach(([key, value]) => {
						const result =
							Number(key) === aiSelectedJourney.id &&
							value.length > 0 &&
							!value[0].farthest &&
							Number(value[0].id) === Number(aiSelectedJourney.id);
						if (result) {
							journey = value[0];
						}
					});
					// Security if not find a journey launch a new oone
					if (!journey) {
						journey = await this.lmsService.getAiRecommendation(this.cabriService, this);
					} else {
						const targettedJourney = gllobalJourneys.find(targettedJ => {
							return targettedJ.id === journey.id;
						});

						if (targettedJourney) {
							if (
								(journey.exercises?.length > 0 && journey.exercises.length === targettedJourney.exercises.length) ||
								this.lmsService.startRecommendedJourney
							) {
								if (!this.lmsService.startRecommendedJourney && aiSelectedJourney?.exercises.length > 0) {
									journey.exercises = aiSelectedJourney.exercises;
								} else if (this.lmsService.startRecommendedJourney) {
									// if all exercises have been done so enable the creatioon of ne id session and change status
									journey._id = journey.idSession = null;
									journey.exercises.forEach((ex, index) => {
										journey.exercises[index].status = Status.notDone;
										journey.exercises[index].skip = false;
										journey.exercises[index].skippedExerciseId = null;
									});
									journey.allAskedQuestions = new Array();
								}
							} else if (aiSelectedJourney?.exercises.length > 0) {
								aiSelectedJourney.exercises = journey.exercises;
							}
						}
					}
				} else {
					journey = aiSelectedJourney;
				}
			}
			resolve(journey);
		});
	}

	defineAiUserJourneysState(aiJourney: Journey) {
		const allCompletedExercises = aiJourney.allAskedQuestions.filter(startedExercise => {
			return aiJourney.exercises.some((exercises: ProposedActivity) => {
				return startedExercise.completed === 1 && exercises.step === startedExercise.step;
			});
		});
		allCompletedExercises.forEach(completedJourney => {
			aiJourney.exercises.forEach((aiJ: ProposedActivity, index) => {
				if (completedJourney.step === aiJ.step) {
					aiJourney.exercises[index].status = Status.done;
				}
			});
		});
		this.lmsService.journeyState = { [aiJourney.id]: [aiJourney] } as any;
		this.lmsService.storedJourneyStatementLoad = true;
	}

	initializeUserStatistics() {
		this.lmsService.exerciseStatistics = null;
		this.lmsService.exerciseStatisticsDetails = null;
		if (this.cabriService.exercices?.nextExerciseProposed) {
			this.cabriService.exercices.nextExerciseProposed = null;
		}

		for (const stat in this.exerciseStatistics) {
			if (stat) {
				this.exerciseStatistics[stat] = {};
			}
		}
		this.lmsService.alreadyDoneExercises = null;
		this.exerciseStatistics.exercisePerGoodAnswer = {};
	}

	disconnectUser(redirect = true) {
		this.InAppPurchaseService.appleUser = null;
		this.InAppPurchaseService.logWithApple = false;
		this.lmsService.allJourneysLrsProgression = null;
		this.lmsService.needRefreshStudentJourneysData = false;
		this.studentsClassLoaded = false;
		this.storyService.allStories = null;
		this.studentsProfiles = null;

		this.loadGabaritStudentInfos = false;
		this.initializeUserStatistics();
		if (this.lmsService.gamification) {
			this.lmsService.gamification.logBookStatistics = new Array();
		}
		this.lmsService.allJourneys = null;
		this.lmsService.userJourneyFromParams = null;
		this.lmsService.journeysByAssignation = null;
		this.lmsService.storedJourneyStatementLoad = false;
		LrsUtils.assignationId = undefined;
		this.user = new User();
		this.user.loggedFromWp = false;
		if (!this.globalService.isKidaia && !this.globalService.isOse) {
			this.user.premium = !environment.kidaia || environment.ose;
		} else if (environment.ose) {
			this.user.premium = true;
			this.user.premium_ose = true;
		}
		this.allStudents = null;
		this.lmsService.allJourneysByStatus = new Array();
		this.teams = new Array();
		this.team = new Array();
		if (this.classroom) {
			this.classroom.idProf = null;
		}

		// remove OAuth2 token
		this.classService.logout();

		this.globalService.setHtmlClass("logged-from-home", false);

		// Real codeclasse value
		localStorage.removeItem("codeclasse");
		localStorage.removeItem("codeFamille");

		this.removeStoryJourney();
		// Legacy codeclasse
		if (localStorage.getItem("codeclass")) {
			localStorage.removeItem("codeclass");
		}
		localStorage.removeItem("level");
		localStorage.removeItem("team");
		localStorage.removeItem("teams");
		localStorage.removeItem("sentencesTop");
		localStorage.removeItem("sentencesCurent");
		localStorage.removeItem("sentencesBottom");
		localStorage.removeItem("codeClassProf");
		localStorage.removeItem("activitiesChoosed");
		localStorage.removeItem("categories");
		localStorage.removeItem("allPhrases");
		localStorage.removeItem("openList");
		localStorage.removeItem("newActivitySuggested");
		localStorage.removeItem("lrs_id");
		localStorage.removeItem("clientBasicAuth");
		localStorage.removeItem("variables");
		localStorage.removeItem("exerciceId");
		localStorage.removeItem("maxStudent");
		localStorage.removeItem("page-ose");
		localStorage.removeItem("premium");
		localStorage.removeItem("savedActivity");
		if (redirect) {
			this.router.navigateByUrl("/starting");
		}
	}

	/**
	 * Autolog with tralalere auth when kidaia send parameter on app launch
	 * also verify if the user is logged on bubble
	 */
	autologKidaia(noRedirect = false) {
		this.logReadyPromise = new Promise<void>((resolve, reject) => {
			if (!this.classService.authBearer) {
				this.classService.getKidaiaSso().then(
					kidaiaSso => {
						if (environment.ose){
							if (kidaiaSso.oseauthbearer_text && !this.classService.isLoggedIn()){
								let oseBearerDecode = window.atob(kidaiaSso.oseauthbearer_text);
								const email = oseBearerDecode.split(":")[0];
								const password = oseBearerDecode.split(":")[1];
								const login = this.classService.login(email, password);
								login.subscribe({
									next: async data => {
										await this.getStudentsAndJourneysLoggedIn();
										this.isUserLoaded = new ReplaySubject(1);
										this.isUserLoaded.next(true);
										this.isUserLoaded.complete();
										this.maxKidaiaAccount = -1;
										if(!noRedirect){
											this.router.navigateByUrl("/");
										}
										resolve();
									},
									error: error => {
										console.log("ERROR", error);
										reject(error);
									}
								});
							}else{
								console.log("connected to mon.kidaia.com but not a kidaia account");
								reject("not a kidaia account");
							}
						}else{
							if (kidaiaSso.tralalereauthbearer_text)
							this.classService
								.login(kidaiaSso.tralalereauthbearer_text)
								.pipe(
									catchError(err => {
										// if request error so send throwError observable result as object
										return err;
									})
								)
								.subscribe({
									next: () => {
										if (kidaiaSso.maxuser_number) {
											this.maxKidaiaAccount = kidaiaSso.maxuser_number;
										}
										//add account access subscribed
										this.user.premium = kidaiaSso.premium;
										this.user.premium_ose = kidaiaSso.premium_ose;
										this.user.loggedFromWp = true;
										this.user.family = kidaiaSso.famille;
										this.user.admin = kidaiaSso.admin;
										this.user.teacher = kidaiaSso.enseignant;
										this.user.admin = kidaiaSso.admin;
										resolve();
									},
									error: async error => {
										this.globalService.setGlobalLoading(false);
										if (environment.kidaia && !noRedirect) {
											this.kidaiaErrorRedirectionRule(error);
										}
										reject(error);
									}
								});
						}
					},
					async error => {
						this.globalService.setGlobalLoading(false);
						if (environment.kidaia && !noRedirect) {
							this.kidaiaErrorRedirectionRule(error);
						}
						reject(error);
					}
				);
			} else {
				resolve();
			}
		});
		return this.logReadyPromise;
	}

	/**
	 * logwordpress
	 * @param email or codemaison
	 * @param password needed with email
	 * @param apple true if appleCode
	 * @returns
	 */
	logWordpress(email: string, password?: string, apple = false, f2a?:string,idUser?:string) {
		return new Promise<Observable<BearerToken>>((resolve, reject) => {
			let promise: Promise<KidaiaSso>;
			if (apple) {
				promise = this.classService.authFromAppleCode(email, password);
			} else if (email && password) {
				promise = this.classService.authFromApp(email, password,f2a,idUser);
			} else {
				promise = this.classService.authFromFamilyCode(email);
			}
			promise.then(
				kidaiaSso => {
					if (!kidaiaSso.tralalereauthbearer_text) {
						const text = $localize`Code incorrect`
						reject(text);
					}else{
						// console.log("kidaiaSso",kidaiaSso)
						if (!password && !apple) {
							localStorage.setItem("codeFamille", email);
						} else if (kidaiaSso.codefamille) {
							localStorage.setItem("codeFamille", kidaiaSso.codefamille);
						}
						if (kidaiaSso.maxuser_number) {
							this.maxKidaiaAccount = kidaiaSso.maxuser_number;
							localStorage.setItem("maxStudent", String(kidaiaSso.maxuser_number));
						}
						this.user.premium = kidaiaSso.premium;
						this.user.admin = kidaiaSso.admin;
						localStorage.setItem("premium", String(kidaiaSso.premium));
						this.user.premium_ose = kidaiaSso.premium_ose;
						this.user.family = kidaiaSso.famille;
						this.user.teacher = kidaiaSso.enseignant;
						this.user.loggedFromWp = true;
						resolve(this.classService.login(kidaiaSso.tralalereauthbearer_text));
					}
				},
				async error => {
					this.globalService.setGlobalLoading(false);
					reject(error);
				}
			);
		});
	}

	/**
	 * Verify Kidaia error status type to allow or to forbid navigation on the site
	 * @param error HttpError
	 */
	async kidaiaErrorRedirectionRule(error) {
		this.urlBeforeRedirection = this.router["currentNavigation"]?.extractedUrl;
		if (error?.status === HttpErrorStatus.InternalServerError) {
			const getStudents = (await this.localStorageService.get(StorageKey.students)) as Promise<Student[]>;
			if (!getStudents) {
				this.classService.redirectionError();
			}
		} else {
			if (!window.location.href.includes("/inscription")) {
				this.classService.redirectionError();
			}
		}
	}

	/**
	 * Change current with another one from participants list
	 */
	async switchKidaiaCurrentUser(student: Student, $event?) {
		if ($event) {
			$event.stopPropagation();
			await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget }, 150);
		}
		if (Number(student.id) !== Number(this.team[0].id)) {
			this.globalService.setGlobalLoading(true);
			this.globalService.fullPageConfirmationAlert = false;
			if (this.team.length > 0) {
				this.initializeUserStatistics();
				const studentSelected = this.allStudents.find(eachStudent => {
					return Number(eachStudent.id) === Number(student.id);
				});

				this.user.codeClasse = Number(student.classe?.id);
				this.team = new Array();
				if (this.user.codeClasse) {
					localStorage.setItem("codeclasse", this.user.codeClasse);
				}
				localStorage.setItem("team", JSON.stringify([studentSelected.id]));
				this.restoreTeam(this.allStudents);
				this.updateUserData(this.team[0].id)
					.then(() => {
						if (this.router.url.startsWith("/accueil")) {
							// Pass new value each time to accueil page router so that the page could be notified in every single change
							const uniqueQueryValue = (Math.random() + 10).toString(36).substring(0, 6);
							const queryParams: Params = { updateMap: uniqueQueryValue };
							this.router.navigate([], {
								queryParams,
								queryParamsHandling: "merge" // add new param in url
							});
							this.globalService.setGlobalLoading(false);
						} else {
							const isOse = this.globalService.isPageOse || this.globalService.isOse;
							if (isOse) {
								if (this.router.url.startsWith("/choix-activites")) {
									// if same page no loading
									this.globalService.setGlobalLoading(false);
								}
								this.router.navigateByUrl("/choix-activites");
							} else {
								this.router.navigateByUrl("/accueil");
							}
						}
					})
					.catch(err => {
						console.error("switched student", err);
						this.globalService.setGlobalLoading(false);
					});
			}
		}
		this.globalService.closeStudentsMenu();
	}

	public checkIframeIntegration(params) {
		if (params.org && params.org === "aren") {
			this.classService.isAren = true;
		}
		if (params.org && params.org === "beneylu") {
			this.classService.isBeneylu = true;
		}
	}
	objectify(array) {
		return array.reduce((p, c) => {
			p[c[0]] = c[1];
			return p;
		}, {});
	}

	public isClassLoggedIn() {
		return this.classService.isLoggedIn();
	}

	public removeStorageConnection(codemaison = false) {
		this.localStorageService.remove(StorageKey.getAiRecommendations);
		this.localStorageService.remove(StorageKey.getAllJourneys);
		this.localStorageService.remove(StorageKey.getAllBadges);
		this.localStorageService.remove(StorageKey.getAllStorys);

		if (!codemaison) {
			this.classService.logout();
		}
	}

	recoverCodeConnection() {
		return new Promise<void>(async resolve => {
			// only in mathia
			if (this.environment.kidaia === false) {
				// recover data from localstorage
				this.user.codeMaison = localStorage.getItem("codemaison");
				const token_student = localStorage.getItem("id_token_student");

				// logged user
				if (this.user.codeMaison && token_student) {
					if (this.networkService.isConnected) {
						if (this.classService.isLoggedInStudent()) {
							this.classService.homeStudent = this.user.studentId;
							this.classService.loggedFromHome = true;
						} else {
							await this.classService.loginCodeMaison(this.user.codeMaison);
						}
					} else {
						this.classService.homeStudent = this.user.studentId;
						this.classService.loggedFromHome = true;
					}

					this.globalService.setHtmlClass("logged-from-home", this.classService.loggedFromHome);
				}
				if (localStorage.getItem("codeFamille")) {
					try {
						let login = await this.logWordpress(localStorage.getItem("codeFamille"));
						login?.subscribe({
							next: async data => {},
							error: error => {
								throw error;
							}
						});
					} catch (error) {
						if (error.status === 0) {
							this.user.loggedFromWp = true;
							this.user.premium = localStorage.getItem("premium") === "true";
							const maxStudent = Number(localStorage.getItem("maxStudent"));
							this.maxKidaiaAccount = maxStudent && !isNaN(maxStudent) ? maxStudent : 2;
						}
					}
				}
				if (this.globalService.isCordova && this.globalService.isIos) {
					await lastValueFrom(this.InAppPurchaseService.storeReadyObs);
					await this.cabriService.getAllActivitiesAsync();
					if (this.InAppPurchaseService.state.activeSubscription) {
						try {
							this.disconnectUser(false);
							let login = await this.logWordpress(this.InAppPurchaseService.state.activeSubscription.purchaseId, null, true);
							await lastValueFrom(login);
						} catch (error) {
							if (error.status === 0) {
								this.user.loggedFromWp = true;
								this.user.premium = localStorage.getItem("premium") === "true";
								const maxStudent = Number(localStorage.getItem("maxStudent"));
								this.maxKidaiaAccount = maxStudent && !isNaN(maxStudent) ? maxStudent : 2;
							}
						}
					}
				}
			}
			resolve();
		});
	}
	/**
	 * Get all student profiles with competencies validated or not by classroom
	 * @param codeClasse string
	 */
	public getStudentsProfilesFromClassroom(): Promise<void> {
		return new Promise(async resolve => {
			if (environment.kidaia || environment.ose || this.studentsProfiles) {
				resolve();
			} else {
				let errorOccured = false;
				this.http
					.get(this.postApiMathia + `/profilesFromClass?idstudents=${this.studentsIds.join(",")}`)
					.pipe(
						catchError(async () => {
							errorOccured = true;
							this.studentsProfiles = await this.localStorageService.get(StorageKey.profilesFromClass);
							return this.studentsProfiles;
						})
					)
					.subscribe({
						next: (data: any) => {
							this.studentsProfiles = data;
							if (!errorOccured) {
								this.localStorageService.set(StorageKey.profilesFromClass, this.studentsProfiles);
							}

							resolve();
						}
					});
			}
		});
	}

	/**
	 * Update user profile and save result in indexDb
	 * @param usersIdsMadeActivity
	 */
	async updateUserProfile(usersIdsMadeActivity: Array<string>) {
		if (usersIdsMadeActivity?.length > 0) {
			for (const userId of usersIdsMadeActivity) {
				await this.badgeService.getUserCompetencies(userId);
			}

			if (this.isRealClassConnected) {
				this.getStudentsProfilesFromClassroom();
			}
		}
	}

	/**
	 * Get all recommendations for a classe or just for one student if studentId set
	 * @param studentId (optional) to get only one recommendation
	 * @return Promise<JourneySuggestion[]>
	 */
	public getAiRecommendations(): Promise<JourneyRecommendation[]> {
		return new Promise(async (resolve, reject) => {
			const codeClasse: number | string = this.team[0].classe?.id;
			const studentId = this.team[0]?.id;
			let token;
			try {
				token = await this.classService.getSessionToken();
			} catch (err) {
				console.error("no login token");
			}

			const searchParams = new URLSearchParams();
			searchParams.append("codeClasse", codeClasse.toString());
			if (studentId) {
				searchParams.append("student", studentId.toString());
			}
			if (token) {
				searchParams.append("token", token);
			}
			this.http
				.get<JourneyRecommendation[]>(this.postApiMathia + `/recommendations?${searchParams.toString()}`)
				.pipe(
					catchError(async err => {
						console.error("err while recovering journey recommendation");
						let recommendedJourney: JourneyRecommendation[] = [];
						if (!environment.kidaia) {
							if (!this?.studentsProfiles) {
								await this.getStudentsProfilesFromClassroom();
							}
							if (this?.studentsProfiles) {
								const journeyToDo = this.selectProfileRecommendationOffline();
								if (journeyToDo) {
									recommendedJourney = [
										{
											journey: journeyToDo.id,
											journeyDifficulty: JourneyMode.remediation,
											journeyTitle: journeyToDo.title,
											student: studentId
										}
									];
								}
							}
						}

						return recommendedJourney;
					})
				)
				.subscribe({
					next: (data: any) => {
						if (Array.isArray(data) && data.length > 0 && data[0] !== null) {
							data = data.map(item => {
								return Object.assign(new JourneyRecommendation(), item);
							});
						}
						resolve(data);
					}
				});
		});
	}

	/**
	 * Select recommended journey based on the student's profile competencies that have not yet been validated.
	 * @returns Journey
	 */
	public selectProfileRecommendationOffline(): Journey {
		let currentJourneyToDo;
		if (this.studentsProfiles) {
			// student profiles
			const studentProfileData = this.studentsProfiles?.[this.team[0]?.id];
			const allUserCompetencies = new Array();
			const studentLevel = this.team[0]?.level;
			const originalJourney = this.lmsService._globalJourneys.slice().filter(j => j.level === studentLevel);
			AppUtils.shuffleArray(originalJourney);
			if (studentProfileData) {
				for (const competency in studentProfileData) {
					allUserCompetencies.push({ competencyId: competency, status: studentProfileData[competency] });
				}
			}
			AppUtils.shuffleArray(allUserCompetencies);
			allUserCompetencies.find(userCompetency => {
				if (userCompetency.status === false) {
					currentJourneyToDo = originalJourney.find(jEx => {
						return jEx.exercises.find((ex: ProposedActivity) => {
							// get exercise object
							const currentEx = this.cabriService.exercices.getExercise(ex.exerciseId);
							if (currentEx) {
								// all competencies of the exercise that need to be validated
								const exerciseCompetenciesToValidate = Object.keys(currentEx.competencesValidees);

								if (exerciseCompetenciesToValidate?.length > 0) {
									// return the journey to do if the student profile has not been created or if their at least one competency have not been validated.
									let competencyNoValidatedFromJourneyEx = exerciseCompetenciesToValidate.find(exCompetencies => {
										return Number(exCompetencies) === Number(userCompetency.competencyId);
									});

									if (competencyNoValidatedFromJourneyEx) {
										// console.log("competencyNoValidatedFromJourneyEx",competencyNoValidatedFromJourneyEx)
										// console.log("exexex",ex)
										return true;
									}
								}
							}
						});
					});

					if (currentJourneyToDo) {
						return true;
					}
				}
			});
		}
		return currentJourneyToDo;
	}

	public isDemoAccount() {
		return (
			this.allStudents &&
			this.allStudents.length > 0 &&
			this.allStudents.every(a => {
				a.classe.isDemo;
			})
		);
	}

	connectDemoAccount() {
		const code = "81517";
		this.removeStorageConnection();
		this.analyticService.sendAnalytics("connexion premium");
		this.user.codeClasse = Number(code);
		localStorage.setItem("codeclasse", code);
		this.remoteService.updateRegister();
		this.remoteService.getListRemoteHost(Number(code));
		this.team = new Array();
		this.loadGabaritStudentInfos = false;
		this.lmsService.journeysByAssignation = null;
		this.lmsService.allJourneysByStatus = new Array();
		this.studentsClassLoaded = false;
		this.lrsService.autenticateEnterCodeClass(code);
	}

	loginCodeMaison(codeMaison: string): Promise<string> {
		return new Promise(async (resolve, reject) => {
			try {
				const codeClasse = await this.classService.loginCodeMaison(codeMaison);
				this.team = [this.classService.homeStudent];
				this.user.codeMaison = codeMaison;
				localStorage.setItem("team", JSON.stringify(this.team));
				this.globalService.setHtmlClass("logged-from-home", true);
				resolve(codeClasse);
			} catch {
				reject("Code incorrect");
			}
		});
	}

	async autologCodeMaison(codeMaison: string): Promise<string> {
		return new Promise(async resolve => {
			const codeClasse = await this.loginCodeMaison(codeMaison);
			this.removeStorageConnection(true);
			this.analyticService.sendAnalytics("autolog_codemaison");
			this.setCodeClasse(codeClasse);
			this.resetClassVariables();
			resolve(codeClasse);
		});
	}

	setCodeClasse(codeclasse: string) {
		this.user.codeClasse = Number(codeclasse);
		localStorage.setItem("codeclasse", codeclasse);
		this.remoteService.updateRegister();
		this.remoteService.getListRemoteHost(Number(codeclasse));
	}

	resetClassVariables() {
		this.team = new Array();
		this.loadGabaritStudentInfos = false;
		this.lmsService.journeysByAssignation = null;
		this.lmsService.allJourneysByStatus = new Array();
		this.studentsClassLoaded = false;
	}

	async loginApple(purchaseId?:string) {
		let login: Observable<BearerToken>;
		this.globalService.setGlobalLoading(true);
		if (purchaseId){
			try {
				login = await this.logWordpress(purchaseId, null, true);
			} catch (error) {
				console.log(error);
				this.globalService.setGlobalLoading(false);
			}
		} else {
			if (!this.InAppPurchaseService.appleUser) {
				try {
					const signInAppleResult = await this.InAppPurchaseService.signInApple(false);
				} catch (error) {
					console.log(error);
					this.globalService.setGlobalLoading(false);
				}
			}
			if (this.InAppPurchaseService.appleUser) {
				try {
					login = await this.logWordpress(this.InAppPurchaseService.appleUser.user, this.InAppPurchaseService.appleUser.authorizationCode, true);
				} catch (error) {
					console.log(error);
					this.globalService.setGlobalLoading(false);
				}
			}
		}


		login?.subscribe({
			next: async data => {
				this.InAppPurchaseService.logWithApple = true;
				await this.getStudentsAndJourneysLoggedIn();
				this.isUserLoaded = new ReplaySubject(1);
				this.isUserLoaded.next(true);
				this.isUserLoaded.complete();
				console.log("User is logged in");
				this.globalService.setGlobalLoading(false);
				if (this.restore){
					this.user.premium = true;
					this.restore = false;
				}
				if (this.user.premium) {
					this.router.navigateByUrl("/activity-participants");
				} else {
					this.router.navigateByUrl("/starting");
				}
			},
			error: error => {
				console.log(error);
				this.globalService.setGlobalLoading(false);
			}
		});
	}
}
