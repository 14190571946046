
    <div class="formContainer">
      <form [formGroup]="form" class="form" (submit)="onSubmit($event)">
        <ion-grid class="selectedItem">
          <ion-row>
            <ion-col>
              <ion-item>
                <!-- <ion-label color="primary" ><h1 i18n>Type de demande :</h1></ion-label> -->
                <ion-select interface="popover" class="buttonSelect pointer" formControlName="typeFeedback">
                  <ion-select-option  *ngFor="let type of typeFeedbackList" [value]="type.key">
                    {{type.value}}
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="!accountService.user.codeClasse || classService.loggedFromHome">
            <ion-col>
              <ion-item>
                <ion-input type="text" formControlName="mail" i18n-label  label="Mail :" labelPlacement="floating"></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <div *ngIf="typeFeedbackList[1].key === form.value.typeFeedback">
            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-input type="text" formControlName="title" i18n-label  label="Que souhaiteriez vous changer ou améliorer dans
                  l'application :" labelPlacement="floating"></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-textarea  inputmode="text" rows="5" spellcheck="true" auto-grow="true" formControlName="content" i18n-label  label="Des suggestions :" labelPlacement="floating"></ion-textarea>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-label color="primary" i18n>Note</ion-label>
                  <ion-range class="rangeCustom" mode="ios" min="0" max="5" step="1" [ticks]="true" [snaps]="true" formControlName="note" pin="true">
                    <ion-label slot="start"><h1 >0</h1></ion-label>
                    <ion-label slot="end"><h1 > 5</h1></ion-label>
                  </ion-range>
                </ion-item>
              </ion-col>
            </ion-row>
          </div>
          <div *ngIf="typeFeedbackList[0].key === form.value.typeFeedback">
            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-input type="text" formControlName="title"  i18n-label  label="Titre :" labelPlacement="floating"></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-textarea  inputmode="text" rows="5" spellcheck="true" auto-grow="true" formControlName="content"  i18n-label  label="Description :" labelPlacement="floating"></ion-textarea>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-input type="text" formControlName="userModel" i18n-label  label="Modèle d'appareil (optionnel):" labelPlacement="floating"></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-input type="text" formControlName="userRam" i18n-label  label="Quantité de ram en Giga (optionnel):" labelPlacement="floating"></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
          </div>
        </ion-grid>
        
        <div class="btn">         
          <ion-button color="success" shape="round" size="large" type="submit" [disabled]="!form.valid" i18n>Envoyer</ion-button>
        </div>
      </form>
    </div>
