

<div class="buttonWrapper">
    <ion-button  *ngIf="devices?.length>1" (click)="changeCamera()">
        <ion-icon slot="icon-only" name="camera"></ion-icon>
    </ion-button>
    <ion-button *ngIf="canTorch" class="torch" (click)="toggleTorch()" >
        <ion-icon slot="icon-only" [name]="scanner?.isTorch? 'flash':'flash-off'"></ion-icon>
    </ion-button>
</div>
<ngx-scanner-qrcode [config]="config"></ngx-scanner-qrcode>
