import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { environment } from "src/environments/environment";
import {
	AuthGuard,
	WaitingOperationGuard,
	PremiumConnectionGuard,
	PremiumOseConnectionGuard,
	canActivateAdmin
} from "./auth-guard.guard";
import { RoutingRules } from "./models/routing";
import { RgpdGuard } from "./services/rgpd.guard";

const routes: Routes = [
	{ path: "", redirectTo: "home?clmc=assets/cabri/calculmental.clmc", pathMatch: "full" },
	{ path: "home", loadChildren: () => import("./page/home/home.module").then(m => m.HomePageModule), canActivate: [RgpdGuard] },
	{
		path: "login",
		loadChildren: () => import("./page/login/login.module").then(m => m.LoginPageModule),
		canDeactivate: [WaitingOperationGuard],
		canActivate: []
	},
	{
		path: "profile",
		loadChildren: () => import("./page/profile/profile.module").then(m => m.ProfilePageModule),
		canActivate: [RgpdGuard]
	},
	{
		path: "starting",
		loadChildren: () => import("./page/starting/starting.module").then(m => m.StartingPageModule),
		canActivate: [RgpdGuard]
	},
	{
		path: "record/:id",
		loadChildren: () => import("./page/record/record.module").then(m => m.RecordPageModule),
		canActivate: [RgpdGuard]
	},
	{
		path: "class-code-entry",
		loadChildren: () => import("./page/class-code-entry/class-code-entry.module").then(m => m.ClassCodeEntryPageModule),
		canActivate: [RgpdGuard]
	},
	{
		path: "grades",
		loadChildren: () => import("./page/grades/grades.module").then(m => m.GradesPageModule),
		canActivate: [AuthGuard, RgpdGuard]
	},
	{
		path: "manage-class",
		loadChildren: () => import("./page/manage-class/manage-class.module").then(m => m.ManageClassPageModule),
		canActivate: []
	},
	{ path: "blog", loadChildren: () => import("./page/blog/blog.module").then(m => m.BlogPageModule), canActivate: [] },
	{ path: "news", loadChildren: () => import("./page/news/news.module").then(m => m.NewsPageModule), canActivate: [] },
	{
		path: "newsletter",
		loadChildren: () => import("./page/newsletter/newsletter.module").then(m => m.NewsletterPageModule),
		canActivate: []
	},
	{ path: "feedback", loadChildren: () => import("./page/feedback/feedback.module").then(m => m.FeedbackPageModule), canActivate: [] },
	{ path: "parents", loadChildren: () => import("./page/parents/parents.module").then(m => m.ParentsPageModule), canActivate: [] },
	{ path: "logout", loadChildren: () => import("./logout/logout.module").then(m => m.LogoutPageModule), canActivate: [] },
	{
		path: "activities",
		loadChildren: () => import("./page/activities/activities.module").then(m => m.ActivitiesPageModule),
		canActivate: [RgpdGuard]
	},
	{
		path: "verification",
		loadChildren: () => import("./page/verification/verification.module").then(m => m.VerificationPageModule),
		canActivate: []
	},
	{ path: "test-stt", loadChildren: () => import("./page/test-stt/test-stt.module").then(m => m.TestSttPageModule), canActivate: [] },
	{ path: "test-tts", loadChildren: () => import("./page/test-tts/test-tts.module").then(m => m.TestTtsPageModule), canActivate: [] },
	{
		path: "calculmental",
		canActivate: [RgpdGuard],
		loadChildren: () => import("./page/cabri/cabri.module").then(m => m.CabriPageModule)
	},
	{
		...RoutingRules.activityParticipants
	},
	{
		path: "gabarits",
		loadChildren: () => import("./page/gabarits/gabarits.module").then(m => m.GabaritsPageModule),
		canActivate: [RgpdGuard]
	},
	{ path: "ocr", loadChildren: () => import("./page/ocr/ocr.module").then(m => m.OcrPageModule), canActivate: [RgpdGuard] },
	{
		path: "jeudufuret",
		loadChildren: () => import("./page/jeudufuret/jeudufuret.module").then(m => m.JeudufuretPageModule),
		canActivate: [RgpdGuard]
	},
	{
		path: "remote-control",
		loadChildren: () => import("./page/remote-control/remote-control.module").then(m => m.RemoteControlPageModule),
		canActivate: []
	},
	{
		path: "jeudekim",
		loadChildren: () => import("./page/jeudekim/jeudekim.module").then(m => m.JeudekimPageModule),
		canActivate: [RgpdGuard]
	},
	{
		path: "solides",
		loadChildren: () => import("./page/solides/solides.module").then(m => m.SolidesPageModule),
		canActivate: [RgpdGuard]
	},
	{ path: "heure", loadChildren: () => import("./page/heure/heure.module").then(m => m.HeurePageModule), canActivate: [RgpdGuard] },
	{
		path: "galaxiedescalculs",
		loadChildren: () => import("./page/galaxiedescalculs/galaxiedescalculs.module").then(m => m.GalaxieDesCalculsPageModule),
		canActivate: [RgpdGuard]
	},
	{ path: "traces", loadChildren: () => import("./traces/traces.module").then(m => m.TracesPageModule), canActivate: [] },
	{
		path: "test-connexion-bubble",
		loadChildren: () => import("./test-connexion-bubble/test-connexion-bubble.module").then(m => m.TestConnexionBubblePageModule),
		canActivate: []
	},
	{
		path: "accueil",
		loadChildren: () => import("./page/accueil/accueil.module").then(m => m.AccueilPageModule),
		canDeactivate: [],
		canActivate: [RgpdGuard,PremiumConnectionGuard]
	},
	{
		path: "map",
		loadChildren: () => import("./page/map/map.module").then(m => m.MapPageModule),
		canActivate: [RgpdGuard, PremiumConnectionGuard]
	},
	{
		path: "narration",
		loadChildren: () => import("./page/narration/narration.module").then(m => m.NarrationPageModule),
		canActivate: [RgpdGuard, PremiumConnectionGuard]
	},
	{
		path: "assignation",
		loadChildren: () => import("./page/assignation/assignation.module").then(m => m.AssignationPageModule),
		canActivate: [RgpdGuard]
	},
	{
		path: "ar",
		loadChildren: () => import("./page/ar/ar.module").then(m => m.ArPageModule)
	},
	{
		path: "logbook",
		loadChildren: () => import("./page/logbook/logbook.module").then(m => m.LogbookPageModule),
		canActivate: [PremiumConnectionGuard]
	},
	{
		path: "model-page-cabri",
		loadChildren: () => import("./page/model-page-cabri/model-page-cabri.module").then(m => m.ModelPageCabriPageModule)
	},
	{
		path: "jeu-juste-point",
		loadChildren: () => import("./page/jeu-juste-point/jeu-juste-point.module").then(m => m.JeuJustePointPageModule),
		canActivate: [RgpdGuard]
	},
	{
		path: "videos",
		loadChildren: () => import("./page/videos/videos.module").then(m => m.VideosPageModule),
		canActivate: [PremiumConnectionGuard]
	},
	{
		path: "test-bee",
		loadChildren: () => import("./pages/test-bee/test-bee.module").then(m => m.TestBeePageModule)
	},
	{
		path: "supervision",
		loadChildren: () => import("./pages/supervision/supervision.module").then(m => m.SupervisionPageModule),
		canActivate : [canActivateAdmin]
	},
	{
		path: "mathador",
		loadChildren: () => import("./page/mathador/mathador.module").then(m => m.MathadorPageModule)
	},
	{
		path: "jeu-des-erreurs",
		loadChildren: () => import("./pages/jeu-des-erreurs/jeu-des-erreurs.module").then(m => m.JeuDesErreursPageModule),
		isOse: true
	},
	{
		path: "photo-dome",
		loadChildren: () => import("./page/photo-dome/photo-dome.module").then(m => m.PhotoDomePageModule)
	},
	{
		path: "photo-dome-choice",
		loadChildren: () => import("./page/photo-dome-choice/photo-dome-choice.module").then(m => m.PhotoDomeChoicePageModule)
	},
	{
		path: "parcours-environnement",
		loadChildren: () =>
			import("./pages/parcours-environnement/parcours-environnement.module").then(m => m.ParcoursEnvironnementPageModule)
	},
  {
    path: 'inapp-payment',
    loadChildren: () => import('./page/inapp-payment/inapp-payment.module').then( m => m.InappPaymentPageModule)
  }

];
const routeskidaia: Routes = [
	{
		path: "supervision",
		loadChildren: () => import("./pages/supervision/supervision.module").then(m => m.SupervisionPageModule)
	},
	{
		path: "",
		loadChildren: () =>
			import("./page/activity-participants-kidaia/activity-participants-kidaia.module").then(
				m => m.ActivityParticipantsKidaiaPageModule
			),
		isOse: true
	},
	{
		path: "home",
		loadChildren: () =>
			import("./page/activity-participants-kidaia/activity-participants-kidaia.module").then(
				m => m.ActivityParticipantsKidaiaPageModule
			),
		isOse: true
	},
	{
		path: "choix-activites",
		loadChildren: () => import("./pages/accueil-ose/accueil-ose.module").then(m => m.AccueilOsePageModule),
		canDeactivate: [],
		canActivate: [PremiumOseConnectionGuard],
		isOse: true
	},
	{
		path: "accueil",
		loadChildren: () => import("./page/accueil/accueil.module").then(m => m.AccueilPageModule),
		canDeactivate: [],
		canActivate: [],
		isOse: false
	},
	{
		path: "login",
		loadChildren: () => import("./page/login/login.module").then(m => m.LoginPageModule),
		canDeactivate: [WaitingOperationGuard],
		canActivate: []
	},
	{ path: "profile", loadChildren: () => import("./page/profile/profile.module").then(m => m.ProfilePageModule) },
	{
		path: "starting",
		loadChildren: () => import("./page/starting/starting.module").then(m => m.StartingPageModule),
		canActivate: [],
		isOse: false
	},
	{
		path: "assignation",
		loadChildren: () => import("./page/assignation/assignation.module").then(m => m.AssignationPageModule),
		canActivate: [],
		isOse: false
	},
	{
		path: "record/:id",
		loadChildren: () => import("./page/record/record.module").then(m => m.RecordPageModule),
		canActivate: [],
		isOse: false
	},
	{
		path: "class-code-entry",
		loadChildren: () => import("./page/class-code-entry/class-code-entry.module").then(m => m.ClassCodeEntryPageModule),
		canActivate: [],
		isOse: false
	},
	{
		path: "grades",
		loadChildren: () => import("./page/grades/grades.module").then(m => m.GradesPageModule),
		canActivate: [AuthGuard],
		isOse: false
	},
	{
		path: "manage-class",
		loadChildren: () => import("./page/manage-class/manage-class.module").then(m => m.ManageClassPageModule),
		canActivate: [],
		isOse: false
	},
	{ path: "blog", loadChildren: () => import("./page/blog/blog.module").then(m => m.BlogPageModule), canActivate: [] },
	{ path: "news", loadChildren: () => import("./page/news/news.module").then(m => m.NewsPageModule), canActivate: [] },
	{
		path: "newsletter",
		loadChildren: () => import("./page/newsletter/newsletter.module").then(m => m.NewsletterPageModule),
		canActivate: []
	},
	{ path: "feedback", loadChildren: () => import("./page/feedback/feedback.module").then(m => m.FeedbackPageModule), canActivate: [] },
	{ path: "parents", loadChildren: () => import("./page/parents/parents.module").then(m => m.ParentsPageModule), canActivate: [] },
	{ path: "logout", loadChildren: () => import("./logout/logout.module").then(m => m.LogoutPageModule), canActivate: [] },
	{
		path: "activities",
		loadChildren: () => import("./page/activities/activities.module").then(m => m.ActivitiesPageModule),
		isOse: false
	},
	{
		path: "verification",
		loadChildren: () => import("./page/verification/verification.module").then(m => m.VerificationPageModule),
		canActivate: [],
		isOse: false
	},
	{ path: "test-stt", loadChildren: () => import("./page/test-stt/test-stt.module").then(m => m.TestSttPageModule), canActivate: [] },
	{ path: "test-tts", loadChildren: () => import("./page/test-tts/test-tts.module").then(m => m.TestTtsPageModule), canActivate: [] },
	{
		path: "calculmental",
		loadChildren: () => import("./page/cabri/cabri.module").then(m => m.CabriPageModule),
		canActivate: [],
		isOse: false
	},
	{
		...RoutingRules.activityParticipants
	},
	{
		path: "gabarits",
		loadChildren: () => import("./page/gabarits/gabarits.module").then(m => m.GabaritsPageModule),
		canActivate: [],
		isOse: false
	},
	{ path: "ocr", loadChildren: () => import("./page/ocr/ocr.module").then(m => m.OcrPageModule), canActivate: [] },
	{
		path: "jeudufuret",
		loadChildren: () => import("./page/jeudufuret/jeudufuret.module").then(m => m.JeudufuretPageModule),
		canActivate: [],
		isOse: false
	},
	{
		path: "remote-control",
		loadChildren: () => import("./page/remote-control/remote-control.module").then(m => m.RemoteControlPageModule),
		canActivate: []
	},
	{
		path: "jeudekim",
		loadChildren: () => import("./page/jeudekim/jeudekim.module").then(m => m.JeudekimPageModule),
		canActivate: [],
		isOse: false
	},
	{
		path: "solides",
		loadChildren: () => import("./page/solides/solides.module").then(m => m.SolidesPageModule),
		canActivate: [],
		isOse: false
	},
	{ path: "heure", loadChildren: () => import("./page/heure/heure.module").then(m => m.HeurePageModule), canActivate: [], isOse: false },
	{
		path: "galaxiedescalculs",
		loadChildren: () => import("./page/galaxiedescalculs/galaxiedescalculs.module").then(m => m.GalaxieDesCalculsPageModule),
		canActivate: [],
		isOse: false
	},
	{ path: "traces", loadChildren: () => import("./traces/traces.module").then(m => m.TracesPageModule), canActivate: [], isOse: false },
	{
		path: "test-connexion-bubble",
		loadChildren: () => import("./test-connexion-bubble/test-connexion-bubble.module").then(m => m.TestConnexionBubblePageModule),
		canActivate: []
	},
	{
		path: "narration",
		loadChildren: () => import("./page/narration/narration.module").then(m => m.NarrationPageModule),
		canActivate: [PremiumConnectionGuard],
		isOse: false
	},
	{
		path: "map",
		loadChildren: () => import("./page/map/map.module").then(m => m.MapPageModule),
		canActivate: [PremiumConnectionGuard],
		isOse: false
	},
	{
		path: "logbook",
		loadChildren: () => import("./page/logbook/logbook.module").then(m => m.LogbookPageModule),
		canActivate: [PremiumConnectionGuard],
		isOse: false
	},
	{
		path: "jeu-juste-point",
		loadChildren: () => import("./page/jeu-juste-point/jeu-juste-point.module").then(m => m.JeuJustePointPageModule),
		isOse: false
	},
	{
		path: "quizz",
		loadChildren: () => import("./page/quizz/quizz.module").then(m => m.QuizzPageModule),
		canActivate: [RgpdGuard],
		isOse: true
	},
	{
		path: "memory",
		loadChildren: () => import("./pages/memory/memory.module").then(m => m.MemoryPageModule),
		isOse: true
	},
	{
		path: "puzzle",
		loadChildren: () => import("./pages/puzzle/puzzle.module").then(m => m.PuzzlePageModule),
		isOse: true
	},
	{
		path: "territoire",
		loadChildren: () => import("./page/territoire/territoire.module").then(m => m.TerritoirePageModule),
		canActivate: [PremiumOseConnectionGuard],
		isOse: true
	},
	{
		path: "puzzle",
		loadChildren: () => import("./pages/puzzle/puzzle.module").then(m => m.PuzzlePageModule),
		isOse: true
	},
	{
		path: "coloriage",
		loadChildren: () => import("./pages/coloriage/coloriage.module").then(m => m.ColoriagePageModule),
		isOse: true
	},
	{
		path: "cours",
		loadChildren: () => import("./pages/cours/cours.module").then(m => m.CoursPageModule),
		isOse: true
	},
	{
		path: "search-words",
		loadChildren: () => import("./pages/search-words/search-words.module").then(m => m.SearchWordsPageModule),
		isOse: true
	},
	{
		path: "inscription",
		loadChildren: () => import("./pages/inscription/inscription.module").then(m => m.InscriptionPageModule),
		isOse: true
	},
	{
		path: "videos",
		loadChildren: () => import("./page/videos/videos.module").then(m => m.VideosPageModule),
		canActivate: [PremiumConnectionGuard]
	},
	{
		path: "test-bee",
		loadChildren: () => import("./pages/test-bee/test-bee.module").then(m => m.TestBeePageModule),
		isOse: true
	},
	{
		path: "waste-sorting",
		loadChildren: () => import("./page/waste-sorting/waste-sorting.module").then(m => m.WasteSortingPageModule),
		isOse: true
	},
	{
		...RoutingRules.landing
	},
	{
		path: "jeu-des-erreurs",
		loadChildren: () => import("./pages/jeu-des-erreurs/jeu-des-erreurs.module").then(m => m.JeuDesErreursPageModule),
		isOse: true
	},
	{
		path: "mathador",
		loadChildren: () => import("./page/mathador/mathador.module").then(m => m.MathadorPageModule)
	},
	{
		path: "photo-dome",
		loadChildren: () => import("./page/photo-dome/photo-dome.module").then(m => m.PhotoDomePageModule)
	},
	{
		path: "photo-dome-choice",
		loadChildren: () => import("./page/photo-dome-choice/photo-dome-choice.module").then(m => m.PhotoDomeChoicePageModule)
	},
	{
		path: "parcours-environnement",
		loadChildren: () =>
			import("./pages/parcours-environnement/parcours-environnement.module").then(m => m.ParcoursEnvironnementPageModule)
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(environment.kidaia ? routeskidaia : routes, {
			preloadingStrategy: PreloadAllModules
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
