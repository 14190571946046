import { ViewChild, ChangeDetectorRef, Directive } from "@angular/core";
import { ToolbarWithButtonsComponent } from "src/app/components/toolbar-with-buttons/toolbar-with-buttons.component";
import { Subscription } from "rxjs";
import { GlobalService } from "src/app/services/global.service";
import { PlayTTSService } from "src/app/services/play-tts.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Scenario } from "src/app/models/scenario";
import { environment } from "src/environments/environment";
import { EventsBasePage } from "../base/eventsbasepage.page";
import { AppUtils } from "src/app/app-utils";

@Directive()
export class ToolbarBasePage extends EventsBasePage{
	environment: {
		production: boolean;
		activityVersion: number;
		storyVersion: number;
		kidaia: boolean;
		bearerToken: string;
		ose: boolean;
		autolog: boolean;
		login: any;
		password: any;
	};
	constructor(
		public globalService: GlobalService,
		public ttsService: PlayTTSService,
		public cd: ChangeDetectorRef,
		public router: Router,
		public route: ActivatedRoute,
	) {
		super(globalService);
		this.environment = environment;
	}
	@ViewChild(ToolbarWithButtonsComponent, { static: true })
	toolbar: ToolbarWithButtonsComponent;

	// toolbar toggle input methods subscriptions
	public toolbarIMToggleSubscription: Subscription;

	// menu events:
	public menuOpen = false;
	public appMenusSubscription: Subscription;
	public appIdleSubscription: Subscription;
	public appIdleTimeOut: NodeJS.Timeout;
	allCallbacks = [];

	public scenario: Scenario;

	// resize
	waitingLandscapeModeEnding: boolean;
	public windowResizedSubscription: Subscription;

	public ionViewWillEnter() {
		this.initWindowResizeEventSubscription();
		super.ionViewWillEnter();
	}

	public ionViewDidEnter() {
		if (this.toolbar) {
			this.toolbar.setChangeDetector(this.cd);
		}
	}

	public ionViewWillLeave() {
		this.allCallbacks.forEach(sub => {
			sub = () => {};
		});
		this.allCallbacks = [];
		this.unsubscribeWindowResizeEventSubscription();
		super.ionViewWillLeave();
	}

	protectedCallback(promises: Array<Promise<any>>, callback) {
		return new Promise<void>((resolve, reject) => {
			const id = this.allCallbacks.push(callback) - 1; // save callback for deletion on ionViewWillLeave
			Promise.all(promises).then(
				async () => {
					callback = this.allCallbacks[id];
					if (callback) {
						// trigger callback and resolve
						await callback();
						resolve();
					}
				},
				() => {
					// if promise rejected don't trigger callback and reject
					reject();
				}
			);
		});
	}

	/**
	 * App Menu Open/Close Events subscription / unsubscription
	 * Stop/Resume STT / TTS playback + its callback if outside events happen
	 * (app menu / voice settings menu / player selection menu)
	 */
	initAppMenusSubscription() {
		if (this.appMenusSubscription) {
			this.appMenusSubscription.unsubscribe();
			this.appMenusSubscription = null;
		}
		this.appMenusSubscription = this.globalService.menuOpenEvent.subscribe({
			next: async v => {
				console.log("%c menuOpenEvent => " + "v.target = " + v.target, "v.open = " + v.open + "background: pink; color: black;");
				this.menuOpen = v.open;
				if (this.menuOpen) {
					// ON MENU OPEN
					this.onMenuToggle(this.menuOpen);
					if (this.ttsService.protectedTTSisPlaying) {
						this.ttsService.playTTSPWaiting = true;
						console.log("%cTTS INTERRUPTED BY " + v.target + " OPENING : ", "background: pink; color: black;");
					}
					await this.ttsService.killSpeech();
				} else {
					// ON MENU CLOSED
					if (!v.redirecting) {
						await this.ttsService.killSpeech();
						await this.globalService.waitLandscapeMode();
						if (this.globalService.runResizeOnMenuClose) {
							this.globalService.setGlobalLoading(true);
							// insert resize here
							this.globalService.setGlobalLoading(false);
							this.globalService.runResizeOnMenuClose = false;
						}
						if (this.scenario?.tutoCallbackSave) {
							this.scenario.runMathiaSpeechCore(this.scenario.tutoCallbackSave);
							this.scenario.tutoCallbackSave = null;
						} else if (this.ttsService.playTTSPWaiting) {
							this.ttsService.playTTSPWaiting = false;
							setTimeout(() => {
								this.ttsService.playTTSEventProtected(this.ttsService.currentTTSPText, this.ttsService.currentTTSPCallback);
							}, 200);
						}
						this.onMenuToggle(this.menuOpen);
					}
				}
			}
		});
	}
	/**
	 * additional method triggered on app menu closing/opening if additional actions are needed in child page
	 * @param status menu opened status
	 */
	onMenuToggle(status: boolean) {}
	unsubscribeMenuEventsSubscription() {
		if (this.appMenusSubscription) {
			this.appMenusSubscription.unsubscribe();
		}
	}

	/**
	 * App Idle Event subscription / unsubscription
	 * Stop/Resume STT or TTSEP playback + associated callback if app enter/exit idle or tab/window is out of focus/in focus
	 */
	initAppIdleSubscription() {
		if (this.appIdleSubscription) {
			this.appIdleSubscription.unsubscribe();
			this.appIdleSubscription = null;
		}
		console.log("%cthis.initAppIdleSubscription", "background: yellow; color: black");
		this.appIdleSubscription = this.globalService.appIdleEvent.subscribe({
			next: v => {
				console.log("this.setAppIdle(" + v + ")");
				this.setAppIdle(v);
			}
		});
	}
	unsubscribeinitAppIdleSubscription() {
		if (this.appIdleSubscription) {
			this.appIdleSubscription.unsubscribe();
			console.log("%cappIdleSubscription unsubscribed", "background: yellow; color: black");
		}
	}

	/**
	 * basic method called on app idle on/off used in pages that do not contain 3d render
	 * => kill & store TTSEP if playing on idle ON to relaunch it with its associated callback on idle OFF
	 * @param idle idle status on/off
	 * @param loaderStop stops the loader after restoring from idle
	 * @param afterResize
	 */
	async setAppIdle(idle: boolean, loaderStop = true, afterResize?: boolean): Promise<void> {
		return new Promise(async (resolve, reject) => {
			this.globalService.appIdle = idle;
			if (
				!idle &&
				!this.waitingLandscapeModeEnding &&
				!this.globalService.displayVoiceSettingsStatus &&
				!this.globalService.storySettingOpened
			) {
				// wait landscape mode before RESTORING FROM IDLE
				await this.globalService.waitLandscapeMode(true);
				afterResize = true;
				loaderStop = true;
			}
			if (idle && !this.globalService.toolbarMenuOpened.status && !this.globalService.waitingLandscapeMode) {
				// GOING IDLE
				AppUtils.debug("going idle");
				if (!environment.ose) {
					this.startLoader();
				}
				if (this.ttsService.protectedTTSisPlaying) {
					this.ttsService.playTTSPWaiting = true;
				}
				await this.ttsService.killSpeech();
				resolve();
			} else if (!idle && !this.globalService.toolbarMenuOpened.status && !this.globalService.waitingLandscapeMode) {
				// RESTORING FROM IDLE
				AppUtils.debug("resume from idle");
				setTimeout(async () => {
					if (this.scenario?.tutoCallbackSave) {
						if (!this.appIdleTimeOut) {
							this.appIdleTimeOut = setTimeout(() => {
								this.appIdleTimeOut = null;
								this.scenario.runMathiaSpeechCore(this.scenario.tutoCallbackSave);
								this.scenario.tutoCallbackSave = null;
							}, 500);
						}
					} else if (this.ttsService.playTTSPWaiting) {
						this.ttsService.playTTSPWaiting = false;
						if (!this.appIdleTimeOut) {
							this.appIdleTimeOut = setTimeout(() => {
								this.appIdleTimeOut = null;
								if (this.ttsService.currentTTSPText) {
									this.ttsService.playTTSEventProtected(this.ttsService.currentTTSPText, this.ttsService.currentTTSPCallback);
								}
							}, 500);
						}
					} else {
						this.stopLoader();
					}
					if (this.appIdleTimeOut) {
						await Promise.all([this.appIdleTimeOut]);
					}
					if (loaderStop) {
						await this.stopLoader();
					}
					resolve();
				}, 500);
			}
		});
	}

	/**
	 * Window Resize Events subscription / unsubscription (subscribed in base initActivity()
	 * after updateCabriPoisition ran once @ cabri init)
	 */
	async initWindowResizeEventSubscription() {
		console.log("%cinitWindowResizeEventSubscription", "background: yellow; color: black");
		this.unsubscribeWindowResizeEventSubscription();
		this.windowResizedSubscription = this.globalService.windowResizedEvent.subscribe({
			next: () => {
				if (
					!this.globalService.waitingLandscapeMode &&
					!this.waitingLandscapeModeEnding &&
					!this.globalService.displayVoiceSettingsStatus &&
					!this.globalService.storySettingOpened
				) {
					this.customOnResize();
				}
			}
		});
	}
	unsubscribeWindowResizeEventSubscription() {
		if (this.windowResizedSubscription) {
			this.windowResizedSubscription.unsubscribe();
			this.windowResizedSubscription = null;
		}
	}

	/**
	 * starts loader ON if window resized to trigger appIdle() & waitLandscapeMode() methods then stop the loader
	 * can be used for video related pages or other that needs to run actions on such event (pause/play video or else)
	 */
	async customOnResize(): Promise<void> {
		return new Promise(async resolve => {
			console.log("%ccustomOnResize() cabri.base ", "background: orange; color: black;");
			if (
				this.globalService.isLandscape ||
				(this.globalService.isBrowser && !this.globalService.isMobile && !this.globalService.isTablet)
			) {
				if (!this.globalService.isCordova) {
					await this.stopLoader();
				}
			} else {
				if (!this.globalService.isCordova && !this.globalService.toolbarMenuOpened.status) {
					await this.startLoader();
					this.setAppIdle(true);
					this.waitingLandscapeModeEnding = true;
					await this.globalService.waitLandscapeMode(true);
					await this.setAppIdle(false, false, true);
					await this.stopLoader();
					this.waitingLandscapeModeEnding = false;
					this.detectChanges();
				}
			}
			resolve();
		});
	}

	startLoader(adobe = null, globalLoader = true) {
		try {
			console.log("%cLOADER START", "background: red; color: black");
			if (this.globalService) {
				this.globalService.setGlobalLoading(true, adobe);
			}
			// this.detectChanges();
		} catch (error) {
			throw error;
		}
	}

	stopLoader() {
		try {
			console.log("%cLOADER STOP", "background: red; color: black");
			if (this.globalService) {
				this.globalService.setGlobalLoading(false);
			}
			// this.detectChanges();
		} catch (error) {
			throw error;
		}
	}

	detectChanges() {
		if (this.cd) {
			this.cd.detectChanges();
		}
	}

	/**
	 * Custom async setTimeOut for waiting end of exe
	 */
	async timeOut(ms, callback: any = null): Promise<void> {
		return new Promise((resolve, reject) => {
			if (this.isActivePage()) {
				setTimeout(() => {
					if (!this.isActivePage()) {
						reject("timeOut rejected on page leave");
					} else {
						if (callback) {
							callback();
						}
						resolve();
					}
				}, ms);
			} else {
				reject("timeOut rejected on page leave");
			}
		});
	}

	public isActivePage(): boolean {
		// console.error("initiator component "+this.constructor.name);
		// console.error("active component "+this.router['rootContexts'].contexts.get('primary').route.component.name);
		//console.error(this.route.routeConfig.component?.name);
		if (this.constructor.name === this.route.routeConfig.component?.name) {
			return true;
		} else {
			return false;
		}
	}
}
