
<div class="difficulty-button">
  <ng-container *ngFor="let currDif of difficulties">
    <ion-button [ngClass]="{'activated' : difficultySelected === currDif}"  (click)="setCurrentDifficulty(currDif)">
      <ion-icon slot="start" name="star"></ion-icon>
      <ng-container *ngIf="currDif !== OseDifficulty.easy">
        <ion-icon slot="start" name="star"></ion-icon>
        <ion-icon slot="start" name="star" *ngIf="currDif !== OseDifficulty.normal"></ion-icon>
      </ng-container>

      <span *ngIf="currDif === OseDifficulty.easy">Facile</span>
      <span *ngIf="currDif === OseDifficulty.normal">Moyen</span>
      <span *ngIf="currDif === OseDifficulty.hard">Difficile</span>
    </ion-button>
  </ng-container>

</div>