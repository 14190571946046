<ng-container *ngIf="currentColoring">
  <div class="title-container">
  </div>
  <div class="title-wrapper" *ngIf="!won">
    <app-ose-difficulty-selector (setDifficulty)="difficultyChanged($event, true)" [difficultySelected]="difficultySelected"
      [difficulties]="difficultiesAvailable"></app-ose-difficulty-selector>
    <p class="colorizedItems">Le nombre d'éléments coloriés {{counterItemsToColor}}/{{totalItemsColored}}</p>
    <ion-button class="skip-button" shape="round" (click)="nextDrawing()"
      *ngIf="!environment.production || accountService.team[0].preview">{{accountService.team[0].preview?"Passer":"Dessin
      suivant"}}</ion-button>
  </div>

  <pinch-zoom #pinch [properties]="pZProperties">
    <div id="svg-wrapper" #svgElement></div>
  </pinch-zoom>
</ng-container>

<div id="nav-wrapper" *ngIf="!currentColoring && cabriService.coloring">
  <nav>
    <ul>
      <h2>Liste des coloriages</h2>
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrer">
      </mat-form-field>
      <mat-table [dataSource]="dataSource">
        <!-- Position Column -->
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
        </ng-container>


        <!-- Image Column -->
        <ng-container matColumnDef="image">
          <mat-header-cell *matHeaderCellDef> Image </mat-header-cell>
          <mat-cell *matCellDef="let element"> <ion-img src="{{element.image}}" style="width:100px"></ion-img></mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> Titre </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.title | titlecase}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="loadSVG(row.id)"></mat-row>
      </mat-table>
    </ul>
  </nav>
</div>