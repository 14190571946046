import { HttpClient } from "@angular/common/http";
import { Injectable, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { ReplaySubject, lastValueFrom } from "rxjs";
import { environment } from "src/environments/environment";
import { FicheEtapes } from "../components/fiche/fiche.component";
import { LrsUtils } from "../models/lrs/lrsUtils";
import { OseActivity } from "../models/ose-activities";
import { OseCompetencies } from "../models/ose-competencies";
import { OseMap } from "../models/ose-maps";
import { Ose2Journey, OseExerciceType } from "../models/ose2-journey";
import { POI } from "../models/poi";
import { AccountService } from "./account.service";
import { BaseService } from "./base.service";
import { CabriDataService } from "./cabri-data.service";
import { GlobalService } from "./global.service";
import { LmsService } from "./lms.service";
import { AudioService } from "./audio.service";
import { LocalStorageService } from "./local-storage-service";
import { OseStatsService } from "./ose-stats.service";
import { ClassService } from "./class.service";
import { AppUtils } from "../app-utils";
import { ActivityLinkUserOpenSteam } from "../models/activity-opensteam";
import { cloneDeep } from 'lodash';
@Injectable({
	providedIn: "root"
})
export class OseJourneyService extends BaseService {
	journeys: Ose2Journey[];
	public currentJourney: Ose2Journey;
	public currentMap: Ose2Journey | OseMap;
	oseActivities: OseActivity[];
	oseCompetencies: OseCompetencies[]; // @TODO another service
	public allJourneysLoaded: ReplaySubject<boolean> = new ReplaySubject(1);
	public allAssignationsLoaded: ReplaySubject<boolean> = new ReplaySubject(1);
	selectedPOIActivity:POI
	public launchExercise = new EventEmitter<OseActivity>();
	public exerciseEnd = new EventEmitter<void | boolean>();
	public exercisePrev = new EventEmitter<void | boolean>();
	public feedbackEnd = new EventEmitter<Event>();
	newCompletion: boolean;
	isPlaying: boolean;
	savedFeedback: any;
	initialized = false;
	tooltipsForWordSearch: Array<string> = [];
	assignations: Array<{ uid: string; activities: Array<ActivityLinkUserOpenSteam> }> = [];
	assignationTeacherModalClosed:boolean = false;

	tooltipOncePerJourney:Array<string> = [];

	public oseMapsList:OseMap[] = new Array();
	constructor(
		public http: HttpClient,
		public router: Router,
		public accountService: AccountService,
		public cabriService: CabriDataService,
		public lmsService: LmsService,
		public globalService: GlobalService,
		public audioService: AudioService,
		public localStorageService: LocalStorageService,
		public oseStats: OseStatsService,
		public classeService: ClassService
	) {
		super();

		if (environment.ose || this.globalService.isPageOse) {
			this.init();
		}
	}

	init() {
		if (!this.initialized) {
			this.initialized = true;

			this.exerciseEnd.subscribe({
				next: data => {
					this.launchNextJourneyExercise(data);
				}
			});

			this.exercisePrev.subscribe({
				next: data => {
					this.launchPrevJourneyExercise();
				}
			});

			this.getAllJourneys();
			this.getAssignations();
			window.addEventListener("popstate", () => {
				if (this.currentJourney) {
					// enable to go back to the last journey exercise when browser return back is clicked
					const currExercise = this.currentJourney.getCurrentExercise();
					if (currExercise) {
						const currExerciseIndex = this.currentJourney.exercises.findIndex(ex => {
							return Number(ex.id) === Number(currExercise.id);
						});

						if (this.currentJourney.exercises[currExerciseIndex - 1]) {
							this.currentJourney.exercises[currExerciseIndex - 1].completed = false;
						}
					}
				}
			});
		}
	}

	getUserAssignation(uid) {
		return this.assignations.find(a => a.uid === uid);
	}
	hasAssignations(uid: string) {
		const assignations = this.getUserAssignation(uid);
		return assignations?.activities.some(a => a.correction !== 2);
	}

	async getAssignations() {
		await this.journeysLoaded();
		await lastValueFrom(this.accountService.isUserLoaded);
		this.classeService.getAssignationList("").subscribe((data: any) => {
			console.error("getAssignations",data);
			if(Array.isArray(data)){
				data.forEach((c: any) => {
					c.students.forEach(s => {
						let studentAssignations = this.assignations.find(a => a.uid === s.user.id);
						if (!studentAssignations) {
							studentAssignations = { uid: s.user.id, activities: [] };
							this.assignations.push(studentAssignations);
						}
						s.activities.forEach((a: ActivityLinkUserOpenSteam) => {
							if (a.activity.type === "journeyKidaia") {
								a.classroomLink = c.classroom.link;
								a.activity.contentOse = JSON.parse(AppUtils.decodeHTMLEntities(a.activity.content));
								studentAssignations.activities.push(a);
							}
						});
					});
				});
	
				this.allAssignationsLoaded.next(true);
			}
		});
	}
	getAllPOI(): Promise<POI[]> {
		return new Promise<POI[]>(async resolve => {
			const allMaps = await this.getMaps();
			await this.journeysLoaded();
			this.http.get(this.postApiKidaia + "?action=getPOI").subscribe({
				next: async (listePoi: POI[]) => {
					listePoi.forEach((poiElement, index) => {
						listePoi[index] = new POI(poiElement, allMaps, this.oseCompetencies);
					});
					resolve(listePoi);
				},
				error: error => {
					console.error("An error occured while recovering POI", error);
				}
			});
		});
	}



	public getMaps(): Promise<OseMap[]> {
		return new Promise<OseMap[]>((resolve,reject) => {
			if(this.oseMapsList?.length > 0){
				resolve(this.oseMapsList)
			}else{
				this.http.get(this.postApiKidaia + "?action=getOseMaps").subscribe({
					next: (oseMaps: OseMap[]) => {
						this.oseMapsList = oseMaps
						resolve(oseMaps);
					},
					error: error => {
						console.error("An error occured while recovering ose maps", error);
						reject(true)
					}
				});
			}
		});
	}

	private async getAllJourneys() {
		await Promise.all([this.getOseCompetencies(), this.getAllActivities(), this.cabriService.getAllFiches()]);
		this.http.get(this.postApiKidaia + `?action=getOseJourneys`).subscribe({
			next: async (oseJourneys: any[]) => {
				await this.cabriService.getAllQuizz();
				oseJourneys.forEach(j => {
					j.exerciseIds = j.exerciseIds.concat(j.quizz);
				});
				oseJourneys.forEach((element, index, currItem) => {
					currItem[index] = new Ose2Journey(element, this.oseCompetencies, this.oseActivities);

					currItem[index].exercises.forEach((currEx, indEx) => {
						if (currEx && currItem[index] && currItem[index].quizz) {
							const isEvaluation = currItem[index].quizz?.some((quizz, indQuizz) => {
								return Number(quizz.id) === Number(currEx.id);
							});
							if (isEvaluation) {
								currEx.isevaluation = "1";
							}
						}
						if (!currEx) {
							currItem[index].exercises.splice(indEx, 1);
						}
					});
				});
				this.journeys = oseJourneys;
				// create of conclusion fiche element

				this.journeys.forEach(j => {
					let addedConclusionCount = 0;
					let nextType;
					let currentType;
					this.addFicheData(j);

					const getSeqExercisesIndexes = j.exercises
						.map((elm, idx) => {
							currentType = elm.type;
							nextType = j.exercises[idx + 1]?.type;
							// Do not add steps between two exercises except for the following two quizzes
							const notAddStepBtwnExercises =
								(!nextType && currentType !== OseExerciceType.fiche) ||
								(currentType !== OseExerciceType.fiche && nextType === OseExerciceType.fiche) ||
								(currentType === OseExerciceType.quizz && nextType === OseExerciceType.quizz);

							const nextIsSeq =
								(elm.isworkshop === "1" && j.exercises[idx + 1]?.isworkshop !== "1") ||
								(j.exercises[idx + 1]?.isevaluation === "1" && elm.isevaluation !== j.exercises[idx + 1]?.isevaluation);
							if (nextIsSeq) {
								return { index: idx, type: FicheEtapes.titleNextSeq };
							}
							if ((notAddStepBtwnExercises || elm.hasvideo === "1") && idx !== j.exercises?.length - 1) {
								if (!nextIsSeq) {
									return { index: idx, type: FicheEtapes.titleConclusion };
								}
							} else {
								return "";
							}
						})
						.filter(String);
					getSeqExercisesIndexes.forEach((item: any) => {
						const startExIndexInsert = item.index + addedConclusionCount + 1;
						j.exercises.splice(startExIndexInsert, 0, {
							...j.createNewEtape(item.type)
						});
						addedConclusionCount++;
					});
				});
				this.journeys.forEach(j => {
					j.exercises.forEach(ex => {
						if (ex.type === OseExerciceType.quizz && this.cabriService.quizz) {
							// replace ose activities quizz type title by quizz title
							const quizz = this.cabriService.quizz.find(currQuizz => currQuizz.id === ex.id);
							if (quizz) {
								ex.title = quizz.title;
							}
						}
					});
				});
				this.allJourneysLoaded.next(true);
			},
			error: error => {
				this.allJourneysLoaded.next(false);
				console.error("An error occured while recovering ose journeys", error);
			}
		});
	}

	addFicheData(j) {
		j.exercises.forEach((currEx, index, currArray) => {
			if (currEx.type === OseExerciceType.fiche) {
				const fiche = this.cabriService.fiches.find(f => Number(f.id) === Number(currEx.id));
				if (fiche) {
					currArray[index].titre_sommaire = fiche.titre_sommaire;
				}
			}
		});
		let lastSubpart = null;
		j.exercises.forEach((e, index) => {
			// define table of content
			if (e.isEtape || e.type !== OseExerciceType.fiche) {
				// steps and games are not displayed
				e.displayInModal = false;
			} else {
				if (index > 0) {
					// check if title is different from last fiche's title
					const previousFicheIndex = this.getPreviousFiche(j.exercises, index);
					e.displayInModal = j.exercises[previousFicheIndex].titre_sommaire !== e.titre_sommaire;
				} else {
					// first element is always displayed
					e.displayInModal = true;
				}
				if (e.displayInModal) {
					// new subpart
					e.subpart = {
						hasGame: false,
						hasQuiz: false,
						hasVideo: false
					};
					lastSubpart = e;
				}
			}
			// check content of each subpart
			if (lastSubpart) {
				if (e.hasvideo) {
					lastSubpart.hasSubpart = lastSubpart.subpart.hasVideo = true;
				}
				if (e.type === OseExerciceType.quizz) {
					lastSubpart.hasSubpart = lastSubpart.subpart.hasQuiz = true;
				}
				if (e.type !== OseExerciceType.fiche && e.type !== OseExerciceType.quizz) {
					lastSubpart.hasSubpart = lastSubpart.subpart.hasGame = true;
				}
			}
		});
	}

	private getPreviousFiche(exercises, currentIndex) {
		for (let i = currentIndex - 1; i > -1; i--) {
			if (!exercises[i].isEtape && exercises[i].type === OseExerciceType.fiche) {
				return i;
			}
		}
		return 0;
	}

	public getOseCompetencies() {
		return new Promise<void>(resolve => {
			this.http.get(this.postApiKidaia + "?action=getOseCompetencies").subscribe({
				next: (oseCompetencies: OseCompetencies[]) => {
					this.oseCompetencies = oseCompetencies;
					resolve();
				},
				error: error => {
					console.error("An error occured while recovering ose competencies", error);
				}
			});
		});
	}

	// thematique
	public journeysLoaded(): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			if (this.journeys) {
				resolve();
			} else {
				this.allJourneysLoaded.subscribe(loaded => {
					if (loaded) {
						resolve();
					}
				});
			}
		});
	}

	async getAllActivities() {
		return new Promise<void>(resolve => {
			this.http.get(this.postApiKidaia + "?action=getOseActivities").subscribe({
				next: (oseActivities: OseActivity[]) => {
					this.oseActivities = oseActivities;
					resolve();
				},
				error: error => {
					console.error("An error occured while recovering Activities", error);
				}
			});
		});
	}

	public getById(journeyId: number) {
		return this.journeys.find(journey => {
			return Number(journey.id) === journeyId;
		});
	}

	/**
	 * Initialize exercises statuses after completion of all of them
	 */
	public initializeStatuses(currJourney?: Ose2Journey) {
		if (currJourney) {
			currJourney.skippedMode = false;
			currJourney.started = false;
			currJourney.exercises.forEach(ex => {
				ex.completed = false;
			});
		} else {
			this.journeys.forEach(journey => {
				journey.started = false;
				journey.skippedMode = false;
				journey.exercises.forEach(ex => {
					ex.completed = false;
				});
			});
		}
	}

	public initializeJourney() {
		this.currentJourney = null;
		this.currentMap = null;
		if (this.journeys) {
			this.journeys.forEach(j => {
				j.exercises.forEach(jEx => {
					jEx.alreadyCompleted = false;
				});
			});
		}

		this.initializeOseStats();
	}

	public initializeOseStats() {
		this.oseStats.journeysStatus = null;
		this.oseStats.exercisesStatus = null;
		this.oseStats.completedExercises = null;
		this.oseStats.currentCompletedJourneys = null;
		this.oseStats.onGoingJourneys = null;
	}

	public getCurrentExerciseId() {
		if (this.currentJourney) {
			return this.currentJourney.getCurrentExercise().id;
		} else {
			return null;
		}
	}

	async modalQuitJourney() {
		const confirmAnswer = await this.globalService.simpleAlert(
			`Veux-tu vraiment quitter cette activité ?`,
			[
				{ text: $localize`Quitter`, value: true },
				{ text: $localize`Continuer`, value: false }
			],
			"alertConfirm"
		);
		if (confirmAnswer) {
			this.quitJourney();
		}
	}

	quitJourney() {
		this.tooltipsForWordSearch = [];
		this.audioService.playCancelSound();
		this.isPlaying = false;
		if (this.currentJourney) {
			this.currentJourney.selectedCategoryType = null;
			this.currentJourney.currentCategoryExercise = new Array();
		}
		this.router.navigateByUrl("/territoire", {
			replaceUrl: true
		});
	}

	/**
	 * Verify if new journey is completed
	 */
	public isNewJourneyCompleted() {
		return (
			Array.isArray(this.oseStats.journeysStatus) &&
			Array.isArray(this.oseStats.prevCompletedJourneys) &&
			this.oseStats.currentCompletedJourneys.length !== this.oseStats.prevCompletedJourneys.length
		);
	}

	/**
	 * Launch next exercise of jouney when completed
	 * @return lrs statement not send at the end of the activity
	 */
	launchNextJourneyExercise(lrsError: boolean = false, startFromBeginning = false, isLaunchingJourney = false) {
		if (this.currentJourney) {
			const previousExercise = this.currentJourney.getCurrentExercise();
			if (previousExercise) {
				this.currentJourney.setExerciseCompleted(previousExercise.id);
			} else {
				this.tooltipOncePerJourney = [];
				LrsUtils.durationStartJourney = new Date().getTime();
				if (this.currentJourney.lrsResume) {
					const resumesSeqSessionId = this.oseStats.sequencesTraces.find(seqTraces => {
						return seqTraces.parcoursId === this.currentJourney.id;
					})?._id;
					if (resumesSeqSessionId) {
						this.currentJourney.idJourneySession = resumesSeqSessionId;
					} else {
						this.currentJourney.idJourneySession = LrsUtils.generateUniqueSessionId(this.accountService, false, this.lmsService);
					}
				} else {
					this.currentJourney.idJourneySession = LrsUtils.generateUniqueSessionId(this.accountService, false, this.lmsService);
				}
			}

			if (this.currentJourney.isCompleted(false)) {
				if (!lrsError) {
					const areAllCompleted = this.currentJourney.exercises.filter(ex => !ex.isEtape)?.every(ex => ex.completed);
					this.newCompletion = areAllCompleted;
				}
				if (this.currentJourney.assignation_id) {
					const currentUser = this.accountService.team[0];
					const myAssignations = this.getUserAssignation(currentUser.id).activities;
					const currentAssignation = myAssignations.find(assignation => {
						return assignation.id === this.currentJourney.assignation_id;
					});
					if (currentAssignation){
						currentAssignation.correction = 2;
						this.updateOpenSteamActivityStatusResponse(currentAssignation);
					}
				}
				this.initializeStatuses();
				this.quitJourney();
			} else {
				const getNextExercise = () => {
					// Make sure that the first exercise from launching page(territoire) is not etape type
					let nextExercise = this.currentJourney.getCurrentExercise(startFromBeginning);
					if (isLaunchingJourney && nextExercise.isEtape) {
						nextExercise.completed = true;
						nextExercise = this.currentJourney.getCurrentExercise(startFromBeginning);
					}
					return nextExercise;
				};
				this.newCompletion = false;
				this.currentJourney.started = true;
				const nextExercise = getNextExercise();
				this.currentJourney.selectedCategoryType = this.currentJourney.getSelectedCategoryType(nextExercise);
				localStorage.setItem("oseJourney", JSON.stringify(this.currentJourney));
				if (previousExercise && previousExercise.type === nextExercise.type) {
					this.launchExercise.next(nextExercise);
				} else {
					const url = this.currentJourney.getExerciseUrlByType(nextExercise.type);
					this.router.navigateByUrl("/" + url,{
						replaceUrl: this.globalService.lowPerformanceMode						
					}).then(() => {
						this.isPlaying = true;
					});
				}
			}
		}
	}

	/**
	 * Launch prev exercise of jouney when completed
	 * @return lrs statement not send at the end of the activity
	 */
	launchPrevJourneyExercise() {
		if (this.currentJourney) {
			const currentExercise = this.currentJourney.getCurrentExercise();
			let previousExercise = this.currentJourney.getPrevExercise();
			if (previousExercise) {
				previousExercise.completed = false;

				if (previousExercise.isEtape) {
					previousExercise = this.currentJourney.getPrevExercise();
					previousExercise.completed = false;
				}

				if (currentExercise && currentExercise.type === previousExercise.type) {
					localStorage.setItem("oseJourney", JSON.stringify(this.currentJourney));
					this.launchExercise.next(previousExercise);
				} else {
					const url = this.currentJourney.getExerciseUrlByType(previousExercise.type);
					this.router.navigateByUrl("/" + url,{
						replaceUrl: this.globalService.lowPerformanceMode						
					}).then(() => {
						this.isPlaying = true;
					});
				}
			}
		}
	}

	addTooltipForWordSearch(title: string) {
		// if only one word in title and not already in list
		if (title.trim().split(" ").length === 1 && this.tooltipsForWordSearch.indexOf(title) === -1) {
			this.tooltipsForWordSearch.push(title);
		}
	}

	/**
	 * Verify if journey is stored in indexDB if it is stored, retrieve the journey and return the first exercise that needs to be completed.
	 * @returns OseACtivity
	 */
	public async recoverLSJourneyWithCurrentEx(): Promise<OseActivity> {
		if (!this.currentJourney) {
			const journeyStoredProgression = JSON.parse(localStorage.getItem("oseJourney"));
			if (journeyStoredProgression) {
				this.currentJourney = journeyStoredProgression;
				this.currentJourney = new Ose2Journey(this.currentJourney);
				this.currentJourney.started = true;
				this.currentJourney.lrsResume = true;
				const ex = this.currentJourney.getCurrentExercise();
				this.isPlaying = true;
				try {
					await this.oseStats.sequencesProgression();
				} catch (err) {
					console.error("some stats include errors");
				}
				this.currentJourney.calculateSessionExerciseCompletionPercentage(this.oseStats.sequencesTraces);
				return ex;
			}
		}
	}

	/**
	 * return true if new quizz or a journey is completed
	 */
	public get isNewCompletion(): boolean {
		return this.newCompletion || this.currentJourney?.isQuizzTypeCompleted;
	}

	/**
	 * Create student response to activity
	 * @param activityLinkUser
	 * @param correction 0 : init 1 : evaluated 2 corrected if not use correction inside activitylink
	 * @param note 4 : non noté, 0-3 a revoir -> tres bien
	 * @param response repose de leleve string
	 * @returns Promise<ActivityLinkUserOpenSteam>
	 */
	updateOpenSteamActivityStatusResponse(activityLinkUser: ActivityLinkUserOpenSteam, correction?: number, note = 4, response?: string) {
		const formData = new FormData();
		formData.append("id", activityLinkUser.activity.id.toString());
		formData.append("activityLinkUserId", activityLinkUser.id.toString());
		formData.append(
			"correction",
			correction ? correction.toString() : activityLinkUser.correction !== null ? activityLinkUser.correction.toString() : ""
		);
		formData.append("classroomLink", activityLinkUser.classroomLink);
		formData.append("note", note.toString());
		formData.append("response", response ? response : "");
		return lastValueFrom(
			this.http.post<ActivityLinkUserOpenSteam>(
				this.postOpenSteam + "/?controller=newActivities&action=save_new_activity",
				formData,
				{
					withCredentials: true
				}
			)
		);
	}



	/**
	 * Set journey exercises in order to resume
	 */
		setJourneyExercisesStatuses() {
			const checkIsAlreadyDone = (journeyId:number,exIdToCompare:number) => {
				return this.oseStats.completedExercises.some(completedEx => {
					if(completedEx?.associatedJourneys){
						// Verify the completion status of an exercise within its journey
						// without taking into account a different journey that may also include the same exercise
						return completedEx.id === exIdToCompare && completedEx?.associatedJourneys?.some((currJId:number) => {
							return currJId === journeyId;
						})
					}else{
						return completedEx.id === exIdToCompare
					}
					
				});
			}
			// resumed activity is conclusion(fiche)
			this.journeys.forEach((j,journeyIndex,currJourney) => {
				j.exercises.forEach((ex, indexEx) => {
					ex.subPartProgression = false;
					ex.selected = false;
					if(ex.hasSubpart){
						/**
						 *  Verify if all exercises contained in a subpart have been done at least once and set the variable alreadyCompleted to True of the exercise and subpart
						 */
						let stopIndex = j.exercises.findIndex((ex,ind) => ex.hasSubpart && ind > indexEx); 
						if(stopIndex === -1){
							// until last index of workshop
							stopIndex = j.exercises.findIndex((currEx) => currEx.id === j.quizz?.[0]?.id);
						}
						// filter and keep all contained excercises of subparts
						const currentSubPartActivities = j.exercises.slice(indexEx + 1, stopIndex)?.filter(ex => !ex.isEtape && !ex.displayInModal) 
						if(currentSubPartActivities?.length > 0){
							// Verify if the exercises have been completed at least once
							const isAllSubPartsDone = currentSubPartActivities.every((currEx) => {
								return checkIsAlreadyDone(j.id,currEx.id)
							})
	
							if(isAllSubPartsDone){
								currentSubPartActivities.forEach((currSubPart) => currSubPart.alreadyCompleted = true)
								ex.alreadyCompleted = true;
							}
						}else{
							// subpart no contains any exercises
							j.exercises[indexEx].alreadyCompleted = checkIsAlreadyDone(j.id,ex.id)
						}
					}else{
						j.exercises[indexEx].alreadyCompleted = checkIsAlreadyDone(j.id,ex.id)
					}
	
					if(ex?.type !== OseExerciceType.fiche){
						j.exercises[indexEx].alreadyCompleted = checkIsAlreadyDone(j.id,ex.id)
					}
				});
					// all exercises of journey done so set the completed status differently from alreadyCompleted
					this.oseStats.sequencesTraces.forEach(journeyTracesProgression => {
							if (j.id === journeyTracesProgression.parcoursId) {
								const lastExercise = journeyTracesProgression.exercises_progressions[journeyTracesProgression.exercises_progressions.length - 1];
								const beforeLast = journeyTracesProgression.exercises_progressions[journeyTracesProgression.exercises_progressions.length - 2];
								if (lastExercise) {
									const lastExToDoIndex = j.exercises.findIndex(journeyEx => {
										return Number(journeyEx.id) === Number(lastExercise.id);
									});
									if(beforeLast && (lastExercise.completed || beforeLast.completed && lastExercise.id === beforeLast.id)){
										if (lastExToDoIndex > -1) {
											for (let i = 0; i <= lastExToDoIndex; i++) {
												const currEx = currJourney[journeyIndex].exercises[i];
												currEx.completed = true;
											}
										}
									}else{
										if (lastExToDoIndex > -1) {j
											for (let i = 0; i < lastExToDoIndex; i++) {
												const currEx = currJourney[journeyIndex].exercises[i];
												currEx.completed = true;
											}
										}
									}
	
									if(lastExToDoIndex > -1){
										this.getLatestSubPartInProgress(j,lastExToDoIndex);
									}
								}
							}
					});
				j.startedProgression = j.exercises.some((currEx) => currEx.completed);
			});
		}
	
		/**
		 * Add subPartProgression to the current subPart that currently being played by the user
		 */
		public getLatestSubPartInProgress(j,currentExerciseIndex):void{
			for (let i = currentExerciseIndex; i >= 0; i--) {
				const currentExercise = j.exercises[i];
				let isQuizz = false;
				if(j.quizz){
					isQuizz = j.quizz.some((currentQuizz) => currentQuizz.id === currentExercise.id)
				}
				 if(currentExercise?.hasSubpart || isQuizz){
					currentExercise.subPartProgression = true;
					break;
				}
			}
		}
}



