import { Component, OnInit, Input, ElementRef, QueryList, ViewChildren, Renderer2, ChangeDetectorRef } from "@angular/core";
import { AlertController, ModalController, Platform } from "@ionic/angular";
import { AccountService, userAlertActions } from "src/app/services/account.service";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { Student } from "src/app/models/student";
import { GlobalService } from "src/app/services/global.service";
import { AudioService } from "src/app/services/audio.service";
import { AppUtils } from "src/app/app-utils";
import { Classroom } from "src/app/models/classroom";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { ClassService } from "src/app/services/class.service";
import { cloneDeep } from "lodash";
import { NetworkService } from "src/app/services/network.service";
import { AppLanguage } from "src/app/models/enums/enum-list";

@Component({
	selector: "app-modal-activity-participants",
	templateUrl: "./modal-activity-participants.component.html",
	styleUrls: ["./modal-activity-participants.component.scss"]
})
export class ModalActivityParticipantsComponent implements OnInit {

	@ViewChildren("editStudentIcon") editStudentIcon: QueryList<ElementRef>;
	@ViewChildren("removeStudentIcon") removeStudentIcon: QueryList<ElementRef>;

	@Input() students: Array<Student>;
	@Input() team: Array<any>;
	@Input() teamsCopy: Array<any>;
	@Input() teams: Array<any>;
	@Input() maxPlayer: number;
	@Input() keepTeam: boolean;
	@Input() teamAlreadyFull = false;
	@Input() maxKidaiaAccount: number;
	@Input() spinner: { status: boolean; text: string };
	@Input() classes: Classroom[];
	@Input() updateEvent: Subject<Student[]>;
	@Input() updateSpinnerEvent: Subject<{ status: boolean, text: string }>;
	@Input() removeStudentCallback = (student: Student): Promise<void> => {
		return null;
	};

	@Input() createStudentCallback = (student: Student): Promise<void> => {
		return null;
	};
	@Input() editStudentCallback = (student: Student): Promise<void> => {
		return null;
	};

	public formLog = {
		email: "",
		password: "",
		error: ""
	};
	public placeholderName = $localize`Prénom`;
	public placeholderLevel = $localize`Niveau`;
	public placeholderEmail = $localize`Email`;
	public placeholderPassword = $localize`Mot de passe`;


	public showStudents = true;
	studentParticipate = new Array();
	public student: Student;
	public editMode = false;
	editionStudent: Student;
	modalAlertDisplay: boolean;
	login: boolean;
	public userAlertActions: any;
	public alertTitle = $localize`Êtes-vous sûr de vouloir supprimer ce joueur ?`;
	studentNameValidation: any[];
	elementFocused: string;
	editModeAllowed: boolean;
	public get AppLanguage(){
		return AppLanguage
	}

	constructor(
		public modalCtrl: ModalController,
		public accountService: AccountService,
		public platform: Platform,
		public cabriDataService: CabriDataService,
		public globalService: GlobalService,
		public audioService: AudioService,
		private renderer: Renderer2,
		private alertController: AlertController,
		public cd: ChangeDetectorRef,
		public classService: ClassService,
		public networkService: NetworkService
	) {
		this.userAlertActions = userAlertActions;
	}

	ngOnInit() {
		this.editModeAllowed = this.isAllowedToEdit();
		if(this.editModeAllowed){
			 this.editMode = this.students?.filter(student => !student.selected)?.length === 0
		}
		this.updateEvent.subscribe((students) => {
			this.students = students;
			this.editionStudent = null;
			this.cd.detectChanges();
			if(this.students.length === 0){
				this.editMode = true;
			}
		})
		this.updateSpinnerEvent.subscribe((spinner) => {
			this.spinner = spinner;
		});
	}

	isAllowedToEdit() {
		return (!this.classService.loggedFromHome 
			&& (!this.accountService.user.codeClasse || this.accountService.user.loggedFromWp) 
			&& !this.globalService.inIframe 
			&& this.networkService.isConnected
			&& !this.accountService.isDemoAccount())
	}
	
	ionViewWillEnter() { }

	tabs(type, $event) {
		this.audioService.playSelectSound();
		this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget });
		this.showStudents = type === "students";
	}

	/**
	 * Add a group containing multiple students
	 * @param teamSelected
	 */
	selectCurrentTeam(teamSelected) {
		const studentSelectedFromGroup = new Array();
		this.students.forEach(student => {
			teamSelected.forEach(currentTeam => {
				if (student.id === currentTeam.teamId) {
					student.selected = true;
					studentSelectedFromGroup.push(student);
				}
			});
		});

		this.modalCtrl.dismiss({
			dismissed: true,
			team: this.team,
			studentSelectedFromGroup
		});
	}

	addStudent(student: Student) {
		const userAlreadyInTeam = this.accountService.team.some((tStudent) => tStudent.id === student.id);
		if(!userAlreadyInTeam){
			this.audioService.playSelectSound();
			if (this.team.length < this.maxPlayer) {
				if (!this.keepTeam) {
					student.selected = true;
				}
				this.student = student;
				this.team.push(student);
			} else {
				this.teamAlreadyFull = true;
			}
	
			this.modalCtrl.dismiss({
				dismissed: false,
				team: this.team,
				student,
				teamAlreadyFull: this.teamAlreadyFull
			});
		}
	}

	dismiss($event) {
		this.audioService.playCancelSound();
		this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget });
		this.modalCtrl.dismiss({
			dismissed: true,
			team: this.team,
			student: this.student
		});
	}

	/**
	 * Enter studentEdition mode (hide other cards & allows to change player's name & level)
	 */
	async editStudent(student: Student, i, $event) {
		$event.stopPropagation();
		// await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget }, 150);
		const el = this.editStudentIcon.toArray()[i];
		this.renderer.removeClass(el.nativeElement, "managePlayerIcon");
		this.renderer.addClass(el.nativeElement, "managePlayerIconClicked");
		await AppUtils.timeOut(150);
		this.editionStudent = cloneDeep(student);
	}

	/**
	 * Triggers an Alert to confirm player profile deletion
	 */
	async confirmStudentRemoval(student: Student, i, $event): Promise<void> {
		$event.stopPropagation();
		// await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget }, 150);
		console.log(this.removeStudentIcon);
		console.log(i);
		console.log(this.removeStudentIcon.toArray());
		const el = this.removeStudentIcon.toArray()[i];
		this.renderer.removeClass(el.nativeElement, "removePlayerIcon");
		this.renderer.addClass(el.nativeElement, "removePlayerIconClicked");
		await AppUtils.timeOut(150);
		this.editionStudent = cloneDeep(student);

		this.modalAlertDisplay = true;
		// this.removeStudent(student);
	}

	alertDemandRemoveStudent($event) {
		if (!$event) {
			this.modalAlertDisplay = false;
			this.dismissLogin();
		} else {
			this.modalAlertDisplay = false;
			this.login = true;
		}
	}

	async dismissLogin($event?) {
		if ($event) {
			await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget }, 150);
		}
		this.login = false;
		this.editionStudent = null;
		this.editMode = false;
	}

	/**
	 * Save modifications done in studentEdition mode calling the editStudentCallback method in component input
	 */
	async confirmStudentEdition(student, $event = null) {
		await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget }, 150);
		this.studentNameValidation = this.creationEditionFormValidation();
		if (this.studentNameValidation.length === 0) {
			this.editStudentCallback(student)
				.then(() => {
					this.editionStudent = null;
					this.editMode = false;
				})
				.catch(err => {
					console.error("error update", err);
					this.studentNameValidation.push($localize`Une erreur s’est produite, veuillez réessayer ultérieurement.`);
					this.displayFormError();
				});
		} else {
			this.displayFormError();
		}
	}

	/**
	 * Edition / Creation student name field validation
	 */
	creationEditionFormValidation() {
		const formError = new Array();
		// Verify if another student with the same first name exist
		let studentWithSameFirstNameExist = false;
		if (this.accountService.allStudents.length >= 1) {
			let found;
			if (this.editMode) {
				found = this.accountService.allStudents.some((student: Student) => {
					return (
						Number(student.id) !== Number(this.editionStudent.id) &&
						student.name.toLowerCase() === this.editionStudent.name.toLowerCase()
					);
				});
			} else {
				found = this.accountService.allStudents.some((student: Student) => {
					return student.name.toLowerCase() === this.editionStudent.name.toLowerCase();
				});
			}

			if (found) {
				studentWithSameFirstNameExist = true;
			}
		}

		if (studentWithSameFirstNameExist) {
			formError.push($localize`Ce prénom est déjà utilisé.`);
		}

		if (this.editionStudent.name.length < 3) {
			formError.push($localize`Le champ prénom doit comporter au moins 3 caractères.`);
		}

		const regexName = /^[a-zA-ZÀ-ÿ-. ]*$/;
		// check if a string contains at least one number
		const regexNumber = /\d/;
		if (regexNumber.test(String(this.editionStudent.name).toLowerCase())) {
			formError.push($localize`Le champ prénom ne peut contenir que des lettres.`);
		} else if (!regexName.test(String(this.editionStudent.name).toLowerCase())) {
			formError.push($localize`Le champ prénom ne doit pas comporter des caractères spéciaux.`);
		}

		if (!this.editionStudent.classe || !this.editionStudent.classe.id) {
			formError.push($localize`Veuillez sélectionner un niveau.`);
		}

		return formError;
	}

	capitalizeFirstLetter(string: string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}


	async displayFormError() {
		let message = "";
		this.studentNameValidation.forEach(element => {
			message += element;
		});
		const alert = await this.alertController.create({
			cssClass: "creationEditionStudentAlert",
			header: "Message",
			message,
			buttons: [
				{
					text: $localize`Corriger`,
					handler: () => {
						console.log("Corriger");
					}
				}
			]
		});
		await alert.present();
	}

	/**
	 * to enter Student Creation Mode and display the "create student" card
	 */
	enterStudentCreation(firstTime = false) {
		this.editionStudent = new Student(null, this.classes.length > 0 ? cloneDeep(this.classes[0]) : new Classroom(null, null, null), "");
		this.editionStudent.parentalConsent = environment.kidaia;
		this.editMode = true;
	}


	/**
	 * Remove student after confirmation from Form
	 */
	async submitRemoveStudent($event) {
		await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget }, 150);
		this.formLog.error = "";
		if (this.formLog.email.length > 0 && this.formLog.password.length > 0) {
			const regexEmail = /\S+@\S+\.\S+/;
			const emailValide = regexEmail.test(String(this.formLog.email).toLowerCase());
			if (emailValide) {
				const formData = new FormData();
				formData.append("email", this.formLog.email);
				formData.append("password", this.formLog.password);
				this.classService.verifPassword(formData).subscribe({
					next: (response: any) => {
						this.formLog.error = "";
						this.removeStudent(this.editionStudent);
					},
					error: (err) => {
						this.formLog.error = $localize`L’email ou le mot de passe est incorrect`;

					},
				});
			} else {
				this.formLog.error = $localize`Veuillez saisir une adresse email valide`;
			}
		}
	}
	/**
	 * Remove student method from component Input
	 */
	async removeStudent(student: Student) {
		this.removeStudentCallback(student).then(() => {
			this.login = false;
			this.editionStudent = null;
		});
	}

	setFocusedElement(elId: string) {
		if ((this.globalService.isTablet || this.globalService.isMobile)) {
			const element = document.getElementById(elId);
			element?.classList.add("focusedInput");
			this.elementFocused = elId;
		}
	}

	removeFocusedElement(elId: string) {
		if (this.globalService.isTablet || this.globalService.isMobile) {
			const element = document.getElementById(elId);
			element?.classList.remove("focusedInput");
			this.elementFocused = null;
		}
	}
}


