import { Student } from "./student";

export class Classroom {
	id: string;
	idProf: string;
	name: string;
	level: string;
	students: Student[];
	isDemo = false;

	constructor(idProf: string, codeClasse, name, level = "") {
		this.id = codeClasse;
		this.idProf = idProf;
		this.name = name;
		this.level = level;
		this.students = new Array();
	}
}
