import { ScenarioOse } from "src/app/models/scenario-ose";
import { ChangeDetectorRef } from "@angular/core";
import { AccountService } from "../services/account.service";
import { GlobalService } from "../services/global.service";
import { ScenarioPhrase } from "./scenario-phrase";
import { PlayTTSService } from "../services/play-tts.service";
import { OseJourneyService } from "../services/ose-journeys.service";
import {TerritoireMaps } from "../page/territoire/territoire.page";

export class ScenarioOseBubble extends ScenarioOse {
	constructor(
		accountService: AccountService,
		globalService: GlobalService,
		oseJourneyService: OseJourneyService,
		cd: ChangeDetectorRef,
		public ttsService: PlayTTSService,
		public bubblePage?: any
	) {
		super(accountService, globalService, oseJourneyService, globalService.speechBubbleComponent, cd, ttsService);
	}

	/**
	 * overload for globalBubble // do not trigger when another runMathiaSpeech() is running as it messes with sequences !
	 */
	async runMathiaSpeech(speechSequence, globalBubble = false) {
		if (globalBubble) {
			this.globalService.speechBubbleComponent?.displayGlobalBubble(true);
		}
		await super.runMathiaSpeech(speechSequence);
		if (globalBubble) {
			this.globalService.speechBubbleComponent?.displayGlobalBubble(false);
		}
	}

	/**
	 * read a text through runMathiaSpeech() to be handled as it (pausable by events, display through tts bubble)
	 * @param consigne text to read / display
	 */
	async readCustomText(consigne: string, globalBubble = false) {
		const speechSequence: Array<any> = [new ScenarioPhrase([consigne])];
		await this.runMathiaSpeech(speechSequence, globalBubble);
	}

	/**
	 * Journey scenario
	 */
	async launchJourneyFeedback() {
		const speechSequences = new Array();
		this.bubblePage.isCategoryListOpen = true;
		if (!this.bubblePage.oseJourneyService?.currentJourney?.isQuizzTypeCompleted) {
				// fin de séquence découverte
				if (this.bubblePage.oseJourneyService.currentJourney.feedback) {
					// feedback defined in wordpress
					await this.launchFeedbackWithEnd(this.bubblePage.oseJourneyService.currentJourney.feedback);
				} else {
					// feedback not defined so read the default's one
					let firstTextRead: string;
					const title = this.bubblePage.oseJourneyService.currentJourney.title;
					if (this.bubblePage.oseJourneyService.isNewJourneyCompleted()) {
						firstTextRead = $localize`Bravo, tu as terminé la séquence suivante : ${title} pour la première fois.`;
					} else {
						firstTextRead = $localize`Bravo, tu as terminé la séquence suivante : ${title}.`;
					}
					speechSequences.push(new ScenarioPhrase([firstTextRead]));
				}
			}

			if(this.bubblePage.oseJourneyService.currentJourney?.quizz){
				const quizzToDo = this.bubblePage.oseJourneyService.currentJourney.quizz.filter(quizz => {
					return !quizz.completedQuizz;
				});
				if (quizzToDo.length > 0) {
					// Journey with quizz to do
					let text;
					if (quizzToDo.length === 1) {
						text = $localize`Teste ton niveau d’écolo avec le dernier quiz`;
					} else {
						text = $localize`Teste ton niveau d'écolo avec un des ${quizzToDo.length} quiz qu’il te reste à faire`;
					}
					speechSequences.push(
						new ScenarioPhrase([text], async () => {
							await this.bubblePage.animHighlightQuizToDo();
						})
					);
				} else {
					let text;
					if(this.bubblePage.oseJourneyService.currentJourney.quizz.length === 1){

					}
					if(this.bubblePage.oseJourneyService?.currentJourney?.isQuizzTypeCompleted &&this.bubblePage.oseJourneyService.currentJourney.quizz.length === 1){
						text = $localize`Bravo, tu as gagné l'étoile. Repars à l'aventure pour découvrir un nouveau point d’intérêt`;
					}else if(this.bubblePage.oseJourneyService?.currentJourney?.isQuizzTypeCompleted &&this.bubblePage.oseJourneyService.currentJourney.quizz.length != 1){
						text = $localize`Bravo, tu as gagné les ${this.bubblePage.oseJourneyService.currentJourney.quizz.length} étoiles. Repars à l'aventure pour découvrir un nouveau point d’intérêt`;
					}
					else{
						text = $localize`Tu peux refaire un quiz ou découvrir un nouveau point d’intérêt.`;
					}
					speechSequences.push(new ScenarioPhrase([text]));
					await this.bubblePage.animHighlightQuizToDo();
				}
			}
			await this.runMathiaSpeech(speechSequences, true);
	}

	/**
	 * skip the scenario sequence on skip button click or manual call from anywhere
	 * @param data
	 */
	async skipMathiaSpeechSequence(data: any) {
		if (this.ttsService.protectedTTSisPlaying) {
			if (Array.isArray(data)) {
				this.skipSequence = data[0];
				await this.ttsService.killSpeech();
				data[1]();
			} else {
				this.skipSequence = data;
				await this.ttsService.killSpeech();
			}
		}
	}

	/**
	 * Introduction to the territory of a map depends on the type of map
	 * @param type TerritoireMaps
	 */
	public async welcomeOnTerritoire(type:TerritoireMaps){
		switch (type) {
			case TerritoireMaps.bearn: 
				await this.welcomeInBearnTerritory();
			break;
			case TerritoireMaps.france:
				await this.welcomeInFranceTerritory();
			break;
			default: 
				await this.welcomeInFranceTerritory();
			break;
		}
	}

	// territoire page intro
	private async welcomeInBearnTerritory() {
		const speechSequence: Array<any> = [
			new ScenarioPhrase([$localize`Bienvenue dans le Béarn !`]),
			new ScenarioPhrase([$localize`Explore ce territoire en cliquant sur les points lumineux !`])
		];
		await this.runMathiaSpeech(speechSequence, true);
	}

	// France page intro
	private async welcomeInFranceTerritory() {
		const speechSequence: Array<any> = [
			new ScenarioPhrase([$localize`Bienvenue à la découverte de la carte de France  !`]),
			new ScenarioPhrase([$localize`Explore ce territoire en cliquant sur les points lumineux !`])
		];
		await this.runMathiaSpeech(speechSequence, true);
	}

	public async assignations(firstname: string, nb_activities: number, consigne: string = ""){
		this.skipSequence = false;
		const speechSequence: Array<any> = [
			new ScenarioPhrase([$localize`Super ${firstname} ! Tu as ${nb_activities > 1 ? "des activités" : "une activité"} à faire.`]),
		];
		if(consigne){
			speechSequence.push(new ScenarioPhrase([$localize`Ton professeur t’a même laissé un petit message.`]));
			speechSequence.push(new ScenarioPhrase([consigne]));
		}
		speechSequence.push(new ScenarioPhrase([$localize`Clique sur l’image quand tu es prêt.`]));
		await this.runMathiaSpeech(speechSequence, true);
	}
}
