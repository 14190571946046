import { Component, OnInit } from "@angular/core";
import { ActivityLinkUserOpenSteam } from "src/app/models/activity-opensteam";
import { ScenarioOseBubble } from "src/app/models/scenario-ose-bubble";
import { AccountService } from "src/app/services/account.service";
import { GlobalService } from "src/app/services/global.service";
import { ModalsService } from "src/app/services/modals.service";
import { OseJourneyService } from "src/app/services/ose-journeys.service";

@Component({
	selector: "app-assignations-controller",
	templateUrl: "./assignations-controller.component.html",
	styleUrls: ["./assignations-controller.component.scss"]
})
export class AssignationsControllerComponent implements OnInit {
	public myAssignations: ActivityLinkUserOpenSteam[] = new Array();
	displayModal: boolean = false;
	flowerPower: number;
	flowerInterval: NodeJS.Timeout;
	get scenarioOseBubble(): ScenarioOseBubble {
		return this.globalService.speechBubbleComponent.scenario;
	}
	constructor(
		public ose: OseJourneyService,
		private modalService: ModalsService,
		private globalService: GlobalService,
		public accountService:AccountService
	) {}

	async ngOnInit() {
		this.flowerPower = Math.ceil(Math.random() * 48);
		this.flowerInterval = setInterval(() => {
			this.flowerPower = Math.ceil(Math.random() * 48);
		}, 5000);

		// this.refreshAssignations();
	}

	closeModal(){
		this.displayModal = false
		this.ose.assignationTeacherModalClosed = true;
	}

	refreshAssignations() {
		this.displayModal = false;
		this.myAssignations = [];
		this.ose.allAssignationsLoaded.subscribe(async () => {
			const currentUser = this.ose.accountService.team[0];
			if(currentUser){
				if (this.ose.hasAssignations(currentUser.id) && !(this.accountService.team[0]?.preview && this.ose.assignationTeacherModalClosed)) {
					this.myAssignations = this.ose.getUserAssignation(currentUser.id).activities.filter(assignations => {
						return assignations.correction !== 2;
					});
					await this.scenarioOseBubble.skipMathiaSpeechSequence(true);
					this.scenarioOseBubble.assignations(
						currentUser.name,
						this.myAssignations.length,
						this.myAssignations[0].introduction
					);
					this.displayModal = true;
				} else {
					this.globalService.speechBubbleComponent.displayGlobalBubble(false);
				}
			}
		});
	}
	startAssignation() {
		const a = this.myAssignations.filter(assignations => {
			return assignations.correction !== 2;
		})[0];
		console.log(a);
		if (a.activity.contentOse.type === "video") {
			console.log(this.ose.accountService.user.videos.getAllVideos());
			const v = this.ose.accountService.user.videos.getAllVideos().find(v => v.id === a.activity.contentOse.id);
			v.assignation_id = a.id;
			const subscription = this.modalService.endStatementVideoEvent.subscribe(result => {
				// update assigned video with correction 2 completed
				if (result) {
					a.correction = 2;
					this.refreshAssignations();
					try {
						this.ose.updateOpenSteamActivityStatusResponse(a);
					} catch (error) {
						console.error(error);
					}
				}
			});
			this.modalService.popVideoModal(v).then(() => {
				subscription.unsubscribe();
			});
		}
		if (a.activity.contentOse.type === "journey") {
			this.ose.currentJourney = this.ose.journeys.find(j => j.id === a.activity.contentOse.id);
			this.ose.currentJourney.assignation_id = a.id;
			this.ose.currentJourney.lrsStart = true;
			const notDoneLastExercise = this.ose.currentJourney.exercises.slice().reverse().find(ex => ex.completed);
			this.ose.currentJourney.lrsResume = this.ose.currentJourney.isLastExerciseCompleted(notDoneLastExercise);
			this.ose.launchNextJourneyExercise(false, false,true);
			this.ose.currentJourney.started = true;
		}
		// start assigned activity set correction to 0
		if (a.correction === null) {
			a.correction = 0;
			try {
				this.ose.updateOpenSteamActivityStatusResponse(a);
			} catch (error) {
				console.error(error);
			}
		}
	}

	ngOnDestroy(){
		if(this.flowerInterval){
			clearInterval(this.flowerInterval);
		}
	}
}
