<div class="globalContainerTrain" *ngIf="(mode === 'train')">
    <div class="controls">
        <div class="buttonWrapper"> <button (click)="testTesseract()">SEND</button></div>
        <div class="buttonWrapper"> <button id="clear" >clear</button></div>
        <div class="buttonWrapper " style="vertical-align: bottom;"> Training <input type="checkbox" [(ngModel)]="training" /></div>
        <div class="buttonWrapper "><select [(ngModel)]="currentNumber">
            <option [value]="-1">+</option>
            <option [value]="-2">-</option>
            <option [value]="-3">×</option>
            <option [value]="-4">÷</option>
            <option [value]="-5">=</option>
            <option [value]="0">0</option>
            <option [value]="1">1</option>
            <option [value]="2">2</option>
            <option [value]="3">3</option>
            <option [value]="4">4</option>
            <option [value]="5">5</option>
            <option [value]="6">6</option>
            <option [value]="7">7</option>
            <option [value]="8">8</option>
            <option [value]="9">9</option>
        </select></div>
        <div class="buttonWrapper"><h2>Value received: {{textExtraction}}</h2></div>
    </div>

    <div class="canvaswrapper">
        <canvas id="canvas" width="80vw" height="calc(100vh - 80px)" style="border: 1px solid black;"></canvas>
        <video id="live" width="320" height="240" autoplay style="border:5px solid #000000; position: fixed; bottom: 0px; right: 0px;">  </video>
   
    </div> 

    <div class="imagewrapper ">
        <div class="buttonWrapper"> <button (click)="emptyImageList()">Empty</button></div>
        <div *ngFor="let img of imagelist">
        <span>{{img.text}} = </span>
        <img src="{{img.src}}" />
        <select [(ngModel)]="img.correctedValue"  [ngModelOptions]="{standalone: true}">
            <option [value]="-1">+</option>
            <option [value]="-2">-</option>
            <option [value]="-3">×</option>
            <option [value]="-4">÷</option>
            <option [value]="-5">=</option>
            <option [value]="0">0</option>
            <option [value]="1">1</option>
            <option [value]="2">2</option>
            <option [value]="3">3</option>
            <option [value]="4">4</option>
            <option [value]="5">5</option>
            <option [value]="6">6</option>
            <option [value]="7">7</option>
            <option [value]="8">8</option>
            <option [value]="9">9</option>
        </select>
        <button (click)="train(img)">send</button>
        </div>
    </div>

</div>

<div class="globalContainerProd" *ngIf="(mode === 'prod')">

    <div class="canvaswrapperProd">
        <h2 *ngIf="!(activity && activity === 'galaxy' && globalService.isMobile) && !cabriService.mirrorMode" i18n>Écris le résultat ci-dessous</h2>
        <canvas #drawingcanvasprod
        id="canvasProd" 
        width="80vw" 
        height="57vh" 
        style="
        border: solid 4px #024d6c;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;" 
         [ngClass]="{'canvasProd': !cabriService.mirrorMode, 'canvasProdMirrorMode': cabriService.mirrorMode}">
        </canvas>

    </div>

    <div class="footer" [ngClass]="{'footerMirrorMode': cabriService.mirrorMode}">
        <div class="bottomButtonsContainer" [ngClass]="{'bottomButtonsContainerMirrorMode': cabriService.mirrorMode}">
            <div class="customButton" [ngClass]="{'customButtonMirrorMode': cabriService.mirrorMode}" id="clear" (click)="emitEvent('clear')">
                <span class="textButtonFont" [ngClass]="{'dyslexicFontTextBtn': dyslexicMode}" i18n="erase drawing|erases the drawing the user draw@@eraseDrawing">
                    Effacer
                </span>
            </div>
            
            <div class="customButton activatedButton" [ngClass]="{'customButtonMirrorMode': cabriService.mirrorMode}" (click)="emitEvent('enter')">
                <span class="textButtonFont" [ngClass]="{'dyslexicFontTextBtn': dyslexicMode}" i18n="validate drawing|validate the drawing the user draw@@validateDrawing">
                    Valider
                </span>
            </div>
        </div>
    </div>

</div>