import { PlayTTSService } from "src/app/services/play-tts.service";
import { ToolbarBasePage } from "src/app/page/cabri/toolbar.base.page";
import { AudioService } from "./../../services/audio.service";
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, QueryList, Renderer2, ViewChild, ViewChildren, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { GlobalService } from "src/app/services/global.service";
import { HttpClient } from "@angular/common/http";
import { OseJourneyService } from "src/app/services/ose-journeys.service";
import { POI } from "src/app/models/poi";
import { OseMap } from "src/app/models/ose-maps";
import { Ose2Journey, OseExerciceType, QuizzStatus } from "src/app/models/ose2-journey";
import { ScenarioNavigationOse } from "src/app/models/scenario-navigation-ose";
import { ModalStateName, oseModalPoiJourney } from "src/app/models/custom-angular-animations";
import { AccountService } from "src/app/services/account.service";
import { OseStatsService } from "src/app/services/ose-stats.service";
import { forkJoin, lastValueFrom, Observable, ReplaySubject, Subject, Subscription } from "rxjs";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { AppUtils } from "src/app/app-utils";
import { ScenarioOseBubble } from "src/app/models/scenario-ose-bubble";
import { FicheComponent, FicheEtapes } from "src/app/components/fiche/fiche.component";
import { LrsService } from "src/app/services/lrs.service";
import { LrsVerbs, XapiVerbs } from "src/app/models/lrs/xapiverbs";
import { Statement } from "src/app/models/statement";
import { XapiExtensions } from "src/app/models/lrs/xapicontext";
import { ConfettiOptions } from "src/app/models/confetti-canvas";
import { OseActivity } from "src/app/models/ose-activities";
import Swiper, { SwiperOptions } from "swiper";
import { SwiperComponent } from "swiper/angular";
import { PinchZoomComponent } from "@meddv/ngx-pinch-zoom";
import { once } from "events";
import {  MatExpansionPanel } from "@angular/material/expansion";
import { cloneDeep } from 'lodash';
import { AssignationsControllerComponent } from "src/app/components/assignations-controller/assignations-controller.component";
import { environment } from "src/environments/environment";

export enum ActivityType {
	journey,
	map,
	fiche
}

export enum TerritoireType {
	journey = "journey",
	poi = "poi",
	fiche = "fiche"
}

export enum Details {
	workshop = "workshop",
	learning = "learning",
	evaluation = "evaluation",
}

export enum TerritoireMaps{
	france,
	bearn
}
export enum PinchZoomTransition{
	primary = "cubic-bezier(0.68, -1, 0.27, 2.2)",
	secondary = "cubic-bezier(1,-0.01,.51,.29)"
}

export class Status {
	image: SVGImageElement
	  
	constructor(svgElement: string | SVGImageElement) {
		if (svgElement) {
			if(svgElement instanceof SVGImageElement){
				this.image = svgElement
			}else{
				let svg = new DOMParser().parseFromString(svgElement, "image/svg+xml")?.documentElement;
				this.image = svg?.querySelector("image");
			}
		}
	}
	/**
	 * Replace image src by instance
	 * @param poiElement HTMLBaseElement
	 */
	replaceStatus(poiElement: HTMLBaseElement | SVGImageElement, attributeName: string) {
		if (this.image) {
			poiElement.setAttribute("xlink:href", this.image.getAttribute(attributeName));
		}
	}
}

@Component({
	selector: "app-territoire",
	templateUrl: "./territoire.page.html",
	styleUrls: ["./territoire.page.scss"],
	animations: [oseModalPoiJourney]
})

export class TerritoirePage extends ToolbarBasePage{
	@HostListener("window:keyup", ["$event"])
	keyEvent(event: KeyboardEvent) {
		// Change map
		if (event.key === "ArrowRight") {
			this.nextSlide();
		} else if (event.key === "ArrowLeft") {
			this.prevSlide();
		}
	}
	//** Use the getCurrentSvgContent function to determine which ElementRef to use depending on whether we are in the territory or maps context */
	@ViewChildren("territoireSvgContent", { read: ElementRef }) territoireSvgContent: QueryList<ElementRef>;
	@ViewChildren("mapSvgContents", { read: ElementRef }) mapSvgContents: QueryList<ElementRef>;
	@ViewChildren("cardActivities", { read: ElementRef }) cardActivities: QueryList<ElementRef>;
	@ViewChildren("quizzContainer", { read: ElementRef }) quizzContainer: QueryList<ElementRef>;
	@ViewChildren("uncompletedQuizzStars", { read: ElementRef }) uncompletedQuizzStars: QueryList<ElementRef>;
	@ViewChildren("expansionPanels") expansionPanels: QueryList<MatExpansionPanel>;
	@ViewChildren("pinchZoom") pinchZoomElements: QueryList<PinchZoomComponent>;
	@ViewChildren("ficheComponent") ficheComponent: QueryList<FicheComponent>;
	@ViewChild("ionSlides", { static: false }) slideTerritoire: SwiperComponent;
	@ViewChild(AssignationsControllerComponent) acc: AssignationsControllerComponent;

	currentPOIElement: HTMLElement;
	territoirePOIElements: HTMLBaseElement[] = new Array();
	territoireMarkerGroupElements: HTMLBaseElement[] = new Array();
	listPOI: Array<POI>;
	public angularAnimationCardDisplayedEvent: Subject<boolean> = new Subject<boolean>();
	public activitiesDOM: HTMLBaseElement[] = new Array<HTMLBaseElement>();
	public selectedActivities: Ose2Journey[] | OseMap[] = new Array();
	modalState: string;
	modalFinalPosition = "0%";
	modalDisappearPosition = "-40%";
	animationRunning = false;

	clickEventListeners = [];
	first = true;
	selectedPOIType: ActivityType;
	public displayTerritoire = true;
	scenarioNav: ScenarioNavigationOse;

	public initialStatus: Status;
	public onGoingStatus: Status;
	public completedStatus: Status;
	groupsElements: HTMLBaseElement[] = new Array();
	groupImages: HTMLBaseElement[] = new Array();
	mapPoiElements: HTMLBaseElement[] = new Array();
	newStatementSendSubscription: Subscription;
	competenciesOpen = false;
	displayQuizChoice = false;
	allQuizCompleted = false;
	isJourneyDisplayed = false;
	canJourneyResume = false;
	completedExercises: Array<OseActivity>;
	totalExercises: Array<OseActivity>;
	get scenarioOseBubble():ScenarioOseBubble{
		return this.globalService.speechBubbleComponent.scenario;
	}
	initSwiperPromise: Promise<unknown>;
	public lastTerritoireSwiperIndex:number

	alreadyCompletedJourney:boolean
	initSwiperPromiseResolve: () => void;
	isCategoryListOpen:boolean;

	get swiper() {
		return this.slideTerritoire?.swiperRef;
	}
	public get Details() {
		return Details;
	}

	public get pinchZoom():PinchZoomComponent{
		const index = this.pinchZoomElements.toArray()[this.swiper.activeIndex] ? this.swiper.activeIndex : 0
		return this.pinchZoomElements.toArray()[index];
	}

	sliderOptions: SwiperOptions = {
		loop: false,
		rewind: true,
		threshold: 30,
		slidesPerView: 1,
		on: {
			init: () => {
				this.initSwiperPromiseResolve();
			}
		}
	};

	public get TerritoireMaps(){
		return TerritoireMaps;
	}
	currentTerritoireMap:{title:string;type:TerritoireMaps,maps:SVGElement,introRead:boolean};
	public journeysTitles = [];
	detailsOpened = false;
	disableValidateSelectionEx = false;
	public get QuizzStatus() {
		return QuizzStatus;
	}

	public get OseExerciceType() {
		return OseExerciceType;
	}
	public startX;
	public startY
	public deltaDistance = 30;
	svgTerritoire:HTMLBaseElement
	scaling = false;

	get cardActivity():ElementRef{
		return this.cardActivities.toArray()[0]
	}

	territoireMapsToDisplay = new Array();
	currentTerritoireFranceMapJourneys = new Array();
	public getRecapPOI = false;
	public dropDownIndex;
	public mapCurrentJourneys = new Array<Ose2Journey>(); // all journeys of current map
	constructor(
		public renderer: Renderer2,
		public router: Router,
		public elRef: ElementRef,
		public globalService: GlobalService,
		public oseJourneyService: OseJourneyService,
		public oseStats: OseStatsService,
		public http: HttpClient,
		public accountService: AccountService,
		public audioService: AudioService,
		public cd: ChangeDetectorRef,
		public ttsService: PlayTTSService,
		public cabriService: CabriDataService,
		public playTTS: PlayTTSService,
		public lrsService: LrsService,
		public route: ActivatedRoute
			) {
		super(globalService, ttsService, cd, router, route);
		this.scenarioNav = new ScenarioNavigationOse(
			this.accountService,
			this.globalService,
			this.oseJourneyService,
			this,
			this.cd,
			playTTS
		);
		this.initSwiperPromise = new Promise<void>(resolve => {
			this.initSwiperPromiseResolve = resolve;
		});
		if(!this.environment.production){
			// (window as any).tttt = this;
		}
	}

	ionViewWillEnter() {
		this.globalService.speechBubbleComponent.scenario = new ScenarioOseBubble(
			this.accountService,
			this.globalService,
			this.oseJourneyService,
			this.cd,
			this.playTTS,
			this
		);
		super.ionViewWillEnter();
		this.first = true;
		this.globalService.setGlobalLoading(true);
	}

	async ionViewDidEnter() {
		if(!this.oseJourneyService.currentMap && localStorage.getItem("currentMap")){
			this.oseJourneyService.currentMap = JSON.parse(localStorage.getItem("currentMap"));
			if(localStorage.getItem("selectedPOIActivity") && !this.oseJourneyService.selectedPOIActivity){
				this.oseJourneyService.selectedPOIActivity = JSON.parse(localStorage.getItem("selectedPOIActivity"));
			}
		}
		AppUtils.removeLocalSotageItems(["currentMap","selectedPOIActivity","oseJourney"])
		this.oseJourneyService.isPlaying = false;
		this.oseJourneyService.tooltipsForWordSearch = [];
		this.detailsOpened = false;
		await this.oseJourneyService.journeysLoaded();
		this.oseStats.prevCompletedJourneys = this.oseStats.currentCompletedJourneys;
		this.oseJourneyService.initializeOseStats();
		await this.cabriService.getAllQuizz();
		await lastValueFrom(this.accountService.isUserLoaded);
		if (!this.accountService.team || this.accountService.team?.length === 0) {
			this.router.navigateByUrl("/activity-participants");
		}
		try {
			await Promise.all([
				this.oseStats.completedJourneys(),
				this.oseStats.sequencesProgression(),
				this.cabriService.getAllTerritoires(),
			]);
			this.setCompletedJourneysStatus();
			this.oseJourneyService.initializeStatuses();
			this.oseJourneyService.setJourneyExercisesStatuses();
		} catch (err) {
			console.error("some stats include errors");
		}
		await this.globalService.waitLandscapeMode();
		if (this.oseJourneyService.currentMap) {
			await this.skipAllScenarioSpeechSequences();
			const currIndex = this.oseJourneyService.selectedPOIActivity.maps.findIndex((currMap) => currMap.id === this.oseJourneyService.currentMap.id);
			await this.loadMapPage();
			this.swiper.slideTo(currIndex,0,null)
		} else {
			await this.loadTerritoireMap();
		}
		// back from journey end
		if (this.oseJourneyService.isNewCompletion) {
			// launch tts feedback before
			// Call it before opening of modal in order to keep parentId property of current journey
			// to differentiate the quizz from the journey
			this.scenarioOseBubble.launchJourneyFeedback();
			// open journey modal
			this.openMAPModal(this.oseJourneyService.currentJourney);
		}

		await this.initSwiperPromise;
		this.acc.refreshAssignations();
	}


	launchSelectedExoSeq() {
		// total exerciseRemaing/done
		const exercises = this.oseJourneyService.currentJourney.exercises;

		const exercisesSelectedToDo = exercises.filter(ex => {
			return ex.selected;
		});

		exercises.forEach((ex, index, currArr) => {
			const needDo = exercisesSelectedToDo.find(exToDo => {
				return exToDo.id === ex.id;
			});
			if (!needDo) {
				currArr[index].completed = true;
			} else {
				currArr[index].completed = false;
			}
		});
		this.goNextActivity(this.oseJourneyService.currentJourney,false);
	}

	/**
	 * Find svg element to trigger modal
	 * @param journey Ose2Journey
	 */
	openMAPModal(journey: Ose2Journey) {
		let journeyHtmlElement: HTMLElement;
		// find journey group
		journeyHtmlElement = this.getCurrentSvgContent.nativeElement.querySelector(`[id^='journey-${journey.id}-group'] [id^='journey-${journey.id}-poi']`)
		if (journeyHtmlElement) {
			this.selectMAP({ target: journeyHtmlElement });
		} else {
			// find journey POI
			journeyHtmlElement = this.territoirePOIElements.find(mapElement => {
				return mapElement.id === `journey-${journey.id}`;
			});

			if (journeyHtmlElement) {
				this.selectPOI({ target: journeyHtmlElement });
			}
		}
		if (!journeyHtmlElement) {
			this.oseJourneyService.newCompletion = false;
			if(this.oseJourneyService.currentJourney){
				this.oseJourneyService.currentJourney.isQuizzTypeCompleted = false;
			}
		}else{
			if(this.displayTerritoire){
				this.angularAnimationCardDisplayedEvent = new Subject();
				this.angularAnimationCardDisplayedEvent.subscribe(() => {
					this.pinchZoomOnSvgTarget(journeyHtmlElement,{duration:1200,effect:PinchZoomTransition.primary});
				})
			}
		}
	}

	/**
	 * selected exercises that should be done starting from the selected index and filters 
	 * them based on index is greater than or equal to the selected index.
	 * @param selectedEx Ose activity
	 * @param index number
	 */
	async startSelectedActivityFromIndex(selectedEx:OseActivity, index:number,$event:Event) {
		$event.stopPropagation();
		let categoryExercises = this.oseJourneyService.currentJourney.getSelectedCategory();
		categoryExercises = categoryExercises.filter((catEx, catIndex) => index <= catIndex);
		const checkedSelectedExIndexes = this.oseJourneyService.currentJourney.
										exercises.map((ex,index) =>ex.selected ? index : "").filter(String)
		// Save temp exercise completed state
		this.oseJourneyService.currentJourney.exercises.forEach((ex) => {
			ex.selected = categoryExercises.some(catEx => catEx.id === ex.id);
		});
		const title = selectedEx.titre_sommaire || selectedEx.title;
		const confirmAnswer = await this.globalService.simpleAlert(
			`Souhaites-tu débuter à partir de l'exercice suivant ? ` + `</br><strong>${title}</strong>`,
			[
				{ text: $localize`Valider`, value: true },
				{ text: $localize`Annuler`, value: false,cssButton:'undoButtonAlert' }
			],
			"alertConfirm"
		);

		if (confirmAnswer) {
			this.oseJourneyService.currentJourney.skippedMode = true;
			let skipStatus = true;
			let needSelectedExToBeDone = false;
			// change the state of exercises  based on whether they need to be completed or not
			this.oseJourneyService.currentJourney.exercises.forEach((ex, index, currArray) => {
				if(currArray[index].id === selectedEx.id){
					skipStatus = false;
					needSelectedExToBeDone = true;
				}
				currArray[index].completed = skipStatus;
					if(needSelectedExToBeDone){
						currArray[index].requiredExercisesToComplete = true;
					}
			});
			this.oseJourneyService.currentJourney.lrsStart = true;
			this.oseJourneyService.currentJourney.lrsResume = true;
			this.goNextActivity(this.oseJourneyService.currentJourney, this.oseJourneyService.currentJourney.lrsResume);
		} else {
			this.oseJourneyService.currentJourney.skippedMode = false;
			// resetting the selected exercises to their previous state.
			this.oseJourneyService.currentJourney.exercises.forEach((ex, index) => {
				ex.selected = checkedSelectedExIndexes.some((indexes) => indexes === index);
			});
		}
	}

	/**
	 * Start doing category exercises based status => preview(only checked exercises) or student(all included)
	 */
	startJourneyWithSelectedExercises($event:Event) {
		$event.stopPropagation();
		this.audioService.playSelectSound();
		let exercisesToDo;
		this.oseJourneyService.currentJourney.currentCategoryExercise = this.oseJourneyService.currentJourney.getSelectedCategory();
		if (this.accountService.team[0].preview) {
			exercisesToDo = this.oseJourneyService.currentJourney.exercises.filter(ex => ex.selected);
			if (exercisesToDo.length === 0) {
				exercisesToDo = this.oseJourneyService.currentJourney.currentCategoryExercise;
			}
		}

		const lastExIndexOfCategory = this.oseJourneyService.currentJourney.exercises.findIndex((exercise) => {
			const lastEx = this.oseJourneyService.currentJourney.currentCategoryExercise[this.oseJourneyService.currentJourney.currentCategoryExercise.length - 1];
			return lastEx.id === exercise.id;
		})

		this.oseJourneyService.currentJourney.exercises.forEach((exercise, i, currArr) => {
			let toDo: boolean;
			if (this.accountService.team[0].preview) {
				toDo = exercisesToDo.some(exToDo => exToDo.id === exercise.id);
			} else {
				toDo = true;
			}
			let isCurrentEx;
			if(this.accountService.team[0].preview){
				isCurrentEx = true;
			}else{
				isCurrentEx = this.oseJourneyService.currentJourney.
								currentCategoryExercise.some(currEx => currEx.id === exercise.id);
			}
			if (isCurrentEx) {
				currArr[i].completed = toDo ? false : true;
			} else {
				const completed = i > lastExIndexOfCategory ? false : true;
				currArr[i].completed = completed;
			}
		
		});
		this.oseJourneyService.currentJourney.lrsStart = true;
		// not resume for discovering in order to start from beginning
		const resume = this.oseJourneyService.currentJourney.selectedCategoryType === Details.workshop ? true : false;
		this.goNextActivity(this.oseJourneyService.currentJourney, resume);
	}

	async animHighlightQuizToDo() {
			const animateQuizzDoneStars = async (quizz) => {
				if(quizz?.last?.nativeElement){
					let quizzStars = Array.from(quizz.last.nativeElement.querySelectorAll(".isCompleted.notAwarded"));
					if(quizzStars.length == 0){
						quizzStars = Array.from(quizz.last.nativeElement.querySelectorAll(".isCompleted.awarded"));
					}

					if(quizzStars?.length > 0){
						for (let index = 0; index <= quizzStars.length - 1; index++) {
							const star = quizzStars[index] as any;
							this.renderer.addClass(star, "highlightRemaining");
							try{
								await once(star, "animationend");
							}catch(err){
								// on ipad
								await AppUtils.timeOut(800);
							}
							this.renderer.removeClass(star, "highlightRemaining");
						}
					}
				}
			}
			if (this.quizzContainer?.last && typeof this.quizzContainer.last !== "undefined") {
				// if the Dom element is loaded not need to subscribe
				animateQuizzDoneStars(this.quizzContainer);
			} else {
				this.quizzContainer.changes.subscribe(quizz => {
					animateQuizzDoneStars(quizz);
				});
			}
	}

	async loadTerritoireMapSVG() {
		return new Promise<void>(async resolve => {
			let france:Observable<string>;
			try {
				// get carte de france from server
				await this.oseJourneyService.getMaps();
				const franceMap = this.oseJourneyService.oseMapsList.find((map) => map.city === 'France') as any
				let franceMapUrl;
				if(!franceMap){
					throw new Error("no map")
				}else{
					if(!this.environment.production && franceMap.svgUrlDev && franceMap.svgUrlDev !== ''){
						franceMapUrl =  franceMap.svgUrlDev +=`?env=${this.env}`
					}else{
						franceMapUrl =  franceMap.svgUrl +=`?env=${this.env}`
					}
				}
				
				france = this.http.get(franceMapUrl, { responseType: "text" });
			} catch (err) {
				console.error("err not recovered carte de france",err)
				france = this.http.get(`/assets/territoire/carte_france.svg?env=${this.env}`, { responseType: "text" });
			}
			// france = this.http.get("/assets/territoire/carte_france.svg", { responseType: "text" });
			this.territoireMapsToDisplay = new Array();
			const territoire = this.http.get(`/assets/territoire/bearn.svg`, { responseType: "text" });
			const initialized = this.http.get(`/assets/territoire/status/initialized.svg`, { responseType: "text" });
			const ongoing = this.http.get(`/assets/territoire/status/ongoing.svg`, { responseType: "text" });
			const completed = this.http.get(`/assets/territoire/status/completed.svg`, { responseType: "text" });
			forkJoin([initialized, ongoing, completed,territoire,france]).subscribe(async (results) => {
				this.initialStatus = new Status(results[0]);
				this.onGoingStatus = new Status(results[1]);
				this.completedStatus = new Status(results[2]);
				this.territoireMapsToDisplay.push({title:'La carte de France',map:results[4],type:TerritoireMaps.france,introRead:false},{title:'Le Béarn',map:results[3],type:TerritoireMaps.bearn,introRead:false});
				this.currentTerritoireMap = this.territoireMapsToDisplay[0]
				let promise:Promise<void>
				const displayTerritoireElements = (queryListSvgContent:QueryList<ElementRef>) => {
					// when the 'queryList' in the DOM is ready display the SVG maps of the territory
					if(queryListSvgContent.toArray()?.length > 0){
						this.territoireMapsToDisplay.forEach((svgObj,index) => {
							queryListSvgContent.toArray()[index].nativeElement.innerHTML = svgObj.map;
						})
					}
				}
	
			
				if(this.territoireSvgContent.last){
					promise = new Promise((resolve) => {
						displayTerritoireElements(this.territoireSvgContent);
						resolve();
					})
				}else{
					promise = new Promise((resolve) => {
						this.territoireSvgContent.changes.subscribe((elem) => {
							displayTerritoireElements(elem);
							resolve();
						})
					})
				}

				await promise;
				this.setSwiperSwiping(true);
				resolve();
			
		
			});
		});
	}

	async loadMapSvg() {
		return new Promise<void>(resolve => {
			// const map1 = {
			// 	id: 8530,
			// 	intro: "<!-- wp:paragraph -->\n<p>Bienvenue à la chambre d'agriculture ! Ici nous allons voir ensemble ce qu'est l'alimentation responsable. Et surtout les bonnes habitudes à prendre pour limiter notre impact sur la planète. </p>\n<!-- /wp:paragraph -->\n\n<!-- wp:paragraph -->\n<p>Clique sur les points de couleur pour accéder aux différentes activités.</p>\n<!-- /wp:paragraph -->\n\n<!-- wp:paragraph -->\n<p>C’est parti !</p>\n<!-- /wp:paragraph -->",
			// 	title: "Chambre d'agriculture",
			// 	city: "Pau",
			// 	background: "https://kidaia.com/wp-content/uploads/2022/07/territoire-agriculture.png",
			// 	competencies: [
			// 		{
			// 			"id": 315,
			// 			"name": "Reconnaître les états de l’eau et leur manifestation dans divers phénomènes naturels."
			// 		},
			// 		{
			// 			"id": 317,
			// 			"name": "Identifier les interactions des êtres vivants entre eux et avec leur milieu"
			// 		},
			// 		{
			// 			"id": 289,
			// 			"name": "Comparer des espaces géographiques simples"
			// 		},
			// 		{
			// 			"id": 288,
			// 			"name": "Identifier des paysages : reconnaître différents paysages (les littoraux, les massifs montagneux, les campagnes, les villes, etc)"
			// 		}
			// 	],
			// 	"svgUrl": "https://kidaia.com/wp-content/uploads/2023/09/poi_eau_montagne-1.svg",
			// 	"svgUrlDev": "https://kidaia.com/wp-content/uploads/2023/09/poi_eau_montagne-1.svg",
			// 	"journeys": [
			// 		32686,
			// 		32687
			// 	],
			// 	"prod": "1"
			// } as any
			const svgUrls = this.oseJourneyService.selectedPOIActivity.maps.map((activity) => {
				if(!this.environment.production && activity.svgUrlDev && activity.svgUrlDev !== ''){
					return activity.svgUrlDev += `?env=${this.env}`
				}else{
					return activity.svgUrl += `?env=${this.env}`;
				}
			});

			
			const observables = svgUrls.map((svgUrl) => {
				return this.http.get(svgUrl, { responseType: "text" })
			});
			

			forkJoin(observables).subscribe(async (svgContents:Array<any>) => {
				svgContents.forEach((svgContent,index) => {
					this.mapSvgContents.toArray()[index].nativeElement.innerHTML = svgContent;
					const currentSvg =  this.mapSvgContents.toArray()[index].nativeElement.querySelector("svg");
					const poiInit = currentSvg.querySelector("#poi-initialized");
					const poiOngoing = currentSvg.querySelector("#poi-ongoing");
					const poiCompleted = currentSvg.querySelector("#poi-completed");
					if(poiInit){
						this.initialStatus = new Status(poiInit);
					}
					if(poiOngoing){
						this.onGoingStatus = new Status(poiOngoing);
					}
					if(poiCompleted){
						this.completedStatus = new Status(poiCompleted);
					}
					currentSvg.setAttribute("preserveAspectRatio", "xMidYMid slice");
				})
				resolve();
			});
		});
	}

	/**
	 * Settings swiper sliding rules
	 * @param enable allow to swiper slide
	 */
	public setSwiperSwiping(enable: boolean,swiperComponent:SwiperComponent = this.slideTerritoire) {
		swiperComponent?.updateParameter("rewind", enable);
		swiperComponent?.updateParameter("simulateTouch", enable);
		swiperComponent?.updateParameter("allowTouchMove", enable);
		if(!enable){
			swiperComponent?.updateParameter("noSwipingClass", "swiper-slide");
		}
		swiperComponent?.updateParameter("noSwiping", !enable);
	}


	/**
	 * Read tts intro territoire map / activity map 
	 */
	async readTTSIntro() {
		let currentMap;
		if(!this.oseJourneyService.isNewCompletion){
			await this.skipAllScenarioSpeechSequences();
		}
		if(this.displayTerritoire){
			if(this.territoireMapsToDisplay?.length > 0){
				currentMap = this.territoireMapsToDisplay.find((item,index) => index === this.swiper.activeIndex);
			}
			if (currentMap && !currentMap?.introRead) {
				currentMap.introRead = true;
				await this.scenarioOseBubble.welcomeOnTerritoire(currentMap.type);
			}
		}else{
			currentMap = this.oseJourneyService.currentMap as OseMap;
			if(currentMap && !currentMap?.introRead){
				currentMap.introRead = true;
				// read intro
				await this.scenarioOseBubble.readHTML(currentMap.intro, true);
			}
		}
	}

	public removeConfettiAnimation(){
		if(this.globalService.confettiCanvasComponent){
			this.globalService.confettiCanvasComponent.removeConfetti();
		}
	}

	async loadTerritoireMap(swiperChange = false) {
		this.removeConfettiAnimation();
		// map loaded
		this.displayTerritoire = true;
		this.getRecapPOI = false;
		if(!swiperChange){
			await this.loadTerritoireMapSVG();
			this.swiper.slideTo(this.lastTerritoireSwiperIndex || 0,0);
			this.lastTerritoireSwiperIndex = null
		}
		if (this.pinchZoom) {
			if(this.pinchZoom.isZoomedIn){
				this.pinchZoom.toggleZoom();
			}
			this.pinchZoom.pinchZoom.maxScale = this.pinchZoom.pinchZoom.defaultMaxScale;
		}
		const territoireContent = this.territoireSvgContent.toArray()[this.swiper.activeIndex] || this.territoireSvgContent.toArray()[0]
		this.svgTerritoire = territoireContent.nativeElement.querySelector("svg");
		this.getCurrentSvgContent.nativeElement.addEventListener("wheel", this.onWheel.bind(this));
		this.getCurrentSvgContent.nativeElement.addEventListener("touchstart", this.touchstart.bind(this));
		this.getCurrentSvgContent.nativeElement.addEventListener("touchmove", this.touchmove.bind(this));
		this.getCurrentSvgContent.nativeElement.addEventListener("touchend", this.touchend.bind(this));
		try {
			this.listPOI = await this.oseJourneyService.getAllPOI();
		} catch (err) {
			console.error("POI error");
		}
		// from svg select id's images of journey/poi
		this.territoirePOIElements = Array.from(this.getCurrentSvgContent.nativeElement.querySelectorAll("svg image, svg g, svg use")).filter(
			(htmlElement: HTMLBaseElement) => {
				return (
					htmlElement.id?.startsWith("poi") ||
					htmlElement.id?.startsWith("image") ||
					htmlElement.id?.startsWith("journey") ||
					(htmlElement.id?.startsWith("fiche") && !htmlElement.id?.includes("marker"))
				);
			}
		) as HTMLBaseElement[];
		this.territoirePOIElements.forEach(poiElement => {
			poiElement.addEventListener("click", this.selectPOI.bind(this));
		});

		this.currentTerritoireFranceMapJourneys = new Array();
		this.mapCurrentJourneys = new Array();
		if(this.currentTerritoireMap.type === TerritoireMaps.france){
			// new array of the existing journeys in the current map based on the SVG ids
			this.currentTerritoireFranceMapJourneys = this.territoirePOIElements.map((currentItem) => {
				this.getDomJourneys(currentItem);
				if(currentItem?.id?.startsWith("journey-") && currentItem?.id?.endsWith("-group")){
					const idElement = currentItem?.id?.split("-")[1];
					const journey = this.oseJourneyService.journeys.find((journey) => journey.id === Number(idElement))
					if(journey && !this.currentTerritoireFranceMapJourneys.includes(journey)){
						const {completedExercises,totalExercises } = journey.getProgressionJourney();
						const isTotalExCompleted = completedExercises?.length === totalExercises?.length;
						return {...journey,isTotalExCompleted};
					}else{
						return "";
					}
				}else{
					return "";
				}
			}).filter(String);
			this.pinchZoom.pinchZoom.maxScale = this.pinchZoom.pinchZoom.defaultMaxScale - 0.1; // IOS fix pinchzoom when the map is zoomed in to its maximum extent
			if(!this.oseJourneyService.newCompletion){
				this.recapPOI();
			}
		}

		this.territoireMarkerGroupElements = Array.from(this.getCurrentSvgContent.nativeElement.querySelectorAll("svg g")).filter(
			(htmlElement: HTMLBaseElement) => {
				return htmlElement.id?.includes("marker");
			}
		) as HTMLBaseElement[];

		this.territoireMarkerGroupElements.forEach(poiElement => {
			poiElement.addEventListener("click", this.selectPOI.bind(this));
		});


		this.hideHighlightImages();
		this.setJourneyStatuses(this.territoirePOIElements);
		this.globalService.setGlobalLoading(false);
		await this.globalService.unlockAudioAndTTSIfPageReloaded(true);
		if(this.router.url === "/territoire"){
			this.readTTSIntro();
		}
	}

	/**
	 * mapCurrentJourneys contains instances of journeys. These instances are identified based on HTML ID tags, which contain journey IDs number.
	 * @param currentItem HtmlBaseElement
	 */
	public getDomJourneys(currentItem:HTMLBaseElement){
		const idNumber = currentItem?.id?.match(/(\d+)/)?.[0];
		// Display output if number extracted
		if (idNumber) {
			if(this.mapCurrentJourneys.findIndex(j => j.id === Number(idNumber)) === -1){
				const currentJ = this.oseJourneyService.getById(Number(idNumber));
				if(currentJ){
					this.mapCurrentJourneys.push(currentJ);
				}
			}
		}
	}

	/**
	 * Hide highlight images displayed by default
	 */
	hideHighlightImages() {
		if (this.displayTerritoire) {
			this.territoirePOIElements.forEach(element => {
				const imageGlow =
					(element.id.startsWith("fiche-") || element.id.startsWith("poi-")) &&
					element.id.endsWith("image") &&
					element.tagName === "image";
				if (imageGlow) {
					this.renderer.setStyle(element, "pointer-events", "none");
					this.renderer.setStyle(element, "opacity", "0");
				}
			});
		} else {
			this.groupImages.forEach(groupElement => {
				this.renderer.setStyle(groupElement, "opacity", "1");
			});
		}
	}

	/**
	 * Set poi statuses based on journey or exercise progression
	 * @param poiJourneysElements poi HTML elements
	 * @param isJourneyType if true the status will be based on all started or completed journeys otherwise it will be depend on all exercises
	 */

	setJourneyStatuses(poiJourneysElements: HTMLBaseElement[]) {
		const currentCompletedJourneys = new Array();
		const onGoingJourneys = new Array();
		let validatedItems = new Array();
		let onGoingItems = new Array();

		if(this.displayTerritoire && this.currentTerritoireMap.type !== TerritoireMaps.france){
			validatedItems = [...this.oseStats.currentCompletedJourneys,...this.oseStats.completedExercises];
			onGoingItems = onGoingItems = [...this.oseStats.onGoingJourneys,...this.oseStats.onGoingExercises]
			onGoingItems = [...onGoingItems,...validatedItems]
		}else{
			// Get completed journeys by comparing the number of exercises completed to the total number of exercises
			this.mapCurrentJourneys.forEach((currJ) => {
				const {completedExercises,totalExercises } = currJ.getProgressionJourney();
				if(totalExercises?.length > 0){
					if(completedExercises?.length === totalExercises?.length){
						currentCompletedJourneys.push({	id: currJ.id,status: XapiVerbs.get(LrsVerbs.completed).id})
					}
					if(completedExercises?.length > 0){
						onGoingJourneys.push({id: currJ.id,status: XapiVerbs.get(LrsVerbs.completed).id});
					}
				}
			})
			validatedItems = [...currentCompletedJourneys,...this.oseStats.completedExercises];
			onGoingItems = [...onGoingJourneys,...this.oseStats.onGoingExercises];
		}
		/**
		 * Change poi's marker style to display none
		 * @param poiId fiche id
		 * @param progressionStatus progression status including 3 possibilites
		 */
		const displayMarkerElementByProgressionStatus = (poiId: string, progressionStatus: string) => {
			this.territoireMarkerGroupElements.forEach((markerGroupElement: any) => {
				// group containing three images by status (initialized/ongoing/completed)
				if (markerGroupElement?.id === `fiche-${poiId}-marker`) {

					Array.from(markerGroupElement.children).forEach((children:HTMLBaseElement) => {
						// Group childrens
						if (children?.id !== `fiche-${poiId}-marker-${progressionStatus}`) {
							this.renderer.setStyle(children, "display", "none");
							this.renderer.removeAttribute(children, children.dataset.displayed);
						} else {
							// children.dataset.displayed = true;
							this.renderer.setStyle(children, "display", "block");
						}
					});
				}
			});
		};

		poiJourneysElements.forEach(journeyElem => {
			if (!journeyElem.id?.endsWith("image")) {
				const poi = journeyElem.id.split("-")?.[1];
				if (poi) {
					let currJourney = this.oseJourneyService.journeys.find(currJ => currJ.id === Number(poi));

					if (!currJourney) {
						// Create nasty journey, in the case where journey doesn't come from server
						currJourney = new Ose2Journey({
							id: Number(poi)
						});
					}

					if (currJourney) {
						const validated = currJourney.hasJourneyStatusMatch(validatedItems);
						if (validated) {
							if (this.displayTerritoire) {
								// not display
								displayMarkerElementByProgressionStatus(poi, "completed");
								// replace href of poi image source directly
								this.completedStatus.replaceStatus(journeyElem, "xlink:href");
							} else {
								if(journeyElem.id.startsWith("poi-")){
									this.completedStatus.replaceStatus(journeyElem, "xlink:href")
								}else if (!journeyElem.id.includes("completed")) {
									// not display completed poi's on map
									this.renderer.setStyle(journeyElem, "display", "none");
								}
							}
						} else {
							const onGoing = currJourney.hasJourneyStatusMatch(onGoingItems);
							if (onGoing) {
								// debugger;
								if (this.displayTerritoire) {
									let poiStatus = this.completedStatus;
									let markerStatus = "completed"
									if(this.currentTerritoireMap.type === TerritoireMaps.france){
										markerStatus = "ongoing";
										poiStatus = this.onGoingStatus
									}
									displayMarkerElementByProgressionStatus(poi, markerStatus);
									// replace href of poi image source directly
									poiStatus.replaceStatus(journeyElem, "xlink:href");
								} else {
									// replace href id
									if(journeyElem.id.startsWith("poi-")){
										this.completedStatus.replaceStatus(journeyElem, "xlink:href")
									}else if (!journeyElem.id.includes("ongoing")) {
										// not display ongoing poi's on map
										this.renderer.setStyle(journeyElem, "display", "none");
									}
								}
							}  else {
								if (this.displayTerritoire) {
									displayMarkerElementByProgressionStatus(poi, "initialized");
								} else {
									if (!journeyElem.id.includes("initialized") && !journeyElem.id.startsWith("poi-")) {
										// not display initialized poi's on map
										this.renderer.setStyle(journeyElem, "display", "none");
									}
								}
							}
						}
					}
				}
			}
		});
	}

	onAnimationStart($event: AnimationEvent) {
		// console.error("animation start ", this.first);
		if (!this.first) {
			this.animationRunning = true;
		}
		this.first = false;
	}

	closeModal() {
		this.audioService.playCancelSound();
		this.pinchZoomOnSvgTarget(null,{duration:350,effect:""});
		this.getRecapPOI = false;
		this.selectedActivities = new Array();
		this.setFichePOIStatus();
		this.ficheComponent.last?.sendJourneyEndLrs();
		this.modalState = ModalStateName.close;
		this.detailsOpened = false;
		this.hideHighlightImages();
		this.cd.detectChanges(); // Modal DOM not fired(closed) instantly
	}

	initializeJourneyCategory(){
		this.oseJourneyService.currentJourney.selectedCategoryType = null;
		this.oseJourneyService.currentJourney.currentCategoryExercise = new Array();
	}

	/**
	 *  change the status of the POI fiche of HTML Element according to its reading status asynchronously
	 * @param started boolean
	 */
	setFichePOIStatus() {
		if (this.oseJourneyService.currentJourney?.isFiche) {
			this.lrsService.newStatementSend = new ReplaySubject(1);
			this.newStatementSendSubscription = this.lrsService.newStatementSend.subscribe((statementSent: Statement) => {
				if (statementSent && statementSent.context?.extensions?.[XapiExtensions.id]) {
						const idExercise = statementSent.context.extensions[XapiExtensions.id];
						let poiElementsType:HTMLBaseElement[];
						this.oseStats.completedExercises.push({
							id: Number(idExercise),
							status: XapiVerbs.get(LrsVerbs.completed).id
						});
						if(this.displayTerritoire){
							poiElementsType = this.territoirePOIElements;
						}else{
							poiElementsType = this.mapPoiElements;
						}
						if(poiElementsType){
							this.setJourneyStatuses(poiElementsType);
						}
				}
			});
		}
	}

	openCategories($event:Event){
		$event.stopPropagation();
		this.isCategoryListOpen = !this.isCategoryListOpen;
		this.detailsOpened = false;
		this.oseJourneyService.currentJourney.selectedCategoryType = null;
	}

	async goNextActivity(activity: Ose2Journey | OseMap, resume: boolean) {
		switch (this.selectedPOIType) {
			case ActivityType.journey:
				this.audioService.playSelectSound();
				// journey POI
				this.oseJourneyService.currentJourney.lrsResume = resume;
				this.oseJourneyService.currentJourney.calculateSessionExerciseCompletionPercentage(this.oseStats.sequencesTraces);
				const currEx = this.oseJourneyService.currentJourney.getCurrentExercise(!resume,true);
				console.log("currEx",currEx)
				if(currEx && this.oseJourneyService.currentJourney.quizz){
					this.oseJourneyService.currentJourney.isQuizzTypeStarted = this.oseJourneyService.currentJourney.quizz.some((quiz) => quiz.id === currEx.id)
				}else{
					this.oseJourneyService.currentJourney.isQuizzTypeStarted = false
				}
				if(this.oseJourneyService.currentMap){
					localStorage.setItem("currentMap",JSON.stringify(this.oseJourneyService.currentMap))
					if(this.oseJourneyService.selectedPOIActivity){
						localStorage.setItem("selectedPOIActivity",JSON.stringify(this.oseJourneyService.selectedPOIActivity))
					}
				}
				
				// start of journey
				this.oseJourneyService.launchNextJourneyExercise(false, !resume,true);
				if (this.displayTerritoire) {
					this.oseJourneyService.currentMap = null;
				}
				this.modalState = ModalStateName.close;
				break;
			case ActivityType.map:
				this.audioService.playSelectSound();
				this.hideHighlightImages();
				this.oseJourneyService.currentMap = activity;
				const currIndex = this.oseJourneyService.selectedPOIActivity.maps.findIndex((currMap) => currMap.id === activity.id);
				this.modalState = ModalStateName.close;
				this.lastTerritoireSwiperIndex = this.swiper.activeIndex;
				await this.skipAllScenarioSpeechSequences();
				this.loadMapPage().then(async () => {
					this.swiper.slideTo(currIndex,0);
				})
				break;
		}
		this.currentPOIElement = null;
	}

	startResumeJourney(activity, canJourneyResume,$event:Event){
		$event.stopPropagation();
		this.initializeJourneyCategory();
		this.goNextActivity(activity, canJourneyResume);
	}

	async onAnimationEnd($event: AnimationEvent) {
		// console.error("onanimationEnd", this.first);
		if (!this.first) {
			if (this.modalState === ModalStateName.show) {
				this.getModalContent();
				this.modalState = ModalStateName.hide;
			} else if (this.modalState === ModalStateName.close) {
				if(this.cardActivity?.nativeElement){
					this.renderer.removeClass(this.cardActivity.nativeElement, "card-one-poi");
					this.renderer.addClass(this.cardActivity.nativeElement, "card-reduce");
				}
				this.currentPOIElement = null;
			} else if (this.modalState === ModalStateName.hide) {
				this.angularAnimationCardDisplayedEvent.next(true)
				this.angularAnimationCardDisplayedEvent.complete();
				if (this.oseJourneyService.newCompletion || this.oseJourneyService.currentJourney?.isQuizzTypeCompleted) {
					if(this.oseJourneyService.currentJourney?.isQuizzTypeCompleted){
						this.openDetails(Details.evaluation,null,true);
					}
					this.manageEndConfetti();
				}
			}

			if (this.globalService.isSafari) {
				// hack for svg element resize bug
				const svgElement = this.getCurrentSvgContent.nativeElement.querySelector("svg");
				if (svgElement) {
					svgElement.style.opacity = 0.99;
					this.cd.detectChanges();
					await AppUtils.timeOut(100);
					svgElement.style.opacity = 1;
					this.cd.detectChanges();
				}
			}
		}
		this.animationRunning = false;
	}

	public manageEndConfetti(){
		const confettiOptions: ConfettiOptions = {
			particleCount: this.globalService.isDesktop ? 250 : 150,
			spread: 70,
			startVelocity: 30
		};
		this.removeConfettiAnimation();
		this.globalService.confettiCanvasComponent
			?.launchConfetti(this.cardActivity.nativeElement, confettiOptions).then(() => {
				this.oseJourneyService.newCompletion = false;
				if(this.oseJourneyService.currentJourney){
					this.oseJourneyService.currentJourney.isQuizzTypeCompleted = false;
				}
			})
			
	}

	/**
	 * Grab top most svg's group element of current svg element
	 * @param domElement HTMLElement
	 * @returns SvgElement
	 */
	getClosestGroup(domElement: HTMLElement) {
		let groupParent;
		let counterGrabTop = 0;

		while (domElement && (!groupParent && counterGrabTop <= 10) || (groupParent?.parentElement && groupParent.tagName !== "g")) {
			counterGrabTop++;
			groupParent = domElement.parentElement;
		}
		return groupParent;
	}

	public isAutorizedSvgElement(targetPoiId) {
		if(targetPoiId){
			const idStartsAutorized = [TerritoireType.poi,TerritoireType.journey,TerritoireType.fiche];
			for (const authorizedIdStart of idStartsAutorized) {
				if (targetPoiId.startsWith(authorizedIdStart)) {
					return true;
				}
			}
			return false;
		}
	}

	selectPOI($event: { target: HTMLElement; offsetX?: number; offsetY?: number, clientY?:number,clientX?:number}) {
			this.displayQuizChoice = false;
			this.competenciesOpen = false;
			this.allQuizCompleted = false;
			if (!this.animationRunning) {
				let targetPOI = $event.target;
				if (targetPOI.tagName === "path") {
					targetPOI = this.getClosestGroup(targetPOI);
				}
				// prevent click on same element
				if (targetPOI && this.isAutorizedSvgElement(targetPOI.id) && (!this.currentPOIElement || targetPOI.id !== this.currentPOIElement?.id)) {
					this.isJourneyDisplayed = false;
					if (this.pinchZoom?.isZoomedIn) {
						this.pinchZoom.toggleZoom();
					}
					this.closeModal()
					if(targetPOI.id.startsWith("journey")){
						// Simulate as opening a journey in map (carte de france)
						this.selectedPOIType = ActivityType.journey;
						this.isJourneyDisplayed = true
						this.selectMAP({ target: targetPOI });
						this.angularAnimationCardDisplayedEvent = new Subject();
						this.pinchZoomOnSvgTarget(null,{duration:0});
						this.angularAnimationCardDisplayedEvent.subscribe(() => {
							this.pinchZoomOnSvgTarget(targetPOI,{duration:1200,effect:PinchZoomTransition.primary});
						})
					}else{
						this.modalFinalPosition = "0%";
						this.modalDisappearPosition = String(-(this.swiper.activeIndex*100) - 40)+"%";
						this.audioService.playSelectSound();
						// remove css class on previous poi
						// set current poi
						const poiData = targetPOI.id.split("-");
						this.currentPOIElement = this.getCurrentSvgContent.nativeElement.querySelector(`#${poiData[0]}-${poiData[1]}`);
						this.hideHighlightImages();
						switch (poiData[0]) {
							case TerritoireType.journey:
								this.selectedPOIType = ActivityType.journey;
								this.oseJourneyService.currentJourney = this.oseJourneyService.getById(Number(poiData[1]));
								break;
							case TerritoireType.poi:
								this.selectedPOIType = ActivityType.map;
								this.oseJourneyService.currentJourney = null;
								break;
							case TerritoireType.fiche:
								this.selectedPOIType = ActivityType.fiche;
								this.highlightSelectedFichePOIImage(poiData[1]);
								this.oseJourneyService.currentJourney = this.createJourneyPOIFicheType(poiData[1]);
								this.scenarioNav.previousTitleRead = null;
								this.setFichePOIStatus();
								break;
							default:
								throw new Error("poi type not recognized");
						}
						this.isJourneyDisplayed = this.selectedPOIType === ActivityType.journey;
						this.openModal();
						// animation
						this.renderer.removeClass(this.cardActivity.nativeElement, "card-reduce");
					}
					this.first = false;
				}
			}
	}

	
	/**
	 * Zoom in on the selected SVG element inside its parent SVG using pinch-zoom
	 * @param element HTMLElement
	 * @param options transition duration and effect with cubic bezier
	 */
	public async pinchZoomOnSvgTarget(element: HTMLElement, options: { duration?: number, effect?: string } = { duration: 1200, effect: "" }) {
		if(this.displayTerritoire){
			if(element && options.effect){
				await AppUtils.timeOut(this.pinchZoom.getDefaultComponentProperties()?.transitionDuration);
			}
			const currentEffect = options.effect === "" ? "ease-out" : options.effect;
			const elementPosition = this.pinchZoom.pinchZoom.element.parentElement.getBoundingClientRect();
			let clientX = elementPosition.left;
			let clientY = elementPosition.top;
			this.pinchZoom.pinchZoom.scale = 1;
			if(element){
				const {left,top} = element.getBoundingClientRect();
				clientX = left + 50;
				clientY = top;
				this.pinchZoom.pinchZoom.scale = this.pinchZoom.pinchZoom.defaultMaxScale - 0.1;
			}
			const xCenter = (clientX - elementPosition.left) - this.pinchZoom.pinchZoom.moveX;
			const yCenter = (clientY - elementPosition.top) - this.pinchZoom.pinchZoom.moveY;
			this.pinchZoom.pinchZoom.setZoom({
				scale: this.pinchZoom.pinchZoom.scale,
				center: [xCenter, yCenter]
			});
			this.pinchZoom.pinchZoom.element.style.transition = !this.globalService.lowPerformanceModeExceptIOS || this.globalService.isDesktop 
																?`${options.duration}ms ${currentEffect}`
																: 'none'
			this.setSwiperSwiping(this.pinchZoom.isZoomedIn ? false : true)
		}
	}

	/**
	 * 
	 * @param id 
	 * @returns new journey with isFiche tag
	 */
	public createJourneyPOIFicheType(id):Ose2Journey{
		const journey = new Ose2Journey(
			{
				id,
				title: "",
				exerciseIds: [id],
				competencies: []
			},
			this.oseJourneyService.oseCompetencies,
			this.cabriService.fiches as any
		);
		this.oseJourneyService.tooltipOncePerJourney = new Array();
		journey.started = journey.isFiche = true;
		return journey;
	}

	/**
	 * Open modal based on its status
	 */
	openModal() {
		this.modalState = ModalStateName.show;
	}

	/**
	 * Highlight associated fich image of poi
	 * @param ficheId string
	 */
	highlightSelectedFichePOIImage(ficheId: string) {
		const associatedFicheImg = Array.from(this.currentPOIElement.parentNode.childNodes).find((elem: HTMLBaseElement) => {
			return elem.id === `fiche-${ficheId}-image`;
		});
		if (associatedFicheImg) {
			this.renderer.setStyle(associatedFicheImg, "transition", "opacity 0.3s linear");
			this.renderer.setStyle(associatedFicheImg, "opacity", "1");
		}
	}

	async customOnResize(): Promise<void> {
		return new Promise(async resolve => {
			super.customOnResize();
			this.checkNeedResizeModal();
			resolve();
		});
	}

	checkNeedResizeModal() {
		if (this.cardActivity?.nativeElement) {
			if (
				Array.isArray(this.selectedActivities) && !this.getRecapPOI && 
				(this.selectedActivities.length > 1 || (this.selectedActivities[0]?.isFiche && window.innerWidth <= 1200))
			) {
				// more than one poi/journey
				this.renderer.addClass(this.cardActivity.nativeElement, "card-multiple-poi");
				this.renderer.removeClass(this.cardActivity.nativeElement, "card-one-poi");
				this.calculateCardsPosition(true);
			} else if (Array.isArray(this.selectedActivities)) {
				this.renderer.removeClass(this.cardActivity.nativeElement, "card-multiple-poi");
				this.renderer.addClass(this.cardActivity.nativeElement, "card-one-poi");
				this.calculateCardsPosition();
			}
		}
	}

	getModalContent() {
		if (this.displayTerritoire) {
			this.selectedActivities = this.getCurrentPOIActivities();
		} else {
			this.selectedActivities = [this.oseJourneyService.currentJourney];
			if (this.oseJourneyService.currentJourney.quizz) {
				this.allQuizCompleted = this.oseJourneyService.currentJourney.quizz.every(q => q.completedQuizz);
			}
		}
		this.renderer.removeClass(this.cardActivity.nativeElement, "card-reduce");
		this.customOnResize();
	}

	setCompletedJourneysStatus() {
		this.oseJourneyService.journeys.forEach(journey => {
			journey.completed = journey.hasJourneyStatusMatch(this.oseStats.currentCompletedJourneys);
			if (journey.quizz?.length > 0) {
				journey.setQuizzCompletionStatuses(this.oseStats.completedExercises);
			}
		});
	}

	// /**
	//  * Set journey exercises in order to resume
	//  */
	// setJourneyExercisesStatuses() {
	// 	const checkIsAlreadyDone = (journeyId:number,exIdToCompare:number) => {
	// 		return this.oseStats.completedExercises.some(completedEx => {
	// 			if(completedEx?.associatedJourneys){
	// 				// Verify the completion status of an exercise within its journey
	// 				// without taking into account a different journey that may also include the same exercise
	// 				return completedEx.id === exIdToCompare && completedEx?.associatedJourneys?.some((currJId:number) => {
	// 					return currJId === journeyId;
	// 				})
	// 			}else{
	// 				return completedEx.id === exIdToCompare
	// 			}
				
	// 		});
	// 	}
	// 	// resumed activity is conclusion(fiche)
	// 	this.oseJourneyService.journeys.forEach((j,journeyIndex,currJourney) => {
	// 		j.exercises.forEach((ex, indexEx) => {
	// 			ex.subPartProgression = false;
	// 			ex.selected = false;
	// 			if(ex.hasSubpart){
	// 				/**
	// 				 *  Verify if all exercises contained in a subpart have been done at least once and set the variable alreadyCompleted to True of the exercise and subpart
	// 				 */
	// 				let stopIndex = j.exercises.findIndex((ex,ind) => ex.hasSubpart && ind > indexEx); 
	// 				if(stopIndex === -1){
	// 					// until last index of workshop
	// 					stopIndex = j.exercises.findIndex((currEx) => currEx.id === j.quizz?.[0]?.id);
	// 				}
	// 				// filter and keep all contained excercises of subparts
	// 				const currentSubPartActivities = j.exercises.slice(indexEx + 1, stopIndex)?.filter(ex => !ex.isEtape && !ex.displayInModal) 
	// 				if(currentSubPartActivities?.length > 0){
	// 					// Verify if the exercises have been completed at least once
	// 					const isAllSubPartsDone = currentSubPartActivities.every((currEx) => {
	// 						return checkIsAlreadyDone(j.id,currEx.id)
	// 					})

	// 					if(isAllSubPartsDone){
	// 						currentSubPartActivities.forEach((currSubPart) => currSubPart.alreadyCompleted = true)
	// 						ex.alreadyCompleted = true;
	// 					}
	// 				}else{
	// 					// subpart no contains any exercises
	// 					j.exercises[indexEx].alreadyCompleted = checkIsAlreadyDone(j.id,ex.id)
	// 				}
	// 			}else{
	// 				j.exercises[indexEx].alreadyCompleted = checkIsAlreadyDone(j.id,ex.id)
	// 			}

	// 			if(ex?.type !== OseExerciceType.fiche){
	// 				j.exercises[indexEx].alreadyCompleted = checkIsAlreadyDone(j.id,ex.id)
	// 			}
	// 		});
	// 			// all exercises of journey done so set the completed status differently from alreadyCompleted
	// 			this.oseStats.sequencesTraces.forEach(journeyTracesProgression => {
	// 					if (j.id === journeyTracesProgression.parcoursId) {
	// 						const lastExercise = journeyTracesProgression.exercises_progressions[journeyTracesProgression.exercises_progressions.length - 1];
	// 						const beforeLast = journeyTracesProgression.exercises_progressions[journeyTracesProgression.exercises_progressions.length - 2];
	// 						if (lastExercise) {
	// 							const lastExToDoIndex = j.exercises.findIndex(journeyEx => {
	// 								return Number(journeyEx.id) === Number(lastExercise.id);
	// 							});
	// 							if(beforeLast && (lastExercise.completed || beforeLast.completed && lastExercise.id === beforeLast.id)){
	// 								if (lastExToDoIndex > -1) {
	// 									for (let i = 0; i <= lastExToDoIndex; i++) {
	// 										const currEx = currJourney[journeyIndex].exercises[i];
	// 										currEx.completed = true;
	// 									}
	// 								}
	// 							}else{
	// 								if (lastExToDoIndex > -1) {j
	// 									for (let i = 0; i < lastExToDoIndex; i++) {
	// 										const currEx = currJourney[journeyIndex].exercises[i];
	// 										currEx.completed = true;
	// 									}
	// 								}
	// 							}

	// 							if(lastExToDoIndex > -1){
	// 								this.getLatestSubPartInProgress(j,lastExToDoIndex);
	// 							}
	// 						}
	// 					}
	// 			});
	// 		j.startedProgression = j.exercises.some((currEx) => currEx.completed);
	// 	});
	// }

	openDetails(type,$event:Event = null,force = false) {
		if($event){
			$event.stopPropagation();
		}
		if (type !== null && type === this.oseJourneyService.currentJourney.selectedCategoryType && !force) {
			this.initializeJourneyCategory();
		} else {
			this.oseJourneyService.currentJourney.selectedCategoryType = type;
			this.oseJourneyService.currentJourney.currentCategoryExercise = this.oseJourneyService.currentJourney.getSelectedCategory();
			this.oseJourneyService.currentJourney.currentCategoryExercise.forEach((ex) => {
				if(ex.isworkshop === "1"){
					ex.icon = "assets/icon/chisel.svg";
				}else if(ex.type === OseExerciceType.fiche){
					ex.icon = "assets/icon/book.svg";
				}else if(ex.type === OseExerciceType.quizz){
					ex.icon = "assets/icon/quiz.svg";
				}else{
					ex.icon = "assets/icon/game.svg";
				}
			})
		}
		this.detailsOpened = this.oseJourneyService.currentJourney.selectedCategoryType ? true : false;
		
		if(this.detailsOpened && !this.getRecapPOI){
			setTimeout(() => {
					const cardWrapper = this.cardActivity.nativeElement.querySelector(".card-wrapper-content");
					const offset = cardWrapper?.querySelector("."+this.oseJourneyService.currentJourney.selectedCategoryType+"-details")?.offsetTop
					if(offset){
						cardWrapper.scrollTo({ top: offset, behavior: 'smooth'});
					}
			}, 300);
		}
	}

	/**
	 * Get POI map(s)
	 */
	getCurrentPOIActivities(): Ose2Journey[] | OseMap[] {
		if(!this.getRecapPOI){
			const poiId = this.currentPOIElement.id.split("-")?.[1];
			if (poiId) {
				if (this.oseJourneyService.currentJourney) {
					return Array(this.oseJourneyService.currentJourney);
				} else {
					this.oseJourneyService.selectedPOIActivity = this.listPOI.find(poi => {
						return poi.id === Number(poiId);
					})
					return this.oseJourneyService.selectedPOIActivity?.maps;
				}
			}
		}else{
			return this.currentTerritoireFranceMapJourneys;
		}
	
	}

	/**
	 * Map activity
	 */
	async loadMapPage(swiperChange = false) {
		this.removeConfettiAnimation();
		if(!swiperChange){
			this.globalService.setSmallLoading(true);
		}
		this.selectedActivities = new Array();
		// map loaded
		this.displayTerritoire = false;
		if(!swiperChange){
			await this.loadMapSvg();
		}
		if (this.pinchZoom?.isZoomedIn) {
			this.pinchZoom.toggleZoom();
		}

		this.setSwiperSwiping(false);
		// all maps are journey's type
		this.selectedPOIType = ActivityType.journey;
		this.activitiesDOM = Array.from(this.getCurrentSvgContent.nativeElement.querySelectorAll("svg image,svg defs,svg g, svg use"));
		this.groupsElements = new Array();
		const groupFichePOI = new Array();
		this.mapPoiElements = new Array();
		this.groupImages = new Array();
		this.mapCurrentJourneys = new Array();
		this.activitiesDOM.forEach(mapElement => {
			if (mapElement.tagName === "g") {
				if(mapElement.id === "poi-group"){
					groupFichePOI.push(mapElement);
					Array.from(mapElement.children).forEach((child:HTMLBaseElement) => {
						this.mapPoiElements.push(child);
					})
				}else if (mapElement.id.startsWith("journey-")){
				let addSvgElement = true;
				const journeyId = mapElement?.id?.split("-");
				if (journeyId?.length > 0) {
					const poiElement = this.activitiesDOM.find(mapElement => {
						return mapElement?.id === `${journeyId[0]}-${journeyId[1]}-poi`;
					}) as any;

					if (poiElement?.style?.display === "none") {
						mapElement.style.opacity = "0";
						addSvgElement = false;
					}
				}
				if (addSvgElement) {
					this.groupsElements.push(mapElement);
					if (mapElement?.id?.endsWith("-image")) {
						this.groupImages.push(mapElement);
					}
					mapElement.childNodes.forEach((groupItem: HTMLBaseElement) => {
						if (groupItem.tagName === "image") {
							// add images tags
							if (groupItem.nextElementSibling) {
								// draw elements are at the same level than image. Make image no clickable
								const tagNames = ["g", "polygon", "polyline", "path", "rect"];
								const containSvgDrawElement = tagNames.includes(groupItem.nextElementSibling.tagName);
								if (containSvgDrawElement) {
									this.renderer.setStyle(groupItem, "pointer-events", "none");
								}
							}
						}
						if (groupItem.tagName === "use" || groupItem.tagName === "image") {
							if((!groupItem.parentElement) || (!groupItem.parentElement?.id?.endsWith("image"))){
								this.mapPoiElements.push(groupItem);
							}
						}
					});
					}
				}
			}
			this.getDomJourneys(mapElement)
		});
		this.groupsElements.forEach(activityElem => {
			activityElem.addEventListener("click", this.selectMAP.bind(this));
		});
		groupFichePOI.forEach(activityElem => {
			activityElem.addEventListener("click", this.readFiche.bind(this));
		});
		this.setJourneyStatuses(this.mapPoiElements);
		this.globalService.setSmallLoading(false);
		this.globalService.setGlobalLoading(false);
		if(!swiperChange){
			this.readTTSIntro();
		}
	}

	/**
	 * Skip all instances scenarios
	 */
	async skipAllScenarioSpeechSequences() {
		await this.scenarioNav?.skipMathiaSpeechSequence(true);
		await this.scenarioOseBubble?.skipMathiaSpeechSequence(true);
	}

	async goBackTerritoire() {
		this.audioService.playCancelSound();
		this.scenarioOseBubble?.skipMathiaSpeechSequence(true);
		this.selectedActivities = new Array();
		this.oseJourneyService.selectedPOIActivity = null;
		this.globalService.setGlobalLoading(true);
		await this.loadTerritoireMap();
		this.detailsOpened = false;
	}

	// /**
	//  * Add subPartProgression to the current subPart that currently being played by the user
	//  */
	// public getLatestSubPartInProgress(j,currentExerciseIndex):void{
	// 	for (let i = currentExerciseIndex; i >= 0; i--) {
	// 		const currentExercise = j.exercises[i];
	// 		let isQuizz = false;
	// 		if(j.quizz){
	// 			isQuizz = j.quizz.some((currentQuizz) => currentQuizz.id === currentExercise.id)
	// 		}
	// 		 if(currentExercise?.hasSubpart || isQuizz){
	// 			currentExercise.subPartProgression = true;
	// 			break;
	// 		}
	// 	}
	// }

	/**
	 * Returns the HTMLBaseElement of the selected POI map element.
	 * @param selectedPoiSvgElement HtmlBaseElement
	 */
	public getSelectedPoiMap(selectedPoiElement:HTMLBaseElement):HTMLBaseElement{
		let currentImgSelected;
		if (selectedPoiElement.id.includes("poi")) {
			// poi group is clicked
			currentImgSelected = Array.from(selectedPoiElement.parentElement.childNodes).find((currElement: any) => {
				return currElement && currElement.id?.includes("image");
			}) as HTMLBaseElement;
		} else {
			// image group is clicked
			currentImgSelected = selectedPoiElement;
		}
		return currentImgSelected;
	}

	readFiche($event: { target: HTMLElement }){
		if ($event.target) {
			this.isJourneyDisplayed = true;
			const id = $event.target.id.split("-")?.[1]
			if(id){
				if(this.pinchZoom.isZoomedIn){
					this.pinchZoom.toggleZoom();
				}
				this.oseJourneyService.currentJourney = this.createJourneyPOIFicheType(id);
				this.openModal();
				this.setFichePOIStatus();
				this.ficheComponent.changes.subscribe(item => {
					const ficheContainer = item.last as FicheComponent
					ficheContainer?.swiperInitializedEvent.subscribe((e) => {
						this.ficheComponent.last.document.documentElement.style.setProperty("--tooltip-width", `200px`);
						this.ficheComponent.last.document.documentElement.style.setProperty("--tooltip-minWidth", `200px`);
						if(e){
							this.setSwiperSwiping(false,this.ficheComponent.last.slideElement);
						}
					})
				});
			}
		}
	}

	/**
	 * Select map journey
	 */
	async selectMAP($event: { target: HTMLElement },openModal = true) {
		// console.error("selectMap", this.animationRunning);
		this.removeConfettiAnimation();
		if (!this.animationRunning) {
			// reset modal parameters
			const isPoiOrGroup = $event.target?.id.endsWith("poi") || $event.target?.id.endsWith("group");
			const targettedActivity = isPoiOrGroup ? $event.target : this.getClosestGroup($event.target);
			// prevent click on same element
			if (targettedActivity) {
				this.isJourneyDisplayed = true;
				this.detailsOpened = false;
				this.audioService.playSelectSound()
				const idElement = targettedActivity.id.split("-");
				this.totalExercises = this.completedExercises = null;
				this.competenciesOpen = false;
				this.displayQuizChoice = false;
				this.allQuizCompleted = false;
				this.oseJourneyService.currentJourney = this.oseJourneyService.getById(Number(idElement[1]));
				this.oseJourneyService.currentJourney.exoCompletedDuringSession = new Array();
				this.initializeJourneyCategory();
				// this.oseJourneyService.currentJourney = this.oseJourneyService.getById(24331);
				this.getSeqCategories();
				this.alreadyCompletedJourney = this.oseStats.currentCompletedJourneys.some((compJ) => compJ.id === this.oseJourneyService.currentJourney.id)
				
				// title //
				this.modalState = ModalStateName.hide;
				this.cd.detectChanges();
				this.currentPOIElement = targettedActivity;
				if (targettedActivity?.childNodes) {
					// get group image element
					let currentImageSelected = this.getSelectedPoiMap(targettedActivity);
					if (currentImageSelected) {
						this.groupImages.forEach(groupElement => {
							if (groupElement?.id.includes("image")) {
								// Extract the dynamic id from the clicked id and parent id
								const selectedId = currentImageSelected.id?.match(/^journey-(\d+)-image/)?.[1];
								const parentId = groupElement.id?.match(/^journey-(\d+)-image/)?.[1];
								this.renderer.setStyle(groupElement, "opacity", selectedId !== parentId ? "0" : "1");
							}
						});
					}
				}

				if(!this.displayTerritoire){
					this.resizeCardMaxHeight();
				}
				if (this.oseJourneyService.currentJourney.quizz) {
					this.allQuizCompleted = this.oseJourneyService.currentJourney.quizz.every(q => q.completedQuizz);
				}
				if(openModal){
					this.openModal();
				}
				const notDoneLastExercise = this.oseJourneyService.currentJourney.exercises.slice().reverse().find(ex => ex.completed);
				this.canJourneyResume = this.oseJourneyService.currentJourney.isLastExerciseCompleted(notDoneLastExercise);
				if(!this.canJourneyResume){
					this.oseJourneyService.currentJourney.setAllExercisesStatuses(false);
				}
				
				const {completedExercises,totalExercises } = this.oseJourneyService.currentJourney.getProgressionJourney();
				this.completedExercises = completedExercises;
				this.totalExercises = totalExercises;
				if(!this.canJourneyResume){
					this.oseJourneyService.currentJourney.lrsStart = true;
					this.oseJourneyService.currentJourney.setAllExercisesStatuses(false);
				}
			}
		}
	}

	getSeqCategories() {
		const { workshops, learnings, evaluations } = this.oseJourneyService.currentJourney.initializeCategoryExercises();
		this.oseJourneyService.currentJourney.workshops = workshops;
		this.oseJourneyService.currentJourney.learnings = learnings;
		this.oseJourneyService.currentJourney.evaluations = evaluations;
	}

	/**
	 * Resize card height in order to take full window height
	 */
	resizeCardMaxHeight() {
		AppUtils.timeOut(50).then(async () => {
			let counter = 0;
			while (this.cardActivity.nativeElement.offsetTop === 0 && counter < 50) {
				await AppUtils.timeOut(10);
				counter++;
			}
			this.cardActivity.nativeElement.style.maxHeight =
			window.innerHeight - this.globalService.toolbarHeight - this.cardActivity.nativeElement.offsetTop - 15 + "px";
		});
	}

	ionViewWillLeave() {
		super.ionViewWillLeave();
		this.lastTerritoireSwiperIndex = null
		this.removeListeners();
		this.getCurrentSvgContent.nativeElement.innerHTML = "";
		this.removeDOMSVGElements()
		this.first = true;
		this.selectedActivities = new Array();
		this.currentPOIElement = null;
		this.oseStats.prevCompletedJourneys = null;
		this.skipAllScenarioSpeechSequences();
		this.newStatementSendSubscription?.unsubscribe();
	}

	public removeDOMSVGElements(){
		const removeItems = (arrayItem:Array<any>,elementArray:Array<ElementRef>) => {
			if(arrayItem){
				arrayItem.forEach((currentItem,index) => {
					if(elementArray[index]?.nativeElement?.innerHTML){
						elementArray[index].nativeElement.innerHTML = "";
					}
				})
			}
		}

		// maps
		removeItems(this.oseJourneyService.selectedPOIActivity?.maps,this.mapSvgContents.toArray());
		// terrioire
		removeItems(this.territoireMapsToDisplay,this.territoireSvgContent.toArray());
	}

	public removeListeners(){
		AppUtils.removeElementListener(this.getCurrentSvgContent.nativeElement, ["wheel","touchstart","touchmove","touchend"]);
		AppUtils.removeElementListener(this.svgTerritoire,["mousedown","mouseup","touchstart","touchend"]);
	}

	grimoire($event) {
		if (this.isJourneyDisplayed) {
			$event.stopPropagation();
			this.competenciesOpen = !this.competenciesOpen;
		}
	}

	async onSwiperChange(swiper: Swiper) {
		if (this.swiper) {
			this.closeModal();
			this.calculateCardsPosition();
			if(this.displayTerritoire){
				this.getRecapPOI = false;
				const i = this.swiper.activeIndex;
				const item = [...this.territoireMapsToDisplay, ...this.cabriService.territoires].find((currMap,index) => i === index)
				if(item){
					this.currentTerritoireMap = cloneDeep(item);
					this.currentTerritoireMap.title = item.title + (i > this.territoireMapsToDisplay.length - 1 ? " (verrouillé)" : "");
				}
				const realMap = this.territoireMapsToDisplay.some((item,index) => index === i);
				if(realMap){
					if(!this.lastTerritoireSwiperIndex){
						this.removeListeners();
						await this.loadTerritoireMap(true)
					}
				}else{
					this.currentTerritoireFranceMapJourneys = new Array();
					await this.skipAllScenarioSpeechSequences();
					if (this.pinchZoom.isZoomedIn) {
						this.pinchZoom.toggleZoom();
					}
				}
			}else{
				if(this.oseJourneyService.selectedPOIActivity?.maps?.length > 1){
					const activity = this.oseJourneyService.selectedPOIActivity.maps[this.swiper.activeIndex];
					this.oseJourneyService.currentMap = activity;
					await this.loadMapPage(true);
				}
			}
		}
	}

	public get getCurrentSvgContent():ElementRef{
		if(!this.displayTerritoire){
			const currentIndex = this.oseJourneyService.selectedPOIActivity.maps.findIndex((selectedActivity) => {
				return selectedActivity.id === this.oseJourneyService.currentMap.id
			})
			return this.mapSvgContents.toArray()[currentIndex];
		}else{
			let index = this.territoireMapsToDisplay.findIndex((item,index) => index === this.swiper.activeIndex);
			if(index === -1){
				// security
				index = 0;
			}
			return this.territoireSvgContent.toArray()[index];
		}
	}

	nextSlide() {
		this.setSwiperSpeed(this.cabriService.territoires.length);
		if(this.displayTerritoire){
			this.currentTerritoireFranceMapJourneys = new Array();
			this.swiper.slideNext(this.swiperSlideChangeSpeed);
		}else{
			if(this.swiper.activeIndex < this.oseJourneyService.selectedPOIActivity.maps.length - 1){
				this.swiper.slideNext(this.swiperSlideChangeSpeed);
				this.oseJourneyService.currentMap = this.oseJourneyService.selectedPOIActivity.maps.find((currMap,index) => index === this.swiper.activeIndex);
				this.readTTSIntro();
			}
		}
	}

	public get swiperSlideChangeSpeed(){
		if(((navigator.userAgent.indexOf('Firefox') > -1 && navigator.userAgent.indexOf('Chrome') == -1) &&  this.oseJourneyService.selectedPOIActivity?.maps?.length > 0) 
		|| (this.globalService.lowPerformanceMode || this.globalService.isIos)){
			return 0
		}else{
			return this.swiper.originalParams.speed
		}
	}

	prevSlide() {
		this.setSwiperSpeed(0);
		this.swiper.slidePrev(this.swiperSlideChangeSpeed);
		if(!this.displayTerritoire){
			this.oseJourneyService.currentMap = this.oseJourneyService.selectedPOIActivity.maps.find((currMap,index) => index === this.swiper.activeIndex);
			this.readTTSIntro();
		}
	}

	/**
	 * To calculate the starting and ending positions of cards(poi/fiche/journey) based on the swiper index for an Angular animation
	 */
	calculateCardsPosition(isMulti = false){
		let finalPosition;
		let disappearPosition;
		if(isMulti){
			const modalSizeAsPerc = 80;
			const modalCenteredPos = 50;
			this.cardActivity.nativeElement.style.setProperty("--modalFullSize", String(modalSizeAsPerc) + "%");
			disappearPosition = String(-(this.swiper.activeIndex*100) - modalCenteredPos)+"%"
			if(!this.displayTerritoire){
				finalPosition = String(modalCenteredPos -(this.swiper.activeIndex*100))+"%";
			}else{
				finalPosition = "50%";
			}
		}else{
			let modalMarginSwiperNextPrevBtn = 0;
			if(!this.displayTerritoire){
				modalMarginSwiperNextPrevBtn = this.swiper.activeIndex > 0 || this.swiper.activeIndex < this.oseJourneyService.selectedPOIActivity?.maps.length - 1 ? 2 : 0;
			}
			if(this.oseJourneyService.selectedPOIActivity?.maps.length > 1 && !this.displayTerritoire){
				finalPosition = String(-(this.swiper.activeIndex*100) + modalMarginSwiperNextPrevBtn)+"%";
				disappearPosition = String(-(this.swiper.activeIndex*100) - 40)+"%";
			}else{
				finalPosition = "0%";
				disappearPosition = "-40%";
			}
		}

		this.modalFinalPosition = finalPosition;
		this.modalDisappearPosition = disappearPosition;
	}

	setSwiperSpeed(index: number) {
		const i = this.swiper.activeIndex;
		if (i === index) {
			this.slideTerritoire?.updateParameter("speed", 0);
		} else {
			if (this.swiper.params.speed !== 300) {
				this.slideTerritoire?.updateParameter("speed", 300);
			}
		}
	}

	async onWheel(){
		await AppUtils.timeOut(this.pinchZoom.getDefaultComponentProperties()?.transitionDuration);
		this.setSwiperSwiping(this.pinchZoom.isZoomedIn ? false : true)
	}

	public touchstart(e){
		if (e.touches?.length === 2) {
			this.scaling = true;
		}
	}

	public touchmove(){
		if (this.scaling) {
			this.setSwiperSwiping(this.pinchZoom.isZoomedIn ? false : true)
		}
	}
	public touchend(){
		this.scaling = false;
	}


	public recapPOI(){
		this.dropDownIndex = null;
		if(this.currentTerritoireMap.type === TerritoireMaps.france){
			this.getRecapPOI = true;
			this.openModal();
			this.cd.detectChanges();
		}
	}

	/**
	 * Show the details of the selected journey when the dropdown is clicked 
	 * @param currentJourney Ose2Journey
	 * @param activityIndex number
	 */
	showSelectedJourney(currentJourney:Ose2Journey,activityIndex:number){
		let initialDuration = 550;
		if(this.pinchZoom.isZoomedIn && this.expansionPanels.toArray()[activityIndex]?.expanded){
			initialDuration = 0;
		}
		this.pinchZoomOnSvgTarget(null,{duration:initialDuration,effect:PinchZoomTransition.secondary});
		if(this.expansionPanels.toArray()[activityIndex]?.expanded){
			this.dropDownIndex = activityIndex + 1;
			// before the clicked dropdown opens close all panels already opened
			this.expansionPanels.toArray().forEach((currentItem) => currentItem.close());
			this.expansionPanels.toArray()[activityIndex].open();
			// select random DOM element among all themes available on map
			const allAssociatedPOI = this.getCurrentSvgContent.nativeElement.querySelectorAll(`[id^='journey-${currentJourney.id}-poi']`);
			const currentItem = allAssociatedPOI[AppUtils.getRandomIntInclusive(0,allAssociatedPOI.length -1)];
			this.selectedPOIType = ActivityType.journey;
			this.selectMAP({ target: currentItem },false);
			this.pinchZoomOnSvgTarget(currentItem,{duration:1050,effect:PinchZoomTransition.secondary});
			if(!this.globalService.isDesktop){
				this.cd.detectChanges();
			}
		}else{
			this.audioService.playCancelSound();
			this.dropDownIndex = null;
		}
	}

	public get env(){
		return environment.production ? 'prod' : 'dev';
	}

}


