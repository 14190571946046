import { HttpClient, HttpErrorResponse, HttpXhrBackend } from "@angular/common/http";
import { catchError, lastValueFrom, map, Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { AppLanguage } from "./models/enums/enum-list";

declare var window: any;

export class Fraction extends Number implements Number {
	numerateur: number;
	denominateur: number;

	constructor(numerateur, denominateur) {
		super();
		this.numerateur = numerateur;
		this.denominateur = denominateur;
	}

	toLatex() {
		return `$$\\frac{${this.numerateur}}{${this.denominateur}}$$`;
	}

	toString(): string {
		return this.numerateur + "/" + this.denominateur;
	}

	valueOf(): number {
		return this.numerateur / this.denominateur;
	}
}
export class AppUtils {
	static preventBackButton() {
		window.history.pushState(null, "", document.URL);
	}
	static months = [
		"Janvier",
		"Fevrier",
		"Mars",
		"Avril",
		"Mai",
		"Juin",
		"Juillet",
		"Août",
		"Septembre",
		"Octobre",
		"Novembre",
		"Decembre"
	];
	/**
	 * Circular replacer for Json stringify
	 */
	static getCircularReplacer() {
		const seen = new WeakSet();
		return (key, value) => {
			if (typeof value === "object" && value !== null) {
				if (seen.has(value)) {
					return;
				}
				seen.add(value);
			}
			return value;
		};
	}

	static encodeURI(uri) {
		return encodeURIComponent(JSON.stringify(uri));
	}

	static decodeHTMLEntities(text: string) {
		var textArea = document.createElement("textarea");
		textArea.innerHTML = text;
		return textArea.value;
	}

	static toFixed = (number, n) => {
		const reg = new RegExp("^-?\\d+(?:\\.\\d{0," + n + "})?", "g");
		const a = number.toString().match(reg)[0];
		const dot = a.indexOf(".");
		if (dot === -1) {
			// integer, insert decimal dot and pad up zeros
			return a + "." + "0".repeat(n);
		}
		const b = n - (a.length - dot) + 1;
		return b > 0 ? a + "0".repeat(b) : a;
	};

	static modulo(val, step) {
		const valDecCount = (val.toString().split(".")[1] || "").length;
		const stepDecCount = (step.toString().split(".")[1] || "").length;
		const decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount;
		const valInt = Number(val.toFixed(decCount).replace(".", ""));
		const stepInt = Number(step.toFixed(decCount).replace(".", ""));
		return (valInt % stepInt) / Math.pow(10, decCount);
	}

	/**
	 * Creates guid
	 * @returns guid string
	 */
	static createGuid(): string {
		// tslint:disable-next-line: only-arrow-functions
		return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
			// tslint:disable-next-line: no-bitwise
			const r = (Math.random() * 16) | 0,
				// tslint:disable-next-line: no-bitwise
				v = c === "x" ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	}

	/**
	 * check if a file exist at a given url synchronously
	 */
	static async doesFileExist(urlToFile: string) {
		return new Promise<boolean>(resolve => {
			try {
				const xhr = new XMLHttpRequest();
				xhr.open("GET", urlToFile, false);
				xhr.onload = e => {
					// apache returns 200 (redirect to /) not as ionic serve.
					resolve(xhr.status !== 404 && xhr.responseText !== "" && xhr.responseText.indexOf("<!DOCTYPE html>") === -1);
				};
				xhr.onerror = e => {
					resolve(false);
				};
				xhr.send(null);
			} catch (e) {
				resolve(false);
			}
		});
	}

	/**
	 * Custom async setTimeOut for waiting end of exe
	 */
	static async timeOut(ms, callback: any = null) {
		return new Promise<void>(resolve =>
			setTimeout(() => {
				if (callback) {
					callback();
				}
				resolve();
			}, ms)
		);
	}

	static clearWebGL(gl, cabri = false) {
		if (gl) {
			const buf = gl.createBuffer();
			gl.bindBuffer(gl.ARRAY_BUFFER, buf);
			const numAttributes = gl.getParameter(gl.MAX_VERTEX_ATTRIBS);
			for (let attrib = 0; attrib < numAttributes; ++attrib) {
				gl.vertexAttribPointer(attrib, 1, gl.FLOAT, false, 0, 0);
			}

			let unit = 0;
			while (gl.constructor.prototype.hasOwnProperty("TEXTURE" + unit)) {
				// console.log("clean TEXTURE" + unit);
				gl.activeTexture(gl.TEXTURE0 + unit);
				gl.bindTexture(gl.TEXTURE_2D, null);
				gl.bindTexture(gl.TEXTURE_CUBE_MAP, null);
				unit++;
			}

			gl.bindBuffer(gl.ARRAY_BUFFER, null);
			gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);
			gl.bindRenderbuffer(gl.RENDERBUFFER, null);
			gl.bindFramebuffer(gl.FRAMEBUFFER, null);

			if (!cabri) {
				for (let i = 0; i < 10; i++) {
					gl.clear(gl.COLOR_BUFFER_BIT);
					gl.clear(gl.DEPTH_BUFFER_BIT);
					gl.clear(gl.STENCIL_BUFFER_BIT);
				}
				gl.canvas.width = 1;
				gl.canvas.height = 1;
				for (let i = 0; i < 10; i++) {
					gl.clear(gl.COLOR_BUFFER_BIT);
					gl.clear(gl.DEPTH_BUFFER_BIT);
					gl.clear(gl.STENCIL_BUFFER_BIT);
				}
				this.resetToInitialState(gl);

				// delete webgl context
				gl.getExtension("WEBGL_lose_context")?.loseContext();
			}
		}
	}

	static resetToInitialState = ctx => {
		const isWebGL2RenderingContext = !!ctx.createTransformFeedback;

		if (isWebGL2RenderingContext) {
			ctx.bindVertexArray(null);
		}

		const numAttribs = ctx.getParameter(ctx.MAX_VERTEX_ATTRIBS);
		const tmp = ctx.createBuffer();
		ctx.bindBuffer(ctx.ARRAY_BUFFER, tmp);
		for (let ii = 0; ii < numAttribs; ++ii) {
			ctx.disableVertexAttribArray(ii);
			ctx.vertexAttribPointer(ii, 4, ctx.FLOAT, false, 0, 0);
			ctx.vertexAttrib1f(ii, 0);
			if (isWebGL2RenderingContext) {
				ctx.vertexAttribDivisor(ii, 0);
			}
		}
		ctx.deleteBuffer(tmp);

		const numTextureUnits = ctx.getParameter(ctx.MAX_TEXTURE_IMAGE_UNITS);
		for (let ii = 0; ii < numTextureUnits; ++ii) {
			ctx.activeTexture(ctx.TEXTURE0 + ii);
			ctx.bindTexture(ctx.TEXTURE_CUBE_MAP, null);
			ctx.bindTexture(ctx.TEXTURE_2D, null);
			if (isWebGL2RenderingContext) {
				ctx.bindTexture(ctx.TEXTURE_2D_ARRAY, null);
				ctx.bindTexture(ctx.TEXTURE_3D, null);
				ctx.bindSampler(ii, null);
			}
		}

		ctx.activeTexture(ctx.TEXTURE0);
		ctx.useProgram(null);
		ctx.bindBuffer(ctx.ARRAY_BUFFER, null);
		ctx.bindBuffer(ctx.ELEMENT_ARRAY_BUFFER, null);
		ctx.bindFramebuffer(ctx.FRAMEBUFFER, null);
		ctx.bindRenderbuffer(ctx.RENDERBUFFER, null);
		ctx.disable(ctx.BLEND);
		ctx.disable(ctx.CULL_FACE);
		ctx.disable(ctx.DEPTH_TEST);
		ctx.disable(ctx.DITHER);
		ctx.disable(ctx.SCISSOR_TEST);
		ctx.blendColor(0, 0, 0, 0);
		ctx.blendEquation(ctx.FUNC_ADD);
		ctx.blendFunc(ctx.ONE, ctx.ZERO);
		ctx.clearColor(0, 0, 0, 0);
		ctx.clearDepth(1);
		ctx.clearStencil(-1);
		ctx.colorMask(true, true, true, true);
		ctx.cullFace(ctx.BACK);
		ctx.depthFunc(ctx.LESS);
		ctx.depthMask(true);
		ctx.depthRange(0, 1);
		ctx.frontFace(ctx.CCW);
		ctx.hint(ctx.GENERATE_MIPMAP_HINT, ctx.DONT_CARE);
		ctx.lineWidth(1);
		ctx.pixelStorei(ctx.PACK_ALIGNMENT, 4);
		ctx.pixelStorei(ctx.UNPACK_ALIGNMENT, 4);
		ctx.pixelStorei(ctx.UNPACK_FLIP_Y_WEBGL, false);
		ctx.pixelStorei(ctx.UNPACK_PREMULTIPLY_ALPHA_WEBGL, false);
		// TODO: Delete this IF.
		if (ctx.UNPACK_COLORSPACE_CONVERSION_WEBGL) {
			ctx.pixelStorei(ctx.UNPACK_COLORSPACE_CONVERSION_WEBGL, ctx.BROWSER_DEFAULT_WEBGL);
		}
		ctx.polygonOffset(0, 0);
		ctx.sampleCoverage(1, false);
		ctx.scissor(0, 0, ctx.canvas.width, ctx.canvas.height);
		ctx.stencilFunc(ctx.ALWAYS, 0, 0xffffffff);
		ctx.stencilMask(0xffffffff);
		ctx.stencilOp(ctx.KEEP, ctx.KEEP, ctx.KEEP);
		ctx.viewport(0, 0, ctx.canvas.width, ctx.canvas.height);

		ctx.clear(ctx.COLOR_BUFFER_BIT);
		ctx.clear(ctx.DEPTH_BUFFER_BIT);
		ctx.clear(ctx.STENCIL_BUFFER_BIT);

		if (isWebGL2RenderingContext) {
			ctx.drawBuffers([ctx.BACK]);
			ctx.readBuffer(ctx.BACK);
			ctx.bindBuffer(ctx.COPY_READ_BUFFER, null);
			ctx.bindBuffer(ctx.COPY_WRITE_BUFFER, null);
			ctx.bindBuffer(ctx.PIXEL_PACK_BUFFER, null);
			ctx.bindBuffer(ctx.PIXEL_UNPACK_BUFFER, null);
			const numTransformFeedbacks = ctx.getParameter(ctx.MAX_TRANSFORM_FEEDBACK_SEPARATE_ATTRIBS);
			for (let ii = 0; ii < numTransformFeedbacks; ++ii) {
				ctx.bindBufferBase(ctx.TRANSFORM_FEEDBACK_BUFFER, ii, null);
			}
			const numUBOs = ctx.getParameter(ctx.MAX_UNIFORM_BUFFER_BINDINGS);
			for (let ii = 0; ii < numUBOs; ++ii) {
				ctx.bindBufferBase(ctx.UNIFORM_BUFFER, ii, null);
			}
			ctx.disable(ctx.RASTERIZER_DISCARD);
			ctx.pixelStorei(ctx.UNPACK_IMAGE_HEIGHT, 0);
			ctx.pixelStorei(ctx.UNPACK_SKIP_IMAGES, 0);
			ctx.pixelStorei(ctx.UNPACK_ROW_LENGTH, 0);
			ctx.pixelStorei(ctx.UNPACK_SKIP_ROWS, 0);
			ctx.pixelStorei(ctx.UNPACK_SKIP_PIXELS, 0);
			ctx.pixelStorei(ctx.PACK_ROW_LENGTH, 0);
			ctx.pixelStorei(ctx.PACK_SKIP_ROWS, 0);
			ctx.pixelStorei(ctx.PACK_SKIP_PIXELS, 0);
			ctx.hint(ctx.FRAGMENT_SHADER_DERIVATIVE_HINT, ctx.DONT_CARE);
		}

		// TODO: This should NOT be needed but Firefox fails with 'hint'
		while (ctx.getError()) {}
	};

	/**
	 * Convert number to letter
	 */
	static numberToLetter(nombre, lang: string = null, U = null, D = null) {
		let numberToLetter = "";
		if (lang === AppLanguage.EN) {
			// en-US
			const numbers = {
				0: "zero",
				1: "one",
				2: "two",
				3: "three",
				4: "four",
				5: "five",
				6: "six",
				7: "seven",
				8: "eight",
				9: "nine",
				10: "ten",
				11: "eleven",
				12: "twelve",
				13: "thirteen",
				14: "fourteen",
				15: "fifteen",
				16: "sixteen",
				17: "seventeen",
				18: "eighteen",
				19: "nineteen",
				20: "twenty",
				30: "thirty",
				40: "forty",
				50: "fifty",
				60: "sixty",
				70: "seventy",
				80: "eighty",
				90: "ninety"
			};
			const numberScales = [
				"",
				"",
				"thousand",
				"million",
				"billion",
				"trillion",
				"quadrillion",
				"quintillion",
				"sextillion",
				"septillion",
				"octillion",
				"nonillion",
				"decillion",
				"undecillion",
				"duodecilion",
				"tredecilion",
				"quattuordecilion",
				"quindecilion",
				"sexdecillion",
				"septendecilion",
				"octodecilion",
				"novemdecilion",
				"vigintilion"
			];

			//   const convertNumberToString = amount => {
			const amountString = nombre.toString();
			const splitAmountArray = amountString.split(".");
			const amountIntegerString = splitAmountArray[0];

			const tripleStack = [];
			let n, quotient, reste, nb;
			let numberToLetter = "";
			if (nombre === 81) {
				return "eighty one";
			}
			if (nombre.toString().replace(/ /gi, "").length > 15) {
				return "out of limit";
			}
			if (isNaN(nombre.toString().replace(/ /gi, ""))) {
				return nombre;
			}

			for (let i = amountIntegerString.length; i > 0; i -= 3) {
				const startIndex = i - 3 < 0 ? 0 : i - 3;
				const tripleString = amountIntegerString.slice(startIndex, i);
				const tripleNum = parseInt(tripleString, 10);

				tripleStack.push(tripleNum);
			}

			tripleStack.reverse();
			tripleStack.forEach(function (triple, index) {
				const scalePosition = tripleStack.length - index;

				if (triple < 20) {
					numberToLetter += ` ${numbers[triple]}`;
				} else if (triple < 100) {
					const tensValue = Math.floor(triple / 10) * 10;
					const onesValue = triple - tensValue;
					if (onesValue === 0) {
						numberToLetter += ` ${numbers[tensValue]}`;
					} else {
						numberToLetter += ` ${numbers[tensValue]}-${numbers[onesValue]}`;
					}
				} else {
					const hundredsValue = Math.floor(triple / 100) * 100;
					const realTensValue = triple - hundredsValue;
					console.log("triple " + triple + " hundreds: " + hundredsValue + " realTens: " + realTensValue);
					const tensValue = Math.floor(realTensValue / 10) * 10;
					const onesValue = realTensValue - tensValue;

					if (hundredsValue > 0) {
						numberToLetter += ` ${numbers[hundredsValue / 100]} hundred`;
					}
					if (realTensValue < 20) {
						numberToLetter += ` ${numbers[realTensValue]}`;
					} else {
						if (onesValue === 0) {
							numberToLetter += ` ${numbers[tensValue]}`;
						} else {
							numberToLetter += ` ${numbers[tensValue]}-${numbers[onesValue]}`;
						}
					}
				}
				let scale = numberScales[scalePosition];
				if (scale === undefined) scale = "bajillion";
				numberToLetter += ` ${scale}`;
			});
			return numberToLetter;
		} else {
			nombre = Number(nombre);
			const letter = {
				0: "zéro",
				1: "un",
				2: "deux",
				3: "trois",
				4: "quatre",
				5: "cinq",
				6: "six",
				7: "sept",
				8: "huit",
				9: "neuf",
				10: "dix",
				11: "onze",
				12: "douze",
				13: "treize",
				14: "quatorze",
				15: "quinze",
				16: "seize",
				17: "dix-sept",
				18: "dix-huit",
				19: "dix-neuf",
				20: "vingt",
				30: "trente",
				40: "quarante",
				50: "cinquante",
				60: "soixante",
				70: "soixante-dix",
				80: "quatre-vingt",
				90: "quatre-vingt-dix"
			};
			let n, quotient, reste, nb;

			// manage exceptions
			if (nombre === 81) {
				return "quatre-vingt-un";
			}
			if (nombre === 71) {
				return "soixante-et-onze";
			}

			if (nombre.toString().replace(/ /gi, "").length > 15) {
				return "dépassement de capacité";
			}
			if (isNaN(nombre.toString().replace(/ /gi, ""))) {
				return nombre;
			}

			// cas nominal
			nb = parseFloat(nombre.toString().replace(/ /gi, ""));
			if (Math.ceil(nb) !== nb) {
				nb = nombre.toString().split(".");
				return this.numberToLetter(nb[0]) + (U ? " " + U + " et " : " virgule ") + this.numberToLetter(nb[1]) + (D ? " " + D : "");
			}

			n = nb.toString().length;
			switch (n) {
				case 1:
					numberToLetter = letter[nb];
					break;
				case 2:
					if (nb > 19) {
						quotient = Math.floor(nb / 10);
						reste = nb % 10;
						if (nb < 71 || (nb > 79 && nb < 91)) {
							if (reste === 0) {
								numberToLetter = letter[quotient * 10];
							}
							if (reste === 1) {
								numberToLetter = letter[quotient * 10] + "-et-" + letter[reste];
							}
							if (reste > 1) {
								numberToLetter = letter[quotient * 10] + "-" + letter[reste];
							}
						} else {
							numberToLetter = letter[(quotient - 1) * 10] + "-" + letter[10 + reste];
						}
					} else {
						numberToLetter = letter[nb];
					}
					break;
				case 3:
					quotient = Math.floor(nb / 100);
					reste = nb % 100;
					if (quotient === 1 && reste === 0) {
						numberToLetter = "cent";
					}
					if (quotient === 1 && reste !== 0) {
						numberToLetter = "cent" + "-" + this.numberToLetter(reste);
					}
					if (quotient > 1 && reste === 0) {
						numberToLetter = letter[quotient] + "-cents";
					}
					if (quotient > 1 && reste !== 0) {
						numberToLetter = letter[quotient] + "-cent-" + this.numberToLetter(reste);
					}
					break;
				case 4:
				case 5:
				case 6:
					quotient = Math.floor(nb / 1000);
					reste = nb - quotient * 1000;
					if (quotient === 1 && reste === 0) {
						numberToLetter = "mille";
					}
					if (quotient === 1 && reste !== 0) {
						numberToLetter = "mille" + "-" + this.numberToLetter(reste);
					}
					if (quotient > 1 && reste === 0) {
						numberToLetter = this.numberToLetter(quotient) + "-mille";
					}
					if (quotient > 1 && reste !== 0) {
						numberToLetter = this.numberToLetter(quotient) + "-mille-" + this.numberToLetter(reste);
					}
					break;
				case 7:
				case 8:
				case 9:
					quotient = Math.floor(nb / 1000000);
					reste = nb % 1000000;
					if (quotient === 1 && reste === 0) {
						numberToLetter = "un million";
					}
					if (quotient === 1 && reste !== 0) {
						numberToLetter = "un million" + " " + this.numberToLetter(reste);
					}
					if (quotient > 1 && reste === 0) {
						numberToLetter = this.numberToLetter(quotient) + "-millions";
					}
					if (quotient > 1 && reste !== 0) {
						numberToLetter = this.numberToLetter(quotient) + "-millions-" + this.numberToLetter(reste);
					}
					break;
				case 10:
				case 11:
				case 12:
					quotient = Math.floor(nb / 1000000000);
					reste = nb - quotient * 1000000000;
					if (quotient === 1 && reste === 0) {
						numberToLetter = "un milliard";
					}
					if (quotient === 1 && reste !== 0) {
						numberToLetter = "un milliard" + "-" + this.numberToLetter(reste);
					}
					if (quotient > 1 && reste === 0) {
						numberToLetter = this.numberToLetter(quotient) + "-milliards";
					}
					if (quotient > 1 && reste !== 0) {
						numberToLetter = this.numberToLetter(quotient) + "-milliards-" + this.numberToLetter(reste);
					}
					break;
				case 13:
				case 14:
				case 15:
					quotient = Math.floor(nb / 1000000000000);
					reste = nb - quotient * 1000000000000;
					if (quotient === 1 && reste === 0) {
						numberToLetter = "un billion";
					}
					if (quotient === 1 && reste !== 0) {
						numberToLetter = "un billion" + "-" + this.numberToLetter(reste);
					}
					if (quotient > 1 && reste === 0) {
						numberToLetter = this.numberToLetter(quotient) + "-billions";
					}
					if (quotient > 1 && reste !== 0) {
						numberToLetter = this.numberToLetter(quotient) + "-billions-" + this.numberToLetter(reste);
					}
					break;
			}
			/*respect de l'accord de quatre-vingt*/
			if (numberToLetter.substr(numberToLetter.length - "quatre-vingt".length, "quatre-vingt".length) === "quatre-vingt") {
				numberToLetter = numberToLetter + "s";
			}
		}

		return numberToLetter;
	}

	static longestWord(sentence) {
		let splittedSentence = sentence.split(/(\s|-)/);
		let maxLength = 0;
		let maxWord = "";

		splittedSentence.map(word => {
			if (word.length > maxLength) {
				maxLength = word.length;
				maxWord = word;
			}
		});

		return maxWord;
	}

	static removeLocalSotageItems(localStorageItem:Array<string> | string){
		if(Array.isArray(localStorageItem)){
			for (const key of localStorageItem) {
				localStorage.removeItem(key);
			}
		}else{
			localStorage.removeItem(localStorageItem);
		}
	}
	static setCabriBackground(http: HttpClient, texture, textureContext, imageUrl, canvasWidth, canvasHeight) {
		return new Promise<void>((resolve, reject) => {
			http.get(imageUrl, {
				responseType: "blob"
			}).subscribe(
				blob => {
					const o = new Image();
					o.src = window.URL.createObjectURL(blob);
					o.onload = () => {
						let targetWidth, targetHeight;
						// console.error(o.width, o.height, o.width / o.height);
						// console.error(canvasWidth/canvasHeight);
						if (o.width / o.height > canvasWidth / canvasHeight) {
							targetHeight = canvasHeight;
							targetWidth = (canvasHeight * o.width) / o.height;
							textureContext.drawImage(o, -(targetWidth - canvasWidth) / 2, 0, targetWidth, targetHeight);
						} else {
							targetHeight = (canvasWidth * o.height) / o.width;
							targetWidth = canvasWidth;
							textureContext.drawImage(o, 0, -(targetHeight - canvasHeight) / 2, targetWidth, targetHeight);
						}
						// console.error(targetWidth + " x " + targetHeight);
						texture.update();
					};
					resolve();
				},
				error => {
					reject(error);
				}
			);
		});
	}

	static getRandomIntInclusive(min, max): number {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	static shuffleArray(array: Array<any>): void {
		let counter = array.length;

		// While there are elements in the array
		while (counter > 0) {
			// Pick a random index
			const index = Math.floor(Math.random() * counter);

			// Decrease counter by 1
			counter--;

			// And swap the last element with it
			const temp = array[counter];
			array[counter] = array[index];
			array[index] = temp;
		}
	}

	static debug(title, variable?) {
		if (!environment.production) {
			if (variable) {
				console.error(title, variable);
			} else {
				console.error(title);
			}
		}
	}

	static getKeyByValue(object, value) {
		const keyFound = Object.keys(object).find(key => {
			const key2 = object[key];
			return key2.includes(value);
		});
		return keyFound ? keyFound : null;
	}

	static find(haystack, callback) {
		let item;
		for (let i = 0; i < haystack.length; i++) {
			const element = haystack[i];
			if (callback(element)) {
				item = element;
				break;
			}
		}
		return item;
	}

	static removeLineBreaksFromString(string: string) {
		return string.replace(/[\r\n]/gm, "");
	}

	static addNBSP(e) {
		e = e.replace(new RegExp(/ \!/, "g"), "&nbsp;!");
		e = e.replace(new RegExp(/ \?/, "g"), "&nbsp;?");
		e = e.replace(new RegExp(/ \:/, "g"), "&nbsp;:");
		return e;
	}
	static objectify(array) {
		return array.reduce((p, c) => {
			p[c[0]] = c[1];
			return p;
		}, {});
	}

	static fileExists(url: string): Promise<boolean> {
		const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
		return lastValueFrom(
			httpClient.get(url).pipe(
				map(response => {
					return true;
				}),
				catchError((error: HttpErrorResponse) => {
					if (error.status === 200) {
						return of(true);
					} else return of(false);
				})
			)
		);
	}

	static removeElementListener(element, eventName: string | Array<string>) {
		const removeListener = evt => {
			element.eventListeners(evt).forEach(currentEvent => {
				if (currentEvent) {
					element.removeEventListener(evt, currentEvent);
				}
			});
		};
		if (element) {
			if (Array.isArray(eventName)) {
				eventName.forEach(evt => {
					removeListener(evt);
				});
			} else {
				removeListener(eventName);
			}
		}
	}
}
export class PausableTimer {
	timerId: NodeJS.Timeout;
	startTime: number;
	remainingTime: number;
	callback: any;
	delay: number;
	ended: boolean;
	paused: boolean;
	constructor(callback, delay: number) {
		this.delay = delay;
		this.remainingTime = delay;
		this.callback = () => {
			callback();
			this.ended = true;
			AppUtils.debug("timer END");
		};
		this.start();
	}

	public start(delay?: number) {
		this.startTime = new Date().getTime();
		clearTimeout(this.timerId);
		if (delay) {
			this.remainingTime = this.delay = delay;
		} else {
			this.remainingTime = this.delay;
		}
		this.ended = false;
		this.paused = false;
		this.timerId = setTimeout(this.callback, this.remainingTime);
		// AppUtils.debug("timer started | remainingTime = ", this.remainingTime);
	}

	public pause() {
		if (!this.ended && !this.paused) {
			clearTimeout(this.timerId);
			this.timerId = null;
			this.remainingTime -= new Date().getTime() - this.startTime;
			this.paused = true;
			// AppUtils.debug("timer paused | remainingTime = ", this.remainingTime);
		} else {
			// AppUtils.debug("timer pause | CANCELED -> already ended || paused");
		}
	}

	public resume() {
		if (!this.ended && this.paused) {
			if (!this.timerId) {
				if (this.remainingTime > 0) {
					this.startTime = new Date().getTime();
					clearTimeout(this.timerId);
					this.timerId = setTimeout(this.callback, this.remainingTime);
					this.paused = false;
					// AppUtils.debug("timer resumed | remainingTime = ", this.remainingTime);
				} else {
					// AppUtils.debug("timer resume | CANCELED -> no remainingTime = ", this.remainingTime);
					this.remainingTime = 0;
				}
			} else {
				// AppUtils.debug("timer resume | CANCELED -> already running");
			}
		} else {
			// AppUtils.debug("timer resume | CANCELED -> already ended || paused");
		}
	}

	public kill() {
		clearTimeout(this.timerId);
		this.timerId = null;
		this.remainingTime = 0;
		this.ended = true;
		this.paused = false;
		// AppUtils.debug("timer killed !");
	}
}
