import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Classroom } from "../models/classroom";
import { Student } from "../models/student";
import { KidaiaSso } from "../models/user";
import { BaseService } from "./base.service";
import { Journey } from "../models/journey";
import { CreditType } from "../models/in-app-state";

export interface BearerToken {
	access_token: string;
	expires_in: string;
}

@Injectable({
	providedIn: "root"
})
export abstract class ClassService extends BaseService {
	authBearer: string;
	classes: Classroom[];
	source: string;
	isAren = false;
	isBeneylu = false;
	isMathador: boolean;

	loggedFromHome = false;
	homeStudent: any;
	/**
	 * request a rest transaction token for tralalere
	 * if only login specified the loggin is the authBearer token
	 */
	abstract login(login?: string, password?: string): Observable<BearerToken>;

	public abstract setSession(authResult: BearerToken);
	/**
	 *  return tralalere token for auth
	 *  @returns string
	 */
	public abstract getSessionToken(): Promise<string>;
	public abstract logout();

	public abstract isLoggedIn(): boolean;
	public abstract isLoggedInStudent(): boolean;
	
	public abstract isLoggedOut();

	public abstract getExpirationTralalere(): Date;
	public abstract getExpirationStudent(): Date;
	/**
	 * Get a classe from tralalere with an array of Student
	 * @param codeClass 
	 * @param noKeep don't save classes list for kidaia
	 */
	public abstract getClasseFromGroupDetail(codeClass: string, noKeep? :  boolean): Observable<Classroom>;

	public abstract getAssignationList(idProf: string): Observable<{data : Journey[]}>;

	/**
	 * Get teacher/Parent account information raw data from tralalere
	 */
	public abstract getTeacherInformation(): Promise<any>;

	public abstract getListClasseTeacher(): Promise<Classroom[] | null>;

	/**
	 * Create Student tralalere use classe from student if classe exist or first classe from parent account
	 * @param student use name and parental consent and picture classe not ready actually
	 */
	public abstract createStudent(student: Student): Promise<void>;

	/**
	 * Delete student tralalere use id of The Student
	 */
	public abstract deleteStudent(student: Student): Promise<void>;

	/**
	 * Update Student information update name and parental consent
	 */
	public abstract updateStudent(student: Student): Promise<void>;
	/**
	 * Get Kidaia sso information
	 */
	public abstract getKidaiaSso(): Promise<KidaiaSso>;

	/**
	 * Log into wordpress with account mail/password
	 * @param login email kidaia wordpress
	 * @param password 
	 * @returns KidaiaSso
	 */
	public abstract authFromApp(login?: string, password?: string, f2a?: string,idUser?:string):Promise<KidaiaSso>
	/**
	 * Log into wordpress with family code
	 * @param code 
	 * @returns 
	 */
	public abstract authFromFamilyCode(code?: string):Promise<KidaiaSso>
		/**
	 * Log into wordpress with apple code
	 * @param code 
	 * @returns 
	 */
		public abstract authFromAppleCode(code?: string, token?:string):Promise<KidaiaSso>
	/**
	 * verify password for deletion
	 * @param formData
	 * @returns
	 */
	public abstract verifPassword(formData: FormData): Observable<any>;

	public abstract redirectionError();

	public abstract registerTeacherAccount(email: string, password: string, label: string, firstName: string): Observable<any>;

	public abstract disconnectUser(): Observable<any>;

	public abstract loginCodeMaison(code: string): Promise<string>;

}
