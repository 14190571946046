<main *ngIf="currentWordSearch">
  <div #canvasStroke class="canvas-stroke"></div>
  <div class="buttonsContainer">
    <app-ose-difficulty-selector (setDifficulty)="difficultyChanged($event)" [difficultySelected]="difficultySelected"></app-ose-difficulty-selector>
    <ion-button (click)="generate()" class="generateBtn">
      <ion-icon slot="start" name="refresh"></ion-icon>
      Nouvelle grille
    </ion-button>
  </div>
 
  <div  class="col">
    <div id="grid">
      <canvas #gridCanvas width="0" height="0"></canvas>
      <div #gridTable id="grid-table">
        <div class="grid-row" *ngFor="let row of rows" >
          <div class="grid-col" *ngFor="let column of columns">
            <div #letterBlock class="letter-block" [ngClass]="blockClasses(row, column)" (click)="select(row, column)">
              {{ letter(row, column) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="word-bank">

      <div class="pending-words">
        <h3>Mots à trouver</h3>
        <ul class="pending-list">
          <li *ngFor="let word of pendingWords" (click)="revealHint(word)">
            <span>{{ word }}</span>
          </li>
        </ul>
      </div>
      <div class="discovered-words" *ngIf="discoveredWords && discoveredWords.length > 0">
        <h3>Mots trouvés</h3>
        <ul>
          <li *ngFor="let word of discoveredWords">
            <span>{{ word }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</main>