import { ExerciseJourneyExtractor } from "../components/journey/journey.component";
import { CabriDataService } from "../services/cabri-data.service";
import { GlobalService } from "../services/global.service";
import { CabriActivity } from "./cabri-activity";
import { AppLanguage } from "./enums/enum-list";
import { Exercise } from "./exercise";

export enum Grade {
	cp = "CP",
	ce1 = "CE1",
	ce2 = "CE2",
	cm1 = "CM1",
	cm2 = "CM2"
}
export class CategorizeStatus {
	[grade: string]: {
		[name: string]: { opened: boolean; items: Array<{ exercise: any; selected: boolean }> };
	};
}

export class NoCategoryStatus {
	static label = $localize`Non classé`;
}

export class Categories {
	opened?: boolean;
	items?: Array<{ exercise: any; selected: boolean }>;
}
export class Exercices {
	public exercices: Array<Exercise>;
	public selected = Grade.cp;
	public cp = [];
	public ce1 = [];
	public ce2 = [];
	public cm1 = [];
	public cm2 = [];
	public globalService: GlobalService;
	public allCategories: any;
	public cabriService: CabriDataService;
	extractor: ExerciseJourneyExtractor = {};
	categoryName: string;
	categoryExercise: { [categoryName: string]: Array<{ exercise: any; selected: boolean }> };
	nextExerciseProposed: any;
	selectedCategory:{ catName:string,items: Array<{ exercise: Array<Exercices>; selected: boolean }>,opened:boolean };

	constructor(exercices: Array<Exercise>, globalService: GlobalService, cabriService: CabriDataService) {
		this.globalService = globalService;
		this.exercices = exercices;
		this.cabriService = cabriService;
		this.exercices.forEach((exercice, index) => {
			this.exercices[index] = new Exercise(exercice);
			if (this.globalService.locale === AppLanguage.EN) {
				this.exercices[index].name = this.exercices[index].nameEn;
			}
			if (this.exercices[index].classe === "CP") {
				this.cp.push(this.exercices[index]);
			}

			if (this.exercices[index].classe === "CE1") {
				this.ce1.push(this.exercices[index]);
			}

			if (this.exercices[index].classe === "CE2") {
				this.ce2.push(this.exercices[index]);
			}

			if (this.exercices[index].classe === "CM1") {
				this.cm1.push(this.exercices[index]);
			}
			if (this.exercices[index].classe === "CM2") {
				this.cm2.push(this.exercices[index]);
			}
			this.exercices[index].id = Number(this.exercices[index].id);
		});
	}

	/**
	 * Group all exercises by categories
	 */
	getByCategory(activityId: number | string, exercises = null) {
		this.cabriService.categories = {
			[Grade.cp]: {},
			[Grade.ce1]: {},
			[Grade.ce2]: {},
			[Grade.cm1]: {},
			[Grade.cm2]: {}
		};
		let currentExercises;
		if (!exercises) {
			currentExercises = this.exercices.filter(ex => {
				return Number(ex.gabarit) === Number(activityId) && ex.classe === this.selected;
			});
		} else {
			currentExercises = exercises;
		}
		// Categorise exercises
		currentExercises.forEach(ex => {
			if (ex.categories.length === 0) {
				this.createCategories(NoCategoryStatus.label, ex);
			} else {
				let result;
				if (this.globalService.locale === AppLanguage.EN) {
					result = this.allCategories.filter(category => {
						let found = false;
						Object.entries(ex.categoriesObject).forEach(([key, value]) => {
							if (Number(key) === Number(category.term_id)) {
								found = true;
							}
						});
						return found;
					});
				} else {
					result = this.allCategories.filter(category => {
						return ex.categories.some(exCatName => {
							return exCatName === category.name;
						});
					});
				}

				result.forEach(currentCategory => {
					this.createCategories(currentCategory.name, ex);
				});
			}
		});
		this.cabriService.filterOfflineExerciseCategoryByTermId(95);
		this.categoriesOrdered();
	}

	categoriesOrdered() {
		let categorySearched;
		this.cabriService.categoryNamesOrdered = {};
		// with category exercises
		this.allCategories.forEach((category, index) => {
			if (this.cabriService.categories[this.selected]) {
				categorySearched = Object.keys(this.cabriService.categories[this.selected]).find(keyCategory => {
					const categoryAdded = (Object.entries(this.cabriService.categoryNamesOrdered) as any).some(([key, cat]) => {
						return cat.catName === category.name;
					});
					return !categoryAdded && keyCategory === category.name;
				});
				if (categorySearched) {
					this.cabriService.categoryNamesOrdered[index] = {
						...this.cabriService.categories[this.selected][categorySearched],
						catName: categorySearched
					};
				}
			}
		});
		// no category exercises
		if (!this.withCategory) {
			categorySearched = NoCategoryStatus.label;
			this.cabriService.categoryNamesOrdered["0"] = {
				...this.cabriService.categories[this.selected][categorySearched],
				catName: categorySearched
			};
		}
	}

	// Get category exercise with the position
	private _getCurrentCategoryExerciseWithPosition(
		currentExercise,
		cabriActivity: CabriActivity
	): { currentCategoryExercises: Array<{ exercise: any; selected: boolean }>; currentExerciseIndex: number } {
		this.selected = currentExercise.classe;
		this.getByCategory(cabriActivity.id);
		let currentCategoryExercises: Array<{ exercise: any; selected: boolean }>;
		let currentExerciseIndex = 0;
		const activityCategories = this.cabriService.categories[this.selected];

		// Les nombres autrements garder les exercices en fonction du mode d'affichage choisi
		if (Number(cabriActivity.id) === 7) {
			const getNombreAutrementsExercises = new Array();
			const paramTargetted = cabriActivity.params.find(param => {
				return param.exercices;
			});
			paramTargetted.selectionList.forEach(ex => {
				getNombreAutrementsExercises.push(ex.id);
			});

			for (const categoryId in activityCategories) {
				if (activityCategories.hasOwnProperty(categoryId)) {
					activityCategories[categoryId].items = activityCategories[categoryId].items.filter(item => {
						return getNombreAutrementsExercises.includes(item.exercise.id);
					});
				}
			}
		}

		for (const categoryId in activityCategories) {
			if (activityCategories.hasOwnProperty(categoryId)) {
				currentExerciseIndex = 0;
				for (const item of activityCategories[categoryId].items) {
					if (item.exercise.id === currentExercise.id) {
						currentCategoryExercises = activityCategories[categoryId].items;
						break;
					}
					currentExerciseIndex++;
				}

				if (currentCategoryExercises) {
					break;
				}
			}
		}

		return { currentCategoryExercises, currentExerciseIndex };
	}

	/**
	 * Get next exercise of the category which has not been done
	 */
	getNextCategoryExerciseToDo(cabriService: CabriDataService, completedExercisesIds: Array<number | string>) {
		const { currentCategoryExercises, currentExerciseIndex } = this._getCurrentCategoryExerciseWithPosition(
			cabriService.currentExercice,
			cabriService.currentActivity
		);

		let nextExercise, nextCategoryExercise;
		nextCategoryExercise = this.getNextNotDoneExercise(
			currentCategoryExercises,
			completedExercisesIds,
			currentExerciseIndex + 1,
			currentCategoryExercises.length
		);

		if (!nextCategoryExercise) {
			nextCategoryExercise = this.getNextNotDoneExercise(currentCategoryExercises, completedExercisesIds, 0, currentExerciseIndex);
		}

		if (nextCategoryExercise) {
			nextExercise = this.getExercise(nextCategoryExercise.exercise.id);
		}
		return nextExercise;
	}

	/**
	 * Get next not done exercise
	 */
	getNextNotDoneExercise(currentCategoryExercises, completedExercisesIds, start: number, end: number) {
		const fromSearch = currentCategoryExercises.slice(start, end);
		const exerciseNotDoneYet = fromSearch.filter(catItem => {
			return completedExercisesIds.every(exIdCompleted => {
				return Number(catItem.exercise.id) !== Number(exIdCompleted);
			});
		});

		if (exerciseNotDoneYet.length > 0) {
			return exerciseNotDoneYet[0];
		}
	}

	/**
	 * Get title and id of exercise separately
	 */
	extractExerciseIdFromTitle() {
		// for exercices
		this.exercices.forEach(ex => {
			if (!this.extractor[ex.id]) {
				this.extractor[ex.id] = {};
			}
			let title;
			let id;
			const nameSplitted = ex.name.split("-");
			if (nameSplitted.length === 1) {
				title = nameSplitted[0];
			} else {
				id = nameSplitted[0];
				title = "";
				for (let i = 1; i < nameSplitted.length; i++) {
					title += nameSplitted[i] + (i < nameSplitted.length - 1 ? "-" : "");
				}
			}

			if (title) {
				this.extractor[ex.id].title = title.trim();
			}

			if (id) {
				this.extractor[ex.id].id = id.trim();
			}
		});
	}

	createCategories(categoryName: string, ex) {
		if (this.cabriService.categories[ex.classe]) {
			if (!this.cabriService.categories[ex.classe].hasOwnProperty(categoryName)) {
				// add first exercise in targetted category
				this.cabriService.categories[ex.classe][categoryName] = {
					opened: false,
					items: [{ selected: false, exercise: ex }]
				};
			} else {
				// at least one exercise still exist in that category
				this.cabriService.categories[ex.classe][categoryName].items.push({ exercise: ex, selected: false });
			}
		}
	}

	/**
	 * Get first exercise of category
	 */
	getFirstByCategory() {
		for (const grade in this.cabriService.categories) {
			if (this.cabriService.categories.hasOwnProperty(grade)) {
				const orderedCategorises = Object.keys(this.cabriService.categories[grade]).sort((a, b) => {
					return a.localeCompare(b);
				});
				if (this.cabriService.categories[grade][orderedCategorises[0]]) {
					return this.cabriService.categories[grade][orderedCategorises[0]].items[0].exercise;
				}
			}
		}
	}

	/**
	 * Get selected category name
	 */
	getCategoryName() {
		let currentCategorySelected;
		for (const grade in this.cabriService.categories) {
			if (this.cabriService.categories.hasOwnProperty(grade)) {
				currentCategorySelected = Object.keys(this.cabriService.categories[grade]).find(grade2 => {
					return grade2.length > 0;
				});
				if (currentCategorySelected) {
					break;
				}
			}
		}

		return currentCategorySelected;
	}

	get withCategory() {
		let currentCategorySelected = true;
		for (const grade in this.cabriService.categories) {
			if (
				this.cabriService.categories[grade].hasOwnProperty(NoCategoryStatus.label) &&
				this.cabriService.categories[grade][NoCategoryStatus.label].items?.length > 0
			) {
				currentCategorySelected = false;
				break;
			}
		}

		return currentCategorySelected;
	}

	public getAll() {
		return this.exercices;
	}

	public filter(gabaritId) {
		return this.getSelectedExercise().filter(exercice => {
			return exercice.gabarit === gabaritId;
		});
	}

	public getExercise(exerciseId): Exercise {
		return this.exercices.find(exercice => Number(exercice.id) === Number(exerciseId));
	}

	public getSelectedExercise() {
		if (this.selected === Grade.cp) {
			return this.cp;
		} else if (this.selected === Grade.ce1) {
			return this.ce1;
		} else if (this.selected === Grade.ce2) {
			return this.ce2;
		} else if (this.selected === Grade.cm1) {
			return this.cm1;
		} else if (this.selected === Grade.cm2) {
			return this.cm2;
		}else{
			const student = this.cabriService.accountService?.team[0]
			if(student){
				const data = {
					studentId: student.id,
					classeId:student.classe?.id,
					idProf:student.classe?.idProf
				}
				if(this.globalService.analyticService){
					this.globalService.analyticService.sendAnalytics("student without level",data)
				}
			}
			return this.cp;
		}
	}
}
