import { AfterContentInit, Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { GlobalService } from "src/app/services/global.service";

@Component({
	selector: "app-premium-modal",
	templateUrl: "./premium-modal.component.html",
	styleUrls: ["./premium-modal.component.scss"]
})
export class PremiumModalComponent implements OnInit, AfterContentInit {
	@Input() urlType: "video" | "aventure" | "logbook" | "ose" | "mathiaFamily" | "mathiaTeacher";

	textUrl = {
		video: { text: "", url: "https://kidaia.com/fin-periode-essai/?item=video" },
		aventure: { text: "", url: "https://kidaia.com/fin-periode-essai/?item=aventure" },
		logbook: { text: "", url: "https://kidaia.com/fin-periode-essai/?item=journaldebord" },
		mathiaFamily: { text: "", url: "https://mon.mathia.education/abonnement-famille-expire/" },
		mathiaTeacher: { text: "", url: "https://mon.mathia.education/abonnement-expire/" },
		ose: { text: "", url: "https://kidaia.com/fin-periode-essai/?item=ose" }
	};

	constructor(public globalService: GlobalService, public modalController: ModalController) {}

	ngOnInit() {
		this.globalService.setSmallLoading(true);
	}

	ngAfterContentInit() {}

	goToLink(url: string) {
		window.open(url, "_blank");
	}

	dismiss() {
		this.modalController?.dismiss();
	}

	loaded(event: Event) {
		console.error((event.target as HTMLIFrameElement).contentWindow.location);
		try {
			if (
				(event.target as HTMLIFrameElement).contentWindow.location.href &&
				(event.target as HTMLIFrameElement).contentWindow.location.href !== "about:blank" &&
				(event.target as HTMLIFrameElement).contentWindow.location.href !== this.textUrl[this.urlType].url
			) {
			}
		} catch (error) {}
	}
}
