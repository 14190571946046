import { ModalsService } from "./../../services/modals.service";
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { NgxScannerQrcodeComponent, ScannerQRCodeConfig, ScannerQRCodeResult } from "ngx-scanner-qrcode";

@Component({
	selector: "app-ionic-qr-code",
	templateUrl: "./ionic-qr-code.component.html",
	styleUrls: ["./ionic-qr-code.component.scss"]
})
export class IonicQrCodeComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild(NgxScannerQrcodeComponent) scanner!: NgxScannerQrcodeComponent;
  @Input()config? : ScannerQRCodeConfig;
	devices: InputDeviceInfo[];

	canTorch = false;

	constructor(public modalController: ModalController, public modalService: ModalsService) {}

	ngOnInit() {
		console.log(this.scanner);
	}
	ngAfterViewInit() {
		this.scanner.start();

		this.scanner.devices.subscribe((devices: InputDeviceInfo[]) => {
			this.devices = devices;
		});
		this.modalService.scanner = this.scanner;
		//(window as any).scanner = this.scanner;
		this.scanner.data.subscribe(data => {
			this.sendQrCode(data);
		});
	}

	sendQrCode(data: ScannerQRCodeResult[]) {
		if (data.length > 0) {
			this.modalController.dismiss(data);
		}
	}

	ngOnDestroy(): void {
		let mediaStream = this.scanner.video.nativeElement.srcObject as MediaStream;
		if (mediaStream instanceof MediaStream) {
			mediaStream.getTracks().forEach(t => {
				t.stop();
				mediaStream.removeTrack(t);
			});
		}
		this.modalService.scanner = null;
		(window as any).scanner = null;
	}

	changeCamera() {
		this.scanner.playDevice(this.devices[(this.scanner.deviceIndexActive + 1) % this.devices.length].deviceId).subscribe(() => {
			(this.scanner.video.nativeElement.srcObject as MediaStream)
				.getTracks()[0]
				.applyConstraints({
					advanced: [{ torch: false } as any]
				})
				.then(() => {
					this.canTorch = true;
				})
				.catch(() => {
					this.canTorch = false;
				});
		});
	}
	toggleTorch() {
		this.scanner.isTorch = !this.scanner.isTorch;
		(this.scanner.video.nativeElement.srcObject as MediaStream)
			.getTracks()[0]
			.applyConstraints({
				advanced: [{ torch: this.scanner.isTorch } as any]
			});
	}
}
