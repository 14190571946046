import { HourglassComponent } from './hourglass/hourglass.component';
import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ToggleMenuComponent } from "./toggle-menu/toggle-menu.component";
import { StarboardComponent } from "./starboard/starboard.component";
import { StarboardContainerComponent } from "./starboard-container/starboard-container.component";
import { ProgressBarComponent } from "./progress-bar/progress-bar.component";
import { CabriNumpadComponent } from "./cabri-numpad/cabri-numpad.component";
import { DrawingComponent } from "./drawing/drawing.component";
import { ToolbarWithButtonsComponent } from "./toolbar-with-buttons/toolbar-with-buttons.component";
import { MathiaCalculMentalComponent } from "./mathia-calcul-mental/mathia-calcul-mental.component";
import { TooltipDirective } from "./tooltip-directive/tooltip.directive";
import { AvatarBotComponent } from "./avatar-bot/avatar-bot.component";
import { MathiaSpeechComponent } from "./mathia-speech/mathia-speech.component";
import { RemoteStatusComponent } from "./remote-status/remote-status.component";
import { BabylonjsComponent } from "./babylonjs/babylonjs.component";
import { ClockComponent } from "./clock/clock.component";
import { ClockDigitalComponent } from "./clock-digital/clock-digital.component";
import { AppChoiceComponent } from "./app-choice/app-choice.component";
import { ColorPickerModule } from "@iplab/ngx-color-picker";
import { CirclePercentComponent } from ".//circle-percent/circle-percent.component";
import { BabylonjsParamComponent } from "./babylonjs-param/babylonjs-param.component";
import { ModalActivityParticipantsComponent } from "./modal-activity-participants/modal-activity-participants.component";
import { ModalActivityParticipantsKidaiaComponent } from "./modal-activity-participants-kidaia/modal-activity-participants-kidaia.component";
import { ProgressbarActivityComponent } from "./progressbar-activity/progressbar-activity.component";
import { RemoteConnectComponent } from "./remote-connect/remote-connect.component";
import { ModalRemoteHostComponent } from "./modal-remote-host/modal-remote-host.component";
import { SpashScreenTutoComponent } from "./spash-screen-tuto/spash-screen-tuto.component";
import { TrackingIOSInfoComponent } from "./tracking-iosinfo/tracking-iosinfo.component";
import { FilterParams, SafePipe } from "../services/filter-params.service";
import { CustomAlertComponent } from "./custom-alert/custom-alert.component";
import { ExercisesStatisticsDetailsComponent } from "./exercises-statistics-details/exercises-statistics-details.component";
import { LogBookStatisticsTextComponent } from "./log-book-statistics-text/log-book-statistics-text.component";
import { SettingsComponent } from "./settings/settings.component";
import { JourneyComponent } from "./journey/journey.component";
import { ParamComponent } from "./param/param.component";
import { LeftMenuComponent } from "./left-menu/left-menu.component";
import { AngularProgressBarComponent } from "./angular-progress-bar/angular-progress-bar.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RewardsBoardComponent } from "./rewards-board/rewards-board.component";
import { GalaxyMapComponent } from "./galaxy-map/galaxy-map.component";
import { TelluriqueComponent } from "./galaxy-maps-svg/tellurique/tellurique.component";
import { EtapeComponent } from "./galaxy-maps-svg/etape/etape.component";
import { EnfantsComponent } from "./galaxy-maps-svg/enfants/enfants.component";
import { AeriensComponent } from "./galaxy-maps-svg/aeriens/aeriens.component";
import { VideoComponent } from "./video/video.component";
import { RewardComponent } from "./reward/reward.component";
import { CustomProgressbarComponent } from "./custom-progressbar/custom-progressbar.component";
import { AudioEngineLockedComponent } from "./audio-engine-locked/audio-engine-locked.component";
import { ActivitySettingsComponent } from "./activity-settings/activity-settings.component";
import { CustomSimpleProgressbarComponent } from "./custom-simple-progressbar/custom-simple-progressbar.component";
import { SwiperModule } from "swiper/angular";
import { Etape3Component } from "./galaxy-maps-svg/etape3/etape3.component";
import { Galaxie8Component } from "./galaxy-maps-svg/galaxie8/galaxie8.component";
import { Etape4Component } from "./galaxy-maps-svg/etape4/etape4.component";
import { Galaxie9Component } from "./galaxy-maps-svg/galaxie9/galaxie9.component";
import { Etape5Component } from "./galaxy-maps-svg/etape5/etape5.component";
import { Galaxie10Component } from "./galaxy-maps-svg/galaxie10/galaxie10.component";
import { ConfettiCanvasComponent } from "./confetti-canvas/confetti-canvas.component";
import { Galaxie7Component } from "./galaxy-maps-svg/galaxie7/galaxie7.component";
import { MathjaxModule } from "mathjax-angular";
import { LogBookStatisticsContentComponent } from "./log-book-statistics-content/log-book-statistics-content.component";
import { QuizzPadComponent } from "./quizz-pad/quizz-pad.component";
import { FicheComponent } from "./fiche/fiche.component";
import { SafeHtmlPipe } from "../safe-html.pipe";
import { GameCardComponent } from "./game-card/game-card.component";
import { PuzzleGameComponent } from "./puzzle-game/puzzle-game.component";
import { ColoringComponent } from "./coloring/coloring.component";
import { WordSearchComponent } from "./word-search/word-search.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { PinchZoomComponent, PinchZoomModule } from "@meddv/ngx-pinch-zoom";
import { AfterIfDirective } from "./after-if-directive/after-if.directive";
import { SpeechBubbleComponent } from "./speech-bubble/speech-bubble.component";
import { ImageViewerComponent } from "./image-viewer/image-viewer.component";
import { BeeComponent } from "./bee/bee.component";
import { NgChartsModule } from "ng2-charts";
import { ChartjsComponent } from "./chartjs/chartjs.component";
import { VideosPipe, VideosQualityPipe, VideosThumbUrl } from "../models/videos.pipe";
import { FeedbackComponent } from "./feedback/feedback.component";
import { IframeModalComponent } from "./iframe-modal/iframe-modal.component";
import { PremiumModalComponent } from "./premium-modal/premium-modal.component";
import { SevenErrorsComponent } from "./seven-errors/seven-errors.component";
import { FilterStudent, FilterStudentPreview } from "../pipes/student-filter.pipe";
import { MeshToPhotoDomePipe} from "../pipes/mesh-to-photo-dome.pipe";
import { CallbackPipe } from "../pipes/callback.pipe";
import { linkNamePipe } from "../pipes/link-name.pipe";
import { OseDifficultySelectorComponent } from "./ose-difficulty-selector/ose-difficulty-selector.component";
import { ParamPipe } from "../pipes/param.pipe";
import { NgxScannerQrcodeModule ,LOAD_WASM} from "ngx-scanner-qrcode";
import { IonicQrCodeComponent } from "./ionic-qr-code/ionic-qr-code.component";
import { AssignationsControllerComponent } from "./assignations-controller/assignations-controller.component";
import { MatTableModule } from "@angular/material/table";

LOAD_WASM().subscribe((res: any) => console.log('LOAD_WASM_QR_CODE', res));
@NgModule({
	declarations: [
		StarboardComponent,
		FilterParams,
		VideosPipe,
		VideosQualityPipe,
		VideosThumbUrl,
		ParamPipe,
		ToolbarWithButtonsComponent,
		ProgressbarActivityComponent,
		ToggleMenuComponent,
		StarboardContainerComponent,
		CabriNumpadComponent,
		DrawingComponent,
		ProgressBarComponent,
		MathiaCalculMentalComponent,
		TooltipDirective,
		AvatarBotComponent,
		MathiaSpeechComponent,
		RemoteStatusComponent,
		BabylonjsComponent,
		BabylonjsParamComponent,
		ClockComponent,
		ClockDigitalComponent,
		Galaxie10Component,
		AppChoiceComponent,
		ModalActivityParticipantsComponent,
		ModalActivityParticipantsKidaiaComponent,
		ModalRemoteHostComponent,
		SpashScreenTutoComponent,
		RemoteConnectComponent,
		TrackingIOSInfoComponent,
		CustomAlertComponent,
		CirclePercentComponent,
		SettingsComponent,
		JourneyComponent,
		ParamComponent,
		AngularProgressBarComponent,
		ExercisesStatisticsDetailsComponent,
		Galaxie9Component,
		LogBookStatisticsTextComponent,
		RewardsBoardComponent,
		LeftMenuComponent,
		GalaxyMapComponent,
		TelluriqueComponent,
		Galaxie7Component,
		Etape4Component,
		EtapeComponent,
		EnfantsComponent,
		Etape3Component,
		Galaxie8Component,
		Etape5Component,
		AeriensComponent,
		VideoComponent,
		RewardComponent,
		SafePipe,
		CustomProgressbarComponent,
		AudioEngineLockedComponent,
		ActivitySettingsComponent,
		CustomSimpleProgressbarComponent,
		LogBookStatisticsContentComponent,
		QuizzPadComponent,
		FicheComponent,
		SafeHtmlPipe,
		GameCardComponent,
		PuzzleGameComponent,
		SevenErrorsComponent,
		ColoringComponent,
		WordSearchComponent,
		AfterIfDirective,
		SpeechBubbleComponent,
		ImageViewerComponent,
		ConfettiCanvasComponent,
		BeeComponent,
		ChartjsComponent,
		FeedbackComponent,
		IframeModalComponent,
		PremiumModalComponent,
		FilterStudent,
		FilterStudentPreview,
		MeshToPhotoDomePipe,
		linkNamePipe,
		CallbackPipe,
		OseDifficultySelectorComponent,
		IonicQrCodeComponent,
		AssignationsControllerComponent,
		HourglassComponent
		
	],
	exports: [
		StarboardComponent,
		FilterParams,
		VideosPipe,
		VideosQualityPipe,
		VideosThumbUrl,
		ParamPipe,
		ToolbarWithButtonsComponent,
		ToggleMenuComponent,
		StarboardContainerComponent,
		CabriNumpadComponent,
		DrawingComponent,
		ProgressBarComponent,
		MathiaCalculMentalComponent,
		TooltipDirective,
		AvatarBotComponent,
		MathiaSpeechComponent,
		RemoteStatusComponent,
		BabylonjsComponent,
		BabylonjsParamComponent,
		ClockComponent,
		ClockDigitalComponent,
		Galaxie10Component,
		AppChoiceComponent,
		SpashScreenTutoComponent,
		RemoteConnectComponent,
		Etape4Component,
		CirclePercentComponent,
		CustomAlertComponent,
		TrackingIOSInfoComponent,
		ModalActivityParticipantsKidaiaComponent,
		SettingsComponent,
		LeftMenuComponent,
		JourneyComponent,
		ParamComponent,
		AngularProgressBarComponent,
		ExercisesStatisticsDetailsComponent,
		Galaxie9Component,
		Etape5Component,
		LogBookStatisticsTextComponent,
		RewardsBoardComponent,
		GalaxyMapComponent,
		TelluriqueComponent,
		Galaxie7Component,
		EtapeComponent,
		Etape3Component,
		Galaxie8Component,
		EnfantsComponent,
		AeriensComponent,
		VideoComponent,
		RewardComponent,
		SafePipe,
		CustomProgressbarComponent,
		AudioEngineLockedComponent,
		ActivitySettingsComponent,
		CustomSimpleProgressbarComponent,
		LogBookStatisticsContentComponent,
		QuizzPadComponent,
		FicheComponent,
		SafeHtmlPipe,
		GameCardComponent,
		PuzzleGameComponent,
		SevenErrorsComponent,
		ColoringComponent,
		WordSearchComponent,
		PinchZoomComponent,
		AfterIfDirective,
		SpeechBubbleComponent,
		ImageViewerComponent,
		ConfettiCanvasComponent,
		BeeComponent,
		MathjaxModule,
		ChartjsComponent,
		FeedbackComponent,
		IframeModalComponent,
		PremiumModalComponent,
		MeshToPhotoDomePipe,
		linkNamePipe,
		CallbackPipe,
		OseDifficultySelectorComponent,
		IonicQrCodeComponent,
		AssignationsControllerComponent,
		HourglassComponent
	],
	imports: [
		IonicModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ColorPickerModule,
		NgbModule,
		SwiperModule,
		MathjaxModule.forRoot({
			config: {
				loader: {
					load: ["output/svg", "[tex]/require", "[tex]/ams"]
				},
				tex: {
					inlineMath: [["$", "$"]],
					packages: ["base", "require", "ams"]
				},
				svg: { fontCache: "global" }
			},
			src: "/assets/mathjax/startup.js"
		}),
		MathjaxModule.forChild(),
		PinchZoomModule,
		DragDropModule,
		NgChartsModule,
		NgxScannerQrcodeModule,
		MatTableModule
	]
})
export class ComponentsModule {}
