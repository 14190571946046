import { HttpClient } from "@angular/common/http";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { Student } from "../student";
import { GlobalLrs } from "./globalLrs";
import { GlobalService } from "src/app/services/global.service";
import { LmsService } from "src/app/services/lms.service";
import { AccountService } from "src/app/services/account.service";
import { CabriActivity } from "../cabri-activity";
import { format } from "date-fns";
import { Exercise } from "../exercise";

declare var http: HttpClient;

export class LrsUtils {
	static statementUrl: string;
	// False Lrs Traces
	static nastyVerbs;
	static falseFinalDuration;
	static duration;

	// Statistique
	static durationStartActivity = new Date().getTime();
	static durationStartJourney = new Date().getTime();
	static currentTeam;

	// resume student journey
	static resume = false;

	// Reste
	static idsession: string;
	static hid: string;
	static remoteHost: string;
	static holo: string;
	static idJourneySession: string;
	static typeOfActivity: string;
	static responseTime: number;
	static restartActivity = false;
	static clockResponseMode = "";
	static binome: true;
	static responseMode;
	static activityHour: boolean;
	static formatHeure: number;
	static clockType: number;
	static activityHourNotExist: boolean;
	static currentUserResponseTime;
	static passedWithHelpReading = false;
	static assignationId;
	static jounreyExerciseStep;
	static parcoursId;
	static currentUser: Student;
	static currentOperation;
	static currentActivityConnection = 0;
	static currentActivityConnectionInterval: NodeJS.Timeout;
	static currentId: string;

	static codeClassBlacklist = [61103, 0];
	static idUsersBlacklist = [
		[
			"1",
			"2",
			"3",
			"4",
			"29",
			"34",
			"48",
			"90",
			"14582",
			"22226",
			"22076",
			"20902",
			"6473",
			"21968",
			"19612",
			"21545",
			"21894",
			"21915",
			"15831",
			"2172",
			"1904",
			"1413",
			"1412"
		]
	];
	static toHex(str) {
		let result = "";
		for (let i = 0; i < str.length; i++) {
			result += str.charCodeAt(i).toString(16);
		}
		return result;
	}

	static timeStampConversion(currentTime) {
		const timestampDiff = Date.now() - currentTime;
		const millis = Math.floor(timestampDiff / 1000);
		return millis;
	}

	static clockDisplayMode(showAnalog, showDigital) {
		let responseModeDisplay = "";
		if (showAnalog) {
			responseModeDisplay = "analogique";
		} else if (showDigital) {
			responseModeDisplay = "digitale";
		}

		LrsUtils.clockResponseMode = responseModeDisplay;
	}

	static duration8601(second) {
		const h = Math.floor(second / 3600);
		const m = Math.floor((second - h * 3600) / 60);
		const s = second - (h * 3600 + m * 60);
		let ret = "PT";
		if (h) {
			ret += h + "H";
		}
		if (m) {
			ret += m + "M";
		}
		if ((!h && !m) || s) {
			ret += s + "S";
		}
		return ret;
	}

	static generateUniqueSessionId(accountService: AccountService, exSession: boolean, lmsService?: LmsService) {
		if (exSession) {
			return LrsUtils.generateExerciseSession(accountService);
		} else {
			return LrsUtils.generateJourneySession(accountService, lmsService);
		}
	}

	static checkStatementObjectType(self: GlobalLrs, exerciseMod) {
		let definitionType = "https://xapi.mathia.education/objectType/";
		let type = "";
		let id = 0;
		let name = "";
		let description = "";
		if (exerciseMod) {
			definitionType += "exercise";
			type = "exercise";
			id = self.currentExercise.id;
			name = self.currentExercise.name;
			description = self.currentExercise.description;
		} else {
			definitionType += "journey";
			type = "journey";
			id = self.lmsService.currentUserJourney.id;
			name = self.lmsService.currentUserJourney.title;
			description = self.lmsService.currentUserJourney.title;
		}
		return { definitionType, type, id, name, description };
	}

	static getExerciseObjectParams(cabriService: CabriDataService) {
		const definitionType = "https://xapi.mathia.education/objectType/exercise";
		const type = "exercise";
		const id = cabriService.currentExercice.id;
		const name = cabriService.currentExercice.name;
		const description = cabriService.currentExercice.description;
		return { definitionType, type, id, name, description };
	}

	static getJourneyObjectParams(lmsService: LmsService) {
		const definitionType = "https://xapi.mathia.education/objectType/journey";
		const type = "journey";
		const id = lmsService.currentUserJourney.id;
		const name = lmsService.currentUserJourney.title;
		const description = lmsService.currentUserJourney.title;
		return { definitionType, type, id, name, description };
	}

	/**
	 * Get id's team separated with "-"
	 */
	static getIdTeam(cabriService: CabriDataService, accountService: AccountService): string {
		let idTeam = "";
		if (Number(cabriService.currentActivity.id) === 8) {
			idTeam = accountService.team[0].id;
		} else {
			accountService.team.forEach((student: Student, idx, array) => {
				if (idx === array.length - 1) {
					idTeam += student.id;
				} else {
					idTeam += student.id + "-";
				}
			});
		}
		return idTeam;
	}

	/**
	 * Get activity name
	 */
	static getActivityName(cabriService: CabriDataService, globalService: GlobalService): string {
		if (!globalService.fastBilanFinished) {
			return cabriService.currentActivity.name;
		}
	}

	/**
	 * get exercise id
	 */
	static getExerciseId(cabriService: CabriDataService, globalService: GlobalService): number {
		if (cabriService.currentExercice?.id && !globalService.fastBilanFinished) {
			return cabriService.currentExercice.id;
		}
	}

	/**
	 * Get exercise name
	 */
	static getExerciseName(cabriService: CabriDataService, globalService: GlobalService): string {
		if (cabriService.currentExercice?.name && !globalService.fastBilanFinished) {
			return cabriService.currentExercice.name;
		}
	}

	/**
	 * get activity name (two similar extensions was sent in lrs)
	 */
	static detectCurrentActivityNameById(activityId: number): string {
		let activityName;
		if (activityId === 1) {
			activityName = "activite_calcul_mental";
		} else if (activityId === 3) {
			activityName = "jeu_du_furet";
		} else if (activityId === 4) {
			activityName = "jeu_de_kim";
		} else if (activityId === 7) {
			activityName = "les_nombres_autrement";
		} else if (activityId === 5) {
			activityName = "jeu_de_l'heure";
		} else if (activityId === 6) {
			activityName = "galaxie_des_calculs";
		} else if (activityId === 2) {
			activityName = "lecture";
		} else if (activityId === 8) {
			activityName = "découverte_des_solides";
		}

		return activityName;
	}

	/**
	 * check if it's a team
	 */
	static getTeam(accountService: AccountService): object {
		const team = new Array(accountService.team.length).fill(null);
		if (accountService.team.length > 1) {
			return team.map((empty, id) => {
				return {
					name: accountService.team[id].id,
					account: {
						homePage: "https://xapi.mathia.education",
						name: accountService.team[id].id
					},
					objectType: "Agent"
				};
			});
		}
	}


	/**
	 * Get activity id
	 */
	static getActivityId(cabriService: CabriDataService, globalService: GlobalService): string {
		if (!globalService.fastBilanFinished) {
			return cabriService.currentActivity.id;
		}
	}

	static getExercisesAdaptativeInfos(self: GlobalLrs) {
		const competences = JSON.stringify(self.cabriService.currentExercice.competences);
		const erreursTypes = JSON.stringify(self.cabriService.currentExercice.erreursType);
		const prerequis = JSON.stringify(self.cabriService.currentExercice.prerequis);
		const competenceValidees = JSON.stringify(self.cabriService.currentExercice.competencesValidees);
		const erreursTypeValidees = JSON.stringify(self.cabriService.currentExercice.erreursTypeValidees);
		return { competences, erreursTypes, prerequis, competenceValidees, erreursTypeValidees };
	}

	/**
	 * Get mode operatoire
	 */
	static getModeOperatoire(currentExercice: Exercise): string {
		if (currentExercice) {
			if (currentExercice.resultMode === "result") {
				return "en ligne";
			} else if (currentExercice.resultMode === "nb1" || currentExercice.resultMode === "nb2") {
				return "à trou";
			}
		}
	}

	/**
	 * Generate a new or recover an existing exercise session id according journey state (resume)
	 */
	static defineExerciseSessionId(lmsService: LmsService, cabriService: CabriDataService, accountService: AccountService): string {
		if (lmsService.currentUserJourney && lmsService.currentUserJourney.resume) {
			// Resume
			const journeyExercise = lmsService.currentUserJourney.allAskedQuestions.find(data => {
				return data.exerciseId === cabriService.currentExercice.id;
			});

			if (journeyExercise) {
				// if journey has already in progress
				return journeyExercise.id_session;
			} else {
				// journey exist but first exercise
				return LrsUtils.generateUniqueSessionId(accountService, true);
			}
		} else {
			if (!lmsService.currentUserJourney || (lmsService.currentUserJourney && !lmsService.currentUserJourney.resume)) {
				return LrsUtils.generateUniqueSessionId(accountService, true);
			}
		}
	}

	static getExercisesAdaptativeInfos2(cabriService: CabriDataService) {
		const competences = JSON.stringify(cabriService.currentExercice.competences);
		const erreursTypes = JSON.stringify(cabriService.currentExercice.erreursType);
		const prerequis = JSON.stringify(cabriService.currentExercice.prerequis);
		const competenceValidees = JSON.stringify(cabriService.currentExercice.competencesValidees);
		const erreursTypeValidees = JSON.stringify(cabriService.currentExercice.erreursTypeValidees);
		return { competences, erreursTypes, prerequis, competenceValidees, erreursTypeValidees };
	}

	static generateJourneySession(accountService: AccountService, lmsService: LmsService) {
		let studentid;
		let idSession;
		if (accountService.team[0]) {
			studentid = accountService.team[0].id;
		}
		const currentTimeStamp = Date.now();
		const simpleId = Math.random().toString(36).substring(7);
		if (studentid == null) {
			idSession = currentTimeStamp + simpleId;
		} else {
			idSession = currentTimeStamp + studentid + simpleId;
		}

		if (lmsService.currentUserJourney) {
			idSession += lmsService.currentUserJourney.id;
		}

		return idSession;
	}

	static generateExerciseSession(accountService: AccountService) {
		let studentid;
		let idSession;
		if (accountService.team[0]) {
			studentid = accountService.team[0].id;
		}
		const currentTimeStamp = Date.now();
		const simpleId = Math.random().toString(36).substring(7);
		if (studentid == null) {
			idSession = currentTimeStamp + simpleId;
		} else {
			idSession = currentTimeStamp + studentid + simpleId;
		}
		return idSession;
	}

	static generateUniqueId() {
		const result = LrsUtils.toHex(LrsUtils.currentUser.id);
		return LrsUtils.currentOperation.replace(/ /g, "-") + result + new Date().getTime();
	}

	/**
	 * Mode hors ligne stocker le timestamp du statement sauvegardé
	 */

	static getLrsTimestampFormat() {
		const event = new Date();
		const result: any = event.toLocaleDateString("en-GB", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour: "numeric",
			minute: "2-digit",
			second: "numeric",
			timeZone: "UTC"
		});
		const allData = result.split(",");
		const day = allData[0].split("/");
		const duration = allData[1].split(":");

		let currentMoment = day[2] + "-" + day[1] + "-" + day[0] + "T";
		currentMoment += duration[0].trim() + ":" + duration[1].trim() + ":" + duration[2].trim() + "." + event.getMilliseconds() + "Z";

		return currentMoment;
	}

	/**
	 * Mode hors ligne stocker le timestamp du statement sauvegardé
	 */

	static convertDateToISO8601(date) {
		const event = new Date(date);
		const result: any = event.toLocaleDateString("en-GB", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour: "numeric",
			minute: "2-digit",
			second: "numeric",
			timeZone: "UTC"
		});

		const allData = result.split(",");
		const day = allData[0].split("/");
		const duration = allData[1].split(":");

		let currentMoment = day[2] + "-" + day[1] + "-" + day[0] + "T";
		currentMoment += duration[0].trim() + ":" + duration[1].trim() + ":" + duration[2].trim() + "." + event.getMilliseconds() + "Z";

		return currentMoment;
	}

	/** Random traces */

	static generateHour(possibleMinutes, maxHour, minHour) {
		const hour = Math.random() * (maxHour - minHour) + minHour;
		const minute = possibleMinutes[Math.floor(Math.random() * possibleMinutes.length)];
		const date = new Date();
		date.setHours(hour);
		date.setMinutes(minute);
		return date;
	}

	static createMultiChoice(dateFormatted, possibleMinutes, maxHour, minHour) {
		const multiChoices = new Array();
		for (let i = 0; i < 1; i++) {
			let date = LrsUtils.generateHour(possibleMinutes, maxHour, minHour);
			let choix;
			do {
				date = LrsUtils.generateHour(possibleMinutes, maxHour, minHour);
				choix = LrsUtils.formattingResult(date);
			} while (choix == dateFormatted);
			multiChoices.push(choix);
		}
		return { goodResult: dateFormatted, multiChoices };
	}

	static formattingResult(time) {
		let heures = format(time, "HH");
		const minutes = format(time, "mm");
		let date: string;
		if (heures[0] === "0" && heures[1] !== "0") {
			heures = heures.slice(1);
		}
		if (minutes === "00") {
			date = heures + "h";
		} else {
			date = heures + "h" + minutes;
		}
		return date;
	}
}
