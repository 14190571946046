import { ModalsService } from "src/app/services/modals.service";
import { Router } from "@angular/router";
import { AppUtils } from "./../app-utils";
import { Subscription } from "rxjs";
import { GlobalService } from "src/app/services/global.service";
import { Injectable, OnDestroy } from "@angular/core";
import { Platform } from "@ionic/angular";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { once } from "events";

export enum SoundURL{
  ose = "/assets/sounds/afx.mp3",
  kidaia = "/assets/music/interlude.mp3"
}

@Injectable({
  providedIn: "root"
})
export class AudioService implements OnDestroy {
  music: HTMLAudioElement;
  musicIsPlaying: boolean;
  volumeChangeSub: Subscription;
  audioLocked: boolean;
  gainNodeMusic: GainNode;
  gainNodeSounds: GainNode;
  appIdleSubscription: Subscription;
  selectSound: HTMLAudioElement;
  validateSound: HTMLAudioElement;
  cancelSound: HTMLAudioElement;
  audioContext: AudioContext;
  launchSound: HTMLAudioElement;
  starSound: HTMLAudioElement;
  allowedUrlsForMusic: Array<string>;
  canUseWebAudio: boolean;
  audioDestination: any;
  audioContextInitialized: boolean;
  awardMoonSound: HTMLAudioElement;
  rocketLaunch:HTMLAudioElement;

  
  constructor(
    private platform: Platform,
    private globalService: GlobalService,
    public http: HttpClient,
    public router: Router,
    public modalService: ModalsService
    )
    {
      this.platform.ready().then(() => {
        this.initAudioContext();
        if (environment.ose) {
            this.initAppIdleSubscription();
            this.volumeChangeSub = this.globalService.volumeChangeEvent.subscribe((volume) => {
              if (this.music) {
                this.gainNodeMusic.gain.value = volume.music;
              } else if (volume.music > 0) {
                this.launchMusic();
              }
              this.loadSounds();
            });
          } else {
            this.createAllowedPagesForMusic();
            this.initAppIdleSubscription();
            this.volumeChangeSub = this.globalService.volumeChangeEvent.subscribe((volume) => {
              if (this.music) {
                this.gainNodeMusic.gain.value = volume.music;
              } else if (volume.music > 0 && this.checkIfMusicAllowedOnPage()) {
                this.launchMusic();
              }
              if (this.gainNodeSounds) {
                this.gainNodeSounds.gain.value = volume.fxs;
              }
            });
            this.loadSounds();
          }
        });
    }

  /**
   * Initialize Audio Context
   */
  initAudioContext() {
    try {
      if (typeof window.AudioContext !== "undefined") {
        this.canUseWebAudio = true;
      }
      console.log("audioservice canUseWebAudio = ", this.canUseWebAudio);
      if (this.canUseWebAudio) {
          if (!this.audioContext) {
              this.audioContext = new AudioContext();
          }
          if (!this.audioDestination) {
              this.audioDestination = this.audioContext.destination;
          }
          this.audioContextInitialized = true;
          if (this.audioContext.state === "running") {
              // Do not wait for the promise to unlock.
              this.resumeAudioContext();
          }
      }
    } catch (e) {
        this.canUseWebAudio = false;
        console.error("audioservice canUseWebAudio catch = " + e.message);
    }
  }

  /**
   * Resume Audio Context
   */
  resumeAudioContext(): Promise<void> {
      let result: Promise<void>;
      if (this.audioContext?.resume !== undefined) {
          result = this.audioContext.resume();
      }
      return result || Promise.resolve();
  }

  createAllowedPagesForMusic() {
    this.allowedUrlsForMusic = [
      "/home",
      "/accueil",
      "/login",
      "/starting",
      "/class-code-entry",
      "/manage-class",
      "/activity-participants",
      "/gabarits",
      "/map",
      "/assignation",
      "/logbook",
      "memory",
      "puzzle",
      "coloriage",
      "search-words",
      "cours",
      "inscription",
      "territoire",
      "choix-activites",
    ];
    if (environment.kidaia && !environment.ose) {
      this.allowedUrlsForMusic.push("/");
    }
    if (environment.kidaia && environment.ose) {
      // TODO
    }
  }

  setAppIdle(status: boolean) {
    if (status) {
      this.music?.pause();
    } else if (this.checkIfMusicAllowedOnPage()) {
      this.music?.play();
    }
  }

  checkIfMusicAllowedOnPage() {
    let musicAllowed: boolean;
    if (environment.ose) {
      musicAllowed = true
    } else {
      const musicAllowedRoute = this.allowedUrlsForMusic.includes(this.router.url);
      const musicAllowedContext = !(
        this.globalService.firstLaunchStoryActivity ||
        this.globalService.onActivityPage ||
        this.modalService.modalVideo?.isConnected);
      musicAllowed = musicAllowedRoute && musicAllowedContext;
    }
    return musicAllowed;
  }

  launchMusic(reset?: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      this.unlockAudioContextIfSuspended();
      if (!this.music && !this.globalService.muteMusicVolume) {
        const musicUrl = environment.ose || this.globalService.isPageOse ? SoundURL.ose : SoundURL.kidaia;
        this.music = new Audio(musicUrl);
        this.music.volume = 1;
        this.music.loop = true;
        const source = this.audioContext.createMediaElementSource(this.music);
        this.gainNodeMusic = this.audioContext.createGain();
        this.gainNodeMusic.gain.value = this.globalService.volume.music;
        source.connect(this.gainNodeMusic);
        this.gainNodeMusic.connect(this.audioContext.destination);
        this.music.addEventListener("playing", () => {
          this.musicIsPlaying = true;
          this.audioLocked = false;
          // AppUtils.debug("audio-service.musicIsPlaying = ", this.musicIsPlaying);
          resolve();
        });
        this.music.addEventListener("pause", () => {
          this.musicIsPlaying = false;
          // AppUtils.debug("audio-service.musicIsPlaying = ", this.musicIsPlaying);
          reject();
        });
        this.music.addEventListener("canplaythrough", () => {
          this.music.play().catch((error) => {
            AppUtils.debug("music.play error = ", error);
            this.audioLocked = true;
            // AppUtils.debug("audioLocked = ", this.audioLocked);
          });
        });
      } else if (!this.globalService.muteMusicVolume){
        if (reset) {
          this.music.currentTime = 0;
        }
        this.music.play().catch((error) => {
          AppUtils.debug("music.play error = ", error);
          this.audioLocked = true;
          // AppUtils.debug("audioLocked = ", this.audioLocked);
        });
      }
      if (this.music) {
        this.gainNodeMusic.gain.value = this.globalService.volume.music;
      }
    })
  }

  public setGlobalMusicURL(url:string){
    if(this.music){
      this.music.src = url;
    }
  }

  /**
   * preload all sounds + bind to gain node for iOS compatibility
   */
  async loadSounds() {
    if(!this.globalService.lowPerformanceMode){
    // TODO => isolate ose environment from mathia/kidaia if different sounds
    // create gain node for sounds:
    this.gainNodeSounds = this.audioContext.createGain();
    this.gainNodeSounds.gain.value = this.globalService.volume.fxs;
    // select
    this.selectSound = new Audio("/assets/sounds/select.mp3");
    this.selectSound.volume = 0.3;
    this.selectSound.loop = false;
    const source = this.audioContext.createMediaElementSource(this.selectSound);
    source.connect(this.gainNodeSounds);
    this.gainNodeSounds.connect(this.audioContext.destination);
    await once(this.selectSound, "canplaythrough");
    // cancel
    this.cancelSound = new Audio("/assets/sounds/selectReversed.mp3");
    this.cancelSound.volume = 0.3;
    this.cancelSound.loop = false;
    const source2 = this.audioContext.createMediaElementSource(this.cancelSound);
    source2.connect(this.gainNodeSounds);
    this.gainNodeSounds.connect(this.audioContext.destination);
    await once(this.cancelSound, "canplaythrough");
    // launch
    this.launchSound = new Audio("/assets/sounds/poursuivre.mp3");
    this.launchSound.volume = 0.3;
    this.launchSound.loop = false;
    const source3 = this.audioContext.createMediaElementSource(this.launchSound);
    source3.connect(this.gainNodeSounds);
    this.gainNodeSounds.connect(this.audioContext.destination);
    await once(this.launchSound, "canplaythrough");
    // validate
    this.validateSound = new Audio("/assets/sounds/beep.mp3");
    this.validateSound.volume = 0.5;
    this.validateSound.loop = false;
    const source4 = this.audioContext.createMediaElementSource(this.validateSound);
    source4.connect(this.gainNodeSounds);
    this.gainNodeSounds.connect(this.audioContext.destination);
    await once(this.validateSound, "canplaythrough");
    // award star / shooting star
    this.starSound = new Audio("/assets/sounds/star.mp3");
    this.starSound.volume = 0.5;
    this.starSound.loop = false;
    const source5 = this.audioContext.createMediaElementSource(this.starSound);
    source5.connect(this.gainNodeSounds);
    this.gainNodeSounds.connect(this.audioContext.destination);
    await once(this.starSound, "canplaythrough");
    // award moon / endOfActivity
    this.awardMoonSound = new Audio("/assets/sounds/moon.mp3");
    this.awardMoonSound.volume = 0.3;
    this.awardMoonSound.loop = false;
    const source6 = this.audioContext.createMediaElementSource(this.awardMoonSound);
    source6.connect(this.gainNodeSounds);
    this.gainNodeSounds.connect(this.audioContext.destination);
    await once(this.awardMoonSound, "canplaythrough");

    if(environment.kidaia){
      this.rocketLaunch = new Audio("/assets/sounds/rocket_launch.mp3");
      this.rocketLaunch.volume = 0.3;
      this.rocketLaunch.loop = false;
      const source7 = this.audioContext.createMediaElementSource(this.rocketLaunch);
      source7.connect(this.gainNodeSounds);
      this.gainNodeSounds.connect(this.audioContext.destination);
      await once(this.rocketLaunch, "canplaythrough");
    }
  }
  }

  unlockAudioContextIfSuspended() {
    if (this.audioContext?.state === "suspended") {
      AppUtils.debug("audio context unlocked manually !");
      this.audioContext.resume();
      this.audioLocked = false;
    }
  }

  playSound(sound: HTMLAudioElement): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.globalService.muteFxs && !this.globalService.lowPerformanceMode && sound) {
        // console.error("audioContext.state on playSound= ", this.audioContext.state);
        this.unlockAudioContextIfSuspended();
        sound.onended = () => {
          // AppUtils.debug("playSound.played");
          resolve()
        };
        // AppUtils.debug("playSound.play start");
        sound.play().then(() => {
          // AppUtils.debug("playSound.play ok");
        }).catch((error) => {
          // AppUtils.debug("playSound.play error = ", error);
          this.audioLocked = true;
          // AppUtils.debug("audioLocked = ", this.audioLocked);
          reject(error);
        });
      } else {
        resolve();
      }
    });

  }

  playSelectSound(): Promise<void> {
    if (this.selectSound) {
      return this.playSound(this.selectSound);
    } else {
      return new Promise((resolve, reject) => {
        resolve();
      });
    }
  }

  playValidateSound(): Promise<void> {
    if (this.validateSound) {
      return this.playSound(this.validateSound);
    } else {
      return new Promise((resolve, reject) => {
        resolve();
      });
    }
  }

  playCancelSound(): Promise<void> {
    if (this.cancelSound) {
      return this.playSound(this.cancelSound);
    } else {
      return new Promise((resolve, reject) => {
        resolve();
      });
    }
  }

  playLaunchSound(): Promise<void> {
    if (this.launchSound) {
      return this.playSound(this.launchSound);
    } else {
      return new Promise((resolve, reject) => {
        resolve();
      });
    }
  }

  playStarSound(): Promise<void> {
    if (this.starSound && !this.globalService.lowPerformanceMode) {
      return this.playSound(this.starSound);
    } else {
      return new Promise((resolve, reject) => {
        resolve();
      });
    }
  }

  playAwardMoonSound(): Promise<void> {
    if (this.awardMoonSound) {
      return this.playSound(this.awardMoonSound);
    } else {
      return new Promise((resolve, reject) => {
        resolve();
      });
    }
  }

  // mute music volume (from toolbar ose)
  async toggleMuteMusic() {
    if (!this.audioLocked) {
      this.globalService.toggleMuteMusic(!this.globalService.muteMusicVolume);
    }
    if (!this.music || this.audioLocked || this.globalService.muteMusicVolume) {
      await this.launchMusic();
    }
  }

  playRocketLaunch(): Promise<void> {
    if (this.rocketLaunch && !this.globalService.muteMusicVolume) {
      this.rocketLaunch.currentTime = 0;
      return this.playSound(this.rocketLaunch);
    } else {
      return new Promise((resolve, reject) => {
        resolve();
      });
    }
  }

  // play / pause music if any
  toggleMusicPlayback(status?: boolean) {
    if (this.music) {
      if (status) {
        this.music.play();
      } else if (status === false) {
        if (this.musicIsPlaying) {
          this.music.pause();
        }
      } else if (status === undefined) {
        if (this.musicIsPlaying) {
          this.music.pause();
        } else {
          this.music.play();
        }
      }
    }
  }

	initAppIdleSubscription() {
		if (this.appIdleSubscription) {
			this.appIdleSubscription.unsubscribe();
			this.appIdleSubscription = null;
		}
		this.appIdleSubscription = this.globalService.appIdleEvent.subscribe({
			next: v => {
				this.setAppIdle(v);
			}
		});
	}

  ngOnDestroy() {
    this.appIdleSubscription?.unsubscribe();
    this.volumeChangeSub?.unsubscribe();
	}

}
