import { inject, Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanDeactivate, CanActivateFn } from "@angular/router";
import { Observable } from "rxjs";
import { AccountService } from "./services/account.service";
import { environment } from "src/environments/environment";
import { ConnectionStatus, NetworkService } from "./services/network.service";
import { ToastController } from "@ionic/angular";
import { GlobalService } from "./services/global.service";
import { AppLanguage } from "./models/enums/enum-list";

@Injectable({
	providedIn: "root"
})
export class AuthGuard implements CanActivate {
	constructor(private globalService: GlobalService, private router: Router) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (state.url === "/grades") {
			this.router.navigateByUrl("/activity-participants");
		}

		if (state.url === "/landing") {
			if(this.globalService.locale === AppLanguage.EN){
				this.router.navigateByUrl("/accueil");
			}
		}
		return true;
	}
}

export interface CanComponentDeactivate {
	canDeactivate: (component?: CanComponentDeactivate, route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot,nextState?: RouterStateSnapshot ) => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class WaitingOperationGuard implements CanDeactivate<CanComponentDeactivate> {
	constructor(private router: Router) {}
	canDeactivate(component: CanComponentDeactivate, route: ActivatedRouteSnapshot, state: RouterStateSnapshot ,nextState: RouterStateSnapshot) {
		return component?.canDeactivate ? component.canDeactivate(component,route,state,nextState) : true;
	}
}

@Injectable({ providedIn: "root" })
export class PremiumConnectionGuard implements CanActivate {
	public toastMessageToDisplay = true;
	constructor(private router: Router, public accountService: AccountService, public toastController: ToastController) {}
	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		try{
			await this.accountService.logReadyPromise;
		}catch(err){
		}
		if (!this.accountService.user.premium) {
			this.premiumRedirection();
			return false;
		}
		return true;
	}

	premiumRedirection() {
		this.router.navigateByUrl("/starting", { replaceUrl: true }).then(() => {
			this.presentToast( $localize`Cette page n’est pas accessible avec un abonnement inactif.`);
		});
	}

	async presentToast(message) {
		if (this.toastMessageToDisplay) {
			this.toastMessageToDisplay = false;
			const toast = await this.toastController.create({
				message,
				position: "bottom",
				duration: 5000,
				cssClass: "toastModeAdventureDisabled"
			});

			toast.onDidDismiss().then(() => {
				this.toastMessageToDisplay = true;
			});
			await toast.present();
		}
	}
}
export const canActivateAdmin: CanActivateFn = async (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
  ) => {
	const accountService = inject(AccountService);
	const router = inject(Router);
	const toastController =  inject(ToastController);
	return new Promise<boolean |UrlTree>((resolve, reject) => {
		accountService.isUserLoaded.subscribe(async (loaded)=>{
			if (accountService.user.admin){
				resolve(true);
			} else {
				const toast = await toastController.create({
					message : "Droit insuffisant",
					position: "bottom",
					duration: 5000,
					cssClass: "toastModeAdventureDisabled"
				});
				await toast.present();
				resolve (router.createUrlTree(['login'],{ queryParams: { lastRoute: state.url} }));
			}
		});
	});
};

@Injectable({ providedIn: "root" })
export class PremiumOseConnectionGuard implements CanActivate {
	public toastMessageToDisplay = true;
	constructor(private router: Router, public accountService: AccountService, public toastController: ToastController) {}
	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		try{
			await this.accountService.logReadyPromise;
		}catch(err){
		}
		if (!this.accountService.user.premium_ose) {
			this.premiumRedirection();
			return false;
		}
		return true;
	}

	premiumRedirection() {
		this.router.navigateByUrl("/landing", { replaceUrl: true }).then(() => {
			this.presentToast( $localize`Cette page n’est pas accessible avec votre abonnement.`);
		});
	}

	async presentToast(message) {
		if (this.toastMessageToDisplay) {
			this.toastMessageToDisplay = false;
			const toast = await this.toastController.create({
				message,
				position: "bottom",
				duration: 5000,
				cssClass: "toastModeAdventureDisabled"
			});

			toast.onDidDismiss().then(() => {
				this.toastMessageToDisplay = true;
			});
			await toast.present();
		}
	}
}

