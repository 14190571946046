import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Storage } from "@ionic/storage-angular";
import { lastValueFrom, ReplaySubject } from "rxjs";

export enum StorageKey {
	rgpd = "rgpd",
	activitiesList = "activitiesList",
	exercises = "exercises",
	codeclasse = "codeclasse",
	students = "students",
	journeysByAssignation = "journeysByAssignation",
	allJourneys = "allJourneys",
	allCategories = "allCategories",
	calculatedZooms = "calculatedZooms",
	journeysProgression = "journeysProgression",
	saveKidaiaExercice = "saveKidaiaExercice",
	saveKidaiaExerciceResult="saveKidaiaExerciceResult",
	saveChapterUser="saveChapterUser",
	getExercisesProgression="getExercisesProgression",
	getAiRecommendations = "getAiRecommendations",
	getAllJourneys = "getAllJourneys",
	getAllBadges = "getAllBadges",
	getAllStorys = "getAllStorys",
	profilesFromClass = "profilesFromClass",
	probleme = "probleme",
}

export enum StorageKeyUnused {
	activities = "activities"
}
@Injectable({
	providedIn: "root"
})
export class LocalStorageService {
	storageReady: any;

	constructor(
		public storage: Storage,
		private plt: Platform
	) {
		this.storageReady = new ReplaySubject(1);
		this.storage.create().then(() => {
			this.storageReady.next();
			this.storageReady.complete();
		});
	}

	deleteAllStartingWith(str){
		return new Promise<void>(resolve=> {
			this.storage.keys().then(async data => {
				for(const i in data){
					if(data.hasOwnProperty(i)){
						const sKey = data[i] as string;
						console.log(sKey, str, sKey.startsWith(str));
						if(sKey.startsWith(str)){
							await this.storage.remove(sKey);
						}
					}
				}
				resolve();
			})
		});
	}
	/**
	 * Retrieve data by key name
	 */
	async get(key: string): Promise<any> {
		return new Promise<any>(async (resolve, reject) => {
			this.plt.ready().then(() => {
				lastValueFrom(this.storageReady).then(() => {
					this.storage.get(key).then(
						async (data: string) => {
							resolve(JSON.parse(data));
						},
						err => {
							reject(true);
						}
					);
				});
			});
		});
	}

	/**
	 * Store data by key name
	 */
	async set(keyName: string, dataToStore: any): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.plt.ready().then(() => {
				lastValueFrom(this.storageReady).then(() => {
					this.storage.set(keyName, JSON.stringify(dataToStore)).then(
						storedData => {
							resolve(JSON.parse(storedData));
						},
						err => {
							reject(true);
						}
					);
				});
			});
		});
	}



	remove(key: string) {
		lastValueFrom(this.storageReady).then(() => {
			this.storage.remove(key);
		});
	}
}
