import {
	Component,
	OnInit,
	Input,
	ChangeDetectorRef,
	OnDestroy,
	ViewChild,
	ElementRef,
	QueryList,
	Output,
	EventEmitter,
	ViewChildren,
	Renderer2,
} from "@angular/core";
import { environment } from "src/environments/environment";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { GlobalService, MenuEvents } from "src/app/services/global.service";
import { PlayTTSService } from "../../services/play-tts.service";
import { AccountService, userAlertActions } from "src/app/services/account.service";
import { Subscription } from "rxjs";
import { AnalyticService } from "src/app/services/analytic.service";
import { ToggleCustomEvent } from "@ionic/core/dist/types/components/toggle/toggle-interface";
import { SttService } from "src/app/services/stt.service";
import { AppLanguage } from "src/app/models/enums/enum-list";
import { AppUtils } from "src/app/app-utils";
import { ActivitySettingsComponent } from "../activity-settings/activity-settings.component";
import { ParamComponent } from "../param/param.component";
import { ScenarioSettings } from "src/app/models/scenario-settings";
import { RgpdGuard } from "src/app/services/rgpd.guard";
import { Router } from "@angular/router";
import { ModalController, Platform } from "@ionic/angular";
import { IframeModalComponent } from "../iframe-modal/iframe-modal.component";
import { Media, MediaObject, MEDIA_STATUS } from "@awesome-cordova-plugins/media/ngx";
import { File } from "@awesome-cordova-plugins/file/ngx";
import { versions } from "src/environments/versions";
export enum WizzardConfig {
	start,
	later,
	auto
}
export enum SettingsError {
	noVoice,
	noCompatibleVoice,
	samsungVoice,
	noMicrophone,
	noMicPermission,
	lowRAM,
	veryLowRAM
}
export class SettingName {
	// AUDIO
	static volumeVoix = $localize`Volume voix`;
	static volumeSons = $localize`Volume sons`;
	static volumeMusique = $localize`Volume musique`;
	static vitesseVoix = $localize`Vitesse voix`;
	static menuSansVoix = $localize`Menu sans voix`;
	static testDuMicro = $localize`Test du micro`;

	// Graphique
	static filtreLumiereBleu = $localize`Filtre lumière bleue`;
	static modeFaiblePerformance = $localize`Mode faible performance`;
	static modeDictee = $localize`Mode dictée`;
	static modeDyslexique = $localize`Mode dyslexique`;
	static voix = $localize`Menu silencieux`;
	static deconnexion = $localize`Déconnexion`;
}

export enum MenuTabItems {
	audio = "audio",
	graphic = "graphic",
	parametres = "parametres",
	liensUtiles = "liensUtiles",
	assistant = "assistant",
	feedback = "feedback"
}

declare var audioinput;
declare var navigator;
@Component({
	selector: "app-settings",
	templateUrl: "./settings.component.html",
	styleUrls: ["./settings.component.scss"]
})
export class SettingsComponent implements OnInit, OnDestroy {
	public environment;
	@Output() launchStoryActivityToolbar: EventEmitter<any> = new EventEmitter();
	public changeD: ChangeDetectorRef;
	@Input() changeDetector: ChangeDetectorRef;
	@ViewChild("activitySettings", { static: false }) activitySettings: ActivitySettingsComponent;
	@ViewChildren("titleText") titleText: QueryList<ElementRef>;
	analyser: any;
	haveGain = false;
	gainNode: GainNode;
	analyzerRef;
	locale = "fr-FR";
	needRecording = false;
	firstTimeParamSelection = false;
	rasaSubscription: Subscription;
	getHelp = true;
	appIdleTimeOut2: NodeJS.Timeout;
	appIdleTimeOut: NodeJS.Timeout;
	appIdle: boolean;

	public scenario: ScenarioSettings;

	public arrayBytes: Uint8Array;
	public volumeAudioVisualization = 0;

	appIdleSubscription: Subscription;
	public alertTitle = $localize`Es-tu sûr de vouloir changer le paramètre de l’activité ?`;
	public displayChangeParamAlert = false;
	public statusChoiceConfig: WizzardConfig;

	public isTuto = false;

	public get SettingName() {
		return SettingName;
	}
	public get ScenarioGabarits() {
		return ScenarioSettings;
	}

	public get WizzardConfig() {
		return WizzardConfig;
	}

	@ViewChild("stepWizardItems", { static: false }) stepWizardItems: ElementRef;
	// @ViewChild("paramsContainer", { static: false }) paramsContainer: ElementRef;
	@ViewChildren("paramsContainer") paramsContainer: QueryList<ElementRef>;
	@ViewChild("assistantInit", { static: false }) assistantInit: ElementRef;
	public wizardTabItems = [
		{
			title: $localize`Assistant`,
			img: this.globalService.isOse ? "/assets/mascotte/bebee.png" : "/assets/mathia/mascotte3d/mascotte3d.png",
			menu: MenuTabItems.assistant,
			helpPhrases: ScenarioSettings.assistantPhrases,
			hasHelp: false,
			inOse: true,
			inIframe: true
		},
		{
			title: $localize`Réglages audio`,
			iconSrc: "/assets/icon/icon-music.svg",
			menu: MenuTabItems.audio,
			helpPhrases: ScenarioSettings.audioMicroPhrases,
			hasHelp: true,
			inOse: true,
			inIframe: true
		},
		{
			title: $localize`Accessibilité`,
			iconSrc: "/assets/icon/accessibility-outline.svg",
			menu: MenuTabItems.graphic,
			helpPhrases: ScenarioSettings.graphicPhrases,
			hasHelp: true,
			inOse: true,
			inIframe: true
		},
		{
			title: $localize`Modes de jeu`,
			iconSrc: "/assets/icon/icon-remote.svg",
			menu: MenuTabItems.parametres,
			hasHelp: true,
			inOse: false,
			inIframe: true
		},
		{
			title: $localize`Liens`,
			iconSrc: "/assets/icon/icon-document.svg",
			menu: MenuTabItems.liensUtiles,
			hasHelp: false,
			inOse: false,
			inIframe: false
		},
		{
			title: $localize`Contact`,
			iconSrc: "/assets/icon/icon-feedback.svg",
			menu: MenuTabItems.feedback,
			hasHelp: false,
			inOse: true,
			inIframe: false
		}
	];
	public menuTabItemSelected;

	public get MenuTabItems() {
		return MenuTabItems;
	}

	get userAlertActions() {
		return userAlertActions;
	}

	public get AppLanguage() {
		return AppLanguage;
	}

	public get SettingsError() {
		return SettingsError;
	}

	public commonErrors = [];
	// all errors : [SettingsError.noVoice, SettingsError.noCompatibleVoice, SettingsError.samsungVoice, SettingsError.noMicrophone,
	// SettingsError.noMicPermission, SettingsError.lowRAM, SettingsError.veryLowRAM];
	public firstLaunch = false;

	public mediaAudioFile: MediaObject;

	public versions;
	constructor(
		public ttsService: PlayTTSService,
		public cabriService: CabriDataService,
		public accountService: AccountService,
		public globalService: GlobalService,
		public modalController: ModalController,
		public accountImplService: AccountService,
		public analyticService: AnalyticService,
		public sttService: SttService,
		public playTTSService: PlayTTSService,
		public renderer: Renderer2,
		public rgpdService: RgpdGuard,
		public router: Router,
		public platform: Platform,
		private media: Media,
		public file: File
	) {
		// (window as any).page = this;
		this.environment = environment;
		this.versions = versions;
		this.menuTabItemSelected = this.wizardTabItems[0];
		this.scenario = new ScenarioSettings(this.accountImplService, this.globalService, this, this.changeD, this.ttsService);

		this.filterMenu();
		

		this.globalService.showSettingParams.subscribe(openParamsTab => {
			this.filterMenu();
			if (openParamsTab === "launch_wizard") {
				this.firstLaunch = true;
				// launch wizard
				this.scenario.wizardInit();
			} else if (openParamsTab === "settings_opened_clicked") {
				if (this.menuTabItemSelected.menu === MenuTabItems.parametres) {
					this.globalService.storySettingOpened = true;
					this.changeParamTitleDisplayStyle();
					this.globalService.menuStorySettingOpenedEvent.next(true);
				}
			} else if (openParamsTab) {
				// open activity settings (Modes de jeu / parametres)
				this.firstTimeParamSelection = true;
				this.selectedTabHelp(MenuTabItems.parametres);

				if(this.globalService.isMobile){
					this.getHelp = false;
				}
			}
		});

		this.globalService.menuOpenEvent.subscribe({
			next: v => {
				if (v.open && v.target !== MenuEvents.Burger) {
					this.detectCommonErrors();
				}
			}
		});
		this.getHelp = !this.globalService.isMobile;
	}

	filterMenu(){
		if (this.environment.ose) {
			this.wizardTabItems = this.wizardTabItems.filter(i => i.inOse);
		} else if(this.globalService.inIframe){
			this.wizardTabItems = this.wizardTabItems.filter(i => i.inIframe);
		}
	}
	ngOnInit() {
		this.initAppIdleSubscription();
	}

	async detectCommonErrors() {
		this.commonErrors = [];
		// no voice
		if (this.ttsService.voices.length === 0) {
			this.ttsService.checkVoice().then(() => {
				if (this.ttsService.voices.length === 0) {
					if (this.ttsService.allVoices.length) {
						this.commonErrors.push(SettingsError.noCompatibleVoice);
					} else {
						this.commonErrors.push(SettingsError.noVoice);
					}
				}
			});
		}

		// samsung voice
		if (
			(this.globalService.isCordova && this.ttsService._selectedVoiceCordova.engine === "samsung") ||
			(!this.globalService.isCordova &&
				this.ttsService.allVoices &&
				this.ttsService.allVoices.length > 0 &&
				(this.ttsService.allVoices[0].voiceURI === "moz-tts:android:eng_USA" ||
					this.ttsService.allVoices[0].voiceURI === "moz-tts:android:fra_FRA"))
		) {
			this.commonErrors.push(SettingsError.samsungVoice);
		}

		// no microphone
		if (this.sttService.micCheckDone && this.sttService.noMicrophone) {
			this.commonErrors.push(SettingsError.noMicrophone);
		} else {
			this.sttService.startRecording();
			await AppUtils.timeOut(1000);
			this.sttService.stopRecording();

			if (this.globalService.isCordova && typeof audioinput !== "undefined") {
				await new Promise<void>(resolve => {
					try {
						audioinput.checkMicrophonePermission(hasPermission => {
							if (!hasPermission) {
								this.commonErrors.push(SettingsError.noMicPermission);
							}
							resolve();
						});
					} catch (e) {
						console.error("error on checkMicrophonePermission", e);
						resolve();
					}
				});
			} else {
				if (this.sttService.noMicrophone) {
					this.commonErrors.push(SettingsError.noMicrophone);
				}
			}
		}

		// RAM if low perf mode not already enable
		if (!this.globalService.lowPerformanceMode && navigator.deviceMemory < 4) {
			if (navigator.deviceMemory < 3) {
				this.globalService._lowPerformanceMode = true;
				this.globalService.lowPerformanceModeSetStorageValue();
				this.commonErrors.push(SettingsError.veryLowRAM);
			} else {
				this.commonErrors.push(SettingsError.lowRAM);
			}
		}
	}
	toggleMuteSounds(bool) {
		this.globalService.toggleMuteSounds(bool);
		if (this.changeDetector) {
			this.changeDetector.detectChanges();
		} else if (this.changeD) {
			this.changeD.detectChanges();
		}
	}

	toggleMuteFxs(bool) {
		this.globalService.toggleMuteFxs(bool);
		if (this.changeDetector) {
			this.changeDetector.detectChanges();
		} else if (this.changeD) {
			this.changeD.detectChanges();
		}
	}

	toggleMuteMusic(bool) {
		this.globalService.toggleMuteMusic(bool);
		if (this.changeDetector) {
			this.changeDetector.detectChanges();
		} else if (this.changeD) {
			this.changeD.detectChanges();
		}
	}

	dicteeHelp() {
		if (this.changeD) {
			this.changeD.detectChanges();
		}
		console.log("string replacer : " + this.ttsService.replacer);

		this.ttsService
			.playTTS(
				$localize`Le mode dictée accentue la séparation entre les mots sur la page d’enregistrement ` +
					this.ttsService.replacer +
					$localize` Par exemple ` +
					this.ttsService.replacer +
					$localize`2 plus 2 egal 4` +
					this.ttsService.replacer +
					$localize`donnera.`,
				true,
				false
			)
			.then(() => this.ttsService.playTTS($localize`2 plus 2 egal 4 `, null, true));
	}

	disconnectUser() {
		this.globalService.displayVoiceSettingsStatus = false;
		this.accountImplService.disconnectUser();
		if (this.changeDetector) {
			this.changeDetector.detectChanges();
		} else if (this.changeD) {
			this.changeD.detectChanges();
		}
		this.closeSettings();
	}

	changeVoice(): void {
		this.ttsService.changeVoice();
		if (this.changeD) {
			this.changeD.detectChanges();
		}
		this.environment.kidaia
			? this.ttsService.playTTS($localize`:tts:Bienvenue sur Kidaia`)
			: this.ttsService.playTTS($localize`:tts:Bienvenue sur Mathia`);
	}

	arrayOne(n: number): any[] {
		return Array(n);
	}

	/**
	 * App Idle EventSubscription
	 */
	initAppIdleSubscription() {
		if (this.appIdleSubscription) {
			this.appIdleSubscription.unsubscribe();
			this.appIdleSubscription = null;
		}
		this.appIdleSubscription = this.globalService.appIdleEvent.subscribe({
			next: async v => {
				if (v) {
					await this.ttsService.killSpeech();
				}
				console.log("appEvent", v);
				this.setAppIdle(v);
			}
		});
	}

	/**
	 * killSpeech on appIdle
	 * @todo manage own protectedTTS to relaunch scenario and prevent conflict with page
	 */
	async setAppIdle(idle: boolean): Promise<void> {
		return new Promise(async (resolve, reject) => {
			if (idle) {
				await this.ttsService.killSpeech();
				this.appIdle = true;
				resolve();
			} else if (!idle && !this.globalService.waitingLandscapeMode) {
				// ON RESUME
				if (this.appIdleTimeOut) {
					clearTimeout(this.appIdleTimeOut);
				}
				this.appIdleTimeOut = setTimeout(async () => {
					AppUtils.debug("back from idle");
					this.appIdle = false;
					resolve();
				}, 500);
			}
		});
	}

	/**
	 * AppIdle Event Unsubscription
	 */
	unsubscribeinitAppIdleSubscription() {
		if (this.appIdleSubscription) {
			this.appIdleSubscription.unsubscribe();
			this.appIdleSubscription = null;
			console.log("%cappIdleSubscription unsubscribed", "background: yellow; color: black");
		}
	}

	ngOnDestroy() {
		this.unsubscribeinitAppIdleSubscription();
		if (this.rasaSubscription) {
			this.rasaSubscription.unsubscribe();
		}
	}

	async closeSettings() {
		if (this.menuTabItemSelected.menu === MenuTabItems.parametres) {
			this.globalService.storySettingOpened = true;
		}

		// if (!this.globalService.isPageOse) {
			if (this.firstTimeParamSelection) {
				// first activity settings selection time before landing on the activity page
				this.checkNeedValidateActivityParamChanges();
				this.globalService.launchStoryActivity.emit();
			} else if (this.globalService.storySettingOpened) {
				this.globalService.storySettingOpened = false;
				const isValidParam = this.checkNeedValidateActivityParamChanges();
				if (!isValidParam) {
					this.displayChangeParamAlert = true;
					if (this.changeD) {
						this.changeD.detectChanges();
					}
					return;
				}
			}
		// }

		if (!this.statusChoiceConfig && this.rgpdService.redirectionUrl) {
			// first connexion no any choice made for config
			this.router.navigateByUrl(this.rgpdService.redirectionUrl);
		}
		this.statusChoiceConfig = null;
		this.globalService.displayVoiceSettingsAnimate = true;
		// if (settingsClose) {
		this.rgpdService.redirectionUrl = "";
		
		this.globalService.lowPerformanceModePlayTTS =
			this.isTuto =
			this.globalService.blueLightFilterPlayTTS =
			this.ttsService.dicteemodePlayTTS =
			this.ttsService.menusMutedPlayTTS =
			this.ttsService.rateRatioPlayTTS =
			this.globalService.displayVoiceSettingsStatus =
			this.globalService.storySettingOpened =
				false;
		// if (this.sttService.state.recording) {
		// 	this.stopVoiceDetection();
		// }
		// save localStor rate ratio :
		this.ttsService.rateRatioSetStorageValue();
		// save localStor dictee mode :
		this.ttsService.dicteeModeSetStorageValue();
		// save voice engine :
		this.ttsService.voiceSelectedSetStorageValue();
		// blue light filter:
		this.globalService.blueLightFilterSetStorageValue();
		// mute mode:
		this.ttsService.menusMutedSetStorageValue();
		// low perf mode:
		this.globalService.lowPerformanceModeSetStorageValue();
		// voice volume:
		this.globalService.soundsVolumeSetStorageValue();
		// fxs volume
		this.globalService.fxsVolumeSetStorageValue();
		if (this.globalService.mathiaSpeechRunning) {
			await this.scenario.skipMathiaSpeechSequence(true);
		}
		// music volume:
		// this.globalService.babylonMusicVolumeSetStorageValue();
		if (this.ttsService.rateRatioSliderTimeOut) {
			clearTimeout(this.ttsService.rateRatioSliderTimeOut);
		}
		if (!this.globalService.onActivityPage) {
			await this.ttsService.killSpeech();
		}
		if (this.globalService.menuOpenEvent) {
			this.globalService.menuOpenEvent.next({ open: false, target: MenuEvents.Voice });
		}

		if (this.changeD) {
			this.changeD.detectChanges();
		}

		this.firstTimeParamSelection = false;
	}

	/**
	 * Tab selection
	 * @param menuSelected tab item selected
	 */
	async selectedTabHelp(menuSelected: MenuTabItems) {
		const currMenuSelected = this.wizardTabItems.find(item => item.menu === menuSelected);
		if (this.menuTabItemSelected && this.menuTabItemSelected.menu !== currMenuSelected.menu) {
			// this.getHelp = false;
			this.displayChangeParamAlert = false;
			if (this.sttService.state.recording) {
				this.stopVoiceDetection();
			}

			this.menuTabItemSelected = currMenuSelected;
			if (currMenuSelected.menu === MenuTabItems.parametres) {
				this.globalService.storySettingOpened = true;
				this.changeParamTitleDisplayStyle();
				if (!this.globalService.firstLaunchStoryActivity || !this.globalService.onActivityPage) {
					this.globalService.menuStorySettingOpenedEvent.next(true);
				}
			}

			// chargement de l'aide en fonction des paramètres présent
			if (currMenuSelected.menu === MenuTabItems.parametres) {
				// this.changeParamTitleDisplayStyle();
				if (!currMenuSelected.helpPhrases) {
					await new Promise<void>(resolve => {
						this.activitySettings.onReady.subscribe(() => {
							resolve();
						});
					});
				}
				this.menuTabItemSelected.helpPhrases = Object.values(
					ScenarioSettings.getActivityParamsTutorialPhrases(this.activitySettings.generalParams) as any
				);
			}
			if (this.changeD) {
				this.changeD.detectChanges();
			}
		}
		if (this.globalService.isMobile && (menuSelected === MenuTabItems.liensUtiles || menuSelected === MenuTabItems.feedback)) {
			this.getHelp = false;
		}
	}

	handleHelp() {
		this.getHelp = !this.getHelp;
		if (this.menuTabItemSelected.menu === MenuTabItems.parametres) {
			// this.changeParamTitleDisplayStyle();
			this.menuTabItemSelected.helpPhrases = Object.values(
				ScenarioSettings.getActivityParamsTutorialPhrases(this.activitySettings.generalParams) as any
			);
		}
	}
	nextTab() {
		if (this.globalService.isMobile) {
			this.getHelp = false;
		} else {
			this.getHelp = true;
		}
		const currMenuSelected = this.wizardTabItems.find(item => item.menu === this.menuTabItemSelected.menu);
		let i = this.wizardTabItems.indexOf(currMenuSelected);

		let settingsClosed = false;
		if (i === this.wizardTabItems.length - 1) {
			if (this.firstLaunch) {
				this.firstLaunch = false;
				this.closeSettings();
				settingsClosed = true;
			} else {
				i = -1;
			}
		}
		if(!settingsClosed){
			this.selectedTabHelp(this.wizardTabItems[i + 1].menu);
		}
	}

	/**
	 * Activity settings display mode depending on help
	 */
	changeParamTitleDisplayStyle() {
		const displayHorizontal = paramList => {
			//this is call from an ngAfterviewInit in some way (where exactly ...) 
			//and sync with param to avoid afterChange error put a promise to delay on next cycle
			AppUtils.timeOut(0).then(()=>{			
				paramList.toArray().forEach((paramComponent: ParamComponent) => {
					paramComponent.displayParamToRow = true;
				});
			});
		};

		this.activitySettings.paramContainer.changes.subscribe(paramList => {
			displayHorizontal(paramList);
		});
	}

	/**
	 * Wizard skip to next tab tuto
	 */
	skipNextTuto() {
		const selectedIndex = this.wizardTabItems.findIndex(items => items.menu === this.menuTabItemSelected.menu);
		if (this.wizardTabItems[selectedIndex + 1]) {
			this.selectedTabHelp(this.wizardTabItems[selectedIndex + 1].menu);
		}
	}

	/**
	 * Confirm or not (holo,remote host) changes
	 * @param $event boolean false user remove his changes
	 */
	alertResponse($event?: boolean) {
		if (!$event) {
			// restore changes dismiss changes
			this.activitySettings.restoreGeneralParams();
			this.displayChangeParamAlert = false;
			this.globalService.storySettingOpened = false;
			this.closeSettings();
		} else {
			// changes confirmed so set values
			const holoValue = this.activitySettings.getParamByName("holo")?.value;
			const remoteValue = this.activitySettings.getParamByName("remoteHost")?.value;

			if (holoValue) {
				this.cabriService.currentActivity.setParamValue("holo", holoValue);
			}
			if (remoteValue) {
				this.cabriService.currentActivity.setParamValue("remoteHost", remoteValue);
			}
			this.cabriService.currentActivity.buildVariables();
			this.displayChangeParamAlert = false;
			// reload after setting params
			this.reloadPage();
		}

		if (this.changeD) {
			this.changeD.detectChanges();
		}
	}

	reloadPage() {
		if (this.globalService.isCordova && this.globalService.isAndroid) {
			this.globalService.reloadNavigateAndroid();
		} else {
			window.location.reload();
		}
	}

	/**
	 * Change and save user activity settings selected params
	 * @returns boolean false so display alert
	 */
	checkNeedValidateActivityParamChanges() {
		// this.audioService.playSelectSound();
		if (this.activitySettings) {
			this.globalService.storySettingChoiceMade = true;
			if (this.activitySettings.newParamSelected) {
				// new selection of param made so save the new's one
				this.activitySettings.saveSettingsParam();
				if (this.globalService.onActivityPage && !this.globalService.isPageOse) {
					// Change param made during the activity
					if (this.activitySettings.needWatchOutParamChanges) {
						return false;
					} else {
						this.activitySettings.changeParamsDuringActivity();
					}
				}
				this.displayChangeParamAlert = false;
				if (this.changeD) {
					this.changeD.detectChanges();
				}
			}
		}

		return true;
	}

	switchSettingsParams($mirror: boolean) {
		this.globalService.switchSettingParam.next($mirror);
	}
	launchStoryActivityEmitter() {
		this.globalService.launchStoryActivity.emit();
	}

	/**
	 * Analyser vizualisation Web Audio API
	 * https://developer.mozilla.org/fr/docs/Web/API/Web_Audio_API/Visualizations_with_Web_Audio_API
	 */
	async activateAnalyser() {
		const createGainNode = () => {
			if (this.sttService.audioContext?.createGain && !this.haveGain) {
				if (!this.gainNode) {
					this.gainNode = this.sttService.audioContext.createGain();
				}
				this.haveGain = true;
				console.log("Gain node is available");
				this.sttService.mediaStreamSource.disconnect(this.sttService.processor);
				this.sttService.mediaStreamSource.connect(this.gainNode);
				this.gainNode.connect(this.sttService.processor);
				return true;
			} else if (this.haveGain) {
				return true;
			} else {
				console.log("Your system not handle gain node");
				return false;
			}
		};

		const updateWebAnalyser = () => {
			// Change per frame
			this.analyser.getByteFrequencyData(this.arrayBytes);

			let sum = 0;
			const len = this.arrayBytes.length;
			for (let i = 0; i < len; i++) {
				sum += this.arrayBytes[i];
			}
			this.volumeAudioVisualization = sum / len;

			this.analyzerRef = window.requestAnimationFrame(updateWebAnalyser);
		};

		if (this.globalService.isCordova) {
			const tempDirectory = this.platform.is("ios") ? this.file.tempDirectory : this.file.externalCacheDirectory;
			const filetype = this.platform.is("ios") ? "MathiaTempRecord.wav" : "MathiaTempRecord.aac";
			// const filePath = await this.file.createFile(tempDirectory, filetype, true);
			this.mediaAudioFile = this.media.create(tempDirectory.replace(/^file:\/\//, "") + filetype);
			this.mediaAudioFile.onStatusUpdate.subscribe({
				next: (status: MEDIA_STATUS) => {
					console.log("sttusss", status);
					if (status === 2) {
						// Record is running
					}
				}
			});

			this.mediaAudioFile.onSuccess.subscribe(() => {
				console.log("Action is successful");
			});

			this.mediaAudioFile.onError.subscribe(error => {
				console.log("Error!", error);
			});
			this.mediaAudioFile.release();
			this.mediaAudioFile.startRecord();

			this.analyzerRef = setInterval(() => {
				if (this.mediaAudioFile && typeof this.mediaAudioFile.getCurrentAmplitude !== "undefined") {
					this.mediaAudioFile
						.getCurrentAmplitude()
						.then(waveForm => {
							this.volumeAudioVisualization = waveForm * 100;
							// console.log("this.volumeAudioVisualization", this.volumeAudioVisualization);
						})
						.catch(err => {
							console.log("error wave", err);
						});
				}
			}, 20);
		} else {
			if (createGainNode()) {
				if (this.sttService.audioContext) {
					this.analyser = this.sttService.audioContext.createAnalyser();
					this.analyser.fftSize = 1024;
					this.sttService.mediaStreamSource.connect(this.analyser);
					this.gainNode.connect(this.analyser);
					const tampon = this.analyser.frequencyBinCount;
					this.arrayBytes = new Uint8Array(tampon);
					this.analyser.getByteTimeDomainData(this.arrayBytes);
					updateWebAnalyser();
				}
			} else {
				console.log("error no gainNode");
			}
		}
	}

	async stopVoiceDetection() {
		await this.sttService.stopRecordingWRasa();
		if (!this.globalService.isCordova) {
			window.cancelAnimationFrame(this.analyzerRef);
			if (this.sttService.state.recognitionOutput) {
				this.sttService.state.recognitionOutput = new Array();
			}
		} else {
			this.mediaAudioFile.stopRecord();
			clearInterval(this.analyzerRef);
		}

		this.analyzerRef = null;
		this.needRecording = false;
		this.volumeAudioVisualization = 0;
	}

	public async startVoiceDetection() {
		await this.sttService.startRecording(true, { locale: AppLanguage.FR });
		if (!this.sttService.noMicrophone) {
			this.activateAnalyser();
		} else {
			await this.scenario.readText($localize`Tu n’as pas le micro branché.`, true);
		}
	}

	/**
	 * Read tts in help mode
	 *
	 * @param titleText string
	 * @param titleDescription Array of description or simple text
	 */
	async readSTTParamExplanation(titleText: string, titleDescription: Array<any> | string) {
		if (titleText?.length > 0 && titleDescription?.length > 0) {
			if (this.globalService.mathiaSpeechRunning) {
				await this.scenario.skipMathiaSpeechSequence(true);
			}
			let description = "";
			if (Array.isArray(titleDescription)) {
				if (this.menuTabItemSelected.menu === MenuTabItems.parametres) {
					titleDescription.forEach(param => {
						const dotDesc = param.text.charAt(titleText.length - 1) !== "." ? "." : "";
						description += param.text + dotDesc;
					});
				} else {
					console.log("Add for other menuTabItems if needed");
				}
			} else {
				description = titleDescription;
			}
			const dotTitle = titleText.charAt(titleText.length - 1) !== "." ? "." : "";
			const phraseString = `${titleText}${dotTitle}${description}`;
			await this.scenario.readText(phraseString, true);
		}
	}

	async validateConfigStatus(configStatus: WizzardConfig) {
		this.statusChoiceConfig = configStatus;

		// security to clean playtts
		this.ttsService.currentTTSPCallback = null;
		this.ttsService.playTTSPWaiting = false;
		this.ttsService.currentTTSPText = null;

		if (this.globalService.mathiaSpeechRunning) {
			await this.scenario.skipMathiaSpeechSequence(true);
		}
		if (configStatus === WizzardConfig.start) {
			// this.isTuto = true;
			// this.scenario = new ScenarioSettings(this.accountService, this.globalService, this, this.changeD, this.ttsService);
			// this.scenario.launchSettingsTuto();
		} else {
			if (configStatus === WizzardConfig.later) {
				sessionStorage.setItem("askConfigLaterTemp", "true");
				localStorage.setItem("askConfigLater", "true");
			} else {
				if (this.rgpdService.redirectionUrl) {
					// for the first connection
					this.router.navigateByUrl(this.rgpdService.redirectionUrl).then(() => {
						this.closeSettings();
					});
				} else {
					this.closeSettings();
				}
			}
		}

		// got to next tab
		this.selectedTabHelp(this.wizardTabItems[1].menu);

		if (configStatus !== WizzardConfig.later) {
			sessionStorage.removeItem("askConfigLaterTemp");
			localStorage.removeItem("askConfigLater");
		}
	}

	async manageVoiceTesting() {
		this.needRecording = !this.needRecording;
		if (!this.needRecording || this.sttService.state.recording) {
			await this.stopVoiceDetection();
		} else {
			await this.startVoiceDetection();
			let rasaStopTempAnswering = false;
			this.rasaSubscription = this.sttService.rasaRecognition.subscribe(async data => {
				if (data?.text?.length > 0) {
					if (!rasaStopTempAnswering) {
						rasaStopTempAnswering = true;
						await this.scenario.readText($localize`Le micro fonctionne bien.`, true);
						await AppUtils.timeOut(1000);
						await this.stopVoiceDetection();
					}
				}
			});
		}
	}

	sendAnalytic(event: ToggleCustomEvent) {
		if (event.type === "ionChange" || event.type === "ionKnobMoveEnd") {
			const response = {} as any;
			const htmlElement = event.composedPath().find((element: HTMLElement) => {
				return element.className?.includes("settingWrapper");
			}) as HTMLElement;
			if (htmlElement) {
				response.name = htmlElement.innerText;
			}
			if (event.detail?.checked) {
				response.check = event.detail.checked ? 1 : 0;
			} else {
				response.value = event.detail.value;
			}
			// console.warn("Analytic setting ",response);
			this.analyticService.sendAnalytics("Settings", response);
		}
		// voix
		else if (event.type === "change") {
			const response = {} as any;
			response.name = "Voix";
			const htmlElementListeVoix = event.composedPath().find((element: HTMLElement) => {
				return element.className?.includes("rangeItem");
			}) as HTMLElement;
			if (htmlElementListeVoix) {
				response.listeVoix = htmlElementListeVoix.innerText;
			}
			const htmlElementVoix = event.composedPath().find((element: HTMLElement) => {
				return element.className?.includes("selectedVoice");
			}) as HTMLSelectElement;
			if (htmlElementVoix) {
				response.Voix = htmlElementVoix.selectedOptions[0].outerText;
			}
			response.cordova = this.ttsService.useTTSCordova ? 1 : 0;
			// console.warn("Analytic setting ",response);
			this.analyticService.sendAnalytics("Settings", response);
		}
	}

	public async openLink(url: string) {
		console.log(url);
		if (this.globalService.isDesktop) {
			window.open(url, "_blank");
		} else {
			const modal = await this.modalController.create({
				component: IframeModalComponent,
				cssClass: "modal-inscription",
				componentProps: { myUrl: url }
			});
			return await modal.present();
		}
	}
}
